/* eslint-disable no-prototype-builtins */
/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class Carousel extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-carousel-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    this.NS = 'cmp';
    this.IS = 'ace-carousel';

    this.keyCodes = {
      SPACE: 32,
      END: 35,
      HOME: 36,
      ARROW_LEFT: 37,
      ARROW_UP: 38,
      ARROW_RIGHT: 39,
      ARROW_DOWN: 40
    };

    this.SCROLL_SENSIBILITY_RIGHT = 30;
    this.SCROLL_SENSIBILITY_LEFT = -30; // Keep negative values

    this.selectors = {
      self: `[data-${this.NS}-is="${this.IS}"]`
    };

    this.properties = {
      /**
             * Carousel Type - Determines the number of elements to be displayed in the slide
             *
             * @memberof Carousel
             * @type {Number}
             * @default 5000
             */
      carouselType: {
        default: 5,
        transform: (value) => {
          value = parseFloat(value);
          return !isNaN(value) ? value : null;
        }
      }
    };
    this.onDocumentReady();
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.PageName = this.datalayerValues.pageName.split('::');
    this.latamCarousel = this.componentHost?.querySelectorAll('.ace-carousel__content .ace-carousel__item ');
    if (this.PageName[1] == 'all signature' ) {
      this.latamTracking();
    }
  }

  latamTracking() {
    this.latamCarousel?.forEach((item) =>{
      item?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.blocInteraction = item?.querySelector('.cmp-teaser__content .cmp-teaser__pretitle a')?.innerHTML.trim();
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: 'platform access',
            bloc_interaction: this.blocInteraction
          }
        };
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(datasEvent);
      }
      );
    });
  }


  /**
     * Carousel
     *
     * @class Carousel
     * @classdesc An interactive Carousel component for navigating a list of generic items
     * @param {CarouselConfig} config The Carousel configuration
     */
  carousel(config) {
    /**
         * Caches the Carousel elements as defined via the {@code data-carousel-hook="ELEMENT_NAME"} markup API
         *
         * @private
         * @param {HTMLElement} wrapper The Carousel wrapper element
         */
    const cacheElements = (wrapper) => {
      this._elements = {};
      this._elements.self = wrapper;
      const hooks = this._elements.self.querySelectorAll(
        `[data-${this.NS}-hook-${this.IS}]`
      );
      hooks.forEach((hook) => {
        const key = hook.dataset.cmpHookAceCarousel;
        if (this._elements[key]) {
          if (!Array.isArray(this._elements[key])) {
            const tmp = this._elements[key];
            this._elements[key] = [tmp];
          }
          this._elements[key].push(hook);
        } else {
          this._elements[key] = hook;
        }
      });
    };

    /**
         * Sets up properties for the Carousel based on the passed options.
         *
         * @private
         * @param {Object} options The Carousel options
         */
    const setupProperties = (options) => {
      this._properties = {};

      for (const key in this.properties) {
        if (this.properties.hasOwnProperty(key)) {
          const property = this.properties[key];
          let value = null;

          if (options && options[key] !== null) {
            value = options[key];

            // transform the provided option
            if (property && typeof property.transform === 'function') {
              value = property.transform(value);
            }
          }

          if (value === null) {
            // value still null, take the property default
            value = this.properties[key].default;
          }

          this._properties[key] = value;
        }
      }
    };

    /**
         * Refreshes the item markup based on the current {@code Carousel#_active} index
         *
         * @private
         */
    const accessibilty = () => {
      const content = this._contentEl;
      const itemActive = content.querySelectorAll('.ace-carousel__item');
      const maxStep = (this._activeItemCount * this._active - 1);
      const minStep = (this._activeItemCount * this._active - this._activeItemCount);

      itemActive.forEach((item, index) => {
        const getLink = item.querySelector('.cmp-teaser__action--link');
        if (index >= minStep && index <= maxStep) {
          if (getLink) {
            getLink.setAttribute('tabindex', '0');
          }
          item.closest('.ace-carousel__item').classList.remove('hide');
        } else {
          if (getLink) {
            getLink.setAttribute('tabindex', '-1');
          }
          item.closest('.ace-carousel__item').classList.add('hide');
        }
      });
    };

    const refreshActive = () => {
      const tempItems = [];
      const { item } = this._elements;

      if (item) {
        if (Array.isArray(item)) {
          item.forEach((subItem) => {
            if (window.getComputedStyle(subItem).display !== 'none') {
              tempItems.push(subItem);
            }
          });
        } else {
          if (window.getComputedStyle(item).display !== 'none') {
            tempItems.push(item);
          }
        }
      }
      const content = this._elements.self.querySelector(
        '.ace-carousel__content'
      );
      const actions = this._elements.self.querySelector(
        '.ace-carousel__actions'
      );
      const actionContent = this._elements.self.querySelector(
        '.ace-carousel__action-content'
      );

      if (tempItems && actions) {
        actions.style.display = 'flex';

        const elementWidth = (100 / this._activeItemCount);
        content.scrollLeft = 0;
        this._actions = actions;
        this._actionContentEl = actionContent;
        this._elementWidth = elementWidth;

        this._contentEl = content;
        if (Array.isArray(tempItems)) {
          const totalItems = tempItems.length;
          const carouselIndexSteps = Math.ceil(
            totalItems / Math.floor(this._activeItemCount)
          );
          this._carouselIndexSteps = carouselIndexSteps;
          this._active = 1;
          this._elements.self.querySelector(
            '.ace-carousel__action--previous'
          ).setAttribute('disabled', '');
          this._elements.self.querySelector(
            '.ace-carousel__action--previous'
          ).setAttribute('aria-label', 'Previous button is disabled');
          this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);
          // Hide actions (controls) when only one slide present

          if (this._active === carouselIndexSteps) {
            this._actions.style.display = 'none';
          } else {
            this._actionContentEl.innerHTML = `${this._active}/${carouselIndexSteps}`;
          }

          tempItems.forEach((item) => {
            item.style.width = `${elementWidth}%`;
          });
        } else {
          tempItems.style.width = `${elementWidth}%`;
          this._actions.style.display = 'none';
        }
      }

      accessibilty();
    };

    const getNextIndex = () => {
      this._elements.self.querySelector(
        '.ace-carousel__action--previous'
      ).removeAttribute('disabled');
      if (this._active + 1 > this._carouselIndexSteps) {
        this._active = 1;
      } else {
        this._active += 1;
      }
    };

    const getPreviousIndex = () => {
      this._elements.self.querySelector(
        '.ace-carousel__action--next'
      ).removeAttribute('disabled');
      if (this._active - 1 < 1) {
        this._active = this._carouselIndexSteps;
      } else {
        this._active -= 1;
      }
    };

    /**
         * Navigates to the item at the provided index
         *
         * @private
         * @param {Number} index The index of the item to navigate to
         */
    const navigate = () => {
      const index = (this._active - 1) * Math.floor(this._activeItemCount);
      const element = this._elements.item[index];
      const content = this._contentEl;
      content.scrollLeft = element ? element.offsetLeft : '';
      this._actionContentEl.setAttribute('data-step', this._active);
      this._actionContentEl.innerHTML = `${this._active}/${this._carouselIndexSteps}`;
      if (this._active === 1) {
        this._elements.self.querySelector(
          '.ace-carousel__action--previous'
        ).setAttribute('disabled', '');
        this._elements.self.querySelector(
          '.ace-carousel__action--previous'
        ).setAttribute('aria-label', 'Previous button is disabled');
        this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');
      } else if (this._active === this._carouselIndexSteps) {
        this._elements.self.querySelector(
          '.ace-carousel__action--next'
        ).setAttribute('disabled', '');
        this._elements.self.querySelector(
          '.ace-carousel__action--next'
        ).setAttribute('aria-label', 'Next button is disabled');
        this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', `Previous, go to the ${this._active - 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--previous').removeAttribute('disabled');
      } else {
        this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', `Previous, go to the ${this._active - 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--previous').removeAttribute('disabled');
        this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');
      }
      accessibilty();
    };

    /**
         * Navigates to the next slides
         *
         * @private
         */
    const navigateToNext = () => {
      getNextIndex();
      navigate();
    };

    /**
         * Navigates to the previous slides
         *
         * @private
         */
    const navigateToPrevious = () => {
      getPreviousIndex();
      navigate();
    };

    /**
         * Handles carousel keydown events
         *
         * @private
         * @param {Object} event The keydown event
         */
    const onKeyDown = (event) => {
      const index = this._active;
      const elements = this._elements.indicator;
      if (elements) {
        const lastIndex = elements.length - 1;

        switch (event.keyCode) {
          case this.keyCodes.ARROW_LEFT:
          case this.keyCodes.ARROW_UP:
            event.preventDefault();
            if (index > 0) {
              this.navigateAndFocusIndicator(index - 1);
            }
            break;
          case this.keyCodes.ARROW_RIGHT:
          case this.keyCodes.ARROW_DOWN:
            event.preventDefault();
            if (index < lastIndex) {
              this.navigateAndFocusIndicator(index + 1);
            }
            break;
          case this.keyCodes.HOME:
            event.preventDefault();
            this.navigateAndFocusIndicator(0);
            break;
          case this.keyCodes.END:
            event.preventDefault();
            this.navigateAndFocusIndicator(lastIndex);
            break;
          default:
            return;
        }
      }
    };
    /*
         * Handles carousel start of swype events
         *
         * @private
         * @param {Object} event The keydown event
         */
    const onTouchStart = (e) => {
      this._initialX = e.touches[0].clientX;
      this._initialY = e.touches[0].clientY;
      e.preventDefault();
    };

    /*
           * Handles carousel swype move events to handle navigation
           *
           * @private
           * @param {Object} event The keydown event
           */
    const onTouchMove = (e) => {
      if (!this._initialX) {
        return;
      }

      if (!this._initialY) {
        return;
      }

      const currentX = e.touches[0].clientX;
      const currentY = e.touches[0].clientY;

      const diffX = this._initialX - currentX;
      const diffY = this._initialY - currentY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        // sliding horizontally
        if (diffX > 0) {
          // swiped left
          navigateToNext();
        } else {
          // swiped right
          navigateToPrevious();
        }
      }

      this._initialX = null;
      this._initialY = null;

      e.preventDefault();
    };

    /**
         * Add event listeners for swype events
         *
         * @private
         */
    const bindSwypeEvents = () => {
      this._elements.item.forEach((item) => {
        item.addEventListener(CoreJS.DomEventConstants.TOUCH_START, onTouchStart.bind(this), { passive: true });
        item.addEventListener(CoreJS.DomEventConstants.TOUCH_MOVE, onTouchMove.bind(this), { passive: true });
      });
    };

    /**
         * remove event listeners for swype events
         *
         * @private
         */
    const unbindSwypeEvents = () => {
      const elements = this._elements.item;
      if (elements) {
        if (Array.isArray(elements)) {
          elements.forEach((item) => {
            item.removeEventListener(CoreJS.DomEventConstants.TOUCH_START, onTouchStart);
            item.removeEventListener(CoreJS.DomEventConstants.TOUCH_MOVE, onTouchMove);
          });
        } else {
          elements.removeEventListener(CoreJS.DomEventConstants.TOUCH_START, onTouchStart);
          elements.removeEventListener(CoreJS.DomEventConstants.TOUCH_MOVE, onTouchMove);
        }
      }
    };

    /*
           * Handles carousel trackpad move events to handle navigation
           *
           * @private
           * @param {Object} event The keydown event
           */
    const onMouseWheel = (e) => {
      if (e.wheelDeltaX > this.SCROLL_SENSIBILITY_RIGHT) {
        // Right scroll
        e.preventDefault();
        navigateToPrevious();
      } else if (e.wheelDeltaX < this.SCROLL_SENSIBILITY_LEFT) {
        // Left scroll
        e.preventDefault();
        navigateToNext();
      }
    };

    /**
         * Binds Carousel event handling
         *
         * @private
         */
    const bindEvents = () => {
      if (this._elements.previous) {
        this._elements.previous.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          navigateToPrevious();
        });
      }
      if (this._elements.next) {
        this._elements.next.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          navigateToNext();
        });
      }
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        document.addEventListener(CoreJS.DomEventConstants.MOUSE_WHEEL, onMouseWheel.bind(this));
        // Firefox only
        document.addEventListener(CoreJS.DomEventConstants.DOM_MOUSE_SCROLL, onMouseWheel.bind(this));
      }
      this._elements.self.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, onKeyDown);
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        bindSwypeEvents();
      }
    };
    /**
         * Filter Carousel event handling
         * @private
         */
    const filterControl = () => {
      const filters = this._elements.filter;
      if (filters) {
        if (Array.isArray(filters)) {
          filters.forEach((item) => {
            item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
              refreshActive();
            });
          });
        } else {
          filters.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            refreshActive();
          });
        }
      }
    };

    /**
         * Handles windows resizing for responsive behavior
         *
         * @private
         * @param {Object} event The keydown event
         */
    const onWindowResize = () => {
      this._elements.self.querySelector(
        '.ace-carousel__action--next'
      ).removeAttribute('disabled');
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        bindSwypeEvents();
        this._activeItemCount = 1;
      } else if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        unbindSwypeEvents();
        this._activeItemCount = 2;
      } else {
        unbindSwypeEvents();
        this._activeItemCount = this._properties.carouselType;
      }
      refreshActive();
    };
    /**
         * Initializes the Carousel
         *
         * @private
         * @param {CarouselConfig} initialConfig The Carousel configuration
         */
    const init = (initialConfig) => {
      // prevents multiple initialization
      initialConfig.element.removeAttribute(`data-${this.NS}-is`);

      setupProperties(initialConfig.options);
      cacheElements(initialConfig.element);

      this._active = 1;
      this._activeItemCount = this._properties.carouselType;
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        this._activeItemCount = 1;
      }

      if (this._elements.item) {
        refreshActive();
        bindEvents();
        filterControl();
      }

      if (
        window.Granite &&
                window.Granite.author &&
                window.Granite.author.MessageChannel
      ) {
        window.CQ = window.CQ || {};
        window.CQ.CoreComponents = window.CQ.CoreComponents || {};
        window.CQ.CoreComponents.MESSAGE_CHANNEL =
                    window.CQ.CoreComponents.MESSAGE_CHANNEL ||
                    new window.Granite.author.MessageChannel('cqauthor', window);
        window.CQ.CoreComponents.MESSAGE_CHANNEL.subscribeRequestMessage(
          'cmp.panelcontainer',
          (message) => {
            if (
              message.data &&
                            message.data.type === 'ace-carousel' &&
                            message.data.id ===
                            this._elements.self.dataset.cmpPanelcontainerId
            ) {
              if (message.data.operation === 'navigate') {
                this._active =
                                    Math.floor(message.data.index / this._activeItemCount) + 1;
                if (this._active > this._carouselIndexSteps) {
                  this._active = this._carouselIndexSteps;
                }
                navigate();
              }
            }
          }
        );
      }

      // Responsive event handling
      window.addEventListener(CoreJS.DomEventConstants.RESIZE, onWindowResize.bind(this));
    };
    if (config && config.element) {
      init(config);
    }
  }

  /**
     * Reads options data from the Carousel wrapper element, defined via {@code data-cmp-*} data attributes
     *
     * @private
     * @param {HTMLElement} element The Carousel element to read options data from
     * @return {Object} The options read from the component data attributes
     */
  readData(element) {
    const data = element.dataset;
    const options = [];
    let capitalized = this.IS;
    capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
    const reserved = ['is', `hook${capitalized}`];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (key.indexOf(this.NS) === 0) {
          let newKey = key.slice(this.NS.length);
          newKey = newKey.charAt(0).toLowerCase() + newKey.substring(1);

          if (reserved.indexOf(newKey) === -1) {
            options[newKey] = value;
          }
        }
      }
    }
    return options;
  }

  /**
     * Document ready handler and DOM mutation observers. Initializes Carousel components as necessary.
     *
     * @private
     */
  onDocumentReady() {
    this.carousel({
      element: this.componentHost.querySelector(`.${this.IS}`),
      options: this.readData(this.componentHost.querySelector(`.${this.IS}`))
    });
  }
}
// Register the Carousel component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Carousel.CLASS_NAMESPACE, Carousel);
