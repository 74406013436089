import { TagManager } from '@accor/ace-ui-core';

export default class AllHub extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-hub';

  /** @inheritDoc */

  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.layerElements = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.layerElements)?.pageName?.split('::');
    if (this.datalayerValues[0] === 'europcar') {
      this.europcarPages();
    }
  }

  europcarPages() {
    const link = this.componentHost.querySelector('.ace-hub__content .ace-hub__content__link');
    link?.addEventListener('click', () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: 'loyalty program::partners::mobility::europcar',
          bloc_interaction: 'terms and conditions europcar',
          bloc_type: 'your rewards',
          context: 'partners mobility'
        }
      };
      // eslint-disable-next-line no-undef
      TagManager.trackEvent(datasEvent);
    });
  }
}

CoreJS.BaseComponent.registerComponent(AllHub.CLASS_NAMESPACE, AllHub, true);


