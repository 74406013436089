import { ALL_ACCOR_COM } from '../../common/constants.js'

import {
  getLang,
  attachDefinedQueryParamsToString,
} from '../../common/utils.js'

import { getAuthUrl, EnrollMode } from '../../oidc/getAuthUrl.js'

export const getTSMAuthUrl = ({
  state = 'register',
  lang = getLang(),
  origin = 'tsm',
  promoCode,
  typePC,
}) =>
  getAuthUrl({
    state,
    origin,
    lang,
    mode: EnrollMode.SecurityLink,
    redirect: attachDefinedQueryParamsToString(
      `${ALL_ACCOR_COM}/enroll-loyalty/index.${lang}.shtml#/subscribe/tsm`,
      { origin, promoCode, typePC }
    ),
  })
