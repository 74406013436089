import { addHeaderInterceptor } from './add-header-interceptor-factory'
import { generateTraceId } from '../../common/utils'
import { X_TRACEID } from '../../common/constants'

const traceidInterceptor = async axiosInstance => {
  axiosInstance.interceptors.request.use(config => {
    return addHeaderInterceptor(
      X_TRACEID,
      config.headers[X_TRACEID] || generateTraceId(),
      config
    )
  })
}

export default traceidInterceptor
