/* eslint-disable no-console */
/**
 * @author Alpana
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class SubNavContainer extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'sub-nav-container';

  /** @inheritDoc */

  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */

  initialize() {
    super.initialize();
    this.subNavContainerPushNav();
    this.subNavContainerSimpleNav();
  }
  subNavContainerPushNav() {
    const pushnavlength = this.componentHost.querySelectorAll('.sub-nav-container__push-nav .push-nav-list-item li');
    pushnavlength.forEach((item) => {
      if (item.innerHTML.trim() == '') {
        item.remove();
      }
    });
  }
  subNavContainerSimpleNav() {
    const simplenavlength = this.componentHost.querySelectorAll('.sub-nav-container__secondary-nav .simple-nav-list-item li');
    simplenavlength.forEach((item) => {
      if (item.innerHTML.trim() == '') {
        item.remove();
      }
    });
  }
}
CoreJS.BaseComponent.registerComponent(SubNavContainer.CLASS_NAMESPACE, SubNavContainer);
