export { isMobile, isWebView, isIE }

const matchUserAgent = regex => navigator.userAgent.match(regex)

const isMobile = {
  Android: () => matchUserAgent(/Android/i) && !isMobile.Windows(),
  BlackBerry: () => matchUserAgent(/BlackBerry/i),
  iOS: () => matchUserAgent(/iPhone|iPad|iPod/i),
  Opera: () => matchUserAgent(/Opera Mini/i),
  Windows: () => matchUserAgent(/IEMobile|Windows Phone/i),
  any: () =>
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows(),
  tablet: () => !!isMobile.any() && document.body.clientWidth > 768,
}

const isWebView = (global = window) => {
  const regex = new RegExp('[; ]platform=([^\\s;]*)')
  const platform = (' ' + global.document.cookie).match(regex)
  return platform && /APP-ANDROID|APP-IPHONE/.test(platform[1])
}

const isIE = (global = window) => {
  return (
    '-ms-scroll-limit' in global.document.documentElement.style &&
    '-ms-ime-align' in global.document.documentElement.style
  )
}
