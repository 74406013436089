import Cookies from 'js-cookie'

export const customEnv = Cookies.get('customer-env')

export const recEnv = [
  'int1',
  'int2',
  'int3',
  'int4',
  'rec1',
  'rec2',
  'rec3',
  'rec4',
]

export const suffixEnv = (dynamicSuffix = false) =>
  recEnv.includes(customEnv)
    ? (dynamicSuffix && customEnv) || 'rec'
    : customEnv === 'pre'
    ? 'pre'
    : ''

if (suffixEnv()) {
  var messageEnv = document.createElement('span')
  messageEnv.style.cssText +=
    'color:red;position: fixed;font-size: 25px;z-index: 9999;font-weight: bold; top: 0; right: 0;'
  messageEnv.innerHTML = `CUSTOMER-API - ${customEnv}`
  global.document.body.appendChild(messageEnv)
}

export const REC_API_KEY = 'l7xx7a9903df08b243b2bc858f7fff91ac1a'
export const PROD_API_KEY = recEnv.includes(customEnv)
  ? REC_API_KEY
  : 'l7xx8785261b2a33457db88959a8679a1307'
export const APP_ID = 'all.accor'

export const API_ACCOR_COM = suffixEnv()
  ? `https://${suffixEnv()}-api.accor.com`
  : 'https://api.accor.com'

export const SECURE_ACCOR_COM = suffixEnv()
  ? `https://${suffixEnv(true)}-secure.accor.com`
  : 'https://secure.accor.com'
export const ALL_ACCOR_COM = suffixEnv()
  ? `https://${suffixEnv(true)}-all.accor.com`
  : 'https://all.accor.com'

export const AUTHENTICATION_V2 = '/authentication/v2.0'
export const AUTH_URL = API_ACCOR_COM + AUTHENTICATION_V2 + '/authorization'
export const REFRESH_URL = API_ACCOR_COM + AUTHENTICATION_V2 + '/refresh'
export const LOGIN_URL = API_ACCOR_COM + AUTHENTICATION_V2 + '/login'

export const API_V2_URL = `${SECURE_ACCOR_COM}/web/user/v2/user`
export const API_V1_URL = `${SECURE_ACCOR_COM}/web/user/v1.0/user`

export const API_CONTACT_URL = '/customer/v1/contacts/me'

export const JWT_COOKIE_KEY = 'OAC_' + APP_ID
export const CONNECTION_COOKIE_KEY = 'OCC_' + APP_ID

export const OIDC_LANDING_URL =
  ALL_ACCOR_COM + '/enroll-loyalty/check-authent.html'

export const CREATE_USER_URL = API_ACCOR_COM + '/contacts/v1.0/user'

export const REFERENTIALS_BASE_URL =
  API_ACCOR_COM + '/referentials/v1/list?expand='

export const ALL_SIGNATURE_SUMMARY = '/subscription-cards/all-signature/summary'
export const ENROLL_LOYALTY = 'enroll-loyalty'

export const X_TRACEID = 'x-traceid'
export const ORIGIN = 'origin'
export const X_ORIGIN = 'x-origin'
export const APP_ID_PARAM_NAME = 'appId'
