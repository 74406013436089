/**
 *  *
 * @author Shanmugaraja
 * @class NavFooterList
 * @classdesc Ace NavFooterList component to inherit the base component
 * @inheritDoc
 */
export default class NavFooterList extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-navfooterlist-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Function Initialize
  initialize() {
    super.initialize();
    // Select all the elements with the classes
    this.accItem = this.componentHost?.querySelectorAll('.ace-navfooterlist-accordion__item');
    this.accButtonAll = this.componentHost?.querySelectorAll('.ace-navfooterlist-accordion__button');
    this.centerStyleList = this.componentHost.querySelector('ul.cmp-ace-list.cmp-ace-list__centered');
    // Add click event listeners to each item in the accButtonAll collection
    this.accButtonAll?.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () =>{
        this.buttonClickHandler(item);
      });
    });

    // Call centerList function
    this.centerList();
    // Call columnList function
    this.columnList();
  }

  // columnList Function
  columnList() {
    if (!this.componentHost.closest('footer') && this.centerStyleList) {
      // Add 'space-between' class to the centerStyleList element
      this.centerStyleList?.classList.add('space-between');
      const selectedList = this.centerStyleList;
      const nodesToSort = selectedList.querySelectorAll('.cmp-list__item');
      // Implementation for sorting nodesToSort
      Array.prototype.map.call(nodesToSort, (node) => {
        return {
          node: node,
          relevantText: node.querySelector('.ace-navfooter-list__item-title').textContent
        };
      }).sort((sourceList, destinationList) => {
        return sourceList.relevantText.localeCompare(destinationList.relevantText);
      }).forEach((item) => {
        if (document.title === item.node?.querySelector('.ace-navfooter-list__item-title')?.textContent) {
          item.node.classList.add('hide');
          return;
        }
        selectedList.appendChild(item.node);
      });
      this.centerStyleList.querySelector('.hide')?.remove();
    }
  }

  // centerList function
  centerList() {
    // Select elements using the document.querySelectorAll() method
    this.lastChild = document.querySelectorAll('.ace-navfooterlist-component .cmp-ace-list__centered');
    // Iterate over each item in the lastChild collection
    this.lastChild.forEach((item) => {
      // Add 'last-child' class
      item.closest('.nav-footer-list').classList.add('last-child');
    });
  }

  // buttonClickHandler function
  buttonClickHandler(item) {
    item.closest('.ace-navfooterlist-accordion__item')?.classList.toggle('active');
    if (item.closest('.ace-navfooterlist-accordion__item')?.classList.contains('active')) {
      // Set 'aria-expanded' attribute of the item to 'true'
      item.setAttribute('aria-expanded', 'true');
    } else {
      // Set 'aria-expanded' attribute of the item to 'false'
      item.setAttribute('aria-expanded', 'false');
    }
  }
}

// Register the NavFooterList component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(NavFooterList.CLASS_NAMESPACE, NavFooterList);
