import Axios from 'axios'
import { getToken } from '../oidc'
import {
  APP_ID,
  API_V1_URL,
  API_V2_URL,
  CREATE_USER_URL,
  PROD_API_KEY,
} from '../common/constants.js'
import { Enum, debouncePromiseGlobally } from '../common/utils.js'

export { Error, get, put, post }

const Error = Enum(
  'GetUserEmpty',
  'GetUserFailed',
  'UpdateUserFailed',
  'DeleteUserFailed',
  'CreateUserFailed',
  'CreateUserFailedEnrollFailed'
)

const makeHttpRequestConfig = ({ token }) => ({
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}` },
  params: { t: Date.now(), appId: APP_ID, lang: 'en' },
})

export const fixUsersAddress1Fields = user =>
  user && {
    ...user,
    ...(user.addresses && {
      addresses: user.addresses.map(address => ({
        ...address,
        address1: address.address1 || '',
      })),
    }),
  }

const makeUserGetter = () => {
  let cachedResponse = null

  const handleResponseErrors = response => {
    const { data } = response
    if (data.error) throw Error.GetUserFailed
    if (!data.user) throw Error.GetUserEmpty
    return response
  }

  return async (options = {}) => {
    const { cache, ...rest } = options
    const { token } = await getToken(rest)

    const isCachedResponseValid =
      cache !== false && cachedResponse?.token === token
    if (isCachedResponseValid) return cachedResponse

    return Axios.get(API_V2_URL, makeHttpRequestConfig({ token }))
      .then(handleResponseErrors)
      .then(({ data }) => {
        data.token = token
        cachedResponse = data
        return data
      })
  }
}

const get = debouncePromiseGlobally(makeUserGetter(), '_AccorGetUser')

const put = async options => {
  const { token } = await getToken(options)
  const user = fixUsersAddress1Fields(options?.user)

  return Axios.put(API_V1_URL, { user }, makeHttpRequestConfig({ token })).then(
    ({ data }) => {
      if (data.error) throw Error.UpdateUserFailed
      return { success: true, user: data.user }
    }
  )
}

const post = async (options, config = {}) => {
  const userInfo = fixUsersAddress1Fields(options?.user)
  const { url, apiKey } = config
  const postUserConfig = {
    headers: {
      apikey: apiKey || PROD_API_KEY,
      appId: APP_ID,
    },
    withCredentials: true,
  }

  return Axios.post(
    url || CREATE_USER_URL,
    { user: userInfo },
    postUserConfig
  ).then(({ data: { error, user } }) => {
    if (error || !user) throw Error.CreateUserFailed
    if (user.lcahMember === false) throw Error.CreateUserFailedEnrollFailed

    return { success: true, user }
  })
}
