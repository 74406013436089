/* eslint-disable no-unused-expressions */
/**
 *
 * @author Shanmugaraja
 * @class MyAccount
 * @classdesc Ace MyAccount component to inherit the base component
 * @inheritDoc
 */
export default class MyAccount extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-myaccount-component';

  /** @inheritDoc */
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.setBrandCookie();
    this.setGAEventListener();
    this.modalClose = this.componentHost.querySelectorAll('.ace-myaccount .ace-myaccount__icon');
    this.myAccountHeader = this.componentHost.querySelector('.ace-myaccount__header');
    this.myAccountButton = this.componentHost.querySelectorAll('.ace-myaccount__header> button');
    this.myAccountButton.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        item.getAttribute('aria-expanded') === 'true' ?
          this.closeModal(item) :
          this.openModal(item);
      });
      window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
        if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
          this.closeModal(item);
        }
      });
    });
    this.modalClose.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.hideDropdown(this.myAccountButton);
      });
    });
    this.setSignInLinkListener();
    this.setupMyAccount();
    this.focusLink = document.querySelector('.ace-myaccount__list:last-child li:last-child a');
    this.focusClose = document.querySelector('.ace-myaccount__icon button');
    this.myaccountFocus();
    this.windowClickHideMyAccount();
  }

  /*
       * Handler to open the Modal
      */
  openModal(item) {
    const navToggle = document.querySelector('.navbar-toggler[aria-expanded="true"]');
    if (navToggle && window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      navToggle.click();
    }
    item.setAttribute('aria-expanded', true);
    item.nextElementSibling.classList.add('show');
    document.body.classList.add('disable-scroll-myaccount');
  }

  /*
       * Handler to close the Modal
       */
  closeModal(item) {
    item.setAttribute('aria-expanded', false);
    item.nextElementSibling.classList.remove('show');
    document.body.classList.remove('disable-scroll-myaccount');
  }
  windowClickHideMyAccount() {
    window.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      if (!event.target.closest('.ace-myaccount-component')) {
        this.myAccountButton.forEach((item) => {
          item.setAttribute('aria-expanded', false);
          if (item.nextElementSibling) {
            if (item.nextElementSibling.classList.contains('show')) {
              item.nextElementSibling.classList.remove('show');
              document.body.classList.remove('disable-scroll-myaccount');
            }
          }
        });
      }
    });
  }

  /**
   *
   * handler to set brand cookie
   */
  setBrandCookie() {
    const myAccountData = document.getElementById('data-refresh');
    CoreJS.CookieHelper.createCookie('OAC_all.brand', myAccountData.dataset.brand);
  }

  /*
       * Handler to Hide the Modal
       */
  hideDropdown(myAccountButtons) {
    const allContent = this.componentHost.querySelectorAll('.ace-myaccount');
    const focusMyAccounticon = this.componentHost.querySelector('.ace-myaccount__header button');
    focusMyAccounticon.focus();
    for (const content of allContent) {
      content.classList.remove('show');
      document.body.classList.remove('disable-scroll-myaccount');
    }
    for (const myAccountButton of myAccountButtons) {
      myAccountButton.setAttribute('aria-expanded', false);
    }
  }

  /*
       * Set location cookie and redirect to link
       *
       */
  setLocationCookieAndRedirect(link) {
    CoreJS.CookieHelper.createCookie('currentLoc', window.location.pathname);
    window.location.href = link;
  }

  setSignOutLinkListener(elementId) {
    const signOutLink = document.getElementById(elementId);
    if (signOutLink) {
      signOutLink.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.setLocationCookieAndRedirect(signOutLink.getAttribute('data-href'));
      });
    }
  }
  setSignInLinkListener() {
    const signInLink = document.getElementById('cmp-my-account_login-sign-in');
    if (signInLink) {
      signInLink.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const getLinkurl = signInLink.getAttribute('data-href');
        const partnerId = 'movenpick';
        this.setLocationCookieAndRedirect(`${getLinkurl}&partner_id=${partnerId}`);
      });
    }
  }

  setupConnectedView(name, status, points, accountElement) {
    const nameTitleSection = this.componentHost.querySelector('.ace-myaccount__title');
    const statusSection = this.componentHost.querySelector('.ace-myaccount-btn-clasic .btn-desc-second');
    const pointsSection = this.componentHost.querySelector('.ace-myaccount-btn-clasic .ui-heading-03');
    nameTitleSection.innerText = name;
    const connectedmemberbgcolor = this.componentHost?.querySelector('.ace-myaccount-btn-clasic.btn-bot-margin');
    if (status) {
      statusSection.innerText = status;
      if (status == 'Silver') {
        connectedmemberbgcolor.classList.add('silver');
      } else if (status == 'Gold') {
        connectedmemberbgcolor.classList.add('gold');
      } else if (status == 'Platinum') {
        connectedmemberbgcolor.classList.add('platinum');
      } else if (status == 'Diamond') {
        connectedmemberbgcolor.classList.add('diamond');
      } else if (status == 'Limitless') {
        connectedmemberbgcolor.classList.add('limitless');
      }
    }
    if (points) {
      pointsSection.innerText = points;
    }
    accountElement.classList.remove('acc-hide');
  }
  setupDisconnectedView(memberView, nonmemberView, disconnectedView) {
    if (memberView && nonmemberView && disconnectedView) {
      memberView.remove();
      nonmemberView.remove();
      disconnectedView.classList.remove('acc-hide');
    }
  }

  setGAEventListener() {
    const joinButton = this.componentHost.querySelector('.ace-myaccount-btn.cmp-button.cmp-button--primary');
    if (joinButton) {
      joinButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        window.dataLayer.push({
          'event': 'gaevent',
          'e_category': 'navigation',
          'e_action': 'account',
          'e_label': 'discover the program'
        });
      });
    }
  }
  setupMyAccount() {
    const connectedMemberView = this.componentHost.querySelector('.ace-myaccount-connected');
    const connectedNonmemberView = this.componentHost.querySelector('.ace-myaccount-connected-nonmember');
    const disconnectedView = this.componentHost.querySelector('.ace-myaccount-disconnected');
    const oacTokenCookie = CoreJS.CookieHelper.getCookieValue('OAC_cms.accor');
    const oacAccorTokenCookie = CoreJS.CookieHelper.getCookieValue('OAC_all.accor');
    const dataRefresh = document.getElementById('data-refresh');
    if ((oacTokenCookie || oacAccorTokenCookie) && connectedMemberView && connectedNonmemberView && disconnectedView) {
      const lang = dataRefresh ? dataRefresh.getAttribute('data-language') : null;
      new CoreJS.XHRPromisifiedRequest(`/content/sling/servlets/ace/customer?language=${lang}`, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)
        .setRequestHeaders({})
        .executeRequest()
        .then((result) => {
          // Request completed
          disconnectedView.remove();
          if (!result) {
            // No response/Error, disconnect user
            window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
            window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
            window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
            window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);

            this.setupDisconnectedView(connectedMemberView, connectedNonmemberView, disconnectedView);
          } else {
            if (result.responseText === 'refresh') {
              // refresh required
              window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
              window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
              window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
              window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);

              if (dataRefresh) {
                this.setLocationCookieAndRedirect(dataRefresh.getAttribute('data-refresh-url'));
              }
            }
            try {
              const userData = JSON.parse(result.responseText);
              const name = `${userData.firstName} ${userData.lastName}`;
              if (!sessionStorage.getItem(CoreJS.StorageConstants.USER_DATA)) {
                window.dataLayer.push({
                  'event': 'authentification',
                  'e_category': 'signin',
                  'e_action': 'type',
                  'e_label': 'connect'
                });
              }
              window.sessionStorage.setItem(CoreJS.StorageConstants.USER_DATA, result.responseText);
              window.sessionStorage.setItem(CoreJS.StorageConstants.ACCESS_CODE, userData.accessCode ? userData.accessCode : '');
              window.sessionStorage.setItem(CoreJS.StorageConstants.CLIENT_CODE, userData.companySCCode ? userData.companySCCode : '');
              window.sessionStorage.setItem(CoreJS.StorageConstants.LOYALTY_CARD, userData.cardNumber ? userData.cardNumber : '');
              if (userData.status) {
                // User loyalty member, member view
                connectedNonmemberView.remove();
                window.sessionStorage.setItem('cardNumber', `${userData.cardNumber}`);
                this.setupConnectedView(name, userData.status, userData.loyaltyPoints, connectedMemberView);
                this.setSignOutLinkListener('cmp-my-account_login-sign-out');
              } else {
                // User not loyalty member, non-member view
                connectedMemberView.remove();
                window.sessionStorage.setItem('cardNumber', `${userData.cardNumber}`);
                this.setupConnectedView(name, userData.status, userData.loyaltyPoints, connectedNonmemberView);
                this.setSignOutLinkListener('cmp-my-account_login-sign-out_nonmember');
              }
            } catch (error) {
              console.error(error);
            }
          }
        })
        .catch(() => {
          window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
          window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
          window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
          window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);
          this.setupDisconnectedView(connectedMemberView, connectedNonmemberView, disconnectedView);
        });
    } else {
      window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
      window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
      window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
      window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);
      this.setupDisconnectedView(connectedMemberView, connectedNonmemberView, disconnectedView);
    }
  }

  myaccountFocus() {
    this.focusLink.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey) {
        event.preventDefault();
        this.focusClose.focus();
      }
    }, false);
    this.focusClose?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.shiftKey) {
        event.preventDefault();
        this.focusLink.focus();
      }
    }, false);
  }
}
// Register the MyAccount component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(MyAccount.CLASS_NAMESPACE, MyAccount);
