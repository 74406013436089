export default class NavigationToogle extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-navtoggle-component';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */

  initialize() {
    super.initialize();
    this.toogleNavBar = document.querySelectorAll('.navbar');
    this.languageselector = document.querySelector('.ace-languageselector');
    this.toogleNavBar.forEach((element) => {
      this.menutoggle(element);
    });
  }

  menutoggle(element) {
    this._element = element;

    const accessibilty = () => {
      const burgerMenu = this._element.querySelector('.navbar-toggler');
      const menuMobiletoogle =
                this._element.querySelector('.navbarContent');
      const html = document.querySelector('html');
      if (burgerMenu) {
        window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
          if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
            burgerMenu.setAttribute('aria-expanded', 'false');
            menuMobiletoogle.setAttribute('aria-hidden', 'true');
            html.classList.remove('fixedbyModal');
            document
              .querySelectorAll(
                '.nav-item__dropdown-toggle.active'
              )
              .forEach((active) => {
                active.classList.remove('active');
              });
          }
        });
        burgerMenu.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          if (burgerMenu.getAttribute('aria-expanded') === 'false') {
            burgerMenu.setAttribute('aria-expanded', 'true');
            menuMobiletoogle.setAttribute('aria-hidden', 'false');
            html.classList.add('fixedbyModal');
          } else {
            burgerMenu.setAttribute('aria-expanded', 'false');
            menuMobiletoogle.setAttribute('aria-hidden', 'true');
            html.classList.remove('fixedbyModal');
          }
        });
      }
    };
    accessibilty();
  }
}
CoreJS.BaseComponent.registerComponent(
  NavigationToogle.CLASS_NAMESPACE,
  NavigationToogle
);
