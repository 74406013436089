/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/**
 *
 * @author Rupali
 * @class Callout
 * @version 1.0
 * @inheritDoc
 */
export default class SocialMedia extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'socialMedia';
  static HTTP_METHOD_GET = 'GET';
  static PAGE_SIZE = '8';

  /** @inheritDoc */
  initialize() {
    this.tintApiTrigger();
  }

  tintApiTrigger() {
    this.xhr = new XMLHttpRequest();
    this.tint = this.componentHost?.getAttribute('data-team-id');
    this.tagFilter = this.componentHost?.getAttribute('data-tag');
    const socialMediaLoaded = document.querySelector('.socialMedia.cmp-socialMedia');
    const options = {
      rootMargin: '350px',
      threshold: 0
    };
    const tintObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Call api based on filter available
          if (this.tagFilter && this.tagFilter != '') {
            this.tintApiCall();
          } else {
            this.unfilteredTintApiCall();
          }
          tintObserver.unobserve(socialMediaLoaded);
        }
      });
    }, options);
    tintObserver.observe(socialMediaLoaded);
  }

  // Call initial Tint API with multiple filters, if response data not empty show result preparing html else call another tint api without filter same as initial requirement in 8647
  tintApiCall() {
    // Making our connection
    const url = `https://api.tintup.com/v2/tints/${this.tint}/posts?filter[tag]=${this.tagFilter}&page[size]=${SocialMedia.PAGE_SIZE}&include=product_tags.product,social_feed,cta_associations.cta,asset&filter[status]=public`;
    this.xhr.open(SocialMedia.HTTP_METHOD_GET, url, true);

    // function execute after request is successful
    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState == 4 && this.xhr.status == 200) {
        const responseJson = JSON.parse(this.xhr.responseText);
        if (responseJson?.data?.length === 0) {
          this.unfilteredTintApiCall();
        } else {
          this.appendResponseHTML(responseJson);
        }
      }
    };
    // Sending our request
    this.xhr.send();
  }

  // This function hits Tint api for social media component
  tintApiCallNew() {
    const tint = this.componentHost?.getAttribute('data-team-id');
    const size = '8';
    const url = `https://api.tintup.com/v2/tints/${tint}/posts?page[size]=${size}&filter[status]=public&sort=-published_at`;
    const meData = new CoreJS.XHRPromisifiedRequest(url, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)

      .setRequestHeaders({ 'accept': '*/*' })
      .executeRequest()

      .then((response) => {
        const responseJson = JSON.parse(response.responseText);
        let i = 0;
        const parentContainer = document.getElementById('cmp-socialMedia_teaser--wrapper');
        for (i == 0; i < responseJson.data.length; i++) {
          const childElement = `
            <li class="aem-GridColumn aem-GridColumn--default--3 aem-GridColumn--sm--2 aem-GridColumn--md--4 cmp-socialMedia_listItem">
              <div class="cmp-socialMedia_listItemContainer">
                <a href=${responseJson.data[i].attributes.url} title='Open publication on Instagram in a new window' class='cmp-socialMedia_listItemUrl' target="_blank">
                  <picture class="cmp-socialMedia_listItemPicture">
                    <img aria-describedby="tint-item-${i}"  src="${responseJson.data[i].attributes.image_url}" alt="${responseJson.data[i].attributes.alternate_text ? responseJson.data[i].attributes.alternate_text : '' }" loading="lazy" />
                  </picture>
                </a>
                <div class="cmp-socialMedia_overlay">
                  <p lang="${responseJson.data[i].attributes.language}" id="tint-item-${i}" class="cmp-socialMedia_overlayText">${responseJson.data[i].attributes.text}</p>
                </div>
              </div>
            </li>
          `;
          parentContainer.innerHTML += childElement;
        }
      })
      .catch((error) => {
        console.error('error', error);
      });

    return meData;
  }

  // Call the initial tint api with basic parameters to fetch gallery
  unfilteredTintApiCall() {
    const urlUnFilter = `https://api.tintup.com/v2/tints/${this.tint}/posts?page[size]=${SocialMedia.PAGE_SIZE}&filter[status]=public&sort=-published_at`;
    this.xhr.open(SocialMedia.HTTP_METHOD_GET, urlUnFilter, true);

    // function execute after request is successful
    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState == 4 && this.xhr.status == 200) {
        const responseJson = JSON.parse(this.xhr.responseText);
        this.appendResponseHTML(responseJson);
      }
    };
    // Sending our request
    this.xhr.send();
  }

  /**
   * Append Api response data in html to show social media images
   * @param {Object} responseJson
   */
  appendResponseHTML(responseJson) {
    let i = 0;
    const parentContainer = document.getElementById('cmp-socialMedia_teaser--wrapper');
    for (i == 0; i < responseJson.data.length; i++) {
      const childElement = `
        <li class="aem-GridColumn aem-GridColumn--default--3 aem-GridColumn--sm--2 aem-GridColumn--md--4 cmp-socialMedia_listItem">
          <div class="cmp-socialMedia_listItemContainer">
            <a href=${responseJson.data[i].attributes.url} title='Open publication on Instagram in a new window' class='cmp-socialMedia_listItemUrl' target="_blank">
              <picture class="cmp-socialMedia_listItemPicture">
                <img aria-describedby="tint-item-${i}"  src="${responseJson.data[i].attributes.image_url}" alt="${responseJson.data[i].attributes.alternate_text ? responseJson.data[i].attributes.alternate_text : '' }" loading="lazy" />
              </picture>
            </a>
            <div class="cmp-socialMedia_overlay">
              <p lang="${responseJson.data[i].attributes.language}" id="tint-item-${i}" class="cmp-socialMedia_overlayText">${responseJson.data[i].attributes.text}</p>
            </div>
          </div>
        </li>
      `;
      parentContainer.innerHTML += childElement;
    }
  }
}
CoreJS.BaseComponent.registerComponent(SocialMedia.CLASS_NAMESPACE, SocialMedia);
