// tag for list V2

import { TagManager } from '@accor/ace-ui-core';

export default class ListV2 extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-list-v2-component';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.layerElements = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.layerElements)?.pageName?.split('::');
    if (this.datalayerValues[0] === 'europcar') {
      this.europcarPages();
    }
  }

  europcarPages() {
    const link = this.componentHost.querySelector('.ace-list-default__item .ace-list-default--item__icon');
    link?.addEventListener('click', () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: 'loyalty program::partners::mobility::europcar',
          bloc_name: 'status match',
          bloc_interaction: 'learn more',
          bloc_type: 'your rewards',
          context: 'partners mobility'
        }
      };
      // eslint-disable-next-line no-undef
      TagManager.trackEvent(datasEvent);
    });
  }
}
CoreJS.BaseComponent.registerComponent(ListV2.CLASS_NAMESPACE, ListV2, true);
