/* eslint-disable no-unused-expressions */
/**
 * @author Jonas.Fournel
 * @fileOverview
 */
export default class LanguageSelector extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-language-selector';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.findHTMLElements();
    this.bindLanguageSelectorEvents();
    this.selectedCountryRegion = this.componentHost.querySelector('select[data-country]');
    const langUndo = this.componentHost.querySelector('.ace-language-selector__button .ui-label-01');
    if (langUndo.innerHTML === 'in') {
      langUndo.innerHTML = 'id';
    }
  }

  /**
     *  component elements selectors
     */
  findHTMLElements() {
    this.countrySelector = this.componentHost.querySelector(
      `select.${this.componentName}__dropdown--country`
    );
    this.redirectButton = this.componentHost.querySelector(
      `.${this.componentName}__redirect`
    );
    this.modalTriggerButton = this.componentHost.querySelector(
      `.${this.componentName}__button button`
    );
    this.modalCloseButton = this.componentHost.querySelector(
      `.${this.componentName}__header button`
    );
    this.modal = this.componentHost.querySelector(
      `.${this.componentName}__modal`
    );
  }

  /**
     * Close the language selector modal
     */
  closeLanguageSelector() {
    if (this.modal.classList.contains('show')) {
      this.modal.classList.remove('show');
      this.modal.setAttribute('aria-modal', false);
      this.modalTriggerButton.focus();
    }
  }

  /**
     * Open the language selector modal
     */
  openLanguageSelector() {
    this.modal.classList.add('show');
    this.modal.setAttribute('aria-modal', true);
    this.modalCloseButton.focus();
    this.countrySelector.querySelector('option:not(:disabled)').selected = true;
    this.selectedCountryRegion.querySelector(
      'option:not(:disabled)'
    ).selected = true;
    this.redirectButton.disabled = true;
  }

  /**
     * Escape key handler
     */
  escapeKeyHandler() {
    window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === 'Escape') {
        this.closeLanguageSelector();
      }
    });
  }

  /**
     * Close modal on clicking outside
     */
  modalOutsideClickHandler() {
    this.componentHost.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        if (
          !event.target.closest(`.${this.componentName}__container`) &&
                    !event.target.closest(`.${this.componentName}__button`)
        ) {
          this.closeLanguageSelector();
        }
      },
      false
    );
    window.addEventListener(
      CoreJS.DomEventConstants.FOCUS_IN,
      (event) => {
        if (
          !event.target.closest(`.${this.componentName}__container`) &&
              !event.target.closest(`.${this.componentName}__button`)
        ) {
          if (this.modal.classList.contains('show')) {
            this.modalCloseButton.focus();
          }
        }
      },
      false
    );
  }
  /*
     * to validate user events
     */
  validateUserSelection(event) {
    return !!(event.type === CoreJS.DomEventConstants.CLICK ||
          event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.space ||
          event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter);
  }
  /**
     * Open the language selector modal
     */
  /*
     *
     * @param event
     */
  countryChangeHandler(event) {
    this.componentHost
      .querySelectorAll('select[data-country]')
      .forEach((select) => {
        select.classList.add(`${this.componentName}__dropdown--hidden`);
        select.querySelector('option').selected = true;
      });
    this.selectedCountryRegion = this.componentHost.querySelector(
      `select[data-country='${event.currentTarget.value.trim()}']`
    );
    this.selectedCountryRegion.classList.remove(
      `${this.componentName}__dropdown--hidden`
    );
  }

  /*
     * Redirect modal to requested page
     *
     */
  redirectButtonHandler(event) {
    event.preventDefault();
    const selectedOption =
        this.selectedCountryRegion?.querySelectorAll('option')[
          this.selectedCountryRegion.selectedIndex
        ];
    const { accorCountryCode, languageCode, target } = selectedOption?.dataset;
    const { siteCode, apiUrlManual, key } = JSON.parse(
      document.body?.dataset?.posHandler
    );

    if (
      siteCode &&
      apiUrlManual &&
      accorCountryCode &&
      languageCode &&
      target
    ) {
      // Check for current page in user's language, and if not, redirect to home page with 'target' dataset.
      function redirectToNextPage() {
        const isThereAnAlternativePageInTheCorrectLanguage =
          document.querySelector(
            `link[rel=alternate][hreflang=${languageCode}]`
          );
        const isCurrentPageHome = window.location.pathname?.endsWith('home.html');
        const splitArray = target?.split('/');
        const location = `/${splitArray[3]}/${splitArray[4]}/`;
        document.cookie = `locationcookie=${location};path=/`;
        const redirectedUrl = !isCurrentPageHome && isThereAnAlternativePageInTheCorrectLanguage ?
          isThereAnAlternativePageInTheCorrectLanguage.href :
          target;
        window.location.href = redirectedUrl;
      }

      const apiUrlParams = new URLSearchParams([
        ['siteCode', siteCode],
        ['accorCountryCode', accorCountryCode],
        ['languageCode', languageCode]
      ]);
      const apiUrlManualFinal = `${apiUrlManual }?${ apiUrlParams.toString()}`;
      new CoreJS.XHRPromisifiedRequest(
        apiUrlManualFinal,
        CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET
      )
        .setRequestHeaders({ apikey: key })
        .executeRequest()
        // Do nothing, but xhr call has worked
        .then(redirectToNextPage)
        .catch(redirectToNextPage);
    }
  }

  /**
     * language selector events
     */
  bindLanguageSelectorEvents() {
    this.redirectButton.disabled = true;
    this.countrySelector.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
      this.countryChangeHandler(event);
      this.redirectButton.disabled = true;
    });
    this.componentHost
      .querySelectorAll('select[data-country]')
      .forEach((select) => {
        select?.addEventListener(CoreJS.DomEventConstants.CHANGE, (event)=>{
          if (event.target.options[event.target.selectedIndex].getAttribute('data-target') === null) {
            this.redirectButton.disabled = true;
          } else {
            this.redirectButton.disabled = null;
          }
        });
      });

    this.modalTriggerButton.addEventListener(CoreJS.DomEventConstants.FOCUS_IN, ()=>{
      this.modal.classList.remove('show');
      this.modal.setAttribute('aria-modal', false);
    });
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      this.redirectButton.addEventListener(listener, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey) {
          event.preventDefault();
          this.modal.querySelector('.ace-language-selector__dropdown:not(.ace-language-selector__dropdown--hidden)[data-country]')?.focus();
        } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab) {
          event.preventDefault();
          this.modalCloseButton.focus();
        }
        if (this.validateUserSelection(event)) {
          this.redirectButtonHandler(event);
        }
      });
    });
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      this.modalTriggerButton.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event)) {
          event.preventDefault();
          this.openLanguageSelector();
        }
      });
      this.modalCloseButton.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event)) {
          event.preventDefault();
          this.closeLanguageSelector();
        }
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey) {
          event.preventDefault();
          if (this.redirectButton.disabled) {
            this.modal.querySelector('.ace-language-selector__dropdown:not(.ace-language-selector__dropdown--hidden)[data-country]')?.focus();
          } else {
            this.redirectButton.focus();
          }
        }
      });
    });
    this.modalOutsideClickHandler();
    this.escapeKeyHandler();
  }
}

// Register the LanguageSelector component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(LanguageSelector.CLASS_NAMESPACE, LanguageSelector);
