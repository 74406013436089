/**
 *
 * @author Nitesh.Vaidya, Rishabh
 * @class CoreBookingEngine
 * @version 1.0
 * @inheritDoc
 */
import TagManager from '@accor/core-services/dist/gtm/index';
import i18n from './i18n.json';

export default class CoreBookingEngine extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-core-booking-engine';
  static IDENTIFY_ACTION_ACCEPT = 'application/json, text/plain, */*';
  static BRAND_ALL = 'all';
  static OPTIONS_NAMESPACE = 'ace-customdropdown';
  static OPTIONPANEL_NAMESPACE = 'ace-core-booking-engine__options-panel';
  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.i18nStrings = {};
    const i18 = this.componentHost
      .querySelector('[data-i18n]')
      ?.getAttribute('data-i18n');
    if (i18) {
      this.i18nStrings = JSON.parse(i18);
    }
    this.errorStrings = [];
    this.formErrorCounter = 0;
    this.optionsPanelCounter = 0;
    this.offerPageV2 = document.querySelector('.booking_engine_with_heading');
    if (this.offerPageV2) {
      this.dealsMergeWithHeading();
    }
    this.booknowSelector();
    this.bindBooknowEvents();
    this.rootStyle = document.querySelector(':root');
    this.checkPrivateOfferBooking();
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    if (this.dataLayers) {
      this.datalayerValues = JSON.parse(this.dataLayers);
    }
    this.homePageName = this.datalayerValues?.pageName.split('::');
    this.brand = this.datalayerValues?.brandContext;
    this.awardsToggle();
    this.optionsHtmlPresent = true;
    this.specialratesGA4events();
    this.tagManagerHandler('view', 'view');

    if (this.componentHost?.closest('.sticky-booking-engine-wrapper')) {
      this.startObservingDestination();
    }
  }


  awardsToggle() {
    const method = 'GET';
    const fields = 'loyalty.awardTypes,loyalty.freenights';
    const url = `${CoreJS.Constants.REC_URL_CONSTANT.me}?fields=${fields}`;
    const getUserFilterData = {
      fields: fields
    };
    const data = CoreJS.CommonUtils.toggleEnvironment(url, method, getUserFilterData);
    data?.then((response)=> {
      const evt = new CustomEvent('AwardCustomEvent', { detail: response });
      document.dispatchEvent(evt);
      if (this.homePageName[1] === 'homepage' && this.brand === 'all') {
        this.allHomePage();
      }
      this.bookingEngine = this.componentHost?.parentElement.closest('.core-booking-engine-homepage .core-booking-engine.booking-engine.not-merge-next');
      this.awards = this.componentHost?.querySelector('.awards');
      if (this.bookingEngine && this.awards) {
        this.bookingEngine?.classList.add('awards-toggle');
      } else {
        this.bookingEngine?.classList.remove('awards-toggle');
      }
    });
  }
  allHomePage() {
    this.staysnuBtn = this.componentHost?.querySelectorAll('.awards fieldset');
    this.stayPlus = this.staysnuBtn[0]?.querySelector('.toggle__btn');
    this.snuPlus = this.staysnuBtn[1]?.querySelector('.toggle__btn');
    this.stayPlusEventListener();
    this.snuPlusEventListener();
  }
  stayPlusEventListener() {
    this.stayPlus?.addEventListener('click', () => {
      this.formAction = '';
      this.stayCheckstatus = this.staysnuBtn[0]?.querySelector('#stay-plus-toggle').checked;
      this.snuCheckstatus = this.staysnuBtn[1]?.querySelector('#snu-toggle').checked;
      if (this.stayCheckstatus === true) {
        this.formAction = 'stay plus - close';
      } else {
        this.formAction = 'stay plus - open';
        if (this.snuCheckstatus === true) {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'booking_form_interact',
            event_data: {
              pagename: this.datalayerValues.pageName,
              form_action: 'snu - close'
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          TagManager.trackEvent(datasEvent);
        }
      }
      const datasEvent = {
        event: 'GA4event',
        eventName: 'booking_form_interact',
        event_data: {
          pagename: this.datalayerValues.pageName,
          form_action: this.formAction
        }
      };
      TagManager.trackEvent(datasEvent);
    }
    );
  }
  snuPlusEventListener() {
    this.snuPlus?.addEventListener('click', () => {
      this.formAction = '';
      this.stayCheckstatus = this.staysnuBtn[0]?.querySelector('#stay-plus-toggle').checked;
      this.snuCheckstatus = this.staysnuBtn[1]?.querySelector('#snu-toggle').checked;
      if (this.snuCheckstatus === true) {
        this.formAction = 'snu - close';
      } else {
        this.formAction = 'snu - open';
        if (this.stayCheckstatus === true) {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'booking_form_interact',
            event_data: {
              pagename: this.datalayerValues.pageName,
              form_action: 'stay plus - close'
            }
          };
          TagManager.trackEvent(datasEvent);
        }
      }
      const datasEvent = {
        event: 'GA4event',
        eventName: 'booking_form_interact',
        event_data: {
          pagename: this.datalayerValues.pageName,
          form_action: this.formAction
        }
      };
      TagManager.trackEvent(datasEvent);
    }
    );
  }
  booknowSelector() {
    this.triggerButton = this.componentHost.querySelector(
      '.ace-core-booking-engine__options-trigger button'
    );
    this.dropdownPanel = this.componentHost.querySelector(
      '.ace-core-booking-engine__options-panel'
    );
    this.booknowOpenButton = this.componentHost.querySelector(
      '.ace-core-booking-engine__open--button'
    );
    this.booknowCloseButton = this.componentHost.querySelector(
      '.ace-core-booking-engine__close--button'
    );

    this.booknowForm = this.componentHost.querySelector('form');
    this.booknowFormSubmitButton = this.componentHost.querySelector(
      '.ace-core-booking-engine__footer button'
    );
    this.errorBlock = this.componentHost.querySelector(
      '.ace-core-booking-engine__error-block'
    );
    this.errorBlocks = this.componentHost.querySelectorAll(
      '.ace-core-booking-engine__error-block'
    );
    this.errorBlockList = this.componentHost.querySelector(
      '.ace-core-booking-engine__error-block ul'
    );
    this.optionPanelTriggerButton = this.componentHost.querySelector(
      '#ace-core-booking-engine__option-trigger-12345'
    );
    this.guestTriggerButton = this.componentHost.querySelector(
      '#core-booking-engine-guest-title-12345'
    );
    this.datalayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (this.datalayer) {
      this.datalayerValue = JSON.parse(this.datalayer);
    }
    this.dataPrivateOffer = this.componentHost?.closest('.core-booking-engine__wrapper')?.getAttribute('data-private-offer');
  }
  userStore(item) {
    const data = sessionStorage.getItem(item);
    if (data) {
      if (this.componentHost.classList.contains('core-booking-engine__homepagewrapper')) {
        if (item === CoreJS.StorageConstants.LOYALTY_CARD) {
          const productLabel = sessionStorage.getItem(CoreJS.StorageConstants.PRODUCT_LABEL);
          const loyaltyLabelWrapper = this.componentHost.querySelector('.ace-core-booking-engine__loyalty-product-label');
          if (loyaltyLabelWrapper && productLabel) {
            loyaltyLabelWrapper.innerHTML = '';
            loyaltyLabelWrapper.innerHTML = `<span class="icon icon-listing-24icon"></span> <span class="loyalty__product">${productLabel} : <span class="loyalty__product-card">${data}</span></span>`;
            loyaltyLabelWrapper.classList.add('show-label');
          }
        }
      }
      window[item].value = data;
    }
  }
  renderUserDetails() {
    this.componentHost.querySelectorAll('input').forEach((item) => {
      this.userStore(item.id);
    });
  }

  /**
   * Booknow open handler
   */
  openBooknow() {
    this.componentHost.classList.add('show');
    this.booknowCloseButton.focus();
    document
      .querySelector('body')
      .classList.add('ace-core-booking-engine__open');
    document.documentElement.classList.add('fixedbyModal');
    this.componentHost.setAttribute('aria-modal', true);
  }
  /**
   * Handler to detect small device
   * @return { Boolean }
   */
  isSmallDevice() {
    let status;
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      status = 'true';
    } else {
      status = 'false';
    }
    return status;
  }
  /**
   * Booknow close handler
   */
  closeBooknow() {
    if (this.componentHost.classList.contains('show')) {
      this.booknowOpenButton.focus();
    }
    this.componentHost.classList.remove('show');
    document
      .querySelector('body')
      .classList.remove('ace-core-booking-engine__open');
    this.componentHost.setAttribute('aria-modal', false);
    document.documentElement.classList.remove('fixedbyModal');
  }

  /**
   * Resize or scrren orientation handler
   *
   */
  screenEventHandler() {
    if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.closeBooknow();
    }
  }

  /**
   * Escape key handler
   */
  escapeKeyHandler() {
    window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
        if (
          this.isSmallDevice &&
          this.componentHost.classList.contains('show')
        ) {
          this.closeBooknow();
        }
      }
    });
  }

  /**
   * to validate user events
   * @param { Object } event
   * @return { Boolean }
   */
  validateUserSelection(event) {
    if (
      event.type === CoreJS.DomEventConstants.CLICK ||
      event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.space ||
      event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter
    ) {
      return true;
    }
    return false;
  }
  /**
   * To generate error message
   * @param {String} errorBlock
   * @param {String} id
   * @param {String} message
   * @param {String} componentGroup
   * @param {String} errorGroup
   * @param {String} event
   */
  errorGenerator = (
    errorBlock,
    id,
    message,
    componentGroup,
    errorGroup,
    event
  ) => {
    this.formErrorCounter++;
    this.componentHost.querySelector(errorBlock)?.classList.add('invalid');
    this.errorStrings.push({
      errorBlock,
      id,
      message,
      componentGroup,
      errorGroup
    });
    if (!this.event_form_errors.includes(event)) {
      this.event_form_errors.push(event);
      if (event == 'destination') {
        if (document.querySelector('[name="search.destination"]')?.value == '') {
          this.event_form_errors_type.push('blank');
        } else {
          this.event_form_errors_type.push('invalid value');
        }
      } else if (event == 'arrival date' || event == 'departure date') {
        this.event_form_errors_type.push('invalid value');
      } else if (event == 'child age') {
        this.event_form_errors_type.push('blank');
      } else if (event == 'other') {
        this.event_form_errors_type.push('invalid value');
      }
    }
  };
  /**
   * To validate destination field
   * @param {Object} formData
   */
  validateDestinationBlock = (formData) => {
    const destinationSelector = this.componentHost.querySelector('[name="search.destination"]');
    const userLangSelector = this.componentHost.querySelector("[name='search.destination.userlang']");
    const labelSelector = this.componentHost.querySelector("[name='search.destination.labelSelected']");

    const destination = destinationSelector?.value.replace(/["']/g, '');
    const userLang = userLangSelector?.value.replace(/["']/g, '');
    const label = labelSelector?.value.replace(/["']/g, '');

    if (destination !== '' ) {
      if (label?.length >= 4) {
        formData.append('search.destination.userlang', userLang);
      } else {
        // In other type of destination selection
        formData.append(destinationSelector?.name, destination);
      }

      this.componentHost
        .querySelectorAll('.ahAutocomplete__input__hidden input')
        ?.forEach((input) => {
          formData.append(input.name, (input.value).replace(/["]/g, ''));
        });
      const offercheck = document.querySelector('body.offer-page');
      const participatingChain = 'filter.brands';
      const selectiveTK = ['identification.rateAccess1', 'identification.sub', 'identification.flagExtendTk'];
      let addValue = false;
      this.componentHost
        .querySelectorAll('.ace-core-booking-engine input')
        ?.forEach((input) => {
          if (offercheck) {
            if (input.name === participatingChain) {
              if (input.value) {
                const inputValue = input.value?.replaceAll(';', '|');
                formData.append(input.name, inputValue);
              }
            }
          }
          if (input.name === 'identification.rateAccess1' && this.dropdownPanel.dataset.promocode !== 'true') {
            if (input.value) {
              addValue = true;
            }
          }
          // Add flagRagp in case of day use template
          if (input.classList.contains('day-use--identification-flagRagp') ) {
            formData.append(input.name, input.value);
          }
          // Add flagRagp in case of day use template
          if (input.classList.contains('day-use--identification-flagDayUse-flagExtendTk') && !formData.has('identification.flagExtendTk')) {
              formData.append('identification.flagExtendTk', '1');
            }
          if (addValue) {
            selectiveTK.forEach((val) => {
              if (input.name === val && !formData.has(input.name)) {
                formData.append(input.name, input.value);
              }
            });
          }
        });
    // TODO Should not pass here. To be removed after verification tests
    } else if (destination) {
      formData.append(destinationSelector?.name, destination);
    } else {
      if (this.componentHost.classList.contains('core-booking-engine__homepagewrapper')) {
        if (userLang === '') {
          this.errorGenerator(
            '[id="search.destination.userlang"]',
            'search.destination.userlang',
            this.i18nStrings.destination_empty,
            'destination_empty',
            'destination_empty',
            'destination_empty'
          );
        } else {
          this.errorGenerator(
            '[id="search.destination.userlang"]',
            'search.destination.userlang',
            this.i18nStrings.destination_error,
            'destination_error',
            'destination_error',
            'destination_error'
          );
        }
      } else {
        this.errorGenerator(
          '[id="search.destination.userlang"]',
          'search.destination.userlang',
          this.i18nStrings.destination,
          'destination',
          'destination',
          'destination'
        );
      }
    }
  };
  /**
   *  validate guest details
   * @param {Object} formData
   */
  validateGuestBlock = (formData) => {
    const toatlRooms = parseInt(
      this.componentHost.querySelector('[data-input="room"]')?.value
    );
    const guestBlock = this.componentHost.querySelector(
      '.ace-core-booking-engine__guests>.input'
    );
    const firstRoomAdultCount = this.componentHost.querySelector(
      '#core-booking-engine-room-0-adult'
    )?.value;
    const firstRoomChildrenCount = this.componentHost.querySelector(
      '#core-booking-engine-room-0-children'
    )?.value;
    if (
      toatlRooms > 1 ||
      firstRoomAdultCount >= 1 ||
      firstRoomChildrenCount >= 1
    ) {
      formData.append('search.roomNumber', toatlRooms);
      this.totalChildren = 0;
      this.totalAdults = 0;
      this.componentHost
        .querySelectorAll('.ace-room')
        ?.forEach((room, index) => {
          if (index < toatlRooms) {
            room.querySelectorAll('input').forEach((guest) => {
              switch (guest.dataset.input) {
                case 'adult':
                  formData.append(guest.name, guest.value);
                  this.totalAdults = this.totalAdults + parseInt(guest.value);
                  break;
                case 'child':
                  const totalChildren = parseInt(guest.value);
                  this.totalChildren = this.totalChildren + totalChildren;
                  if (totalChildren >= 1) {
                    formData.append(guest.name, guest.value);
                    room
                      .querySelectorAll('.ace-dropdown__select')
                      ?.forEach((child, childIndex) => {
                        if (childIndex < totalChildren) {
                          if (child.getAttribute('data-value') !== '-') {
                            formData.append(
                              `search.roomCriteria[${index}].children[${childIndex}].age`,
                              child.getAttribute('data-value')
                            );
                          } else {
                            guestBlock?.classList.add('invalid');
                            const selector =
                            child.parentElement.getAttribute('id');
                            this.errorGenerator(
                              `#${selector}`,
                              `chiild-dropdown-button-${index}-${childIndex}`,
                              this.i18nStrings.children,
                              'composition',
                              'children',
                              'child age'
                            );
                          }
                        }
                      });
                  }
                  break;
              }
            });
          }
        });
    }
  };
  /**
   *  validate stay details
   * @param {Object} formData
   */
  validateStay = (formData) => {
    let checkInStatus = false;
    this.componentHost
      .querySelectorAll(
        '.core-booking-engine__date-search input:not([name="search.nightNb"])'
      )
      ?.forEach((input) => {
        if (input.value !== '') {
          if (input.value !== 'default' && input.value !== 'NaN') {
            formData.append(input.name, input.value);
          }
        } else {
          checkInStatus = true;
        }
      });
    if (checkInStatus) {
      this.errorGenerator(
        '#check-in-core-book-engine-12345',
        'check-in-core-book-engine-12345',
        this.i18nStrings.dateFormat,
        'checkIn',
        'dateFormat',
        'arrival date'
      );
    }
    const checkout = this.componentHost.querySelector(
      '.core-booking-engine__date-search input[name="search.nightNb"]'
    );
    if (checkout.value !== '') {
      if (checkout.value !== 'default') {
        formData.append(checkout.name, checkout.value);
      }
    } else {
      this.errorGenerator(
        '#check-out-core-book-engine-12345',
        'check-out-core-book-engine-12345',
        this.i18nStrings.dateFormat,
        'checkOut',
        'dateFormat',
        'departure date'
      );
    }
  };
  /**
   * validate options panel details
   * @param {Object} formData
   */
  validateOptionsPanel = (formData) => {
    const regEx = /^[0-9a-zA-Z]+$/;
    this.moreOptionsInputs?.forEach((input) => {
      if (input.value.length >= 1) {
        switch (input.name) {
          case 'search.accessibleRooms':
            if (input.checked === true) {
              formData.append(input.name, input.value);
            }
            break;
          case 'identification.rateAccess1':
            if (input.value) {
              formData.append(input.name, input.value);
            }
            break;
          case 'identification.sub':
            if (input.value) {
              formData.append(input.name, input.value);
            }
            break;
          case 'identification.flagRagp':
            if (input.value) {
              formData.append(input.name, input.value);
            }
            break;
          case 'identification.fidelityCard.number':
            if (input.value.match(regEx) && input.value.length === 16) {
              formData.append(input.name, input.value);
            } else {
              this.errorGenerator(
                `#${input.id}`,
                'loyalty-card',
                this.i18nStrings.loyaltycard,
                'options',
                'loyalty-card',
                'other'
              );
            }
            break;
          case 'identification.reserverId':
            if (input.value.match(regEx) && input.value.length <= 50) {
              formData.append(input.name, input.value);
              formData.append('identification.reserverType', 'SC');
            } else {
              input.classList.add('invalid');
            }
            break;
          case 'identification.reserverContract':
            if (input.value.match(regEx) && input.value.length === 10) {
              formData.append(input.name, input.value);
              if (!formData.has('identification.reserverType')) {
                formData.set('identification.reserverType', 'SC');
              }
            } else {
              this.errorGenerator(
                `#${input.id}`,
                'access-code',
                this.i18nStrings.accesscode,
                'options',
                'access-code',
                'other'
              );
            }
            break;
          case 'identification.preferredCode.code':
            if (input.value.match(regEx) && input.value.length <= 50) {
              formData.append(input.name, input.value);
            } else {
              input.classList.add('invalid');
            }
            break;
        }
      }
    });
  };
  /**
   * Error reset handler
   */
  errorResetHanlder = () => {
    this.errorStrings = [];
    this.formErrorCounter = 0;
    this.rootStyle.style.setProperty('--errorHeight', '0px');
    this.errorBlocks.forEach((block) => (block.style.display = 'none'));
    this.componentHost.querySelectorAll('.invalid')?.forEach((item) => {
      item.classList.remove('invalid');
    });
  };
  autoOpenOptionsPanel = () => {
    if (
      this.optionPanelTriggerButton.getAttribute('aria-expanded') === 'false'
    ) {
      this.optionPanelTriggerButton.click();
    }
  };
  backControlHandler = () => {
    this.errorResetHanlder();
    let firstInputErrorField = '';
    let counter = true;
    // they are looping all the special rate inputs
    this.moreOptionsInputs?.forEach((input) => {
      if (input.value !== '') {
        input.classList.add('invalid');
        if (counter) {
          firstInputErrorField = input.id;
          counter = false;
        }
      }
    });
    this.errorGenerator(
      `#${firstInputErrorField}`,
      `${firstInputErrorField}`,
      this.i18nStrings.identification,
      'options',
      'identification',
      'other'
    );
    this.displayErrorMessages();
    this.bindErrorMessageEvents();
  };
  /**
   * Create form data object
   * @param {string} data
   * @return {Object}
   */
  surfaceControlCheck = () => {
    const formData = new FormData();
    this.moreOptionsInputs = this.componentHost.querySelectorAll('.ace-core-booking-engine__options input');
    this.validateDestinationBlock(formData);
    this.validateStay(formData);
    this.validateGuestBlock(formData);
    this.validateOptionsPanel(formData);
    return formData;
  };
  /**
   *
   * @param {Array} errorArray
   * @param {String} sortByKey
   * @return {Array}
   */
  getUniqueErrorListBy(errorArray, sortByKey) {
    const uniqueIds = [];
    const unique = errorArray.filter((element) => {
      const isDuplicate = uniqueIds.includes(element[sortByKey]);
      if (!isDuplicate) {
        uniqueIds.push(element[sortByKey]);
        return true;
      }
      return false;
    });
    return unique;
  }

  displayErrorMessages = () => {
    if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      this.errorBlocks[0].style.display = 'flex';
      this.errorBlocks[1].style.display = 'none';
    }
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      this.errorBlocks[1].style.display = 'flex';
      this.errorBlocks[0].style.display = 'none';
    }
    const filteredErrors = this.getUniqueErrorListBy(
      this.errorStrings,
      'errorGroup'
    );

    this.errorBlocks?.forEach((block) => {
      block.querySelector('.error-count').innerHTML = this.formErrorCounter;
      let list = '';
      filteredErrors.forEach((item) => {
        list += `<li><a target="_self" href="#${item.id}" data-component-group="${item.componentGroup}"
         data-error-group="${item.errorGroup}" class="ui-body-01">${item.message}</a></li>`;
      });
      block.querySelector('ul').innerHTML = list;
      block.querySelector('ul li a')?.focus();
    });
    const errorBlockHeight = this.errorBlocks[0]?.offsetHeight;
    this.rootStyle.style.setProperty(
      '--errorHeight',
      `${errorBlockHeight + 32}px`
    );
  };
  bindErrorMessageEvents = () => {
    this.errorBlocks?.forEach((block) => {
      block.querySelectorAll('a')?.forEach((link) => {
        link.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          const belongsTo = event.target.getAttribute('data-component-group');
          const errorId = event.target.getAttribute('href');
          if (belongsTo === 'options') {
            event.preventDefault();
            if (
              this.optionPanelTriggerButton.getAttribute('aria-expanded') ==
              'false'
            ) {
              this.optionPanelTriggerButton.click();
            }
            if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
              setTimeout(() => {
                this.componentHost.querySelector(errorId).parentElement.scrollIntoView(true);
              }, 650);
            } else {
              this.componentHost.querySelector(errorId).parentElement.scrollIntoView(false);
              this.componentHost.querySelector(errorId).focus();
            }
          }
          if (belongsTo === 'composition') {
            event.preventDefault();
            event.stopImmediatePropagation();
            if (
              this.guestTriggerButton.getAttribute('aria-expanded') === 'false'
            ) {
              this.guestTriggerButton.click();
            }
            if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
              setTimeout(() => {
                this.componentHost.querySelector(errorId).focus();
              }, 400);
            }
          }
          if (belongsTo === 'destination') {
            setTimeout(() => {
              this.componentHost.querySelector('.ace-core-booking-engine__autocomplete').scrollIntoView(true);
            }, 50);
          }
          if (belongsTo === 'checkIn' || belongsTo === 'checkOut') {
            setTimeout(() => {
              this.componentHost.querySelector('.ace-core-booking-engine__label').scrollIntoView(true);
            }, 50);
          }
        });
      });
    });
    if (
      this.componentHost.querySelector(
        '.ace-core-booking-engine__options .invalid'
      )
    ) {
      this.autoOpenOptionsPanel();
    }
    this.tagManagerHandler('error_booking_form', 'search - click on cta');
  };

  // Code to be used for ACDAA-10279, commented upto ACDAA-10217 completed
  // Function to transmit SNU/Stay plus informations to formsubmit payload

  snuPayLoad() {
    const checkStayPlus = this.componentHost?.querySelector('#stay-plus-toggle')?.checked;
    const checkSNU = this.componentHost?.querySelector('#snu-toggle')?.checked;
    const payload1 = 'no';
    const payload2 = 'no';
    const finalSnuPayload = {
      'payload1': payload1,
      'payload2': payload2
    };
    if (checkStayPlus) {
      finalSnuPayload.payload1 = 'yes';
    }
    if (checkSNU) {
      finalSnuPayload.payload2 = 'yes';
    }
    return finalSnuPayload;
  }
  /**
   * Form submit handler
   * @param {SubmitEvent} event
   */
  formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.errorResetHanlder();
    this.event_form_errors = [];
    this.event_form_errors_type = [];
    const formBody = this.surfaceControlCheck();
    // US ACDAA-18954 Requirement
    const pageLang = document.documentElement.lang;
    if (pageLang) {
      formBody.append('lang', pageLang);
    }
    // Code to be used for ACDAA-10279, commented upto ACDAA-10217 completed
    if (this.componentHost?.querySelector('.awards')) {
      const snuPayload = this.snuPayLoad();
      formBody.append('search.freeNight', snuPayload.payload1);
      formBody.append('search.snu', snuPayload.payload2);
    }
    formBody.append('referer', sessionStorage.getItem(CoreJS.StorageConstants.REFERRER));
    if ((!this.dropdownPanel.classList.contains('show')) && (this.optionsPanelCounter == 0)) {
      if (sessionStorage.getItem(CoreJS.StorageConstants.LOYALTY_CARD)) {
        formBody.append('identification.fidelityCard.number', sessionStorage.getItem(CoreJS.StorageConstants.LOYALTY_CARD));
      }
      if (sessionStorage.getItem(CoreJS.StorageConstants.CLIENT_CODE)) {
        formBody.append('identification.reserverId', sessionStorage.getItem(CoreJS.StorageConstants.CLIENT_CODE));
        formBody.append('identification.reserverType', 'SC');
      }
      if (sessionStorage.getItem(CoreJS.StorageConstants.ACCESS_CODE)) {
        formBody.append('identification.reserverContract', sessionStorage.getItem(CoreJS.StorageConstants.ACCESS_CODE));
        if (!formBody.has('identification.reserverType')) {
          formBody.set('identification.reserverType', 'SC');
        }
      }
    }
    const httpSessionId = CoreJS.CookieHelper.getCookieValue(CoreJS.CookieConstants.JSESSIONID);
    if (httpSessionId) {
      formBody.append('httpSessionId', httpSessionId);
    }
    // for mantra search.origin param in payload
    const { posHandler } = document.body?.dataset;
    this.siteCode = JSON.parse(posHandler)?.siteCode;
    if (this.siteCode !== CoreBookingEngine.BRAND_ALL) {
      formBody.append('search.origin', this.siteCode);
    }

    if (this.formErrorCounter !== 0) {
      this.displayErrorMessages();
      this.bindErrorMessageEvents();
    } else {
      if (this.datalayerValue && this.datalayerValue.brandContext == 'all') {
        this.dealsFormSubmitTagmanager();
      } else {
        this.tagManagerHandler('submit_booking_form', 'search - click on cta');
      }
      const contextSite =
        event.target.querySelector('[type=submit]')?.dataset.contextSite;
      const actionURL = event.target.getAttribute('action');
      if (contextSite && actionURL) {
        const url = `${contextSite}/ajax${actionURL}`;
        new CoreJS.XHRPromisifiedRequest(url, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_POST)
          .setRequestHeaders({ accept: CoreBookingEngine.IDENTIFY_ACTION_ACCEPT })
          .executeRequest(formBody)
          .then((result) => {
            const data = JSON.parse(result?.responseText?.slice(5));
            if (data?.location) {
              window.location.href = data.location;
            } else if (data?.actionErrors) {
              this.backControlHandler();
            } else if (data?.actionMessages) {
              // For future implementation
            } else if (data?.fieldErrors) {
              // For future implementation
            }
          })
          /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
          .catch((error) => console.error('error', error));
      }
    }
  }


  tagManagerHandler($eventName, $eventAction) {
    const dataLayerValue = document
      .querySelector('[data-cmp-datalayer]')
      ?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const $pagename = this.dataLayerJson.pageName;
      let $eventLabel = '';
      let $eventFields = '';
      let $errorType = '';
      let $formAction = 'error';
      window.dataLayer.push({ 'event_data': null });
      switch ($eventName) {
        case 'view_booking_form':
          $eventLabel = 'simplified form';
          break;
        case 'view':
          $eventLabel = 'view';
          $formAction = 'view';
          break;
        case 'openadvanced':
          $eventLabel = 'openadvanced';
          $formAction = 'openadvanced';
          break;
        case 'view_advanced_booking_form':
          $eventLabel = 'special rates';
          break;
        case 'submit_booking_form':
          const toatlRooms = parseInt(
            this.componentHost.querySelector('[data-input="room"]')?.value
          );
          $eventLabel = `search - rooms-${toatlRooms}`;

          break;
        case 'error_booking_form':
          $eventLabel = `search - error - ${this.event_form_errors
            ?.toString()
            ?.replaceAll(',', '|')}`;
          $eventFields = `${this.event_form_errors
            ?.toString()
            ?.replaceAll(',', '|')}`;
          $errorType = `${this.event_form_errors_type
            ?.toString()
            ?.replaceAll(',', '|')}`;
          break;
      }
      const datasEvent = {
        event: 'GAevent',
        eventName: $eventName,
        eventCategory: $pagename,
        eventAction: $eventAction,
        eventLabel: $eventLabel
      };
      // Deals tag manager object
      if (this.datalayerValue && this.datalayerValue.brandContext == 'all') {
        if ($eventName == 'error_booking_form') {
          const allDataEvents = {
            event: 'GAevent',
            eventCategory: 'promotion',
            eventAction: 'search - click on cta',
            eventLabel: 'search - error',
            eventName: 'booking_form_interact'
          };
          const eventData = {
            pagename: $pagename,
            form_action: $formAction,
            error_type: $errorType,
            error_field: $eventFields
          };
          allDataEvents.event_data = eventData;
          TagManager.trackEvent(allDataEvents);
          const allDataGa4Events = {
            event: 'GA4event',
            eventName: 'booking_form_interact'
          };
          const eventGa4Data = {
            pagename: $pagename,
            form_action: $formAction,
            error_type: $errorType,
            error_field: $eventFields
          };
          allDataGa4Events.event_data = eventGa4Data;
          TagManager.trackEvent(allDataGa4Events);
        } else if ($eventName == 'view' || $eventName == 'openadvanced') {
          const allDataGa4Events = {
            event: 'GA4event',
            eventName: 'booking_form_interact'
          };
          const eventGa4Data = {
            pagename: $pagename,
            form_action: $formAction
          };
          allDataGa4Events.event_data = eventGa4Data;
          TagManager.trackEvent(allDataGa4Events);
        }
      } else {
        TagManager.trackEvent(datasEvent);
      }
    }
  }

  /**
   * Bind booknow events
   */
  bindBooknowEvents() {
    this.triggerButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.tagManagerHandler('openadvanced', 'openadvanced');
      this.opendropdownPanelHandler();
      this.optionsPanelCounter++;
      if (this.optionsHtmlPresent) {
        this.initializeCustomDropdown();
        this.optionsHtmlPresent = false;
      }
    });
    this.renderUserDetails();
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      // closing book now
      this.booknowCloseButton?.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event) && event.type === CoreJS.DomEventConstants.CLICK) {
          this.closeBooknow();
        }
      });
      // open book now
      this.booknowOpenButton?.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event) && event.type === CoreJS.DomEventConstants.CLICK) {
          this.openBooknow();
        }
      });
      this.componentHost
        .querySelector('form')
        ?.addEventListener(CoreJS.DomEventConstants.SUBMIT, (event) => {
          this.formSubmit(event);
        });
      this.componentHost.querySelector('[data-i18n]')?.remove();
    });
    this.escapeKeyHandler();

    window.addEventListener(CoreJS.DomEventConstants.RESIZE, () => {
      this.screenEventHandler();
    });
    document.addEventListener(CoreJS.DomEventConstants.ORIENTAION_CHANGE, () => {
      this.screenEventHandler();
    });
    if (this.componentHost) {
      if (this.datalayerValue && this.datalayerValue.brandContext != 'all') {
        setTimeout(() => {
          this.tagManagerHandler('view_booking_form', 'view booking form ');
        }, 150);
      }
      this.componentHost
        .querySelector('.ace-core-booking-engine__options-trigger')
        ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          if (
            event.target.getAttribute('aria-expanded') === 'true' &&
            this.formErrorCounter === 0
          ) {
            this.tagManagerHandler(
              'view_advanced_booking_form',
              'open advanced booking form'


            );
          }
        });
    }
    // we are managing position of core-booking-engine with 'not-merge-next' class
    if (!this.componentHost.classList.contains('ace-booknow-component__merge-next')) {
      this.componentHost.parentElement.parentElement.classList.add('not-merge-next');
    }
  }

  // Only for deals Template
  dealsMergeWithHeading() {
    if (!document.querySelector('.loyalty-login-block') && !document.querySelector('.loyalty-subscription-block')) {
      document.querySelector('body').classList.add('loyalty-user-logged');
    }
  }
  dealsFormSubmitTagmanager() {
    if (this.datalayer) {
      const destinationvalue = document?.querySelector('[name="search.destination.labelSelected"]')?.value;
      const numberOfRooms = document?.querySelector('.ace-counter .ace-counter__buttons input[name="search.roomNumber"]')?.value;
      const datasEvent = {
        event: 'GAevent',
        eventCategory: 'promotion',
        eventAction: 'search - click on cta',
        eventLabel: 'search'
      };
      TagManager.trackEvent(datasEvent);
      const datasGA4Event = {
        event: 'GA4event',
        eventName: 'booking_form_submit',
        event_data: {
          pagename: this.datalayerValue.pageName,
          number_of_rooms: numberOfRooms,
          adults_nb: this.totalAdults,
          destination: destinationvalue
        }
      };
      TagManager.trackEvent(datasGA4Event);
    }
  }
  opendropdownPanelHandler() {
    const multiLanguage = i18n[document.documentElement.lang] || i18n.en;
    if (this.dropdownPanel.classList.contains('show')) {
      this.closeDropdownPanelHanlder();
    } else {
      this.dropdownPanel.classList.add('show');
      const parentDiv = this.componentHost.querySelector('.ace-core-booking-engine__options-panel');
      let childElement = `<div class="ace-core-booking-engine__options-panel--wrapper"><fieldset class="form-control">
        <legend class="ui-heading-03">${multiLanguage['$booking.LabelTravellers']}</legend>
        <label for="loyalty-card">
            <span class="ui-body-01">${multiLanguage['$booking.fidelityCard.label']}</span>
            <span class="ui-body-02">${multiLanguage['$booking.fidelityCard.helper']}</span>
        </label>
        <input id="loyalty-card" class="form-input ui-body-01" type="text" pattern="[a-zA-Z0-9]+" maxlength="16"
            name="identification.fidelityCard.number">
      </fieldset>
      <fieldset class="form-control">
            <legend class="ui-heading-03">${multiLanguage['$booking.LabelProTravellers']}</legend>
            <div class="content">
                <div>
                    <label for="client-code">
                        <span class="ui-body-01">${multiLanguage['$booking.reserverId.label']}</span>
                        <span class="ui-body-02">${multiLanguage['$booking.reserverId.helper']}</span>
                    </label>
                    <input id="client-code" class="form-input ui-body-01" pattern="[a-zA-Z0-9]+" maxlength="50"
                        type="text" name="identification.reserverId">
                </div>
                <div>
                    <label for="access-code">
                        <span class="ui-body-01">${multiLanguage['$booking.reserverContract.label']}</span>
                        <span class="ui-body-02">${multiLanguage['$booking.reserverContract.helper']}</span>
                    </label>
                    <input id="access-code" class="form-input ui-body-01" type="text" pattern="[a-zA-Z0-9]+"
                        maxlength="10" name="identification.reserverContract">
                </div>
            </div>
        </fieldset>`;
      if (parentDiv && parentDiv.dataset.promocode === 'true') {
        childElement += `<fieldset class="form-control">
        <legend class="ui-heading-03">${multiLanguage['$booking.LabelPromoDrop']}</legend>
        <label for="preferential-code">
            <span id="ace-customdropdown-label" class="ui-body-01">${multiLanguage['$booking.preferredCodeDrop.label']}</span>
            <span aria-hidden="true" class="ui-body-02 placeholder"> &nbsp;</span>
        </label>
        <div class="ace-customdropdown">
          <button id="ace-customdropdown-button" aria-expanded="false" aria-labelledby="ace-customdropdown-label ace-customdropdown-button"
          type="button" aria-haspopup="listbox" class="ace-customdropdown__btn ui-body-01">
            <span class="ace-customdropdown__selected">${multiLanguage['$booking.promoCodeList.1']}</span>
            <span class="ace-customdropdown__arrow">
              <span aria-hidden="true" class="icon icon-chevron-down-24icon"></span>
            </span>
          </button>
          <div class="ace-customdropdown__menu ui-body-01" aria-hidden="true">
            <ul aria-activedescendant="item-rate-0" tabindex="-1" aria-labelledby="ace-customdropdown-label" role="listbox" class="ace-customdropdown__list">
               <li role="option" id="item-rate-0" aria-selected="true" data-value="" data-label="${multiLanguage['$booking.promoCodeList.1']}"
               class="ace-customdropdown__item ace-customdropdown__item--first ace-customdropdown__item--selected">
               <span class="ace-customdropdown__item__radio"></span>
               <span class="ace-customdropdown__item__value">${multiLanguage['$booking.promoCodeList.1']}</span>
               </li>
               <li role="option" id="item-rate-1" aria-selected="false" data-value="AAA|NPMAAH|AAAHDR" data-label="${multiLanguage['$booking.promoCodeList.2']}" class="ace-customdropdown__item">
               <span class="ace-customdropdown__item__radio"></span>
               <span class="ace-customdropdown__item__value">${multiLanguage['$booking.promoCodeList.2']}</span>
               </li>
               <li role="option" id="item-rate-2" aria-selected="false" data-value="SGEGOV|SGEFED|SGEGOL" data-label="${multiLanguage['$booking.promoCodeList.3']}"
               class="ace-customdropdown__item">
               <span class="ace-customdropdown__item__radio"></span>
               <span class="ace-customdropdown__item__value">${multiLanguage['$booking.promoCodeList.3']}</span>
               </li>
               <li role="option" id="item-rate-3" aria-selected="false" data-value="B01MIL" data-label="${multiLanguage['$booking.promoCodeList.4']}" class="ace-customdropdown__item">
               <span class="ace-customdropdown__item__radio"></span>
               <span class="ace-customdropdown__item__value">${multiLanguage['$booking.promoCodeList.4']}</span>
               </li>
               <li role="option" id="item-rate-4" aria-selected="false" data-value="B01SEN" data-label="${multiLanguage['$booking.promoCodeList.5']}" class="ace-customdropdown__item">
               <span class="ace-customdropdown__item__radio"></span>
               <span class="ace-customdropdown__item__value">${multiLanguage['$booking.promoCodeList.5']}</span>
               </li>
               <li role="option" id="item-rate-5" aria-selected="false" data-value="" data-label="${multiLanguage['$booking.promoCodeList.6']}"
               class="ace-customdropdown__item ace-customdropdown__item--last">
               <span class="ace-customdropdown__item__radio"></span>
               <span class="ace-customdropdown__item__value">${multiLanguage['$booking.promoCodeList.6']}</span>
               </li>
               <li aria-hidden="true" class="ace-customdropdown__item-form">
                  <div class="ace-customdropdown__input-control">
                    <input id="pref-code" class="form-input ui-body-01" placeholder="" type="text" pattern="[a-zA-Z0-9]+">
                    <button type="button" disabled="disabled" class="ace-customdropdown__btn-confirm ui-body-01">${multiLanguage['$booking.promoCodeList.cta']}</button>
                  </div>
               </li>
            </ul>
         </div>
         <div class="specialrate-hiddenfields"></div>
        </div>
      </fieldset></div>`;
      } else {
        childElement += `<fieldset class="form-control">
        <legend class="ui-heading-03">${multiLanguage['$booking.LabelPromo']}</legend>
        <label for="preferential-code">
            <span class="ui-body-01">${multiLanguage['$booking.preferredCode.label']}</span>
            <span class="ui-body-02 placeholder"> &nbsp;</span>
        </label>
        <input id="preferential-code" class="form-input ui-body-01" type="text" pattern="[a-zA-Z0-9]+"
            maxlength="50" name="identification.preferredCode.code">
        </fieldset></div>`;
      }
      if (this.optionsHtmlPresent) {
        parentDiv.innerHTML += childElement;
      }
      if (!this.dropdownPanel.classList.contains('filled')) {
        this.dropdownPanel.classList.add('filled');
        this.renderUserDetails();
      }
      this.componentHost
        ?.closest('.core-booking-engine')
        ?.classList.add('expanded');
      this.triggerButton?.setAttribute('aria-expanded', true);
      this.dropdownPanel?.setAttribute('aria-hidden', false);
    }
  }
  closeDropdownPanelHanlder() {
    if (this.dropdownPanel.classList.contains('show')) {
      this.dropdownPanel.classList.remove('show');
      this.triggerButton.focus();
      this.componentHost
        ?.closest('.core-booking-engine')
        ?.classList.remove('expanded');
      this.triggerButton?.setAttribute('aria-expanded', false);
      this.dropdownPanel?.setAttribute('aria-hidden', true);
    }
  }
  /**
   * Check private booking offer for member/non members
   */
  checkPrivateOfferBooking() {
    if (this.dataPrivateOffer == 'true' && !CoreJS.CommonUtils.userLoginStatus()) { // user not logged and checkbox checked
      if (this.componentHost?.closest('.booking-engine-container')) { // for all
        this.componentHost?.closest('.booking-engine-container')?.classList?.add('private-hide');
      } else { // for novotel
        this.componentHost?.closest('.core-booking-engine')?.classList?.add('private-hide');
      }
    } else {
      this.componentHost?.closest('.booking-engine-container')?.classList?.remove('private-hide');
      this.componentHost?.closest('.core-booking-engine')?.classList?.remove('private-hide');
    }
  }
  // US ACDAA-10233 Promo Code Dropdown List Start
  initializeCustomDropdown() {
    this.customDropdownHooksSelector();
    this.bindCustomDropdownEvents();
  }
  /**
   * Custom Dropdown Selector
   */
  customDropdownHooksSelector() {
    this.customDropdownButton = this.componentHost?.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__btn`);
    this.customDropdownMenu = this.componentHost?.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__menu`);
    this.customDropdownList = this.customDropdownMenu?.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__list`);
    this.dropdownOption = this.customDropdownList?.querySelectorAll('[role="option"]');
    this.dropdownHiddenField = this.componentHost?.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE} .specialrate-hiddenfields`);
    this.promoInputHiddenField = this.customDropdownList?.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__item-form`);
    this.firstListItem = this.customDropdownList?.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__item--first`);
    this.promoInputField = this.promoInputHiddenField?.querySelector('input');
    this.promoInputButton = this.promoInputHiddenField?.querySelector('button');
    this.textPromoCode = '';
    this.regEx = /^[0-9a-zA-Z]+$/;
  }
  /**
   * Toggle Custom Dropdown
   */
  openCustomDropdownPanelHandler() {
    if (this.customDropdownMenu?.classList.contains('show')) {
      this.closeCustomDropdownPanelHanlder();
    } else {
      this.customDropdownMenu.classList.add('show');
      this.customDropdownButton.setAttribute('aria-expanded', true);
      this.customDropdownMenu.setAttribute('aria-hidden', false);
      this.customDropdownList.focus();
    }
  }
  closeCustomDropdownPanelHanlder() {
    if (this.customDropdownMenu?.classList.contains('show')) {
      this.customDropdownMenu.classList.remove('show');
      this.customDropdownButton.focus();
      this.customDropdownButton.setAttribute('aria-expanded', false);
      this.customDropdownMenu.setAttribute('aria-hidden', true);
      this.selectListFirstItem(CoreJS.DomEventConstants.CLICK);
    }
  }
  /**
   * pickOptionFromDropdown
   * @param {event} event
   * @param {keyEvent} keyEvent
   */
  pickOptionFromDropdown(event, keyEvent) {
    this.dropdownOption?.forEach((option) => {
      option.setAttribute('aria-selected', false);
      option.classList.remove(`${CoreBookingEngine.OPTIONS_NAMESPACE}__item--selected`);
    });
    event.setAttribute('aria-selected', true);
    event.classList.add(`${CoreBookingEngine.OPTIONS_NAMESPACE}__item--selected`);
    this.customDropdownList?.setAttribute('aria-activedescendant', event.id);
    const dataLabel = event.dataset.label;
    const dataValue = event.dataset.value;
    const lastItem = event.classList.contains(`${CoreBookingEngine.OPTIONS_NAMESPACE}__item--last`);
    this.promoInputField.classList.remove('invalid');
    this.customDropdownButton.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__selected`).innerHTML = dataLabel;
    this.clearCustomFieldMarkup(this.promoInputHiddenField, false);
    this.clearCustomFieldMarkup(this.dropdownHiddenField, true);
    this.showPromoInput(lastItem, dataLabel);
    this.createCustomFieldMarkup(dataValue);
    if (!lastItem && keyEvent !== CoreJS.DomEventConstants.KEY_DOWN) {
      this.closeCustomDropdownPanelHanlder();
    }
  }
  /**
   *  Showing lastItem input field
   *  @param {lastItem} lastItem
   *  @param {dataLabel} dataLabel
   */
  showPromoInput(lastItem, dataLabel) {
    if (lastItem && dataLabel) {
      this.promoInputHiddenField.classList.add('show');
      this.promoInputHiddenField.setAttribute('aria-hidden', false);
      this.textPromoCode = dataLabel;
    }
  }
  /**
   *  Clearing custom hidden fields markup
   *  @param {elementID} elementID
   *  @param {clearElement} clearElement
   */
  clearCustomFieldMarkup(elementID, clearElement) {
    if (clearElement) {
      elementID.innerHTML = '';
    } else {
      elementID.classList.remove('show');
      elementID.setAttribute('aria-hidden', true);
      elementID.querySelector('input').value = '';
    }
  }
  /**
   *  Creating custom hidden fields markup
   *  @param {dataValue} dataValue
   *  @return {string}
   */
  createCustomFieldMarkup(dataValue) {
    if (dataValue) {
      const dynamicMarkup = `<div>
      <input type="hidden" value="ISP" name="identification.sub">
      <input type="hidden" name="identification.rateAccess1" value="${dataValue}">
      <input type="hidden" value="1" name="identification.flagRagp">
      </div>`;
      return this.dropdownHiddenField.insertAdjacentHTML('beforeend', dynamicMarkup);
    }
  }
  /**
   *  Bind Custom Dropdown Button Events Handler
   */
  bindCustomDropdownButtonEvents() {
    this.customDropdownButton?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.openCustomDropdownPanelHandler();
    });
  }
  /**
   * Close Custom On Clicking Outside
   */
  closeCustomDropdownOutsideClickHandler() {
    window.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        if (
          !event.target.closest(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__list`) &&
          event.target.classList.contains('show')
        ) {
          this.closeCustomDropdownPanelHanlder();
        } else if (
          !event.target.closest(`.${CoreBookingEngine.OPTIONS_NAMESPACE}`)
        ) {
          this.closeCustomDropdownPanelHanlder();
        }
      },
      false
    );
  }
  /**
   * Escape key handler
   */
  escapeCustomDropdownKeyHandler() {
    window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc ) {
        this.closeCustomDropdownPanelHanlder();
      }
    });
  }
  /**
   *  Get Current Radio Button Id
   *  @return {string}
   */
  getCurrentRadioButton() {
    const id = this.customDropdownList.getAttribute('aria-activedescendant');
    return this.customDropdownList.querySelector(`#${id}`);
  }
  /**
   *  bind the promo input value
   *  @param {inputValue} inputValue
   */
  confirmCodePromo(inputValue) {
    this.selectedValue = `${this.textPromoCode}: ${inputValue}`;
    this.customDropdownButton.querySelector(`.${CoreBookingEngine.OPTIONS_NAMESPACE}__selected`).innerHTML = this.selectedValue;
    this.promoCodeValue = `<div>
    <input type="hidden" class="hidden-promo-code" name="identification.preferredCode.code" value="${inputValue}">
    </div>`;
    this.clearCustomFieldMarkup(this.dropdownHiddenField, true);
    this.dropdownHiddenField.insertAdjacentHTML('beforeend', this.promoCodeValue);
    this.closeCustomDropdownPanelHanlder();
  }
  /**
   *  Selecting first element
   *  @param {keyEvent} keyEvent
   */
  selectListFirstItem(keyEvent) {
    if (this.promoInputHiddenField.classList.contains('show') && !this.promoInputField.value.length) {
      this.pickOptionFromDropdown(this.firstListItem, keyEvent);
    } else if (this.promoInputHiddenField.classList.contains('show') && !this.dropdownHiddenField.querySelector('.hidden-promo-code')) {
      this.pickOptionFromDropdown(this.firstListItem, keyEvent);
    }
  }
  /**
   *  bind dropdown events handler
   */
  bindDropdownPanelEvents() {
    this.promoInputButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (this.promoInputField.value.length > 0 && this.promoInputField.value.match(this.regEx )) {
        this.promoInputField.classList.remove('invalid');
        this.confirmCodePromo(this.promoInputField.value);
      } else {
        this.promoInputField.classList.add('invalid');
      }
    });
    this.promoInputField?.addEventListener(CoreJS.DomEventConstants.KEY_PRESS, (event) => {
      if (event.keyCode == CoreJS.Constants.KEY_CODES.space) {
        event.returnValue = false;
        return false;
      }
    });
    this.promoInputField?.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
      if (event.target.value.length > 0) {
        this.promoInputButton.removeAttribute('disabled');
      } else {
        this.promoInputButton.setAttribute('disabled', 'disabled');
      }
    });
    this.customDropdownList?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      let flag = false;
      if (event.keyCode === CoreJS.Constants.KEY_CODES.down) {
        const nextElement = this.getCurrentRadioButton().nextElementSibling;
        if (nextElement && !nextElement.classList.contains(`${CoreBookingEngine.OPTIONS_NAMESPACE}__item-form`)) {
          this.pickOptionFromDropdown(nextElement, CoreJS.DomEventConstants.KEY_DOWN);
          flag = true;
        }
      } else if (event.keyCode === CoreJS.Constants.KEY_CODES.up) {
        this.customDropdownList.focus();
        const previousElement = this.getCurrentRadioButton().previousElementSibling;
        if (previousElement) {
          this.pickOptionFromDropdown(previousElement, CoreJS.DomEventConstants.KEY_DOWN);
          flag = true;
        }
      } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter) {
        if (this.promoInputHiddenField.classList.contains('show') && this.promoInputField.value.length > 0 ) {
          if (this.promoInputField.value.match(this.regEx )) {
            this.promoInputField.classList.remove('invalid');
            this.confirmCodePromo(this.promoInputField.value);
          } else {
            this.promoInputField.classList.add('invalid');
          }
        } else {
          this.selectListFirstItem(CoreJS.DomEventConstants.KEY_DOWN);
          this.closeCustomDropdownPanelHanlder();
        }
        flag = true;
      }
      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    });
    this.dropdownOption?.forEach((option) => {
      option.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        if (event.type === CoreJS.DomEventConstants.CLICK) {
          this.pickOptionFromDropdown(option, CoreJS.DomEventConstants.CLICK);
        }
      });
    });
  }
  /**
   *  Bind All Custom Dropdown Events Handlers
   */
  bindCustomDropdownEvents() {
    this.bindDropdownPanelEvents();
    this.bindCustomDropdownButtonEvents();
    this.closeCustomDropdownOutsideClickHandler();
    this.escapeCustomDropdownKeyHandler();
  }
  // US ACDAA-10233 Promo Code Dropdown List End

  specialratesGA4events() {
    const layerElement = document.querySelector('[data-cmp-datalayer]');
    const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
    const pageName = layerElement?.getAttribute('data-layer-page');
    if (pageName === 'offer-page-v2') {
      const datalayerValue = JSON.parse(dataLayer);
      const specialrates = document?.querySelector('.ace-core-booking-engine__options');
      if (specialrates) {
        specialrates.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasGa4Event = {
            event: 'GA4event',
            eventName: 'booking_form_interact',
            event_data: {
              pagename: datalayerValue.pageName,
              form_action: 'openadvanced'
            }
          };
          TagManager.trackEvent(datasGa4Event);
        });
      }
    }
  }

  /**
   * Start observing shared acp if loaded
   */
  startObservingDestination() {
    const sharedACPDestination = this.componentHost.querySelector('.ace-shared-acp-component');
    if (sharedACPDestination) {
      let sharedACPReady = 0;
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          sharedACPReady++;
          if (sharedACPReady >= 2 && this.componentHost.querySelector('.ace-shared-acp-component input.withoutLabel')) {
            this.sortingDestinationWhenReady();
            observer.disconnect();
          }
        });
      });
      observer.observe(sharedACPDestination, {
        childList: true,
        subtree: true
      });
    }
  }

  /**
   * observe destination field dropdown to sort hotels first
   */
  sortingDestinationWhenReady() {
    this.componentHost.querySelector('.ace-shared-acp-component input.withoutLabel').addEventListener(CoreJS.DomEventConstants.INPUT, () => {
      if (this.componentHost.querySelector('.ace-shared-acp-component input.withoutLabel')?.value?.length >= 3) {
        const observer = new MutationObserver(() => {
          if (this.componentHost?.querySelector('.ahAutocomplete__result')) {
            observer.disconnect();
            const ul = this.componentHost.querySelector('.ahAutocomplete__result__items');
            const liElements = ul.querySelectorAll('li');
            const sortedListElements = Array.from(liElements).sort((item) => {
              const itemVal = item?.classList;
              if (itemVal?.contains('autocompleteItem--hotel')) return -1;
              return 0;
            });
            ul.innerHTML = '';
            for (let i = 0; i < sortedListElements.length; i++) {
              ul.appendChild(sortedListElements[i]);
            }
          } else {
            observer.disconnect();
          }
        });
        observer.observe(this.componentHost.querySelector('.ace-shared-acp-component .ahAutocomplete'), {
          childList: true,
          subtree: true
        });
      }
    });
  }
}
CoreJS.BaseComponent.registerComponent(
  CoreBookingEngine.CLASS_NAMESPACE,
  CoreBookingEngine
);
