export {
  getJwt,
  removeCookie,
  setAuthCookie,
  getJwtPayload,
  getAuthStatus,
  setTokenCookie,
  AuthCookieValue,
  deleteAuthCookies,
  setDisconnectCookie,
  AuthenticationStatus,
}

import Cookies from 'js-cookie'
import { JWT_COOKIE_KEY, CONNECTION_COOKIE_KEY } from '../common/constants.js'

const AuthenticationStatus = { Init: 0, Success: 1, Failed: 2 }
const AuthCookieValue = { Success: 'success', Failure: 'fail', Error: 'error' }

const getConnectionCookie = () =>
  Cookies.get(CONNECTION_COOKIE_KEY)?.split('|') || []

const getJwt = () => Cookies.get(JWT_COOKIE_KEY)

const getJwtPayload = jwt => {
  const [, payload] = jwt.split('.')
  return JSON.parse(atob(payload))
}

const isConnected = ([status] = getConnectionCookie()) =>
  status === AuthCookieValue.Success

const hasTokenExpired = accessToken => {
  if (!accessToken) return true
  const { exp, pmid: oldPmid } = getJwtPayload(accessToken)
  const [, newPmid] = getConnectionCookie()
  if (newPmid !== oldPmid) return true

  const dateExpiration = exp * 1000
  const oidcTryExpiration = 60 * 1000 // 60s

  return Date.now() > dateExpiration - oidcTryExpiration
}

const getAuthStatus = (
  $isConnected = isConnected,
  $hasTokenExpired = hasTokenExpired
) => {
  if ($isConnected()) {
    const key = $hasTokenExpired(getJwt()) ? 'Init' : 'Success'
    return AuthenticationStatus[key]
  } else return AuthenticationStatus.Failed
}

const setTokenCookie = token => {
  Cookies.set(JWT_COOKIE_KEY, token, {
    secure: true,
    sameSite: 'None',
    domain: window.location.hostname,
  })

  return { token }
}

const getCurrentDomain = () =>
  window.location.hostname.split('.').slice(-2).join('.')

const setAuthCookie = pmid => {
  const value = `${AuthCookieValue.Success}|${pmid}`
  Cookies.set(CONNECTION_COOKIE_KEY, value, {
    secure: true,
    sameSite: 'None',
    domain: getCurrentDomain(),
  })

  return value
}
const removeCookie = cookie => {
  const domain = getCurrentDomain()
  Cookies.remove(cookie)
  Cookies.remove(cookie, { domain })
  Cookies.remove(cookie, { domain: window.location.hostname })
}

const setDisconnectCookie = () => {
  removeCookie(JWT_COOKIE_KEY)
  const domain = getCurrentDomain()
  Cookies.set(CONNECTION_COOKIE_KEY, AuthCookieValue.Failure, {
    domain,
  })
}

const deleteAuthCookies = () => {
  removeCookie(JWT_COOKIE_KEY)
  removeCookie(CONNECTION_COOKIE_KEY)
}
