/* eslint-disable no-unused-vars */
/** * @author Rishabh * @classdesc
 * This class is defining an implementation from Base * @version 1.0 */

import { getAuthUrl } from '@accor/customer-api';

export default class Loyalty extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-loyalty-container';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.loyaltyFun();
    this.loyaltyGA4events();
    this.oacAllCookie = CoreJS.CommonUtils.userLoginStatus();
    this.componentHost.querySelector('.ace-loyalty__button').addEventListener('click', () => {
      this.loyaltyRefresh();
    });
    document.addEventListener('load', this.loyaltyRefresh() );
  }


  /**
   * Based on the User login we are displaying the loyalty container
   */
  loyaltyRefresh() {
    if (!this.oacAllCookie) {
      document.querySelector('.ace-loyalty-container').style.display = 'block';
    } else {
      document.querySelector('.ace-loyalty-container').style.display = 'none';
      document.querySelector('body').classList.add('loyalty-user-logged');
    }
  }

  // Function for handling loyalty
  loyaltyFun() {
    const loyaltyBtn = document.querySelectorAll('.ace-loyalty__content .ace-loyalty__btn');
    const link = document.querySelector('.ace-loyalty__content a[target="_blank"]');
    loyaltyBtn.forEach((item) => {
      // Adding click Event Listener
      item.addEventListener('click', () => {
        if (link) {
          link.removeAttribute('target');
        }
        // eslint-disable-next-line no-undef
        let url = getAuthUrl({ lang: `${window.navigator.language}&origin=offer&redirect_site_uri=&${window.location.href}` });
        // for lower env
        if (CoreJS.CommonUtils.checkEnvironment()) {
          url = CoreJS.CommonUtils.convertProdToRecUrl(url);
        }
        window.location.href = url;
      });
    });
  }

  loyaltyGA4events() {
    const layerElement = document.querySelector('[data-cmp-datalayer]');
    const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
    const pageName = layerElement?.getAttribute('data-layer-page');
    const pageToTarget = JSON.parse(dataLayer).pageName.split('::');


    if (pageName === 'offer-page-v2') {
      const datalayerValue = JSON.parse(dataLayer);
      const hotelbloc = document?.querySelector('.loyalty-login-block .ace-loyalty-container .ace-loyalty__button .ace-button a.ace-loyalty__btn');
      if (hotelbloc) {
        hotelbloc.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasGa4Event = {
            event: 'GA4event',
            eventName: 'cta_authentication',
            event_data: {
              pagename: datalayerValue.pageName,
              cta_name: 'log in'
            }
          };
          TagManager.trackEvent(datasGa4Event);
        });
      }
    }

    if (pageToTarget[0] === 'all-content-hub') {
      const datalayerValue = JSON.parse(dataLayer);
      const contentHubBtn = document?.querySelector('.loyalty-login-block .ace-loyalty__btn');
      if (contentHubBtn) {
        contentHubBtn.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasGaEvent = {
            event: 'GAevent',
            eventCategory: 'promotion',
            eventAction: 'click on cta',
            eventLabel: 'login'
          };
          TagManager.trackEvent(datasGaEvent);
          const datasGa4Event = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: datalayerValue.pageName,
              bloc_name: 'enroll banner',
              bloc_type: 'cta',
              block_interaction: 'click'
            }
          };
          TagManager.trackEvent(datasGa4Event);
        });
      }
    }
  }
}

// Register the Loyalty component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Loyalty.CLASS_NAMESPACE, Loyalty);
