import { Enum } from '../common/utils'
import { setDisconnectCookie } from './shared.js'
import axiosAuthApi from '../http/axiosAuthApi'
export { Error, logout }

const Error = Enum('LogoutFailed')

const logout = (options = {}) =>
  axiosAuthApi
    .get('/logout', { ...options })
    .then(response => {
      setDisconnectCookie()
      return response
    })
    .catch(() => {
      throw Error.LogoutFailed
    })
