import { HeadingHero, TagManager } from '@accor/ace-ui-core';

export default class AllHeadingHero extends HeadingHero {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.cobrandTemplate = document.querySelector('.cobrand-hub');
    this.headingHero = this.componentHost.parentElement;
    if (this.cobrandTemplate) {
      this.headingHero?.classList?.add('cobrand-hub-template');
    } else {
      this.headingHero?.classList?.remove('cobrand-hub-template');
    }
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    if (this.dataLayers) {
      this.datalayerValues = JSON.parse(this.dataLayers);
      this.homePageName = this.datalayerValues.pageName.split('::');
      if ( this.homePageName[1] == 'cobrand') {
        this.bindHeadingHeroEvent();
      } else if (this.homePageName[1] == 'temporary status match') {
        this.landingPageEvents();
      }
      if (this.homePageName[1] == 'deals') {
        this.dealsCornerHeading();
      }
      if (this.homePageName[0] == 'all-day-use') {
        this.dayUseHeading();
      }

      if (this.homePageName[0] == 'all-content-hub') {
        this.contentHubHeading();
      }
    }
  }

  landingPageEvents() {
    this.Btn = this.componentHost.querySelector('.cmp-headingpagehero-btn-group__item .cmp-ace-hotel__gallery--button');
    this.Btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      window.dataLayer.push({
        'event_data': null
      });
      const datasEvent = {
        event: 'GA4event',
        eventName: 'cta_enroll',
        event_data: {
          pagename: this.datalayerValues.pageName,
          cta_name: 'join us',
          enroll_context: 'loyalty program'
        }
      };
      // eslint-disable-next-line no-undef

      TagManager.trackEvent(datasEvent);
    });
  }

  dealsCornerHeading() {
    const headingHeroButton = this.componentHost.querySelector('.cmp-headingpagehero-btn-group__item .cmp-ace-hotel__gallery--button');
    if (headingHeroButton) {
      headingHeroButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: 'hotels::promotion::all offer page::global index',
            bloc_name: 'deals corner',
            enroll_context: 'see deals'
          }
        };

        TagManager.trackEvent(datasEvent);
      });
    }
  }
  bindHeadingHeroEvent() {
    if (this.layerElements) {
      this.componentHost.querySelector('.cmp-headingpagehero-btn-group__item .cmp-ace-hotel__gallery--button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: 'hero banner',
            bloc_interaction: 'subscribe'
          }
        };
        // eslint-disable-next-line no-undef

        TagManager.trackEvent(datasEvent);
      });
    };
  }

  dayUseHeading() {
    const dayUseButtons = this.componentHost?.querySelectorAll('.cmp-headingpagehero-btn-group__item .button a');
    if (!dayUseButtons || dayUseButtons.length === 0) return;

    dayUseButtons.forEach(dayUseButton => {
      dayUseButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: 'day_use',
            bloc_name: 'heading button',
            bloc_interaction: 'click',
            bloc_type: 'cta'
          }
        };

        TagManager.trackEvent(datasEvent);
      });
    });
  }

  contentHubHeading() {
    const headingHeroButton = document.querySelector('.cmp-headingpagehero-btn-group__item .cmp-button');
    if (headingHeroButton) {
      headingHeroButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            bloc_interaction: 'click',
            bloc_name: 'heading button',
            block_type: 'cta',
            pagename: this.datalayerValues.pageName
          }
        };

        TagManager.trackEvent(datasEvent);
      });
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(HeadingHero.CLASS_NAMESPACE, AllHeadingHero, true);
