{
  "mantra-page": {
    "obj-reference-1": {
        "selector":".homepage main .slideshow + .heading .ace-heading-component .ace-headingpagehero.headingno-image .ace-headingpagehero__content .ace-headingpagehero-headline div  a.ace-headingpagehero__content--link",
        "event":{
          "event": "GA4event",
          "eventName": "bloc_interact",
           "event_data": {
             "pagename" : "homepage",
             "bloc_name" : "discover more of our offers",
             "bloc_interaction" : "offers section"
           }
        }
      },
    "obj-reference-2": {
      "selector": ".homepage main .callout-container + .heading .ace-heading-component .ace-headingpagehero.headingno-image .ace-headingpagehero__content .ace-headingpagehero-headline div  a.ace-headingpagehero__content--link",
      "event":{
        "event": "GA4event",
        "eventName": "bloc_interact",
         "event_data": {
           "pagename" : "homepage",
           "bloc_name" : "check all our destinations",
           "bloc_interaction" : "destinations section"
         }
      }
    },
    "obj-reference-3": {
      "selector": ".heading .ace-heading-component .ace-headingpagehero .ace-headingpagehero--fullWidth + .ace-headingpagehero__content .ace-headingpagehero-headline div  a.cmp-button--primary",
      "event":{
        "event": "GA4event",
        "eventName": "bloc_interact",
         "event_data": {
           "bloc_interaction" : "enquire with us"
         }
      }
    }
  }
}
