/* eslint-disable no-unused-vars */
/** * @author Rishabh * @classdesc
 * This class is defining an implementation from Base * @version 1.0 */

export default class ScrollLazyLoadComponent extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'load-scroll';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    this.lazyLoadingElements = document.querySelectorAll(`.${ScrollLazyLoadComponent.CLASS_NAMESPACE}`);
    if (this.lazyLoadingElements.length) {
      this.checkElements();
      this.addScrollEvent();
    }
  }
  checkIfElementInViewPort(el, limit = 350) {
    const rect = el.getBoundingClientRect();
    return (rect.top >= (0 - limit) &&
      rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + limit));
  }
  addScrollEvent() {
    document.addEventListener('scroll', () => {
      this.checkElements();
    });
  }

  checkElements() {
    this.lazyLoadingElements.forEach((element, index) => {
      if (!element.classList.contains('loaded') && this.checkIfElementInViewPort(element.parentNode)) {
        this.appendScriptHead(element.getAttribute('data-script-src'), index);
        element.classList.add('loaded');
      }
    });
  }

  appendScriptHead(src, index) {
    if (src == '') {
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = () => {
      if (window.scrollCallback.length && typeof window.scrollCallback[index] === 'function') {
        window.scrollCallback[index]();
      }
    };
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
  }
}

// Register the Loyalty component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(ScrollLazyLoadComponent.CLASS_NAMESPACE, ScrollLazyLoadComponent);
