export { validate, Rules }

import { Enum } from '../common/utils.js'

const Rules = Enum(
  'AllowedChars',
  'DigitOrSpecialChar',
  'Length',
  'Lowercase',
  'Uppercase'
)

const validate = password =>
  Object.entries(validators).reduce(accumulateViolatedRules(password || ''), [])

const validators = {
  [Rules.AllowedChars]: x =>
    new RegExp(`^[0-9a-zA-Z${specialChars}]*$`).test(x),
  [Rules.DigitOrSpecialChar]: x => new RegExp(`[0-9${specialChars}]`).test(x),
  [Rules.Lowercase]: x => /[a-z]/.test(x),
  [Rules.Uppercase]: x => /[A-Z]/.test(x),
  [Rules.Length]: ({ length: l }) => l >= 8 && l <= 20,
}

const accumulateViolatedRules =
  password =>
  (acc, [rule, isValid]) => {
    if (!isValid(password)) acc.push(rule)
    return acc
  }

const specialChars =
  "@§\\=\\!\\?;\\:\\.\\<\\>\\/\\|\\\\%_\\-\\*#\\$€£%\\^&\\+\\=\\(\\{\\[\\]\\}\\)éçàèù'"
