export const getRegisterWithContextUrl = args => {
  if (!args.context || args.context === 'enroll-loyalty')
    return getNewRegisterUrl(args)

  if (args.context === 'meeting-planner')
    return getMeetingPlannerRegisterUrl(args)

  return getRegisterUrl(args)
}

const getRegisterUrl = ({ promoCode, redirect, context }) =>
  attachQueryParamsToString(
    ALL_ACCOR_COM + `/authentication/index.${getLang()}.shtml#/register`,
    removeNullAndUndefinedEntries({ context, promoCode, redirect })
  )

export const getNewRegisterUrl = ({ promoCode, redirect }) =>
  attachQueryParamsToString(
    ALL_ACCOR_COM + `/enroll-loyalty/index.${getLang()}.shtml#/enroll-member`,
    removeNullAndUndefinedEntries({ promoCode, redirect })
  )

export const getMeetingPlannerRegisterUrl = ({ promoCode, redirect }) =>
  attachQueryParamsToString(
    ALL_ACCOR_COM +
      `/enroll-loyalty/index.${getLang()}.shtml#/subscribe/meeting-planner/register-your-business`,
    removeNullAndUndefinedEntries({ promoCode, redirect })
  )

import {
  getLang,
  attachQueryParamsToString,
  removeNullAndUndefinedEntries,
} from '../common/utils.js'

import { ALL_ACCOR_COM } from '../common/constants.js'
