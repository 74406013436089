/**
 * @author Jonas.Fournel
 * @fileOverview
 */
export default class BasePageTemplate extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'base-page';


  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);

    this.setSessionReferrer();
    if (document.readyState === CoreJS.DomEventConstants.READY_STATE_CHANGE_COMPLETE) {
      this.triggerAcquisitionApi();
    } else {
      document.addEventListener(CoreJS.DomEventConstants.DOM_CONTENT_LOADED, this.triggerAcquisitionApi);
    }
  }

  /**
     * Calling Acquisition Tracking API on every pages
     */
  triggerAcquisitionApi() {
    new CoreJS.AquisitionTrackingApiIntegration(this.aquisitionApiUrl, this.aquisitionApiKey).createAcquisitionTrackingCookies();
  }

  /**
   * Set referrer in session storage
   */
  setSessionReferrer() {
    if (sessionStorage.getItem(CoreJS.StorageConstants.REFERRER) == null || sessionStorage.getItem(CoreJS.StorageConstants.REFERRER) == '') {
      if (document.referrer != '') {
        sessionStorage.setItem(CoreJS.StorageConstants.REFERRER, document.referrer);
      } else {
        sessionStorage.setItem(CoreJS.StorageConstants.REFERRER, window.location.href);
      }
    }
  }
}

CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, BasePageTemplate);
