import { REFERENTIALS_BASE_URL, PROD_API_KEY } from '../common/constants'
import { getLang } from '../common/lang'
import Axios from 'axios'

async function getReferentials({ params = 'all', lang }) {
  const { data } = await Axios.get(REFERENTIALS_BASE_URL + params, {
    headers: {
      'Accept-Language': lang || getLang(),
      apiKey: PROD_API_KEY,
    },
  })

  return data
}

export { getReferentials }
