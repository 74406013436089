/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class Modal extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-modal-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.popUpLink = this.componentHost.querySelector('[data-modal]');
    this.closeButton = this.componentHost.querySelector('.ace-modal__close-button button');
    this.modal = this.componentHost.querySelector('.ace-modal');
    this.pageModal = this.componentHost.getAttribute('page-modal');
    this.calltoAction = this.componentHost?.querySelector('a');
    this.calltoAction?.setAttribute('href', 'javascript:void()');
    const closeButtonClick = 'close';
    this.closeButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closePopIn(closeButtonClick);
    });
    this.escapeClose();

    // page modal from content fragement
    if (this.pageModal) {
      const { modalPath } = document.body?.dataset;
      if (!CoreJS.CookieHelper.getCookieValue(CoreJS.CookieConstants.MODAL_CLOSED)) {
        this.getUserDataModal(modalPath);
      }
    } else { // for modal component
      this.popUpLink?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        // page modal from experience fragment
        if (this.componentHost.getAttribute('data-component-modal-path')) {
          const modalPath = this.componentHost.getAttribute('data-component-modal-path');
          this.getUserDataModal(modalPath);
        } else {
          this.openPopIn(event);
        }
      });
    }
  }
  /*
  Function to open the popin
  */
  openPopIn() {
    const popShow = this.modal;
    // Set the display property of 'popShow' element to 'block'
    popShow.style.display = 'block';
    popShow.setAttribute('aria-modal', 'true');
    document.body.classList.add('disable-scroll-modal');
    this.closeButton?.focus();
    this.closeButton.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
      if (e.which === 9) {
        e.preventDefault();
      }
    });
    document.addEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.closeModalWindowClick(clickEvent));
  }

  /**
   * call api for content fragement data and inject data in modal
   * @param {string} closeType
   */
  closePopIn(closeType) {
    const popShow = this.modal;
    popShow.style.display = 'none';
    popShow.setAttribute('aria-modal', 'false');
    document.body.classList.remove('disable-scroll-modal');
    this.popUpLink?.focus();
    if (!CoreJS.CookieHelper.getCookieValue(CoreJS.CookieConstants.MODAL_CLOSED) && closeType == 'close') {
      CoreJS.CookieHelper.createCookie(CoreJS.CookieConstants.MODAL_CLOSED, true);
    }
  }
  /*
  Function to handle escape close action.
  */
  escapeClose() {
    const popShow = this.modal;
    if (popShow?.getAttribute('aria-modal') === 'true') {
      window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
          this.closePopIn();
        }
      });
    }
  }

  /**
   * call api for content fragement data and inject data in modal
   * @param {string} contentPath
   */
  getUserDataModal(contentPath) {
    const url = `${contentPath}/master.model.json`;
    new CoreJS.XHRPromisifiedRequest(
      url,
      CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET
    )
      .setRequestHeaders({ 'Content-Type': 'application/json' })
      .executeRequest()
      .then((response) => {
        const items = ':items';
        const dataResponse = JSON.parse(response.responseText);
        if (dataResponse[items]?.root[items]?.container[items]?.text && dataResponse[items]?.root[items]?.container[items]?.text.text) {
          this.componentHost.querySelector('.ace-modal__description').innerHTML = dataResponse[items]?.root[items]?.container[items]?.text.text;
        }
        if (dataResponse[items]?.root[items]?.container[items]?.core_title && dataResponse[items]?.root[items]?.container[items]?.core_title?.text) {
          const modalTitle = document.createElement('h1');
          modalTitle.innerText = dataResponse[items]?.root[items]?.container[items]?.core_title?.text;
          this.componentHost.querySelector('.ace-modal__description')?.prepend(modalTitle);
        }
        this.openPopIn();
      })
      .catch((error) => console.error(error));
  }

  /*
   * Close modal when click outside modal container
   */
  closeModalWindowClick(event) {
    if (!event.target.closest('.ace-modal__content') && this.modal?.getAttribute('aria-modal') == 'true' &&
    (!event.target.closest('.ace-highlight__content--link') && !event.target.closest('.ace-showmap__button'))) {
      this.closePopIn();
    }
  }
}
// Register the Modal component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Modal.CLASS_NAMESPACE, Modal);
