/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */

import { getAuthUrl } from '@accor/customer-api';

export default class SubscriptionBlock extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-subscription-container';
  static AUTO_SUBSCRIBE = 'subscribe';
  static ALREADY_ENROLLED_TO_MULTIPLE_BONUS = 'ALREADY_ENROLLED_TO_MULTIPLE_BONUS';

  /** @inheritDoc */

  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */

  // Initialize Function
  initialize() {
    super.initialize();
    document.addEventListener(CoreJS.DomEventConstants.ON_LOAD, this.loyaltySelector());
    document.addEventListener(CoreJS.DomEventConstants.ON_LOAD, this.onLoyaltyLoad());
    this.offerType = 'activate offer';
    this.failureFlag = false;
  }


  tagManagerPush() {
    const dataLayer = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const pageNameSplit = datalayerValue.pageName;
      const eventData = {
        event: 'GAevent',
        eventCategory: 'promotion',
        eventAction: 'click on cta',
        eventLabel: this.offerType
      };
      TagManager.trackEvent(eventData);
      const eventGA4Data = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: pageNameSplit,
          bloc_name: 'enroll banner',
          bloc_interaction: this.offerType
        }
      };
      TagManager.trackEvent(eventGA4Data);
    }
  }
  /*
  Function to handle loyalty load event.
  */
  onLoyaltyLoad() {
    if (!this.oacAllCookie) {
      this.loyaltyButtonSpan.parentElement.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        e.preventDefault();
        this.openUserLogin();
        this.tagManagerPush();
      });
    } else {
      const loyaltyBonus = CoreJS.ResponsiveUtils.setLoyaltyBonusInSession(this.apiFormBody);
      loyaltyBonus.then((loyaltyBonusData)=>{
        if (!loyaltyBonusData.isBonus) {
          // for bonus not active
          if (loyaltyBonusData.isMember) {
            // For member
            const parsedUrl = new URL(window.location.href);
            const subscribe = parsedUrl.searchParams.get(SubscriptionBlock.AUTO_SUBSCRIBE);
            console.log(subscribe);
            CoreJS.CommonUtils.refreshApiForAuthToken().then((response)=>{
              this.authToken = response;
              if (subscribe == 'true') {
              // Auto subscribe feature
                this.subscriptionApiCall();
              } else {
                this.loyaltyButtonSpan.parentElement.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
                  e.preventDefault();
                  this.subscriptionApiCall();
                  if (this.failureFlag === true) {
                    this.offerType = 'activate offer - error';
                  }
                  this.tagManagerPush();
                });
              }
            });
          }
        } else {
          // When bonus is active
          this.successHandler();
        }
      });
    }
  }

  /*
  Function to select loyalty.
  */
  loyaltySelector() {
    // assigning value from i18n attribute
    this.loyaltyTitle = this.componentHost.querySelector('.ace-subscription__block__des');
    this.loyaltyTitle.classList.remove('show');
    this.loyaltyTitle.classList.add('hide');
    this.loyaltyButtonSpan = this.componentHost.querySelector('.ace-subscription__btn span');
    this.loyaltyButtonSpan.innerHTML = this.componentHost?.getAttribute('data-cta');
    this.loyaltyButtonSpan?.classList?.remove('icon-loader');
    this.loyaltyButtonSpan?.parentElement?.classList?.remove('loader');
    // selectors
    this.loyaltyDescription = this.componentHost.querySelector('.ace-subscription__block-heading');
    this.loyaltyDescription.classList.remove('error__success');

    this.aceLoyalty = this.componentHost.querySelector('.ace-subscription');
    this.aceLoyalty.classList.remove('success');
    this.aceLoyalty.classList.remove('error');

    const { posHandler } = document.body?.dataset;
    this.subscriptionApiKey = JSON.parse(posHandler).key;
    this.oacAllCookie = CoreJS.CommonUtils.userLoginStatus();
    this.subscriptionApiUrl = `${this.componentHost?.getAttribute('data-subscription-api-uri')}?appId=all.accor`;
    this.apiFormBody = {};
    const leahBonusCode = this.componentHost?.getAttribute('data-leah-codes')?.split(';');
    Object.assign(this.apiFormBody, { 'offers': leahBonusCode });
  }

  // Calling subscription api
  subscriptionApiCall() {
    const loyaltyButtonSpanText = this.loyaltyButtonSpan.innerHTML;
    this.loyaltyButtonSpan.innerHTML = '';
    this.loyaltyButtonSpan?.classList?.add('icon-loader');
    this.loyaltyButtonSpan?.parentElement?.classList?.add('loader');

    new CoreJS.XHRPromisifiedRequest(this.subscriptionApiUrl, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_POST)
      .setRequestHeaders({ 'Authorization': `Bearer ${this.authToken}`, 'APIKEY': this.subscriptionApiKey, 'Content-Type': 'application/json' })
      .executeRequest(JSON.stringify(this.apiFormBody))
      .then((result) => {
        console.log(result);
        this.loyaltyButtonSpan.innerHTML = loyaltyButtonSpanText;
        this.loyaltyButtonSpan?.classList?.remove('icon-loader');
        this.loyaltyButtonSpan?.parentElement?.classList.remove('loader');
        this.successHandler();
      })
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      .catch((error) => {
        const errorData = JSON.parse(error.responseText);
        if (errorData.code == SubscriptionBlock.ALREADY_ENROLLED_TO_MULTIPLE_BONUS) {
          this.loyaltyButtonSpan.innerHTML = loyaltyButtonSpanText;
          this.loyaltyButtonSpan?.classList?.remove('icon-loader');
          this.loyaltyButtonSpan?.parentElement?.classList.remove('loader');
          this.successHandler();
        } else {
          this.loyaltyButtonSpan.innerHTML = this.componentHost?.getAttribute('data-failure-cta');
          this.loyaltyButtonSpan?.classList?.remove('icon-loader');
          this.loyaltyButtonSpan?.parentElement?.classList?.remove('loader');
          this.failureFlag = true;
          this.failureHandler();
        }
        console.error('error', error);
      });
  }

  setTitleValue(value) {
    const titleElement = this.loyaltyDescription.querySelector('.expressive-heading-05 h3');
    if (titleElement) {
      titleElement.innerHTML = value;
      titleElement.style.setProperty('display', 'block', 'important');
    }
  }
  /* Success handler function.
  Handles the logic for successful completion of operation.
  */
  successHandler() {
    this.aceLoyalty?.classList?.add('success');
    this.loyaltyTitle?.classList?.add('show');
    this.loyaltyTitle?.classList?.remove('hide');
    this.loyaltyDescription?.classList.add('error__success');
    this.setTitleValue(`${this.componentHost?.getAttribute('data-success-title')}`);
    this.loyaltyTitle.innerHTML = `${this.componentHost?.getAttribute('data-success-subtitle')}`;
    this.loyaltyButtonSpan.parentElement.style.display = 'none';
    this.componentHost.querySelector('.ace-subscription__block-success').classList.add('show');
    this.componentHost.querySelector('.ace-subscription__block-success').setAttribute('aria-hidden', false);
    this.componentHost.querySelector('.ace-subscription__block-error').classList.remove('show');
    this.componentHost.querySelector('.ace-subscription__block-error').setAttribute('aria-hidden', true);
  }

  /* Failure handler function.
  Handles the logic for failures/errors.
  */
  failureHandler() {
    this.aceLoyalty?.classList?.add('error');
    this.loyaltyTitle?.classList?.add('show');
    this.loyaltyTitle?.classList?.remove('hide');
    this.loyaltyDescription?.classList?.add('error__success');
    this.setTitleValue(`${this.componentHost?.getAttribute('data-failure-title')}`);
    this.loyaltyTitle.innerHTML = `${this.componentHost?.getAttribute('data-failure-subtitle')}`;
    this.loyaltyButtonSpan.innerHTML = this.componentHost?.getAttribute('data-failure-cta');
    this.componentHost.querySelector('.ace-subscription__block-error').classList.add('show');
    this.componentHost.querySelector('.ace-subscription__block-error').setAttribute('aria-hidden', false);
    this.componentHost.querySelector('.ace-subscription__block-success').classList.remove('show');
    this.componentHost.querySelector('.ace-subscription__block-success').setAttribute('aria-hidden', true);
    this.loyaltyButtonSpan.parentElement.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
      e.preventDefault();
      this.subscriptionApiCall();
      this.offerType = 'activate offer - error';
    });
  }

  /*
  Function to open the user login.
  */
  openUserLogin() {
    this.loyaltyButtonSpan.innerHTML = '';
    this.loyaltyButtonSpan?.classList.add('icon-loader');
    this.loyaltyButtonSpan?.parentElement?.classList.add('loader');
    const parsedUrl = window.location.href;
    console.log(parsedUrl);
    let url;
    // CONDITION TO CHECK IF AUTOSUBSCRIBE ALREADY ADDED IN URL
    if (parsedUrl.includes('?subscribe=true')) {
      url = getAuthUrl({ origin: 'offer', redirect: window.location.href });
    } else {
      url = getAuthUrl({ origin: 'offer', redirect: `${window.location.href}?&subscribe=true` }); // for login prod
    }
    if (CoreJS.CommonUtils.checkEnvironment()) { // for lower env
      url = CoreJS.CommonUtils.convertProdToRecUrl(url);
    }
    window.open(url, '_self');
  }
}

// Register the SubscriptionBlock component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(SubscriptionBlock.CLASS_NAMESPACE, SubscriptionBlock);
