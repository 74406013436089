import { APP_ID, APP_ID_PARAM_NAME } from '../../common/constants.js'
import { addParamInterceptor } from './add-param-interceptor-factory'

const appIdInterceptor = async axiosInstance => {
  axiosInstance.interceptors.request.use(config =>
    addParamInterceptor(APP_ID_PARAM_NAME, config.appId || APP_ID, config)
  )
}

export default appIdInterceptor
