export { getRegisterFormUrl }

const getRegisterFormUrl = ({
  context,
  promoCode,
  redirect = ALL_ACCOR_COM,
} = {}) =>
  attachDefinedQueryParamsToString(SECURE_ACCOR_COM + '/lien_externe.svlt', {
    goto: 'create-account-token',
    ui_locales: getLang(),
    redirect_site_uri: context
      ? getRegisterWithContextUrl({ promoCode, context, redirect })
      : redirect,
    redirect_uri: OIDC_LANDING_URL,
  })

import {
  ALL_ACCOR_COM,
  OIDC_LANDING_URL,
  SECURE_ACCOR_COM,
} from '../common/constants.js'
import { getRegisterWithContextUrl } from './getRegisterWithContextUrl.js'
import { getLang, attachDefinedQueryParamsToString } from '../common/utils.js'
