export default class BackgroundSection extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'section-background-container';

  constructor(componentHost) {
    super(componentHost);
    this.init();
  }

  init() {
    this.setBackgroundImageIfNeeded();
  }

  setBackgroundImageIfNeeded() {
    const imageSrc = this.componentHost.querySelector('.ace-bgColor[data-bg-img-src]')?.dataset.bgImgSrc;

    if ( imageSrc ) {
      this.componentHost.style.backgroundImage = `url('${window.location.origin}${imageSrc}')`;
      this.componentHost.classList.add('has-background-image');
    }
  }
}

// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  BackgroundSection.CLASS_NAMESPACE,
  BackgroundSection
);
