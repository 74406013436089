/* eslint-disable no-unused-expressions */
/**
 * @author Alpana
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
import { getContact, getPlans, getLatamAuthUrl } from '@accor/customer-api';

export default class TableContainer extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-table-container';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.aceTableContainertableCards = this.componentHost?.querySelectorAll('.ace-table-container__head .ace-table-container__table .ace-table-container__heading');
    this.aceTableContainerContent = this.componentHost?.querySelectorAll('.ace-table-container__accordian-content');

    if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.addClassesCarusel();
    }


    const getUser = getContact();
    const fetchUserLoyaltyProgram = async () => {
      try {
        const response = await getUser({
          fields: 'loyalty.coBrandedCards.coBrandedCard,loyalty.allSignatureSubscriptions'
        });
        return response;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };

    // check if plan button is to be disabled
    this.tableFooter = this.componentHost?.querySelectorAll('.ace-table-container__foot');
    this.footButtonDisable = this.tableFooter[1]?.querySelector('.ace-button');
    if (this.footButtonDisable?.getAttribute('data-force-disable') !== 'true') {
      this.getContactData = fetchUserLoyaltyProgram();
    }
    // get all plan details
    this.plansDetails = getPlans();
    // Select Switcher Period
    this.switcherPeriod = this.componentHost.querySelectorAll('.ace-table-container__switcher-text');
    this.tablePlan = this.componentHost?.querySelectorAll('.ace-table-container__plan');
    this.tableContentCell = this.componentHost?.querySelectorAll('.ace-table-container__content-cell');
    // map plan id with thead and tfoot
    this.mapPlanwithHead();


    this.switcherPeriod.forEach((item)=>{
      if (item.classList.contains('active')) {
        this.displayPeriodPlans(item);
      }
      item.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        this.selectSwitcherPeriod(event);
      });
      this.addAriaAttributeToSwitcher(item);

      item.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter || event.code === CoreJS.Constants.KEYBOARD_EVENT_KEYS.space) {
          event.preventDefault();
          this.selectSwitcherPeriod(event);
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.right) {
          if (event.target.nextElementSibling) {
            event.target.nextElementSibling.focus();
            event.target.nextElementSibling.click();
          } else {
            event.target.parentElement.firstElementChild?.focus();
            event.target.parentElement.firstElementChild?.click();
          }
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.left) {
          if (event.target.previousElementSibling) {
            event.target.previousElementSibling.focus();
            event.target.previousElementSibling.click();
          } else {
            event.target.parentElement.lastElementChild?.focus();
            event.target.parentElement.lastElementChild?.click();
          }
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.home) {
          event.target.parentElement.firstElementChild?.focus();
          event.target.parentElement.firstElementChild?.click();
          event.preventDefault();
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.end) {
          event.target.parentElement.lastElementChild?.focus();
          event.target.parentElement.lastElementChild?.click();
          event.preventDefault();
        }
      });
    });

    // Open close Plan Dropdown
    this.selectPlanDropdownButton = this.componentHost?.querySelector('.ace-table-container__select-dropdown');
    this.selectPlanOpenIcon = this.componentHost?.querySelector('.ace-heading-dropdown__icon');
    this.selectPlanDropdownButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.selectPlanDropdownButton.getAttribute('aria-expanded') === 'true' ?
        this.closePanelDropdown(this.selectPlanDropdownButton) :
        this.openPanelDropdown(this.selectPlanDropdownButton);
    });

    // Select dropdown label
    this.selectPlanDropdownLabel = this.componentHost?.querySelectorAll('.ace-table-container__list-item');
    this.selectPlanDropdownLabel.forEach((item)=>{
      item.addEventListener(CoreJS.DomEventConstants.CLICK, ()=> {
        this.selectPlanLabel(item);
      });
    });


    this.tableCellAcoordian = this.componentHost?.querySelectorAll('.ace-table-container__accordian-cell');
    // Open close Table Cell accordian
    this.tableCellAcoordian.forEach((item)=>{
      item?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter || event.code === CoreJS.Constants.KEYBOARD_EVENT_KEYS.space || event.type === CoreJS.DomEventConstants.CLICK) {
          item.getAttribute('aria-expanded') === 'true' ?
            this.closeCellAccordian(item) :
            this.openCellAccordian(item);
        }
      });
      // set width
      item.style.width = `${100 / this.tablePlan?.length}%`;
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        item.style.width = '100%';
      }
    });

    this.tableFooter.forEach((item, index)=>{
      item?.querySelector('.ace-table-container__foot-btn')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        // this.getSelectPlanUrl(item);
      });
      this.tablePlan.forEach((obj, id)=>{
        if (index === id) {
          item.setAttribute('data-for-plan', obj.firstElementChild.innerText);
        }
      });
      item.style.width = `${100 / this.tableFooter?.length}%`;
      item.parentElement.parentElement.style.display = 'table';
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        item.style.width = '100%';
      }
    });

    // disable enable select plan button
    this.togglePlanButton();

    this.tablePlan.forEach((obj)=>{
      obj.parentElement.style.width = `${100 / this.tablePlan?.length}%`;
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        obj.parentElement.style.width = '100%';
      }
    });

    this.tableContentCell.forEach((item)=>{
      item.style.width = `${100 / this.tablePlan?.length}%`;
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        item.style.width = '100%';
      }
    });


    const windowBreakpoint = CoreJS.ResponsiveUtils.determineActualBreakpointLabel();
    if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg || windowBreakpoint === CoreJS.ResponsiveUtils.M || windowBreakpoint === CoreJS.ResponsiveUtils.S) {
      const tableSelectLabelClass = this.componentHost?.querySelector('.icon-check-24icon');
      tableSelectLabelClass?.classList.add('active');
    // this.selectPlanData(tableSelect.innerText?.toLowerCase());
    }
    this.buttonNext = this.componentHost.querySelector('.ace-table-container__actions .ace-table-container__action--next');
    this.buttonNext?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.changeToActions('plus');
    });
    this.buttonPrevious = this.componentHost.querySelector('.ace-table-container__actions .ace-table-container__action--previous');
    this.buttonPrevious?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.changeToActions('minus');
    });
  }
  /*
  Function to carusel
  */
  addClassesCarusel() {
    this.aceTableContainertableCards.forEach((content, index)=>{
      if (index != 1) {
        content.classList.add('hide');
      }
    });
    this.aceTableContainerContent.forEach((data)=>{
      const tableContent = data?.querySelectorAll('.ace-table-container__content-cell');
      const footerBtn = data?.querySelectorAll('.ace-table-container__foot');
      tableContent.forEach((item, index)=>{
        if (index != 0 && index !== 1) {
          item.classList.add('hide');
        }
      });
      footerBtn.forEach((item, index)=>{
        if (index != 1) {
          item.classList.add('hide');
        }
      });
    });
  }
  changeToActions(toggle) {
    let countIndex;
    this.aceTableContainertableCards.forEach((content, index)=>{
      if (!content.classList.contains('hide')) {
        content.classList.add('hide');
        countIndex = index;
      }
    });
    this.aceTableContainertableCards.forEach((content, index, array)=>{
      if (toggle == 'plus') {
        if (index == countIndex + 1) {
          content.classList.remove('hide');
          if (index == array.length - 1) {
            this.buttonNext.setAttribute('disabled', '');
            this.buttonPrevious.removeAttribute('disabled');
          } else {
            this.buttonNext.removeAttribute('disabled');
            this.buttonPrevious.removeAttribute('disabled');
          }
        }
      } else if (toggle == 'minus') {
        if (index == countIndex - 1) {
          content.classList.remove('hide');
          if (index == 1) {
            this.buttonPrevious.setAttribute('disabled', '');
            this.buttonNext.removeAttribute('disabled');
          } else {
            this.buttonPrevious.removeAttribute('disabled');
            this.buttonNext.removeAttribute('disabled');
          }
        }
      }
    });

    // For mobile table data change based on the craousal
    this.aceTableContainerContent.forEach((data)=>{
      const tableContent = data?.querySelectorAll('.ace-table-container__content-cell');
      const footerBtn = data?.querySelectorAll('.ace-table-container__foot');

      tableContent.forEach((item, index)=>{
        if (!item.classList.contains('hide') && !item.classList.contains('leftcontent')) {
          item.classList.add('hide');
          item = index;
        }
        if (toggle == 'plus') {
          if (index == countIndex + 1) {
            item.classList.remove('hide');
          }
        }
        if (toggle == 'minus') {
          if (index == countIndex - 1) {
            item.classList.remove('hide');
          }
        }
      });

      footerBtn.forEach((item, index)=>{
        if (!item.classList.contains('hide')) {
          item.classList.add('hide');
          item = index;
        }
        if (toggle == 'plus') {
          if (index == countIndex + 1) {
            item.classList.remove('hide');
          }
        }
        if (toggle == 'minus') {
          if (index == countIndex - 1) {
            item.classList.remove('hide');
          }
        }
      });
    });
  }


  /*
  Function to map the plan with the head.
  */
  mapPlanwithHead() {
    // Create a reversed array 'revArr'
    const revArr = [];
    for (let i = this.tablePlan.length - 1; i >= 0; i--) {
      revArr.push(this.tablePlan[i]);
    }
    const revFoot = [];
    for (let a = this.tableFooter.length - 1; a >= 0; a--) {
      revFoot.push(this.tableFooter[a]);
    }

    this.plansDetails.then((resp)=>{
      resp.items.forEach((item, index)=>{
        for (let i = 0; i < revArr.length - 1; i++) {
          if (i === index) {
            revArr[i].setAttribute('data-planid', item.plan_id);
          }
        }
        for (let a = 0; a < revFoot.length - 1; a++) {
          if (a === index) {
            let planNumber = item.plan_code;
            planNumber = planNumber.replace(/[^0-9]/g, '');
            revFoot[a].setAttribute('data-planid', item.plan_id);
            revFoot[a].setAttribute('data-planNumber', planNumber);
          }
        }
      });
    });
  }
  /**
 * To select period
 * @param {Object} e
 */
  selectSwitcherPeriod(e) {
    this.switcherPeriod.forEach((item)=>{
      item.classList.remove('active');
      item.setAttribute('aria-selected', 'false');
      item.setAttribute('tabindex', '-1');
    });
    e.target.classList.add('active');
    e.target.setAttribute('aria-selected', 'true');
    e.target.setAttribute('tabindex', '0');
    this.displayPeriodPlans(e.target);
    e.target.removeEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.selectSwitcherPeriod(clickEvent));

    // create href

    this.tableFooter.forEach((item)=>{
      const languageString = window.location.pathname;
      const stringArray = languageString.split('/');
      const currentPageLanguage = stringArray[2];
      const selectedPeriod = this.componentHost.querySelector('.ace-table-container__switcher-text.active');
      let periodValue;
      if (selectedPeriod.getAttribute('data-interval') === '4') {
        periodValue = 'quarterly';
      } else if (selectedPeriod.getAttribute('data-interval') === '12') {
        periodValue = 'yearly';
      } else {
        periodValue = 'monthly';
      }
      const hrefVal = getLatamAuthUrl({ freq: periodValue, plan: item.getAttribute('data-planNumber'), lang: currentPageLanguage, state: 'register' });
      item?.querySelector('.ace-table-container__foot-btn')?.setAttribute('href', hrefVal);
    });
  }

  /**
 * Set plan Price data
 * @param {Object} data
 */
  displayPeriodPlans(data) {
    this.plansDetails.then((resp)=>{
      resp.items.forEach((item)=>{
        item.pricing_options.forEach((obj)=>{
          let amountToDisplay;
          if (obj.amount >= 10 && obj.amount < 100) {
            amountToDisplay = `0,${ obj.amount}`;
          } else if (obj.amount < 10) {
            amountToDisplay = `0,0${ obj.amount}`;
          } else {
            amountToDisplay = Math.floor(obj.amount / 100);
          }
          if (data?.getAttribute('data-interval') === '1') {
            if (obj.frequency === 'MONTHLY' && obj.interval === 1) {
              for (let i = 1; i < this.tablePlan.length; i++) {
                if (this.tablePlan[i].getAttribute('data-planid') === item.plan_id) {
                  this.tablePlan[i].lastElementChild.innerText = `R$ ${ amountToDisplay}`;
                }
              }
            }
          } else if (data?.getAttribute('data-interval') === '4') {
            if (obj.frequency === 'MONTHLY' && obj.interval === 3) {
              for (let i = 1; i < this.tablePlan.length; i++) {
                if (this.tablePlan[i].getAttribute('data-planid') === item.plan_id) {
                  this.tablePlan[i].lastElementChild.innerText = `R$ ${ amountToDisplay}`;
                }
              }
            }
          } else if (data?.getAttribute('data-interval') === '12') {
            if (obj.frequency === 'YEARLY' && obj.interval === 1) {
              for (let i = 1; i < this.tablePlan.length; i++) {
                if (this.tablePlan[i].getAttribute('data-planid') === item.plan_id) {
                  this.tablePlan[i].lastElementChild.innerText = `R$ ${ amountToDisplay}`;
                }
              }
            }
          }
        });
      });
    });
  }

  /**
   * To add area-label attribute to each switcher tab
   * @param {Object} data
   */
  addAriaAttributeToSwitcher(data) {
    this.plansDetails.then((resp)=>{
      if (data?.getAttribute('data-interval') === '1') {
        let dataText = '';
        resp.items.forEach((item)=>{
          item.pricing_options.forEach((obj)=>{
            let amountToDisplay;
            if (obj.amount >= 10 && obj.amount < 100) {
              amountToDisplay = `0,${ obj.amount}`;
            } else if (obj.amount < 10) {
              amountToDisplay = `0,0${ obj.amount}`;
            } else {
              amountToDisplay = Math.floor(obj.amount / 100);
            }
            if (obj.frequency === 'MONTHLY' && obj.interval === 1) {
              for (let i = 1; i < this.tablePlan.length; i++) {
                if (this.tablePlan[i].getAttribute('data-planid') === item.plan_id) {
                  dataText = `${this.tablePlan[i].firstElementChild.innerText } R$ ${ amountToDisplay } ${ dataText}`;
                }
              }
            }
          });
        });
        data.setAttribute('aria-label', `${data.innerText } ${ dataText}`);
      } else if (data?.getAttribute('data-interval') === '4') {
        let dataText = '';
        resp.items.forEach((item)=>{
          item.pricing_options.forEach((obj)=>{
            let amountToDisplay;
            if (obj.amount >= 10 && obj.amount < 100) {
              amountToDisplay = `0,${ obj.amount}`;
            } else if (obj.amount < 10) {
              amountToDisplay = `0,0${ obj.amount}`;
            } else {
              amountToDisplay = Math.floor(obj.amount / 100);
            }
            if (obj.frequency === 'MONTHLY' && obj.interval === 3) {
              for (let i = 1; i < this.tablePlan.length; i++) {
                if (this.tablePlan[i].getAttribute('data-planid') === item.plan_id) {
                  dataText = `${this.tablePlan[i].firstElementChild.innerText } R$ ${ amountToDisplay } ${ dataText}`;
                }
              }
            }
          });
        });
        data.setAttribute('aria-label', `${data.innerText } ${ dataText}`);
      } else if (data?.getAttribute('data-interval') === '12') {
        let dataText = '';
        resp.items.forEach((item)=>{
          item.pricing_options.forEach((obj)=>{
            let amountToDisplay;
            if (obj.amount >= 10 && obj.amount < 100) {
              amountToDisplay = `0,${ obj.amount}`;
            } else if (obj.amount < 10) {
              amountToDisplay = `0,0${ obj.amount}`;
            } else {
              amountToDisplay = Math.floor(obj.amount / 100);
            }
            if (obj.frequency === 'YEARLY' && obj.interval === 1) {
              for (let i = 1; i < this.tablePlan.length; i++) {
                if (this.tablePlan[i].getAttribute('data-planid') === item.plan_id) {
                  dataText = `${this.tablePlan[i].firstElementChild.innerText } R$ ${ amountToDisplay } ${ dataText}`;
                }
              }
            }
          });
        });
        data.setAttribute('aria-label', `${data.innerText } ${ dataText}`);
      }
    });
  }
  /**
 * Close panel dropdown
 * @param {HTML} button
 */
  closePanelDropdown(button) {
    button.setAttribute('aria-expanded', 'false');
    button.nextElementSibling.classList.remove('active');
    button.nextElementSibling.setAttribute('aria-hidden', 'true');
    this.selectPlanOpenIcon.classList.add('icon-chevron-down-24icon');
    this.selectPlanOpenIcon.classList.remove('icon-chevron-up-24icon');
    window.removeEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideSelectDropdown(clickEvent));
  }
  /**
 * Open panel dropdown
 * @param {HTML} button
 */
  openPanelDropdown(button) {
    button.setAttribute('aria-expanded', 'true');
    button.nextElementSibling.classList.add('active');
    button.nextElementSibling.setAttribute('aria-hidden', 'false');
    this.selectPlanOpenIcon.classList.remove('icon-chevron-down-24icon');
    this.selectPlanOpenIcon.classList.add('icon-chevron-up-24icon');
    window.addEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideSelectDropdown(clickEvent));

    button.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
      if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
        this.closePanelDropdown(button);
      }
      if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter) {
        this.closePanelDropdown(button);
      }
    });
  }
  /**
 * Windown Click Hide Dropdown Close Panel
 * @param {Event} event
 */
  windowClickHideSelectDropdown(event) {
    if (!event.target.closest('.ace-table-container__select-dropdown')) {
      this.closePanelDropdown(this.selectPlanDropdownButton);
    }
  }
  /**
 * Panel Label for Element Child
 * @param {Object} data
 */
  selectPlanLabel(data) {
    this.selectPlanDropdownLabel.forEach((item)=>{
      item.firstElementChild.classList.remove('active');
    });
    const selectedData = this.componentHost?.querySelector('.ace-table-container__dropdown-value');
    selectedData.innerText = ` ${ data.querySelector('.ace-heading-dropdown__item-label').innerText}`;
    data.firstElementChild.classList.add('active');
    this.selectPlanData(selectedData.innerText?.toLowerCase());
  }
  /**
 * Accordian Close cell
 * @param {Object} item
 */
  closeCellAccordian(item) {
    item.setAttribute('aria-expanded', 'false');
    item.parentElement.parentElement.nextElementSibling.classList.add('close');
    item.parentElement.parentElement.nextElementSibling.setAttribute('aria-hidden', 'true');
    item.lastElementChild.classList.add('icon-chevron-down-24icon');
    item.lastElementChild.classList.remove('icon-chevron-up-24icon');
  }
  /**
 * Accordian Open cell
 * @param {Object} item
 */
  openCellAccordian(item) {
    item.setAttribute('aria-expanded', 'true');
    item.parentElement.parentElement.nextElementSibling.classList.remove('close');
    item.parentElement.parentElement.nextElementSibling.setAttribute('aria-hidden', 'false');
    item.lastElementChild.classList.remove('icon-chevron-down-24icon');
    item.lastElementChild.classList.add('icon-chevron-up-24icon');
  }
  /**
 * Show selected plan Data
 * @param {String} tableSelect
 */
  selectPlanData(tableSelect) {
    this.tablePlan.forEach((item)=>{
      if (item.firstElementChild.innerText?.toLowerCase() === tableSelect?.trim()) {
        item.parentElement.style.float = 'left';
        item.parentElement.style.width = '100%';
        item.parentElement.style.display = '';
        item.style.display = '-webkit-box';
        item.style.padding = '0 0 1.25rem 1rem';
      } else {
        item.parentElement.style.display = 'none';
      }
    });
    this.tableFooter.forEach((item)=>{
      if (item.getAttribute('data-for-plan')?.toLowerCase() === tableSelect?.trim()) {
        item.style.display = '';
      } else {
        item.style.display = 'none';
      }
    });
    this.tableContentCell.forEach((item)=>{
      if (item.getAttribute('data-for-plan')?.toLowerCase() === tableSelect?.trim()) {
        item.style.width = '50%';
        item.style.display = 'table-cell';
      } else {
        item.style.display = 'none';
      }
    });
  }
  /*
  Function to toggle the plan button.
  */
  togglePlanButton() {
    this.plansDetails.then(() => {
      if (this.footButtonDisable?.getAttribute('data-force-disable') === 'true') {
        this.tableFooter.forEach((item)=>{
          item?.querySelector('.ace-table-container__foot-btn')?.removeAttribute('href');
          item?.querySelector('.ace-table-container__foot-btn')?.setAttribute('aria-disabled', 'true');
          item?.querySelector('.ace-table-container__foot-btn')?.setAttribute('role', 'link');
        });
      } else {
        this.getContactData.then((resp)=>{
          if (resp && resp.loyalty.coBrandedCards.coBrandedCard[0].partnerCode === 'ACCOR_BRAZIL' && (resp.loyalty.allSignatureSubscriptions[0].plan.status === 'active' ||
          resp.loyalty.allSignatureSubscriptions[0].plan.status === 'pending_cancellation')) {
            this.tableFooter.forEach((item)=>{
              item?.querySelector('.ace-table-container__foot-btn')?.setAttribute('aria-disabled', 'true');
              item?.querySelector('.ace-table-container__foot-btn')?.setAttribute('role', 'link');
              item?.querySelector('.ace-table-container__foot-btn')?.removeAttribute('href');
            });
          } else {
            this.tableFooter.forEach((item)=>{
              item?.querySelector('.ace-table-container__foot-btn')?.removeAttribute('aria-disabled');
              item?.querySelector('.ace-table-container__foot-btn')?.removeAttribute('role', 'link');
            });
          }
        });
      }
    });
  }
}

// Register the TableContainer component with the BaseComponent
CoreJS.BaseComponent.registerComponent(TableContainer.CLASS_NAMESPACE, TableContainer);

