/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable valid-jsdoc */
/**
 *
 * @author Nitesh.Vaidya
 * @class BookNow
 * @version 1.0
 * @inheritDoc
 */
export default class BookNow extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-booknow-component';

  /** @inheritDoc */
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.minimumNightStay = 1;
    this.maximumNightStay = 30;
    this.minimumCheckInDate = new Date();
    this.maximumCheckInDate = this.addDays(new Date(), 405); // today date + 405 days
    this.minimumAdults = 1;
    this.maximumAdults = 9;
    this.numberOfAdults = this.minimumAdults;
    this.minimumchildrens = 0;
    this.maximumchildrens = 6;
    this.minimumSearchCharacter = 3;
    this.numberOfchildrens = this.minimumchildrens;
    this.dateFormat = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    this.language = document.querySelector('html').getAttribute('lang') ?? 'en';
    this.supportedLanguages = {
      'en': 'en-US',
      'fr': 'fr-FR',
      'de': 'de-DE',
      'it': 'it-IT',
      'th': 'th-TH',
      'id': 'id-ID',
      'ar': 'ar-AR',
      'pt-br': 'pt-br',
      'tr': 'tr-TR',
      'zh': 'zh-ZH'
    };
    this.i18nStrings = JSON.parse(this.componentHost.getAttribute('data-i18n'));
    this.pickers = document.querySelectorAll('duet-date-picker');
    if (this.pickers) {
      const mutationConfig = {
        childList: true,
        subtree: true
      };
      let readyPickers = this.componentHost.querySelectorAll('duet-date-picker.hydrated .duet-date__input')?.length;
      this.pickers.forEach((picker) => {
        const observer = new MutationObserver(() => {
          readyPickers++;
          if (readyPickers === 2) {
            this.booknowSelector();
            this.bindBooknowEvents();
            this.configLinkBuilder();
            this.responseData = [];
            this.locationInput.placeholder = this.i18nStrings.location;
          }
        });
        observer.observe(picker, mutationConfig);
      });
    }
  }

  booknowSelector() {
    this.booknowOpenButton = this.componentHost.querySelector(
      '.ace-booknow__open--button'
    );
    this.booknowCloseButton = this.componentHost.querySelector(
      '.ace-booknow__close--button'
    );
    this.locationInput = this.componentHost.querySelector(
      '.ahAutocomplete__input input'
    );
    this.booknowForm = this.componentHost.querySelector('form');
    this.booknowFormSubmitButton = this.componentHost.querySelector(
      '.ace-booknow__footer button'
    );
    this.booknowDropdownsTrigger = this.componentHost.querySelectorAll(
      '[data-toggle-dropdown]'
    );
    this.booknowDropdownsList =
              this.componentHost.querySelectorAll('[data-dropdown-id]');
    this.adultCounter = this.componentHost.querySelector(
      '.ace-booknow__counter--adult'
    );
    this.adultIncrementCounter = this.adultCounter.querySelector(
      "[data-counter='increment']"
    );
    this.adultDecrementCounter = this.adultCounter.querySelector(
      "[data-counter='decrement']"
    );
    this.childrenCounter = this.componentHost.querySelector(
      '.ace-booknow__counter--children'
    );
    this.childrenDecrementCounter = this.childrenCounter.querySelector(
      "[data-counter='decrement']"
    );
    this.childrenIncrementCounter = this.childrenCounter.querySelector(
      "[data-counter='increment']"
    );
    this.childrenSelectDropdown = this.componentHost.querySelectorAll(
      '.ace-booknow__select'
    );
    this.locationSelection = this.componentHost.querySelectorAll('[data-slug]');
    this.checkinDatePicker = this.componentHost.querySelector(
      "[data-date='checkin']"
    );
    this.checkoutDatePicker = this.componentHost.querySelector(
      "[data-date='checkout']"
    );
    this.delegateDates = this.componentHost.querySelector(
      '.ace-booknow__dates-delegate button'
    );
    this.guestInput = this.componentHost.querySelector(
      '.ace-booknow__guests > .ace-booknow__input'
    );
    this.locationContainer = this.componentHost.querySelector(
      '.ace-booknow__location'
    );
    this.localization = this.componentHost
      .querySelector('[data-localization]')
      .getAttribute('data-localization');
    this.errormessage = this.componentHost.querySelector(
      '[data-error-message]'
    );
  }

  /**
       * Link builder defaults
       */
  configLinkBuilder() {
    this.parameters = {
      contextSite: 'contextSite',
      destination: 'nom_ville',
      language: 'code_langue',
      arrivalDate: 'jour_arrivee',
      arrivalMonth: 'mois_arrivee',
      arrivalYear: 'annee_arrivee',
      lengthOfStay: 'nb_nuit',
      adultNumber: 'adultNumber',
      childrenNumber: 'childrenNumber',
      childrenAge: 'childrenAge',
      lodging: 'lodging',
      sub: 'sub'
    };
    this.bookNowLinkBuilder = new Map();
    this.bookNowLinkBuilder.set(this.parameters.language, this.language);
    this.bookNowLinkBuilder.set(
      this.parameters.arrivalDate,
      new Date(this.checkinDatePicker.value).getDate()
    );
    this.bookNowLinkBuilder.set(
      this.parameters.arrivalMonth,
      new Date(this.checkinDatePicker.value).getMonth() + 1
    );
    this.bookNowLinkBuilder.set(
      this.parameters.arrivalYear,
      new Date(this.checkinDatePicker.value).getFullYear()
    );
    this.bookNowLinkBuilder.set(
      this.parameters.lengthOfStay,
      this.getDaysDiffrence()
    );
    this.bookNowLinkBuilder.set(
      this.parameters.adultNumber,
      this.numberOfAdults
    );
    this.bookNowLinkBuilder.set(
      this.parameters.childrenNumber,
      this.numberOfchildrens
    );
    for (
      let childrenCount = 0;
      childrenCount < this.numberOfchildrens;
      childrenCount++
    ) {
      this.bookNowLinkBuilder.set(
        `${this.parameters.childrenAge}[${childrenCount}]`,
        this.componentHost.querySelector(
          `[data-children="${childrenCount + 1}"]`
        )?.value
      );
    }
    this.bookNowLinkBuilder.set(this.parameters.sub, 'ISP');
    this.bookNowLinkBuilder.set(this.parameters.lodging, 'RST');
  }

  /**
       * Show date picker's
       */
  showDatePicker() {
    this.checkinDatePicker.show();
    this.checkoutDatePicker.show();
  }

  /**
       * Add number of days to a Date (Long format) and returns the result Date
       * @param {Date} date
       * @param {Date} days
       * @returns {Date}
       */
  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  /**
       * Day Number of days diffrence betweeen checkin & checkout dates
       * @return {Date}
       */
  getDaysDiffrence() {
    return Math.ceil(
      Math.abs(
        new Date(this.checkoutDatePicker.value) -
                  new Date(this.checkinDatePicker.value)
      ) /
              (1000 * 60 * 60 * 24)
    );
  }

  /**
       * Set the date format for all page's date pickers (according to duet date picker library)
       */
  datePickerFormat() {
    for (const picker of this.pickers) {
      picker.dateAdapter = {
        parse(value = '', createDate) {
          const matches = value.match(this.dateFormat);
          if (matches) {
            return createDate(matches[3], matches[2], matches[1]);
          }
        },
        format(date) {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          switch (document.documentElement.getAttribute('lang')) {
            case 'en':
              return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${date.getFullYear()}`;
            case 'fr':
              return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${date.getFullYear()}`;
            case 'de':
              return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${date.getFullYear()}`;
            case 'it':
              return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${date.getFullYear()}`;
            case 'th':
              return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${date.getFullYear()}`;
            case 'id':
              return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${date.getFullYear()}`;
            default:
              return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${date.getFullYear()}`;
          }
        }
      };
      picker.localization = JSON.parse(this.localization);
    }
    this.componentHost.querySelectorAll('.duet-date__table-header')?.forEach((element) => {
      element.querySelector('span:first-child').innerText = element.querySelector('.duet-date__vhidden')?.innerText.substring(0, 3).toLowerCase();
    });
    this.checkinDatePicker.previousElementSibling.setAttribute(
      'for',
      this.checkinDatePicker.getAttribute('identifier')
    );
    this.checkoutDatePicker.previousElementSibling.setAttribute(
      'for',
      this.checkoutDatePicker.getAttribute('identifier')
    );
  }

  /**
       * Convert Date Long format to ISO-8601 and return the date formatted
       * @param parameterDate {Date} - Date to be transformed to display in YYYY-MM-DD format
       * @return {Date}
       */
  dateFormatConverter(parameterDate) {
    if (parameterDate) {
      const year = parameterDate.getFullYear();
      let month = parameterDate.getMonth() + 1;
      let date = parameterDate.getDate();

      if (date < 10) {
        date = `0${date}`;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      return `${year}-${month}-${date}`;
    }
  }

  /**
       * Handle the date picker elements configuration
       * Store checkIn and checkout data
       */
  configDatePicker() {
    if (this.checkinDatePicker && this.checkoutDatePicker) {
      // show by default checkIn and checkout datepicker
      this.showDatePicker();
      // set the language attribute for checkin and checkout
      this.checkinDatePicker.setAttribute('language', this.language);
      this.checkoutDatePicker.setAttribute('language', this.language);
      // set value to check in and checkout
      // set min and max attributes for the checkin
      this.checkinDatePicker.setAttribute(
        'value',
        this.dateFormatConverter(this.minimumCheckInDate)
      );
      this.checkoutDatePicker.setAttribute(
        'value',
        this.dateFormatConverter(this.addDays(this.minimumCheckInDate, 1))
      );

      // set min and max attributes for the checkin
      this.checkinDatePicker.setAttribute(
        'min',
        this.dateFormatConverter(this.minimumCheckInDate)
      );
      this.checkinDatePicker.setAttribute(
        'max',
        this.dateFormatConverter(this.maximumCheckInDate)
      );

      // set min and max attributes for the checkout
      this.checkoutDatePicker.setAttribute(
        'min',
        this.dateFormatConverter(
          this.addDays(new Date(this.minimumCheckInDate), this.minimumNightStay)
        )
      );
      this.checkoutDatePicker.setAttribute(
        'max',
        this.dateFormatConverter(
          this.addDays(new Date(this.minimumCheckInDate), this.maximumNightStay)
        )
      );
    }
  }

  /**
       * Booknow open handler
       */
  openBooknow() {
    this.componentHost.classList.add('show');
    this.booknowCloseButton.focus();
    document.querySelector('body').classList.add('ace-booknow__open');
    document.documentElement.classList.add('fixedbyModal');
    this.componentHost.setAttribute('aria-modal', true);
  }

  /**
       * Handler to detect small device
       * @return {Boolean}
       */
  isSmallDevice() {
    return window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg;
  }

  /**
       * Booknow close handler
       */
  closeBooknow() {
    if (this.componentHost.classList.contains('show')) {
      this.booknowOpenButton.focus();
    }
    this.componentHost.classList.remove('show');
    document.querySelector('body').classList.remove('ace-booknow__open');
    if (this.isSmallDevice()) {
      this.booknowSummaryEvent();
    }
    this.componentHost.setAttribute('aria-modal', false);
    document.documentElement.classList.remove('fixedbyModal');
  }

  /**
       * Booknow open dropdown handler
       */
  toggleDropdown(state, selector, event) {
    if (state) {
      document
        .querySelector(`[data-dropdown-id='${selector}']`)
        .classList.remove('show');
      event.target.setAttribute('aria-expanded', false);
    } else {
      document
        .querySelector(`[data-dropdown-id='${selector}']`)
        .classList.add('show');
      event.target.setAttribute('aria-expanded', true);
      event.target.classList.add('opened');
    }
  }

  /**
       * Validate location input field
       */
  validateLocationInput() {
    const booknowLocation = this.componentHost.querySelector('.ace-booknow__location');
    const closeValueSelected = this.componentHost.querySelector('.close-button__wrapper');
    this.locationInput.value !== '' && closeValueSelected &&
          this.locationInput.value.length > this.minimumSearchCharacter ?
      booknowLocation.classList.remove('ace-booknow__location-error') :
      booknowLocation.classList.add('ace-booknow__location-error');
  }

  /**
       * Hide all dropdown
       */
  hideAllBooknowDropdowns() {
    this.booknowDropdownsList.forEach((dropdown) => {
      dropdown.classList.remove('show');
    });
    this.booknowDropdownsTrigger.forEach((dropdown) => {
      if (!dropdown.getAttribute('required')) {
        dropdown.setAttribute('aria-expanded', false);
      }
      dropdown.classList.remove('opened');
    });
    this.locationInput.nextElementSibling.style.display = 'none';
  }

  /**
       * Resize or scrren orientation handler
       *
       */
  screenEventHandler() {
    if (window.innerWidth >= CoreJS.ResponsiveConstants.lg) {
      this.closeBooknow();
    }
  }

  /**
       * Close dropdown on clicking outside
       */
  dropdownOutsideClickHandler() {
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      window.addEventListener(
        listener,
        (event) => {
          if (
            !event.target.closest('.ace-booknow__dropdown') &&
                          !event.target.hasAttribute('data-toggle-dropdown')
          ) {
            this.hideAllBooknowDropdowns();
          }
        },
        false
      );
    });
  }

  /**
       * Escape key handler
       */
  escapeKeyHandler() {
    window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
        this.hideAllBooknowDropdowns();
        if (this.isSmallDevice() && this.componentHost.classList.contains('show')) this.closeBooknow();
      }
    });
  }

  /**
       *   input clear handler
       */
  clearInputValue() {
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      this.booknowForm.querySelectorAll('[data-clear]')?.forEach((item) => {
        item.addEventListener(listener, (event) => {
          if (event.type === CoreJS.DomEventConstants.CLICK) {
            event.preventDefault();
            const clearTrigger = event.target.getAttribute('data-clear');
            document.querySelector(clearTrigger).value = '';
            this.locationInput.nextElementSibling.style.display = 'none';
            this.errorHandler(false);
          }
        });
      });
    });
  }

  /**
       * Error Handler
       */
  errorHandler(status) {
    if (status) {
      this.locationContainer.classList.add('ace-booknow__location-error');
      this.errormessage.innerText =
                  this.errormessage.getAttribute('data-error-empty');
    } else {
      this.locationContainer.classList.remove('ace-booknow__location-error');
      this.errormessage.innerText = this.errormessage.getAttribute(
        'data-error-required'
      );
    }
  }


  /**
       *
       * @param day
       * @return day with the suffix ordinal string
       */
  ordinalSuffixOf(day) {
    const modTen = day % 10;
    const modHundred = day % 100;
    if (modTen === 1 && modHundred !== 11) {
      return `${day}st`;
    }
    if (modTen === 2 && modHundred !== 12) {
      return `${day}nd`;
    }
    if (modTen === 3 && modHundred !== 13) {
      return `${day}rd`;
    }
    return `${day}th`;
  }

  /**
       *
       * @return Summary event for the dates
       */
  dateSummaryEvent(checkInDate, checkOutDate) {
    const monthName = new Intl.DateTimeFormat(this.supportedLanguages[this.language], { month: 'long' }).format;
    if (this.language === 'fr') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'de') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'it') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'th') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'id') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'ar') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'pt-br') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'tr') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    if (this.language == 'zh') {
      return `
          <span>
           ${new Date(checkInDate)?.getDate()}
           ${monthName(new Date(checkInDate))}
          </span>
          <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
          <span>
           ${new Date(checkOutDate)?.getDate()}
           ${monthName(new Date(checkOutDate))}
          </span> `;
    }
    return `
         <span>
         ${monthName(new Date(checkInDate))} ${this.ordinalSuffixOf(
  new Date(checkInDate)?.getDate()
)}
         </span>
         <span aria-hidden="true" class="icon icon-arrow-right-24icon"></span>
         <span>
           ${monthName(new Date(checkOutDate))} ${this.ordinalSuffixOf(
  new Date(checkOutDate)?.getDate()
)}
         </span> `;
  }

  /**
       *
       * @return guest summary
       */
  guestSummaryEvent() {
    if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      return `${this.numberOfAdults + this.numberOfchildrens} ${this.i18nStrings.guest}`;
    }
    return `${this.numberOfAdults} ${this.i18nStrings.adult} ${this.numberOfchildrens ?
      ` . ${this.numberOfchildrens} ${this.i18nStrings.children}` :
      ''
    } `;
  }

  /**
       * booknow summary events
       */
  booknowSummaryEvent() {
    if (this.locationInput.value !== '') {
      const monthName = new Intl.DateTimeFormat(this.supportedLanguages[this.language], { month: 'short' })
        .format;
      this.booknowOpenButton.innerHTML = `<span>${this.locationInput.value
      }<span> • ${monthName(
        new Date(this.checkinDatePicker.value)
      )}. ${new Date(this.checkinDatePicker.value).getDate()} - ${monthName(
        new Date(this.checkoutDatePicker.value)
      )}. ${new Date(this.checkoutDatePicker.value).getDate()} • ${this.numberOfAdults + this.numberOfchildrens
      } ${this.i18nStrings.guest} `;
    } else {
      this.booknowOpenButton.innerHTML = `<span aria-hidden="true" class="icon icon-search-24icon"></span> ${this.i18nStrings.location}`;
    }
  }

  /**
       * To validate destination field
       * @param {Object} formData
       */
  validateDestinationBlock = (formData) => {
    const destination = this.componentHost.querySelector(
      '[name="search.destination"]'
    )?.value;
    const label = this.componentHost.querySelector('[name="search.destination.labelSelected"]').value;
    if (destination !== '' && label.length >= 4) {
      formData.append(
        'search.destination.userlang',
        this.componentHost.querySelector("[name='search.destination.userlang']")
          .value
      );
      this.componentHost
        .querySelectorAll('.ahAutocomplete__input__hidden input')
        ?.forEach((input) => {
          formData.append(input.name, input.value);
        });
      const participatingChain = 'filter.brands';
      const selectiveTK = ['identification.rateAccess1', 'identification.sub'];
      let addValue = false;
      this.componentHost
        .querySelectorAll('.booking-engine input')
        ?.forEach((input) => {
          if (input.name === participatingChain) {
            if (input.value) {
              const inputValue = input.value?.replaceAll(';', '|');
              formData.append(input.name, inputValue);
            }
          }
          if (input.name === 'identification.rateAccess1') {
            if (input.value) {
              addValue = true;
            }
          }
          if (addValue) {
            selectiveTK.forEach((val) => {
              if (input.name === val) {
                formData.append(input.name, input.value);
              }
            });
          }
        });
    } else {
      this.errorGenerator(
        'input[id="search.destination.userlang"]',
        'search.destination.userlang',
        this.i18nStrings.destination,
        'destination',
        'destination',
        'destination'
      );
    }
  };

  /**
       * Location search
       */
  locationSearch() {
    this.locationInput.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.backspace || event.which >= 65 && event.which <= 90) {
        this.locationInput.classList.remove('autocomplete-selected');
        this.locationInput.classList.add('autocomplete-selected');
        this.locationInput.setAttribute('aria-expanded', true);
        this.errorHandler(false);
      }
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter && !this.locationInput.classList.contains('autocomplete-selected')) {
        this.validateLocationInput();
      }

      if (this.locationInput.value.length === this.minimumSearchCharacter && !this.locationInput.classList.contains('autocomplete-selected')) {
        this.locationContainer.classList.remove('ace-booknow__location-error');
        if (this.isSmallDevice()) this.locationInput.nextElementSibling.style.display = 'flex';
      } else if (this.locationInput.value.length > this.minimumSearchCharacter && !this.locationInput.classList.contains('autocomplete-selected')) {
      } else {
        this.locationInput.nextElementSibling.style.display = 'none';
        this.componentHost.querySelector('.assertive').innerHTML = `${this.i18nStrings.suggestion}`;
      }
      if (this.minimumSearchCharacter > this.locationInput.value.length && this.locationInput.classList.contains('autocomplete-selected')) {
        this.locationInput.classList.remove('autocomplete-selected');
        this.locationInput.setAttribute('aria-expanded', false);
      }
    });
  }

  /**
       * to validate user events
       */
  validateUserSelection(event) {
    return !!(event.type === CoreJS.DomEventConstants.CLICK ||
              event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.space ||
              event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter);
  }

  /**
       * Bind children guests events
       */
  childrenCounterHandler(counterType) {
    switch (counterType) {
      case 'increment':
        if (
          this.numberOfchildrens >= this.minimumchildrens &&
                      this.numberOfchildrens <= this.maximumchildrens - 1
        ) {
          this.numberOfchildrens = this.numberOfchildrens + 1;
          this.childrenDecrementCounter?.removeAttribute('disabled');
          if (this.numberOfchildrens === this.maximumchildrens) {
            this.childrenIncrementCounter?.setAttribute('disabled', true);
            this.childrenDecrementCounter?.focus();
          }
        } else {
          this.childrenIncrementCounter?.setAttribute('disabled', true);
          this.childrenDecrementCounter?.focus();
        }

        break;
      case 'decrement':
        if (
          this.numberOfchildrens >= this.minimumchildrens + 1 &&
                      this.numberOfchildrens <= this.maximumchildrens
        ) {
          this.numberOfchildrens = this.numberOfchildrens - 1;
          this.childrenIncrementCounter?.removeAttribute('disabled');
          if (this.numberOfchildrens === this.minimumchildrens) {
            this.childrenDecrementCounter?.setAttribute('disabled', true);
            this.childrenIncrementCounter?.focus();
          }
        } else {
          this.childrenIncrementCounter?.setAttribute('disabled', true);
          this.childrenDecrementCounter?.focus();
        }
        break;
    }
    this.childrenCounter.querySelector('.ace-booknow__counter--value').innerHTML = this.numberOfchildrens;
    this.childrenSelectDropdown.forEach((dropdown) => {
      dropdown.style.display = 'none';
    });
    this.childrenSelectDropdown.forEach((dropdown, i) => {
      if (i < this.numberOfchildrens) {
        dropdown.style.display = 'block';
      }
    });
    if (counterType === 'increment' || 'decrement') {
      this.guestInput.innerHTML = this.guestSummaryEvent();
    }
    this.configLinkBuilder();
  }

  dateChangeHandler() {
    //  checkIn change handler
    this.checkinDatePicker.addEventListener('duetChange', () => {
      if (this.checkinDatePicker.value) {
        this.showDatePicker();
        this.checkoutDatePicker.removeAttribute('disabled');
        this.checkoutDatePicker.setAttribute(
          'min',
          this.dateFormatConverter(
            this.addDays(
              new Date(this.checkinDatePicker.value),
              this.minimumNightStay
            )
          )
        );
        this.checkoutDatePicker.setAttribute(
          'value',
          this.dateFormatConverter(
            this.addDays(
              new Date(this.checkinDatePicker.value),
              this.minimumNightStay
            )
          )
        );
        this.checkoutDatePicker.setAttribute(
          'max',
          this.dateFormatConverter(
            this.addDays(
              new Date(this.checkinDatePicker.value),
              this.maximumNightStay
            )
          )
        );
        this.checkinDatePicker.disabled = false;
        this.delegateDates.innerHTML = this.dateSummaryEvent(
          this.checkinDatePicker.value,
          this.checkoutDatePicker.value
        );
      } else {
        this.checkoutDatePicker.value = '';
        this.checkoutDatePicker.setAttribute('disabled', 'disabled');
      }
      this.configLinkBuilder();
    });

    this.checkoutDatePicker.addEventListener('duetChange', () => {
      this.delegateDates.innerHTML = this.dateSummaryEvent(
        this.checkinDatePicker.value,
        this.checkoutDatePicker.value
      );
      this.configLinkBuilder();
    });
  }

  /**
       * Bind adults guests events
       */
  adultsCounterHandler(counterType) {
    switch (counterType) {
      case 'increment':
        if (
          this.numberOfAdults >= this.minimumAdults &&
                      this.numberOfAdults <= this.maximumAdults - 1
        ) {
          this.numberOfAdults = this.numberOfAdults + 1;
          this.adultDecrementCounter?.removeAttribute('disabled');
          if (this.numberOfAdults === this.maximumAdults) {
            this.adultIncrementCounter?.setAttribute('disabled', true);
            this.adultDecrementCounter?.focus();
          }
        } else {
          this.adultIncrementCounter?.setAttribute('disabled', true);
          this.adultDecrementCounter?.focus();
        }

        break;
      case 'decrement':
        if (
          this.numberOfAdults >= this.minimumAdults + 1 &&
                      this.numberOfAdults <= this.maximumAdults
        ) {
          this.numberOfAdults = this.numberOfAdults - 1;
          this.adultIncrementCounter?.removeAttribute('disabled');
          if (this.numberOfAdults === this.minimumAdults) {
            this.adultDecrementCounter?.setAttribute('disabled', true);
            this.adultIncrementCounter?.focus();
          }
        } else {
          this.adultIncrementCounter.setAttribute('disabled', true);
          this.adultIncrementCounter?.focus();
        }
        break;
    }
    this.adultCounter.querySelector('.ace-booknow__counter--value').innerHTML = this.numberOfAdults;
    if (counterType === 'increment' || 'decrement') {
      this.guestInput.innerHTML = this.guestSummaryEvent();
    }
    this.configLinkBuilder();
  }

  /**
       * Return the builded URL according to Accor Link Builder specification
       * @returns
       */
  linkBuilder() {
    this.destinationLocation = this.componentHost.querySelector(
      '.ahAutocomplete__input__hidden input[name="search.destination"]'
    )?.value;
    this.bookNowLinkBuilder.set(
      this.parameters.destination,
      this.destinationLocation
    );
    const contextSite =
              this.booknowFormSubmitButton.getAttribute('data-booknow-link');
    let url = `${contextSite}/lien_externe.svlt?goto=spec_rate`;
    for (const [key, value] of this.bookNowLinkBuilder) {
      url = [key, value] !== null ? `${url}&${key}=${value}` : url;
    }
    const getcardValue = window.sessionStorage.getItem('cardNumber');
    if (getcardValue !== null) {
      const numcarte = `&num_carte_fidelite=${getcardValue}`;
      url = `${url + numcarte}`;
    }
    return url;
  }

  /**
       * Manage the redirection link button events
       */
  bindRedirectionButtonClick() {
    this.booknowFormSubmitButton.addEventListener(CoreJS.DomEventConstants.FOCUS_OUT, () => {
      if (this.isSmallDevice()) this.booknowCloseButton.focus();
    });
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      this.booknowFormSubmitButton.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event)) {
          event.preventDefault();
          const dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
          const destinationvalue = this.componentHost?.querySelector('[name="search.destination.labelSelected"]').value;
          const hotelbrandcode = this.componentHost?.querySelector('[name="search.destination.code"]').value;
          if (dataLayer) {
            const datalayerValue = JSON.parse(dataLayer);
            const pageNameSplit = datalayerValue.pageName.split('::');
            const closeValueSelected = this.componentHost.querySelector('.close-button__wrapper');
            if (
              this.locationInput.value.length > 1 &&
                              closeValueSelected &&
                              this.validateUserSelection(event)
            ) {
              const datasEvent = {
                event: 'GAevent',
                eventName: 'search resorts',
                eventCategory: `resort - ${pageNameSplit[1]}`,
                eventAction: 'search - click on cta',
                eventLabel: 'search'
              };

              TagManager.trackEvent(datasEvent);
              if (this.componentHost?.querySelector('[name="search.destination.type"]').value == 'hotel') {
                const hoteldatasEvents = {
                  event: 'GA4event',
                  eventName: 'booking_form_submit',
                  event_data: {
                    pagename: `resort - ${pageNameSplit[1]}`,
                    accomodation_type: 'resort',
                    arrival_date: this.checkinDatePicker.value,
                    departure_date: this.checkoutDatePicker.value,
                    adults_nb: this.numberOfAdults,
                    children_nb: this.numberOfchildrens,
                    hotel_name: destinationvalue,
                    hotel_rid_code: hotelbrandcode
                  }
                };
                TagManager.trackEvent(hoteldatasEvents);
              } else {
                const datasEvents = {
                  event: 'GA4event',
                  eventName: 'booking_form_submit',
                  event_data: {
                    pagename: `resort - ${pageNameSplit[1]}`,
                    accomodation_type: 'resort',
                    arrival_date: this.checkinDatePicker.value,
                    departure_date: this.checkoutDatePicker.value,
                    adults_nb: this.numberOfAdults,
                    children_nb: this.numberOfchildrens,
                    destination: destinationvalue
                  }
                };
                TagManager.trackEvent(datasEvents);
              }
              window.open(this.linkBuilder(), '_blank');
            } else {
              const datasEvent = {
                event: 'GAevent',
                eventName: 'search resorts',
                eventCategory: `resort - ${pageNameSplit[1]}`,
                eventAction: 'search - click on cta',
                eventLabel: 'search - error'
              };

              TagManager.trackEvent(datasEvent);
              if (destinationvalue == '') {
                const datasblankEvents = {
                  event: 'GA4event',
                  eventName: 'booking_form_interact',
                  event_data: {
                    pagename: `${pageNameSplit[1]}`,
                    bloc_name: 'body',
                    form_action: 'error',
                    error_type: 'blank',
                    error_field: 'destination'
                  }
                };
                TagManager.trackEvent(datasblankEvents);
              } else if (destinationvalue !== '') {
                const datasinvalidEvents = {
                  event: 'GA4event',
                  eventName: 'booking_form_interact',
                  event_data: {
                    pagename: `${pageNameSplit[1]}`,
                    bloc_name: 'body',
                    form_action: 'error',
                    error_type: 'invalid value',
                    error_field: 'destination'
                  }
                };
                TagManager.trackEvent(datasinvalidEvents);
              }
            }
          }
        }
      });
    });
  }


  /**
       * Bind booknow events
       */
  bindBooknowEvents() {
    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.KEY_DOWN].forEach((listener) => {
      // validate location input on form submit
      this.booknowFormSubmitButton?.addEventListener(listener, (event) => {
        this.validateUserSelection(event) ?
          this.validateLocationInput() :
          null;
      });
      // closing book now
      this.booknowCloseButton?.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event) && event.type === CoreJS.DomEventConstants.CLICK) {
          this.closeBooknow();
        }
      });
      // open book now
      this.booknowOpenButton?.addEventListener(listener, (event) => {
        if (this.validateUserSelection(event) && event.type === CoreJS.DomEventConstants.CLICK) {
          this.openBooknow();
        }
      });

      // toggle booknow
      this.booknowDropdownsTrigger?.forEach((item) => {
        item.addEventListener(listener, (event) => {
          if (event.type === CoreJS.DomEventConstants.CLICK) {
            if (event.target.classList.contains('opened')) {
              this.hideAllBooknowDropdowns();
              event.target.classList.add('opened');
            } else this.hideAllBooknowDropdowns();
            const dropdownId = event.target.getAttribute('data-toggle-dropdown');
            if (dropdownId && !event.target.classList.contains('opened')) {
              event.target.getAttribute('aria-expanded') === 'true' ?
                this.toggleDropdown(true, dropdownId, event) : // to close the dropdown
                this.toggleDropdown(false, dropdownId, event); // to open the dropdown
            } else {
              this.hideAllBooknowDropdowns();
            }
          }
        });
      });

      // Adults counter
      this.adultCounter?.querySelectorAll('button').forEach((button) => {
        button.addEventListener(
          listener,
          (event) => {
            if (event.type === CoreJS.DomEventConstants.CLICK) {
              event.preventDefault();
              const counterType = event.target.getAttribute('data-counter');
              if (this.validateUserSelection(event)) {
                this.adultsCounterHandler(counterType);
              }
            }
          },
          false
        );
      });

      // children counter
      this.childrenCounter?.querySelectorAll('button').forEach((button) => {
        button.addEventListener(
          listener,
          (event) => {
            if (event.type === CoreJS.DomEventConstants.CLICK) {
              event.preventDefault();
              const counterType = event.target.getAttribute('data-counter');
              if (this.validateUserSelection(event)) {
                this.childrenCounterHandler(counterType);
              }
            }
          },
          false
        );
      });
      // guest event summary
      this.guestInput.addEventListener(listener, () => {
        this.guestInput.innerHTML = this.guestSummaryEvent();
      });
      this.delegateDates.addEventListener(listener, () => {
        this.delegateDates.innerHTML = this.dateSummaryEvent(
          this.checkinDatePicker.value,
          this.checkoutDatePicker.value
        );
      });
    });
    this.componentHost.querySelectorAll('select').forEach((element) => {
      element.addEventListener(CoreJS.DomEventConstants.CHANGE, () => {
        this.configLinkBuilder();
      });
    });
    // date-picker mobile disable dropdowns once popup opens
    this.componentHost.querySelectorAll('[data-date]').forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if (this.isSmallDevice()) this.hideAllBooknowDropdowns();
      });
    });
    this.checkinDatePicker
      .querySelector('.duet-date__toggle')
      ?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey) {
          event.preventDefault();
          this.checkoutDatePicker.setFocus();
        }
      });
    this.checkoutDatePicker
      .querySelector('.duet-date__toggle')
      ?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey) {
          this.hideAllBooknowDropdowns();
        }
      });

    this.checkinDatePicker?.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
      if (
        event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.left &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.right &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.backspace &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift &&
                  event.target.value.length === 10
      ) {
        this.checkinDatePicker.setAttribute('data-value', event.target.value);

        const dateString = this.checkinDatePicker.getAttribute('data-value');
        let day;
        let month;
        let year;
        switch (this.language) {
          case 'en':
            // Formatted as MM/DD/YYYY
            [month, day, year] = dateString.split('/');
            break;
          case 'fr':
            // Formatted as DD/MM/YYYY
            [day, month, year] = dateString.split('/');
            break;
          default:
            // Formatted as MM/DD/YYYY
            [month, day, year] = dateString.split('/');
        }

        //  Create valid Date object
        const date = new Date(Date.UTC(+year, month - 1, +day));
        if (
          date >= new Date(this.checkinDatePicker.min) &&
                      date <= new Date(this.checkinDatePicker.max)
        ) {
          this.checkinDatePicker.setAttribute(
            'value',
            this.dateFormatConverter(date)
          );
          this.checkinDatePicker.dispatchEvent(new CustomEvent('duetChange'));
          this.showDatePicker();
          setTimeout(() => {
            this.checkinDatePicker.setFocus();
          }, 500);
        }
      }
    });
    this.checkoutDatePicker?.addEventListener(CoreJS.DomEventConstants.KEY_UP, (event) => {
      if (
        event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.left &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.right &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.backspace &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.delete &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
                  event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.shift &&
                  event.target.value.length === 10
      ) {
        this.checkoutDatePicker.setAttribute('data-value', event.target.value);

        const dateString = this.checkoutDatePicker.getAttribute('data-value');
        let day;
        let month;
        let year;
        switch (this.language) {
          case 'en':
            // Formatted as MM/DD/YYYY
            [month, day, year] = dateString.split('/');
            break;
          case 'fr':
            // Formatted as DD/MM/YYYY
            [day, month, year] = dateString.split('/');
            break;
          default:
            // Formatted as MM/DD/YYYY
            [month, day, year] = dateString.split('/');
        }

        // Create valid Date object
        const date = new Date(Date.UTC(+year, month - 1, +day));
        if (
          date >= new Date(this.checkoutDatePicker.min) &&
                      date <= new Date(this.checkoutDatePicker.max)
        ) {
          this.checkoutDatePicker.setAttribute(
            'value',
            this.dateFormatConverter(date)
          );
          this.checkoutDatePicker.dispatchEvent(new CustomEvent('duetChange'));
          this.showDatePicker();
          setTimeout(() => {
            this.checkoutDatePicker.setFocus();
          }, 500);
        }
      }
    });
    // if (this.componentHost.classList.contains('ace-booknow-component__merge-next')) {
    //   this.componentHost.parentElement.classList.add('merge-next');
    // }
    this.dateChangeHandler();
    this.datePickerFormat();
    this.configDatePicker();
    this.dropdownOutsideClickHandler();
    this.escapeKeyHandler();
    this.locationSearch();
    this.clearInputValue();
    this.dateChangeHandler();
    this.bindRedirectionButtonClick();
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, () => {
      this.screenEventHandler();
    });
    document.addEventListener(CoreJS.DomEventConstants.ORIENTAION_CHANGE, () => {
      this.screenEventHandler();
    });
    const element = this.componentHost.querySelectorAll('.duet-date__dialog');
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'aria-hidden') {
          element?.forEach((item) => {
            item.removeAttribute('aria-hidden');
          });
        }
      });
    });
    element.forEach((item) => {
      observer.observe(item, {
        attributes: true
      });
    });
  }
}


CoreJS.BaseComponent.registerComponent(BookNow.CLASS_NAMESPACE, BookNow);
