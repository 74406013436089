import { Billboard, TagManager } from '@accor/ace-ui-core';

export default class AllBillboard extends Billboard {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.setBillboardCobrandClickListener();
  }

  /*
      Function to overwrite tagmanager for Cobrand
    */

  setBillboardCobrandClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const blockTitle = this.componentHost.querySelector('.ace-billboard__fullwidth__title h3')?.innerText;
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.querySelector('.ace-billboard__fullwidth__content .cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: blockTitle?.toLowerCase(),
              bloc_interaction: 'subscribe'
            }
          };
          // eslint-disable-next-line no-undef

          TagManager.trackEvent(datasEvent);
        });
      }
    }
  }
}


// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(Billboard.CLASS_NAMESPACE, AllBillboard, true);
