/* eslint-disable no-unused-expressions */


import gaEvents, { SetGTMTrackingForClickEvents } from './ga-events';

/**
 *  *
 * @author Nitesh Vaidya
 * @class DataLayerHandler
 * @classdesc Ace base data layer for handling events
 * @inheritDoc
 */
export default class DataLayerHandler extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'root';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    // Call the initialize() method of parent class
    super.initialize();
    // Call onDocumentLoad() method
    this.onDocumentLoad();
  }
  onDocumentLoad() {
    // Add EventListener for load event
    window.addEventListener(CoreJS.DomEventConstants.LOAD, () => {
      // Retrieve 'gaEvents'
      const layer = gaEvents[document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-layer-page')];
      // Check if 'layer' exists
      const dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
      const datalayerValue = JSON.parse(dataLayer);
      // const pageNameSplit = datalayerValue.pageName.split('::');
      if (layer) {
        Object.keys(layer).forEach((obj) => {
          let selectedElement;
          Object.keys(layer[obj]).forEach((param) => {
            // Check if the property is 'selector'
            if (param === 'selector') {
              selectedElement = document.querySelectorAll(layer[obj][param]);
            }
            // Check if the param is 'event'
            if (param === 'event') {
              selectedElement?.forEach((item) => {
                const pageNameEvent = layer[obj][param];
                if (pageNameEvent.event_data) {
                  if (datalayerValue.brandContext == 'mantra') { // for mantra when page name is given in .json
                    if (!pageNameEvent.event_data.pagename) {
                      pageNameEvent.event_data.pagename = datalayerValue.pageName;
                    }
                  } else {
                    pageNameEvent.event_data.pagename = datalayerValue.pageName;
                  }
                }
                item.setAttribute('data-ga-click', JSON.stringify(pageNameEvent));
              });
            }
            if (param === 'event2') {
              selectedElement?.forEach((item) => {
                const pageNameEvent = layer[obj][param];
                if (pageNameEvent.event_data) {
                  pageNameEvent.event_data.pagename = datalayerValue.pageName;
                }
                item.setAttribute('data-ga2-click', JSON.stringify(pageNameEvent));
              });
            }
          });
        });
      }
      // Call the 'bindGoogleAnalyticsClickEvent' methos
      this.bindGoogleAnalyticsClickEvent();
    });
  }
  bindGoogleAnalyticsClickEvent() {
    document.querySelectorAll('[data-ga-click]')?.forEach((element) => {
      // Add Event listener for 'click' event
      element.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        const obj = JSON.parse(event.currentTarget.dataset.gaClick);
        if (obj && obj.eventLabel === 'fetch') {
          obj.eventLabel = event.currentTarget.innerText;
        }
        const dataLayerValue = document.querySelector('[data-cmp-datalayer]').getAttribute('data-cmp-datalayer');
        const dataLayerJson = JSON.parse(dataLayerValue);
        const brand = dataLayerJson?.brandContext;
        // Check the value of brand is 'novotel'
        if (brand === 'novotel') {
          // Push an event to 'dataLayer' with 'event_data' as null
          window.dataLayer.push({ 'event_data': null });
          if (window.TagManager) {
            window.TagManager.trackEvent(obj);
          }
        } else {
          SetGTMTrackingForClickEvents(obj);
        }
      });
    });
    document.querySelectorAll('[data-ga2-click]')?.forEach((element) => {
      // Add Event listener for 'click' event
      element.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        const obj = JSON.parse(event.currentTarget.dataset.ga2Click);
        if (obj && obj.eventLabel === 'fetch') {
          obj.eventLabel = event.currentTarget.innerText;
        }
        if (obj && obj.event_data.bloc_interaction === 'fetch') {
          obj.event_data.bloc_interaction = event.currentTarget.innerText;
        }
        if (obj && obj.event_data.bloc_interaction?.includes('fetch')) {
          obj.event_data.bloc_interaction = obj.event_data.bloc_interaction.replace('fetch', event.currentTarget.innerText);
        }
        const dataLayerValue = document.querySelector('[data-cmp-datalayer]').getAttribute('data-cmp-datalayer');
        const dataLayerJson = JSON.parse(dataLayerValue);
        const brand = dataLayerJson?.brandContext;
        // Check the value of brand is 'novotel'
        if (brand === 'novotel') {
          // Push an event to 'dataLayer' with 'event_data' as null
          window.dataLayer.push({ 'event_data': null });
          if (window.TagManager) {
            window.TagManager.trackEvent(obj);
          }
        } else {
          SetGTMTrackingForClickEvents(obj);
        }
      });
    });
  }
}

// Register the DataLayerHandler component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(DataLayerHandler.CLASS_NAMESPACE, DataLayerHandler);
