/**
 *  *
 * @author Rishabh
 * @class DownlaodApp
 * @classdesc Ace download app component to inherit the base component
 * @inheritDoc
 */
export default class DownlaodApp extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-download-app';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.downloadAppCta = this.componentHost.querySelector('.ace-download-app__container-content .ace-button a');
    this.downloadAppCta?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (dataLayerValue) {
        this.dataLayerJson = JSON.parse(dataLayerValue);
        this.pushDatalayer();
      }
    });
  }

  /**
   * Data layer push on CTA click
   */
  pushDatalayer() {
    const datasEvents = {
      event: 'GA4event',
      eventName: 'download_app',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: 'app banner'
      }
    };
    TagManager.trackEvent(datasEvents);
  }
}

// Register the Download-App component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(DownlaodApp.CLASS_NAMESPACE, DownlaodApp);
