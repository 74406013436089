import { addHeaderInterceptor } from './add-header-interceptor-factory'
import { PROD_API_KEY } from '../../common/constants'

const apiKeyInterceptor = async axiosInstance => {
  axiosInstance.interceptors.request.use(config =>
    addHeaderInterceptor('apiKey', PROD_API_KEY, config)
  )
}

export default apiKeyInterceptor
