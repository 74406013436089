import axios from 'axios'
import apiKeyInterceptor from './request-interceptor/api-key-interceptor'
import traceidInterceptor from './request-interceptor/traceid-interceptor'
import originInterceptor from './request-interceptor/origin-interceptor'
import credentialsInterceptor from './request-interceptor/credentials-interceptor'
import appIdIntercepter from './request-interceptor/app-id-interceptor'
import dateIntercepter from './request-interceptor/date-interceptor'
import stateInterceptor from './request-interceptor/state-interceptor'
import codeInterceptor from './request-interceptor/code-interceptor'
import redirectUriInterceptor from './request-interceptor/redirect-uri-interceptor'
import errorInterceptor from './request-interceptor/error-interceptor'
import callerInterceptor from './request-interceptor/caller-interceptor'

import { API_ACCOR_COM, AUTHENTICATION_V2 } from '../common/constants'

const axiosAuthApi = axios.create({
  baseURL: API_ACCOR_COM + AUTHENTICATION_V2,
})

credentialsInterceptor(axiosAuthApi)
errorInterceptor(axiosAuthApi)
redirectUriInterceptor(axiosAuthApi)
codeInterceptor(axiosAuthApi)
stateInterceptor(axiosAuthApi)
dateIntercepter(axiosAuthApi)
appIdIntercepter(axiosAuthApi)
apiKeyInterceptor(axiosAuthApi)
traceidInterceptor(axiosAuthApi)
originInterceptor(axiosAuthApi)
callerInterceptor(axiosAuthApi)

export default axiosAuthApi
