{
  "ar": {
    "$booking.NumRoom": "غرفة (غرف)",
    "$booking.NumGuest": "ضيو(ف)",
    "$booking.room.label": "الغرفة",
    "$booking.adult.label": "بالغ/بالغون",
    "$booking.childrens.label": "طفل/أطفال",
    "$booking.roomsGuest.content": "عمر الطفل",
    "$booking.AddChildren": "أضف طفلا",
    "$booking.AddAdult": "أضف شخص بالغ",
    "$booking.RemoveAdult": "إزالة شخص بالغ",
    "$booking.RemoveChildren": "أخرج طفلا"
  },
  "de": {
    "$booking.NumRoom": "Zimmer",
    "$booking.NumGuest": "Gäst(e)",
    "$booking.room.label": "Zimmer",
    "$booking.adult.label": "Erwachsene(r)",
    "$booking.childrens.label": "Kind(er)",
    "$booking.roomsGuest.content": "Kindesalter",
    "$booking.AddChildren": "Füge ein Kind hinzu",
    "$booking.AddAdult": "Fügen Sie einen Erwachsenen hinzu",
    "$booking.RemoveAdult": "Entfernen Sie einen Erwachsenen",
    "$booking.RemoveChildren": "Entfernen Sie ein Kind"
  },
  "en": {
    "$booking.NumRoom": "Room(s)",
    "$booking.NumGuest": "Guest(s)",
    "$booking.room.label": "Room",
    "$booking.adult.label": "Adult(s)",
    "$booking.childrens.label": "Child(ren)",
    "$booking.roomsGuest.content": "Child age",
    "$booking.AddChildren": "Add a child",
    "$booking.AddAdult": "Add an adult",
    "$booking.RemoveAdult": "Remove an adult",
    "$booking.RemoveChildren": "Remove a child"
  },
  "es": {
    "$booking.NumRoom": "Habitación(es)",
    "$booking.NumGuest": "huésped(es)",
    "$booking.room.label": "habitación",
    "$booking.adult.label": "Adulto(s)",
    "$booking.childrens.label": "Niño(s)",
    "$booking.roomsGuest.content": "Niño edad",
    "$booking.AddChildren": "Agregar un niño",
    "$booking.AddAdult": "Agregar un adulto",
    "$booking.RemoveAdult": "Eliminar a un adulto",
    "$booking.RemoveChildren": "Quitar a un niño"
  },
  "fr": {
    "$booking.NumRoom": "Chambre(s)",
    "$booking.NumGuest": "Invité(s)",
    "$booking.room.label": "Chambre",
    "$booking.adult.label": "Adulte(s)",
    "$booking.childrens.label": "Enfant(s)",
    "$booking.roomsGuest.content": "Âge enfant",
    "$booking.AddChildren": "Ajouter un enfant",
    "$booking.AddAdult": "Ajouter un adulte",
    "$booking.RemoveAdult": "Retirer un adulte",
    "$booking.RemoveChildren": "Retirer un enfant"
  },
  "id": {
    "$booking.NumRoom": "Kamar",
    "$booking.NumGuest": "Tamu",
    "$booking.room.label": "Kamar",
    "$booking.adult.label": "Dewasa",
    "$booking.childrens.label": "Anak-anak",
    "$booking.roomsGuest.content": "Usia anak",
    "$booking.AddChildren": "Tambahkan anak",
    "$booking.AddAdult": "Tambahkan orang dewasa",
    "$booking.RemoveAdult": "Hapus orang dewasa",
    "$booking.RemoveChildren": "Hapus anak"
  },
  "it": {
    "$booking.NumRoom": "Camera(e)",
    "$booking.NumGuest": "Ospit(i)",
    "$booking.room.label": "Camera",
    "$booking.adult.label": "Adulto(i)",
    "$booking.childrens.label": "Bambino(i)",
    "$booking.roomsGuest.content": "Età da bambini",
    "$booking.AddChildren": "Aggiungi un figlio",
    "$booking.AddAdult": "Aggiungi un adulto",
    "$booking.RemoveAdult": "Rimuovi un adulto",
    "$booking.RemoveChildren": "Rimuovi un bambino"
  },
  "ja": {
    "$booking.NumRoom": "客室数",
    "$booking.NumGuest": "ゲスト",
    "$booking.room.label": "客室",
    "$booking.adult.label": "大人（人数）",
    "$booking.childrens.label": "子供（人数）",
    "$booking.roomsGuest.content": "子供の年齢",
    "$booking.AddChildren": "子を追加する",
    "$booking.AddAdult": "大人を追加する",
    "$booking.RemoveAdult": "大人を削除する",
    "$booking.RemoveChildren": "子を削除する"
  },
  "ko": {
    "$booking.NumRoom": "객실",
    "$booking.NumGuest": "명",
    "$booking.room.label": "객실",
    "$booking.adult.label": "성인",
    "$booking.childrens.label": "아동",
    "$booking.roomsGuest.content": "어린이 나이",
    "$booking.AddChildren": "아이 추가",
    "$booking.AddAdult": "성인 추가",
    "$booking.RemoveAdult": "성인 제거",
    "$booking.RemoveChildren": "아이를 제거"
  },
  "nl": {
    "$booking.NumRoom": "Kamer(s)",
    "$booking.NumGuest": "Gast(en)",
    "$booking.room.label": "Kamer",
    "$booking.adult.label": "Volwassene(n)",
    "$booking.childrens.label": "Kind(eren)",
    "$booking.roomsGuest.content": "Kind leeftijd",
    "$booking.AddChildren": "Voeg een kind toe",
    "$booking.AddAdult": "Voeg een volwassene toe",
    "$booking.RemoveAdult": "Verwijder een volwassene",
    "$booking.RemoveChildren": "Verwijder een kind"
  },
  "pl": {
    "$booking.NumRoom": "Pokoje",
    "$booking.NumGuest": "Goście",
    "$booking.room.label": "Pokój",
    "$booking.adult.label": "Liczba dorosłych",
    "$booking.childrens.label": "Liczba dzieci",
    "$booking.roomsGuest.content": "Wiek dziecka",
    "$booking.AddChildren": "Dodaj dziecko",
    "$booking.AddAdult": "Dodaj osobę dorosłą",
    "$booking.RemoveAdult": "Usuń osobę dorosłą",
    "$booking.RemoveChildren": "Usuń dziecko"
  },
  "pt": {
    "$booking.NumRoom": "Quarto(s)",
    "$booking.NumGuest": "Convidado(s)",
    "$booking.room.label": "Quarto",
    "$booking.adult.label": "Adulto(s)",
    "$booking.childrens.label": "Criança(s)",
    "$booking.roomsGuest.content": "Criança anos",
    "$booking.AddChildren": "Adicione uma criança",
    "$booking.AddAdult": "Adicione um adulto",
    "$booking.RemoveAdult": "Remova um adulto",
    "$booking.RemoveChildren": "Remover uma criança"
  },
  "pt-br": {
    "$booking.room.label": "Quarto",
    "$booking.adult.label": "Adulto(s)",
    "$booking.childrens.label": "Criança(s)",
    "$booking.roomsGuest.content": "Criança anos",
    "$booking.NumRoom": "Quarto(s)",
    "$booking.NumGuest": "Hóspede(s)",
    "$booking.AddChildren": "Adicione uma criança",
    "$booking.AddAdult": "Adicione um adulto",
    "$booking.RemoveAdult": "Remova um adulto",
    "$booking.RemoveChildren": "Remover uma criança"
  },
  "ru": {
    "$booking.NumRoom": "номер(-а)",
    "$booking.NumGuest": "Гость(и)",
    "$booking.room.label": "Номер",
    "$booking.adult.label": "Bзрослые",
    "$booking.childrens.label": "Дети",
    "$booking.roomsGuest.content": "Детский возраст",
    "$booking.AddChildren": "Добавить ребенка",
    "$booking.AddAdult": "Добавить взрослого",
    "$booking.RemoveAdult": "Удалить взрослого",
    "$booking.RemoveChildren": "Удалить ребенка"
  },
  "th": {
    "$booking.NumRoom": "ห้อง",
    "$booking.NumGuest": "แขก",
    "$booking.room.label": "ห้อง",
    "$booking.adult.label": "ผู้ใหญ่",
    "$booking.childrens.label": "เด็ก",
    "$booking.roomsGuest.content": "เด็กอายุ ปี",
    "$booking.AddChildren": "เพิ่มลูก",
    "$booking.AddAdult": "เพิ่มผู้ใหญ่",
    "$booking.RemoveAdult": "ลบผู้ใหญ่",
    "$booking.RemoveChildren": "ลบเด็ก"
  },
  "tr": {
    "$booking.NumRoom": "Oda",
    "$booking.NumGuest": "Misafir",
    "$booking.room.label": "Oda",
    "$booking.adult.label": "Yetişkin",
    "$booking.childrens.label": "Çocuk(lar)",
    "$booking.roomsGuest.content": "Çocuk yaşı",
    "$booking.AddChildren": "Çocuk ekle",
    "$booking.AddAdult": "Bir yetişkin ekle",
    "$booking.RemoveAdult": "Bir yetişkin çıkarın",
    "$booking.RemoveChildren": "Çocuk kaldır"
  },
  "zh": {
    "$booking.NumRoom": "客房数",
    "$booking.NumGuest": "客人",
    "$booking.room.label": "客房",
    "$booking.adult.label": "成人（们）",
    "$booking.childrens.label": "儿童（们）",
    "$booking.roomsGuest.content": "儿童[x]岁",
    "$booking.AddChildren": "添加一个孩子",
    "$booking.AddAdult": "加一个成年人",
    "$booking.RemoveAdult": "删除一名成年人",
    "$booking.RemoveChildren": "删除一个孩子"
  }
}
