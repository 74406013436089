{
  "en": {
    "view-more": "See more : Display $value items",
    "tab-filter-refresh": "Page refresh with filter"
  },
  "fr": {
    "view-more": "Voir plus : afficher $value articles",
    "tab-filter-refresh": "Le contenu de la page a changé"
  }
}
