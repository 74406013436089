import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
Swiper.use([Navigation, Pagination]);

export default class CardContainerV2Component extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'card-container-v2';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    this.defineParams();
    super.initialize();
    this.initSwiper();
    this.paginationAnimationIsEnabled = false;
  }

  defineParams() {
    this.animationDuration = 1200;
  }

  initSwiper() {
    new Swiper(this.componentHost.querySelector('.cardscontainer-swiper'), {
      slidesPerView: 'auto',
      initialSlide: 0,
      speed: this.animationDuration,
      spaceBetween: 12,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: this.componentHost.querySelector('.pagination__right-arrow'),
        prevEl: this.componentHost.querySelector('.pagination__left-arrow')
      },
      pagination: {
        el: this.componentHost.querySelector('.pagination__details'),
        type: 'fraction'
      },
      on: {
        init: () => {
          this.manageItemsActivation();
        },
        slideChangeTransitionStart: () => {
          this.manageItemsActivation();
        },
        slideNextTransitionStart: (swiper) => {
          if (this.paginationAnimationIsEnabled) {
            this.animatePagination(swiper, 'next');
          }
        },
        slidePrevTransitionStart: (swiper) => {
          if (this.paginationAnimationIsEnabled) {
            this.animatePagination(swiper, 'previous');
          }
        }
      }
    });
  }

  animatePagination(swiper, direction) {
    const paginationUpdated = swiper.pagination.el.querySelector('.swiper-pagination-current');
    const leftSide = document.createElement('span');
    const rightSide = document.createElement('span');

    let oldIndex;
    let newIndex;

    leftSide.className = 'left-side';
    rightSide.className = 'right-side';

    paginationUpdated.classList.remove('direction-next', 'direction-previous');

    // Define old/new indexes
    if ( direction === 'next' ) {
      paginationUpdated.classList.add('direction-next');
      oldIndex = parseInt(paginationUpdated.textContent) - 1;
      newIndex = oldIndex + 1;
    } else {
      paginationUpdated.classList.add('direction-previous');
      oldIndex = parseInt(paginationUpdated.textContent) + 1;
      newIndex = oldIndex - 1;
    }

    // Manage limits
    if ( oldIndex <= 0 ) {
      oldIndex = swiper.slides.length;
    } else if ( oldIndex > swiper.slides.length ) {
      oldIndex = 1;
    }
    newIndex = newIndex <= 0 ? 1 : newIndex;

    // Update spans contents
    leftSide.textContent = oldIndex;
    rightSide.textContent = newIndex;

    paginationUpdated.textContent = '';

    paginationUpdated.appendChild(leftSide);
    paginationUpdated.appendChild(rightSide);

    // Start animation
    setTimeout(() => {
      leftSide.classList.add('active');
      rightSide.classList.add('active');
    });
  }

  manageItemsActivation() {
    const swiperSlide = this.componentHost.querySelectorAll('.swiper-slide');

    swiperSlide.forEach((slide) => {
      slide.querySelector('.cardimage-container-v2')?.classList.remove('active');
    });

    const activeSlide = this.componentHost.querySelector('.swiper-slide-active');
    const cardActivation = activeSlide.querySelector('.cardimage-container-v2');

    cardActivation?.classList.add('active');
  }
}
CoreJS.BaseComponent.registerComponent(CardContainerV2Component.CLASS_NAMESPACE, CardContainerV2Component);
