/**
 *  *
 * @author keerthana
 * @class List
 * @classdesc Ace List component to inherit the base component
 * @inheritDoc
 */
export default class List extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-list-component';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();
    this.showItemsButton = this.componentHost.querySelector('.ace-list__button');
    this.upDownArrow = this.componentHost.querySelector('.ace-list__button--icon');
    if (this.showItemsButton) {
      this.showItemsButton.style.display = 'none';
    }
    this.showItems = this.componentHost.querySelector('.ace-list__button--title');
    this.fullItems = this.componentHost.querySelectorAll('.ace-list__item .ace-list__item--display').length;
    this.elementList = [...this.componentHost.querySelectorAll('.ace-list__item .ace-list__item--display ')];
    this.refreshList();
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, this.onWindowResize.bind(this));
  }

  /**
   * Refreshing a list
   */
  refreshList() {
    if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.currentItems = 12;
    } else if ((window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) && (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg)) {
      this.currentItems = 6;
    } else {
      this.currentItems = 3;
    }
    this.showHideButton(this.currentItems);
  }

  /**
   * Function for show or hide a button
   * @param {*} currentItems
   */
  showHideButton(currentItems) {
    this.newItems = this.fullItems - currentItems;
    if (this.newItems > 0) {
      this.showItemsButton.style.display = 'block';
      this.showItems.innerHTML = `${this.showItems.dataset.showMoreLabel} (${this.newItems})`;
      this.showItemsButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.executeButton();
      });
    }
  }

  // Executing a button action
  executeButton() {
    for (let i = this.currentItems; i < this.fullItems; i++) {
      if (this.elementList[i].style.display === 'flex') {
        this.elementList[i].style.display = 'none';
        this.showItems.innerHTML = `${this.showItems.dataset.showMoreLabel} (${this.newItems})`;
        this.upDownArrow.classList.remove('icon-chevron-up-24icon');
        this.upDownArrow.classList.add('icon-chevron-down-24icon');
        this.upDownArrow?.setAttribute('aria-hidden', 'true');
        this.showItemsButton?.setAttribute('aria-expanded', 'false');
      } else {
        this.elementList[i].style.display = 'flex';
        this.showItems.innerHTML = `${this.showItems.dataset.showLessLabel} (${this.newItems}) `;
        this.upDownArrow.classList.remove('icon-chevron-down-24icon');
        this.upDownArrow.classList.add('icon-chevron-up-24icon');
        this.upDownArrow?.setAttribute('aria-hidden', 'false');
        this.showItemsButton?.setAttribute('aria-expanded', 'true');
      }
    }
  }

  /**
   * Handling window resize events
   * Show or hide button based on current items
   */
  onWindowResize() {
    this.showHideButton(this.currentItems);
    this.refreshList();
  }
}

// Register the List component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(List.CLASS_NAMESPACE, List);
