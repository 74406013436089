import { TableContainer, TagManager } from '@accor/ace-ui-core';

export default class AllTableContainer extends TableContainer {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.setTableContainerClickListener();
  }

  /*
      Function to overwrite tagmanager for Cobrand
    */

  setTableContainerClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        const tableContainerButtons = this.componentHost.querySelectorAll('.ace-table-container__foot-btn');
        tableContainerButtons.forEach((item, indexbutton) =>{
          const blockTitle = this.componentHost.querySelectorAll('.ace-table-container__plan-title');
          let blockTitleToPrint;
          blockTitle.forEach((data, indexplan) => {
            if (indexbutton == indexplan) {
              blockTitleToPrint = data.innerText;
            }
          });


          item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: dataLayer.pageName,
                bloc_name: 'card comparator',
                bloc_interaction: `subscribe - ${ blockTitleToPrint?.toLowerCase() }`
              }
            };
            // eslint-disable-next-line no-undef

            TagManager.trackEvent(datasEvent);
          });
        });

        const planButtons = this.componentHost.querySelectorAll('.ace-table-container__plan-link a');
        planButtons.forEach((item) =>{
          item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: dataLayer.pageName,
                bloc_name: 'card comparator',
                bloc_interaction: `see details - ${item.parentElement.parentElement.querySelector('.ace-table-container__plan-title').innerText?.toLowerCase()}`
              }
            };
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEvent);
          });
        });
      }
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(TableContainer.CLASS_NAMESPACE, AllTableContainer, true);
