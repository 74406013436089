/* eslint-disable max-len */
export default class NavBar extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-navbar-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */

  // Initialize Function
  initialize() {
    super.initialize();
    this.dropdownContainerElements = document.querySelectorAll('.nav-item.nav-dropdown');
    this.focussableNavItem = document.querySelectorAll('.nav-link');
    this.btntoogle = document.querySelector('.navbar-toggler');
    this.lastFocusablenabbar = this.focussableNavItem[this.focussableNavItem.length - 1];
    this.dropdownContainerElements.forEach((element) => {
      this.dropdownbtn(element);
    });
    this.observeDropDown();

    this.menu = document.querySelector('.navbar ul:first-child');
    this.list = document.createElement('li');
    this.desktopLogo = document.querySelector('.logo + .logo');
    if (this.desktopLogo) {
      this.resortplaceChange();
    }

    this.submenu = document.querySelector('#navbarContent');
    this.closeIcon = document.querySelector('.cmp-ace-message__close__button');
    this.msgComponent = document.querySelector('.ace-message-component');
    this.aceHeader = document.querySelector('.ace-header').offsetHeight;
    this.positionTop();
    this.focussablenavRightLeft();
    this.focusAccess();
    this.backToMainMenuHandler();
    this.navigationOutsideClickHandler();
    this.navigationscrollFixed();
    this.navbarGA4events();

    window.addEventListener(CoreJS.DomEventConstants.ON_LOAD, () => {
      this.positionTop();
    });
    window.addEventListener(CoreJS.DomEventConstants.DOM_CONTENT_LOADED, () => {
      this.focusAccess();
    });
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, () => {
      if (this.desktopLogo) {
        this.resortplaceChange();
      }
      this.focusAccess();
      this.positionTop();
    });
  }

  /*
  Click functionality for heading navigation
  */
  dropdownbtn(element) {
    this._element = element;
    const dropdownaccessibilty = (btntoogle, menutoogle, backbutton) => {
      btntoogle.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const backButton = element.querySelector('.nav-item__back');
        let pageHeading = document.getElementById('menu-heading');
        if (!pageHeading) {
          pageHeading = document.createElement('div');
          pageHeading.id = 'menu-heading';
        }
        pageHeading.innerHTML = `<p id='nav_menu'>${element.querySelectorAll('.nav-item__dropdown-menu')[0].id}</p>`;
        backButton.after(pageHeading);


        this.dropdownContainerElements.forEach((element) => {
          if (this._element !== element) {
            element.querySelector('.nav-item__dropdown-toggle').setAttribute('aria-expanded', 'false');
          }
          element.querySelector('.nav-item__dropdown-menu').setAttribute('aria-hidden', 'true');
        });

        if (btntoogle.getAttribute('aria-expanded') === 'false') {
          btntoogle.setAttribute('aria-expanded', 'true');
          menutoogle.setAttribute('aria-hidden', 'false');
          if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this.focussableNavItem.forEach((ele) => {
              ele.hidden = true;
            });
          }
        } else {
          btntoogle.setAttribute('aria-expanded', 'false');
          menutoogle.setAttribute('aria-hidden', 'true');
          this.focussableNavItem.forEach((ele) => {
            ele.hidden = true;
          });
        }
      });

      if (backbutton) {
        backbutton.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
          menutoogle.setAttribute('aria-hidden', 'true');
          btntoogle.focus();
          e.preventDefault();
          this.focussableNavItem.forEach((ele) => {
            ele.hidden = false;
          });
        });
      }
      document.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        let node = e.target;
        let inside = false;
        while (node) {
          if (node.classList.contains('nav-dropdown')) {
            inside = true;
            break;
          }
          node = node.parentElement;
        }
        if (!inside) {
          btntoogle.setAttribute('aria-expanded', 'false');
          menutoogle.setAttribute('aria-hidden', 'true');
        }
      });
    };
    const navbarsetlistener = () => {
      const btntoogle = this._element.querySelector('.nav-item__dropdown-toggle');
      const menutoogle = this._element.querySelector('.nav-item__dropdown-menu');
      const backbutton = this._element.querySelector('.nav-item__backButton');
      if (btntoogle) {
        dropdownaccessibilty(btntoogle, menutoogle, backbutton);
      }
    };
    navbarsetlistener();
  }

  // NAVBAR ACCESIBILITY STARTS

  /*
  Set attribute based on the dropdown toggle
  */
  observeDropDown() {
    const element = document.querySelectorAll('.nav-item__dropdown-toggle');
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class' && mutation.target.classList.contains('active')) {
          document.querySelectorAll('.nav-item__dropdown-toggle').forEach((item) => {
            item.setAttribute('aria-expanded', 'false');
            item.nextElementSibling.setAttribute('aria-hidden', 'true');
          });
          mutation.target.setAttribute('aria-expanded', 'true');
          mutation.target.nextElementSibling.setAttribute('aria-hidden', 'false');
        } else if (mutation.attributeName === 'class' && !mutation.target.classList.contains('active')) {
          mutation.target.setAttribute('aria-expanded', 'false');
          mutation.target.nextElementSibling.setAttribute('aria-hidden', 'true');
        }
      });
    });

    element.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        queueMicrotask(() => {
          setTimeout(() => {
            document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.RESIZE_EVENT_WRAPPER));
          }, 100);
        });
        if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          if (!e.target.classList.contains('active')) {
            document.querySelectorAll('.nav-item__dropdown-toggle.active').forEach((active) => {
              active.classList.remove('active');
            });
            e.target.classList.add('active');
          } else {
            e.target.classList.remove('active');
          }
          const lastlistChild = this.componentHost.querySelectorAll('.nav-dropdown ul li:last-child a');
          lastlistChild.forEach((item) => {
            item.addEventListener(CoreJS.DomEventConstants.FOCUS_OUT, (event) => {
              event.preventDefault();
              this.componentHost.querySelectorAll('.nav-item__dropdown-toggle.active').forEach((active) => {
                active.classList.remove('active');
              });
            });
          });
        }
        if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          document.querySelectorAll('.nav-item__dropdown-menu')?.forEach((dropdown) => {
            dropdown.style.top = `${document.querySelector('header').clientHeight}px`;
          });
        }
      });
    });

    element.forEach((item) => {
      observer.observe(item, {
        attributes: true
      });
    });

    // NAVBAR ACCESIBILITY ENDS
  }

  // NAV RESORT LOGO POSITION FOR MOBILE VIEW STARTS
  resortplaceChange() {
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      const clonedLogo = this.desktopLogo.cloneNode(true);
      this.desktopLogo.after(clonedLogo);
      this.list.classList = 'mobile-resort';
      this.list.appendChild(clonedLogo);
      this.menu.appendChild(this.list);
      this.menu.insertBefore(this.list, this.menu.firstElementChild);
      this.componentHost.querySelector('.navbarContent .mobile-resort .logo').classList.add('resort-logo');
    }
  }

  // NAV RESORT LOGO POSITION FOR MOBILE VIEW ENDS

  // MESSAGE COMPONENT OVERRIDE
  positionTop() {
    this.self = this;

    const msgHeight = this.msgComponent?.offsetHeight;
    const myaccountTop = document.querySelector('.ace-myaccount__header .ace-myaccount');

    if (this.msgComponent) {
      const dropdownMenu = document.querySelectorAll('.nav-item__dropdown-menu');

      this.submenu.style.top = `${this.aceHeader + msgHeight - 1}px`;
      myaccountTop.style.top = `${this.aceHeader + msgHeight - 1}px`;

      if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        myaccountTop.style.top = `${this.aceHeader - 12}px`;
      }

      this.closeIcon.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.submenu.style.top = `${this.aceHeader}px`;
        myaccountTop.style.top = `${this.aceHeader}px`;
      });

      if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        dropdownMenu.forEach((item) => {
          item.style.top = `${this.aceHeader + msgHeight}px`;
          this.closeIcon.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            item.style.top = `${this.aceHeader}px`;
          });
        });
      }
    }
  }
  // ACCESSIBILITY RELATED CODE STARTS

  focusAccess() {
    if (window.outerWidth / window.innerWidth > 1.5) {
      this.componentHost.querySelectorAll('#navbarContent ul')?.forEach((item) => {
        item.classList.add('navbar-zoom');
      });
      this.componentHost.querySelectorAll('.nav-item > button , .nav-item > a')?.forEach((item) => {
        item.addEventListener(CoreJS.DomEventConstants.FOCUS_IN, (event) => {
          event.target.scrollIntoView({ block: 'center' });
        });
      });
    } else {
      this.componentHost.querySelectorAll('#navbarContent ul')?.forEach((item) => {
        item.classList.remove('navbar-zoom');
      });
    }
    if (this.componentHost.querySelector('header .ace-language-selector__button button')) {
      this.componentHost.querySelector('header .ace-language-selector__button button').addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey && window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          event.preventDefault();
          this.componentHost.querySelector('header .navbar .navbar-toggler').focus();
        }
        if (event.keyCode === CoreJS.Constants.KEY_CODES.left && window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          this.focussableNavItem[this.focussableNavItem.length - 1].focus();
          event.preventDefault();
        }
      }, false);
    }

    this.componentHost.querySelector('header .navbar-toggler').addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.target.getAttribute('aria-expanded') === 'false' && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey && window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        event.preventDefault();
        this.componentHost.querySelector('.navigation-panel .logo:first-child .ace-header__logo--link').focus();
      }
    }, false);

    this.componentHost.querySelector('.ace-myaccount__header > .cmp-button').addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey && window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        event.preventDefault();
        if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.componentHost.querySelector('header .logo .ace-header__logo--link').focus();
        } else if (this.componentHost.querySelector('header .logo:nth-child(2)')) {
          this.componentHost.querySelector('header .logo:nth-child(2) .ace-header__logo--link').focus();
        }
      }
    }, false);

    if (this.componentHost.querySelector('.logo:nth-child(2)')) {
      if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.componentHost.querySelector('.logo:nth-child(2) .ace-header__logo--link').addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
          if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey) {
            this.componentHost.querySelector('.nav-link').focus();
            event.preventDefault();
          }
        });
      }
    }


    if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.componentHost.querySelector('.navbar-toggler').setAttribute('tabindex', '0');
      this.componentHost.querySelectorAll('.ace-header__logo--link').forEach((item, index) => {
        item.setAttribute('tabindex', '-1');
        item.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
          event.preventDefault();
          if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey && index === 0 && window.innerWidth >= CoreJS.ResponsiveConstants.md && this.componentHost.querySelector('header .logo:nth-child(2)')) {
            this.componentHost.querySelector('header .logo:nth-child(2) .ace-header__logo--link').focus();
          } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey && index === 0 && window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
            this.componentHost.querySelector('.ace-myaccount__header > .cmp-button').focus();
          } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey && index === 0) {
            this.componentHost.querySelector('.navbar-toggler').focus();
          } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey && index >= 1) {
            this.componentHost.querySelector('.ace-myaccount__header > .cmp-button').focus();
          } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey && index >= 1) {
            this.componentHost.querySelector('header .logo .ace-header__logo--link').focus();
          }
        });
      });
    } else {
      this.componentHost.querySelector('.navbar-toggler').removeAttribute('tabindex');
      this.componentHost.querySelectorAll('.ace-header__logo--link').forEach((item) => {
        item.removeAttribute('tabindex');
      });
    }

    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      if (this.componentHost.querySelector('.mobile-resort')) {
        this.componentHost.querySelector('.mobile-resort .logo .ace-header__logo--link').removeAttribute('tabindex');
        this.componentHost.querySelector('.mobile-resort .logo .ace-header__logo--link').addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
          if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey) {
            event.preventDefault();
            this.componentHost.querySelector('.nav-link').focus();
          } else if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey) {
            this.componentHost.querySelector('.navbar-toggler').focus();
          }
        }, false);
      }
    }
  }
  /**
   * Handling action of going back to main menu
   */
  backToMainMenuHandler() {
    const elementDropdown = document.querySelectorAll('.nav-item__dropdown-menu');
    const observerDropdown = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'aria-hidden' && mutation.target.classList.contains('nav-item__dropdown-menu') && mutation.target.getAttribute('aria-hidden') === 'false') {
          document.body.classList.add('sub-menu');
        } else if (mutation.attributeName === 'aria-hidden' && mutation.target.classList.contains('nav-item__dropdown-menu') && mutation.target.getAttribute('aria-hidden') === 'true') {
          document.body.classList.remove('sub-menu');
          if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            document.querySelectorAll('.nav-link.nav-item__dropdown-toggle').forEach((item) => {
              item.setAttribute('aria-expanded', 'false');
            });
          }
        }
      });
    });
    elementDropdown.forEach((item) => {
      observerDropdown.observe(item, {
        attributes: true
      });
    });
    if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.componentHost.querySelectorAll('.nav-item__backButton').forEach((item) => {
        item.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
          const listOfLinks = event.target.closest('.nav-item__dropdown-menu').querySelectorAll('a');
          const visibleLinks = [];
          listOfLinks?.forEach((link) => {
            if (link.offsetParent !== null) {
              visibleLinks.push(link);
            }
          });
          visibleLinks?.forEach((element, index, array) => {
            element.addEventListener(CoreJS.DomEventConstants.FOCUS_IN, (focusEvent) => {
              focusEvent.target.scrollIntoView({ block: 'center' });
            });
            if (index === array.length - 1) {
              element.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
                if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !e.shiftKey) {
                  e.preventDefault();
                  event.preventDefault();
                  item.focus();
                }
              });
              if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey) {
                event.preventDefault();
                element.focus();
              }
            }
          });
        });
      });
    }
  }
  // ACCESSIBILITY RELATED CODE ENDS

  // RIGHT KEY FUNCTIONALITIES

  focussablenavRightLeft() {
    this.focussableNavItem.forEach((element, index) => {
      element.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
        if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          if (e.keyCode === CoreJS.Constants.KEY_CODES.left) {
            if (index > 0) {
              this.focussableNavItem[index - 1].focus();
              e.preventDefault();
            }
          } else if (e.keyCode === CoreJS.Constants.KEY_CODES.right) {
            if (index < this.focussableNavItem.length - 1) {
              this.focussableNavItem[index + 1].focus();
              e.preventDefault();
            } else {
              this.componentHost.querySelector('header .ace-language-selector__button button').focus();
            }
          }
        }
      });
    });
  }
  // RIGHT KEY FUNCTIONALITIES

  // OUSIDE CoreJS.DomEventConstants.CLICK

  navigationOutsideClickHandler() {
    this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      if (!event.target.closest('.nav-item__dropdown-menu') && !event.target.closest('.nav-item__dropdown-toggle')) {
        document.querySelectorAll('.nav-item__dropdown-toggle.active').forEach((active) => {
          active.classList.remove('active');
        });
      }
    }, false);
  }

  /**
   * Handling fixed naviagtion scrolling
   */
  navigationscrollFixed() {
    const navbarDropdown = document.querySelector('.navbarContent');
    const observerDropdown = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.getAttribute('aria-hidden') === 'false') {
          document.documentElement.classList.add('fixedbyModal');
        } else {
          document.documentElement.classList.remove('fixedbyModal');
        }
      });
    });

    observerDropdown.observe(navbarDropdown, {
      attributes: true
    });
  }
  navbarGA4events() {
    const layerElement = document.querySelector('[data-cmp-datalayer]');
    const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
    // eslint-disable-next-line no-unused-vars
    const pageName = layerElement?.getAttribute('data-layer-page');
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const pageNameSplit = datalayerValue.pageName.split('::');
      if (pageNameSplit[0] === 'resorts') {
        const headerlogo = document?.querySelector('.header .ace-header .logo.aem-GridColumn--default--2 .ace-header__logo--image');
        if (headerlogo) {
          headerlogo.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'menu_interact_other',
              event_data: {
                pagename: `resorts - ${ pageNameSplit[1]}`,
                menu_position: '1',
                menu_label: 'all logo'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const alllogo = document?.querySelector('.header .ace-header .logo.aem-GridColumn--default--1 .ace-header__logo--image');
        if (alllogo) {
          alllogo.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'menu_interact_other',
              event_data: {
                pagename: `resorts - ${ pageNameSplit[1]}`,
                menu_position: '2',
                menu_label: 'resorts logo'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const destinationvalue = document?.querySelector('.navigation .navbar .navbarContent li:first-child .nav-link');
        if (destinationvalue) {
          destinationvalue.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'menu_interact',
              event_data: {
                pagename: `resorts - ${ pageNameSplit[1]}`,
                menu_position: '1',
                menu_label: 'destinations'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const inspritaionvalue = document?.querySelector('.navigation .navbar .navbarContent li.nav-dropdown .nav-link');
        if (inspritaionvalue) {
          inspritaionvalue.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'menu_interact',
              event_data: {
                pagename: `resorts - ${ pageNameSplit[1]}`,
                menu_position: '2',
                menu_label: 'inspirations'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const allvalue = document?.querySelector('.navigation .navbar .navbarContent li:last-child .nav-link');
        if (allvalue) {
          allvalue.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'menu_interact',
              event_data: {
                pagename: `resorts - ${ pageNameSplit[1]}`,
                menu_position: '3',
                menu_label: 'all'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const languagevalue = document?.querySelector('.language-selector button.ui-label-01');
        if (languagevalue) {
          languagevalue.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'menu_interact_other',
              event_data: {
                pagename: `resorts - ${ pageNameSplit[1]}`,
                menu_position: '1',
                menu_label: 'language'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const myaccount = document?.querySelector('.myaccount .ace-myaccount-disconnected button.cmp-button');
        if (myaccount) {
          myaccount.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_open',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '0'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const discoverbtn = document?.querySelector('.myaccount .ace-myaccount-disconnected a.cmp-button--primary');
        if (discoverbtn) {
          discoverbtn.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '0',
                menu_position: '1',
                menu_label: 'discover the program'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const signinbtn = document?.querySelector('.myaccount .ace-myaccount-disconnected a.cmp-button--secondary');
        if (signinbtn) {
          signinbtn.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '0',
                menu_position: '2',
                menu_label: 'sign in'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const mybooking = document?.querySelector('.myaccount .ace-myaccount-disconnected .ace-myaccount__list .ace-myaccount__item a');
        if (mybooking) {
          mybooking.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '0',
                menu_position: '3',
                menu_label: 'my bookings'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const myaccountloginbtn = document?.querySelector('.myaccount .ace-myaccount-connected button.cmp-button');
        if (myaccountloginbtn) {
          myaccountloginbtn.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_open',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '1'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const myaccountmenu = document?.querySelector('.myaccount .ace-myaccount-connected .ace-myaccount__list li:first-child');
        if (myaccountmenu) {
          myaccountmenu.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '1',
                menu_position: '1',
                menu_label: 'my account'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const mybookinglogin = document?.querySelector('.myaccount .ace-myaccount-connected .ace-myaccount__list li:last-child');
        if (mybookinglogin) {
          mybookinglogin.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '1',
                menu_position: '2',
                menu_label: 'my bookings'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const useyourpts = document?.querySelector('.myaccount .ace-myaccount-connected .ace-myaccount__list li [aria-label="Use your points opens in a new window"]');
        if (useyourpts) {
          useyourpts.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '1',
                menu_position: '3',
                menu_label: 'use your points'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const logout = document?.querySelector('.myaccount .ace-myaccount-connected .ace-myaccount__list li #cmp-my-account_login-sign-out');
        if (logout) {
          logout.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'user_connect_interact',
              event_data: {
                pagename: `${ pageNameSplit[1]}`,
                user_is_logged: '1',
                menu_position: '4',
                menu_label: 'log out'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
        const loyaltyevent = document?.querySelector('.loyalty .ace-loyalty .ace-loyalty__inner .ace-loyalty__button .ace-button .ace-loyalty__btn');
        if (loyaltyevent) {
          loyaltyevent.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              'event': 'GA4event',
              'eventName': 'cta_enroll',
              'event_data': {
                'pagename': `resorts - ${ pageNameSplit[1]}`,
                'bloc_name': 'resorts',
                'bloc_interaction': 'become a member'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        }
      }
    }
  }
}
// Register the NavBar component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(NavBar.CLASS_NAMESPACE, NavBar);
