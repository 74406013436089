/* eslint-disable no-undef */
/**
 *  *
 * @author HariomTomar
 * @class DataLayer
 * @classdesc Ace base data layer for pushing events to datalayer object
 * @inheritDoc
 */
import TagManager from '@accor/core-services/dist/gtm/index';
export default class DataLayer extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-data-layer';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    document.addEventListener(CoreJS.AquisitionTrackingApiIntegration.PUSH_DATALAYER_EVENT, (event) => {
      const json = event.detail.datalayer;
      const brand = json.brandContext;
      if (brand && brand !== 'resorts' && brand !== 'mantra') {
        DataLayer.pushAllDataLayer(brand, json);
      } else {
        DataLayer.setDataLayer(brand);
      }
    });
  }

  /**
    * Function to push all data layer
    *
    * @param {string} brand
    * @param {object} dataLayerJson
    */
  static pushAllDataLayer(brand, dataLayerJson) {
    this.privateOffer = document.querySelector('.booking-engine-container .core-booking-engine__wrapper')?.dataset?.privateOffer &&
     document.querySelector('.booking-engine-container .core-booking-engine__wrapper')?.dataset?.privateOffer;
    this.occAllCookie = CoreJS.CommonUtils.userLoginStatus();
    this.offertyp = '';
    const [category, subcategory1, subcategory2, subcategory3, subcategory4] = dataLayerJson.pageName.split('::');
    window.dataLayer = window.dataLayer || [];

    if (brand === 'airlines') {
      let env;
      // eslint-disable-next-line no-unused-expressions
      window.location.href.indexOf('-all') > -1 ?
        (env = 'staging') :
        (env = 'production');
      const tracksPage = {
        'event': dataLayerJson.event,
        'category': category,
        'sub_category_level1': subcategory1,
        'sub_category_level2': subcategory2,
        'sub_category_level3': subcategory3,
        'partners_type': dataLayerJson.partnersType,
        'env': env
      };
      TagManager.trackPage(dataLayerJson.pageName, tracksPage);
    } else if (brand == 'novotel') {
      let env;
      // eslint-disable-next-line no-unused-expressions
      window.location.href.indexOf('publish') > -1 ||
          window.location.href.indexOf('-resorts') > -1 ||
          window.location.href.indexOf('-novotel') > -1 ?
        (env = 'staging') :
        (env = 'production');
      const datasPage = {
        'category': subcategory1.startsWith('hom') ? '' : subcategory1,
        'sub_category_level1': subcategory2 ?? '',
        'sub_category_level2': subcategory3 ?? '',
        'sub_category_level3': '',
        'brandcontext': brand,
        'pagetype': 'brand content',
        'language': document.documentElement.lang,
        'env': env
      };
      TagManager.trackPage(dataLayerJson.pageName, datasPage);
    } else if (brand === 'all') {
      if (this.privateOffer && this.privateOffer === 'true') {
        this.offertyp = 'private offer';
      } else {
        if (!this.occAllCookie) {
          this.offertyp = 'public offer';
        } else if (this.occAllCookie) {
          this.offertyp = 'private offer';
        }
      }
      let env;
      this.offertypelevel2Var = document?.querySelector('.countdown-container')?.getAttribute('data-offer-category');
      if (this.offertypelevel2Var) {
        this.offertypelevel2 = this.offertypelevel2Var;
      } else {
        this.offertypelevel2 = 'None';
      }
      // eslint-disable-next-line no-unused-expressions
      window.location.href.indexOf('-all') > -1 ?
        (env = 'staging') :
        (env = 'production');
      const tracksPage = {
        'category': category,
        'sub_category_level1': subcategory1,
        'sub_category_level2': subcategory2,
        'sub_category_level3': subcategory3,
        'sub_category_level4': subcategory4,
        'offer_type': this.offertyp,
        'offer_type_level_2': this.offertypelevel2,
        'env': env
      };

      TagManager.trackPage(dataLayerJson.pageName, tracksPage);
    } else if (brand === 'sofitel') {
      const [category, subcategory1, subcategory2, subcategory3] = dataLayerJson?.pageName.split('::')?.slice(1,);
      let env = 'production';
      if (CoreJS.CommonUtils.checkEnvironment()) {
        env = 'staging';
      }
      const tracksPage = {
        'page_category': category ? category : '',
        'page_sub_category_level1': subcategory1 ? subcategory1 : '',
        'page_sub_category_level2': subcategory2 ? subcategory2 : '',
        'page_sub_category_level3': subcategory3 ? subcategory3 : '',
        'env': env,
        'hotel_rid_code': dataLayerJson?.hotelRIDCode ? dataLayerJson?.hotelRIDCode : '',
        'restaurant_code': dataLayerJson?.restaurantCode ? dataLayerJson?.restaurantCode : '',
        'room_code': dataLayerJson?.roomCode ? dataLayerJson?.roomCode : '',
        'wellness_code': dataLayerJson?.wellnessCode ? dataLayerJson?.wellnessCode : '',
        'offer_code': dataLayerJson?.offerCode ? dataLayerJson?.offerCode : ''
      };
      TagManager.trackPage(dataLayerJson?.pageName, tracksPage);
    } else if (brand === 'ibis' || brand === 'mercure' ) {
      const [category, subcategory1, subcategory2] = dataLayerJson?.pageName.split('::')?.slice(1,);
      let env = 'production';
      if (CoreJS.CommonUtils.checkEnvironment()) {
        env = 'staging';
      }
      const tracksPage = {
        'category': category ? category : '',
        'sub_category_level1': subcategory1 ? subcategory1 : '',
        'sub_category_level2': subcategory2 ? subcategory2 : '',
        'env': env
      };
      TagManager.trackPage(dataLayerJson?.pageName, tracksPage);
    } else if (brand === 'mgallery') {
      const [category, subcategory1, subcategory2] = dataLayerJson?.pageName.split('::')?.slice(1,);
      let env = 'production';
      if (CoreJS.CommonUtils.checkEnvironment()) {
        env = 'staging';
      }
      const tracksPage = {
        'category': category ? category : '',
        'sub_category_level1': subcategory1 ? subcategory1 : '',
        'sub_category_level2': subcategory2 ? subcategory2 : '',
        'env': env
      };
      TagManager.trackPage(dataLayerJson?.pageName, tracksPage);
    } else {
      const datasPage = {
        'event': dataLayerJson.event,
        'category': dataLayerJson.category,
        'sub_category_level1': dataLayerJson.subcategory1,
        'sub_category_level2': dataLayerJson.subcategory2,
        'sub_category_level3': dataLayerJson.subcategory3
      };
      TagManager.trackPage(dataLayerJson.pageName, datasPage);
    }
  }

  /**
    * Function to set data layer from My Account
    * @param {string} brand
    */
  static setDataLayer(brand) {
    const dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    let dataLayerJson;
    function setUserDetails() {
      const userData = window.sessionStorage.getItem('userData');
      if (userData) {
        const userDataJson = JSON.parse(userData);

        dataLayerJson.uilogged = true;
        dataLayerJson.uicity = userDataJson.city;
        dataLayerJson.uicountry = userDataJson.country;
        dataLayerJson.userId = userDataJson.userId;

        if (userDataJson.status) {
          dataLayerJson.uiallmember = 'yes';
          dataLayerJson.loyaltycardtypes = userDataJson.cardCodeTARS;
          dataLayerJson.uiloyaltypoints = userDataJson.loyaltypoints;
          dataLayerJson.newsletteroptin = true;
        } else {
          dataLayerJson.uiallmember = true;
        }
      } else {
        dataLayerJson.uiallmember = 'not logged';
        dataLayerJson.uilogged = false;
        dataLayerJson.newsletteroptin = false;
      }
    }
    function setEnvironment() {
      let url = window.location.href;
      // eslint-disable-next-line prefer-destructuring
      url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')[0];
      if (url == 'resorts' || url == 'all' || url == 'novotel') {
        dataLayerJson.env = 'production';
      } else {
        dataLayerJson.env = 'staging';
      }
    }

    function setNumberOfResorts() {
      const callouts = document.querySelectorAll('.ace-callout-container .ace-callout-component .cmp-teaser__content');
      if (callouts) {
        dataLayerJson.numberOfResults = callouts.length;
      }
    }

    function setCategories() {
      const { pageName } = dataLayerJson;
      // eslint-disable-next-line no-unused-vars
      const [category, categoryLevelOne, subcategory2, subcategory3] = pageName.split('::');
      // Add space for resorts credits page
      if (categoryLevelOne === 'resortscredits') {
        dataLayerJson.subcategory1 = 'resorts credits';
      } else {
        dataLayerJson.subcategory1 = categoryLevelOne;
      }
      dataLayerJson.category = category;
      dataLayerJson.subcategory2 = subcategory2;
      dataLayerJson.subcategory3 = subcategory3;
    }

    function setCountryMarket() {
      if (Optanon) {
        const countryCode = Optanon.getGeolocationData();
        if (countryCode) {
          dataLayerJson.countrymarket = countryCode.country.toLowerCase();
        }
      }
    }

    function pushDataLayer() {
      setTimeout(() => {
        setCountryMarket();
        window.dataLayer = window.dataLayer || [];
        const pageData = {
          'event': dataLayerJson.event,
          'currencycode': dataLayerJson.currencyCode,
          'language': dataLayerJson.language,
          'pagename': dataLayerJson.pageName,
          'brandcontext': dataLayerJson.brandContext,
          'clienttype': dataLayerJson.clientType,
          'dynamic_datas_ready': dataLayerJson.dynamicDatasReady,
          'category': dataLayerJson.category,
          'sub_category_level1': dataLayerJson.subcategory1,
          'sub_category_level2': dataLayerJson.subcategory2,
          'sub_category_level3': dataLayerJson.subcategory3,
          'uilogged': dataLayerJson.uilogged,
          'uiallmember': dataLayerJson.uiallmember,
          'loyaltycardtypes': dataLayerJson.loyaltycardtypes,
          'uicity': dataLayerJson.uicity,
          'uicountry': dataLayerJson.uicountry,
          'userId': dataLayerJson.userId,
          'uiloyaltypoints': dataLayerJson.uiloyaltypoints,
          'numberofresults': dataLayerJson.numberOfResults,
          'env': dataLayerJson.env,
          'newsletteroptin': dataLayerJson.newsletteroptin,
          'countrymarket': dataLayerJson.countrymarket
        };

        TagManager.trackPage('resorts::homepage', pageData);
      }, 2000);
    }
    function pushDataLayerMantra() {
      const [subcategory1, subcategory2, subcategory3, subcategory4] = JSON.parse(dataLayer)?.pageName.split('::')?.slice(1,);
      let env = 'production';
      if (CoreJS.CommonUtils.checkEnvironment()) {
        env = 'staging';
      }
      const tracksPage = {
        'brandcontext': dataLayerJson.brandContext,
        'category': subcategory1,
        'sub_category_level1': subcategory2,
        'sub_category_level2': subcategory3,
        'sub_category_level3': subcategory4,
        'env': env
      };
      const trackUserData = {
        'userId': dataLayerJson.userId,
        'uilogged': dataLayerJson.uilogged,
        'uicity': dataLayerJson.uicity,
        'uicountry': dataLayerJson.uicountry,
        'uiallmember': dataLayerJson.uiallmember,
        'uiloyaltypoints': dataLayerJson.uiloyaltypoints,
        'newsletteroptin': dataLayerJson.newsletteroptin,
        'clienttype': JSON.parse(dataLayer)?.clientType,
        'loyaltycardtypes': dataLayerJson.loyaltycardtypes
      };
      tracksPage.user_data = trackUserData;
      TagManager.trackPage(JSON.parse(dataLayer)?.pageName, tracksPage);
    }

    if (brand == 'mantra') {
      if (dataLayer) {
        dataLayerJson = JSON.parse(dataLayer);
        setUserDetails();
        pushDataLayerMantra();
      }
    } else {
      if (dataLayer) {
        dataLayerJson = JSON.parse(dataLayer);
        setEnvironment();
        setCategories();
        setNumberOfResorts();
        setUserDetails();
        pushDataLayer();
      }
    }
  }
}
TagManager.initDefaultAutoTracking();

CoreJS.BaseComponent.registerComponent(DataLayer.CLASS_NAMESPACE, DataLayer);
