{
  "airlines-discover-page": {
    "obj-reference-1": {
      "selector": ".ace-highlight .cmp-button",
      "event": {
        "event": "GA4event",
        "eventCategory": "loyalty program",
        "eventAction": "hub airlines - discover - click on cta",
        "eventLabel": "discover your benefits"
      },
      "event2": {
        "event": "GA4event",
        "eventName": "bloc_interact",
        "event_data": {
          "pagename": "partnership::airlines::discover::$airlineCompany",
          "bloc_name": "airline privilege club",
          "bloc_interaction": "discover your benefits "
        }
      }
    },
    "obj-reference-2": {
      "selector": ".callout-container [data-callout-type='default'] .ace-callout-component a",
      "event": {
        "event": "GA4event",
        "eventCategory": "loyalty program",
        "eventAction": "hub airlines - click on more partners",
        "eventLabel": "fetch"
      },
      "event2": {
        "event": "GA4event",
        "eventName": "bloc_interact",
        "event_data": {
          "pagename": "partnership::airlines::discover::$airlineCompany",
          "bloc_name": "more partners",
          "bloc_interaction": "discover - fetch"
        }
      }
    },
    "obj-reference-3": {
      "selector": ".callout-container [data-callout-type='imageSmall'] .ace-callout-component a",
      "event": {
        "event": "GA4event",
        "eventCategory": "loyalty program",
        "eventAction": "hub airlines - inserts - click on more partners",
        "eventLabel": "fetch"
      },
      "event2": {
        "event": "GA4event",
        "eventName": "bloc_interact",
        "event_data": {
          "pagename": "partnership::airlines::discover::$airlineCompany",
          "bloc_name": "reward points",
          "bloc_interaction": "fetch"
        }
      }
    },
    "obj-reference-4": {
      "selector": ".callout-container [data-callout-type='rating'] .ace-callout-component a",
      "event": {
        "event": "GA4event",
        "eventCategory": "loyalty program",
        "eventAction": "hub airlines - more partners - click on cta",
        "eventLabel": "fetch"
      }
    },
    "obj-reference-5": {
      "selector": ".ace-billboard .cmp-button",
      "event": {
        "event": "GA4event",
        "eventCategory": "loyalty program",
        "eventAction": "hub airlines - footer - click on cta",
        "eventLabel": "join us"
      },
      "event2": {
        "event": "GA4event",
        "eventName": "cta_enroll",
        "event_data": {
          "pagename": "partnerships::airlines::landing page::$airlinesCompany",
          "enroll_context": "footer",
          "cta_name": "discover the program"
        }
      }
    },
    "obj-reference-6": {
      "selector": ".callout-container [data-callout-type='default'] .ace-callout-component:has(.cmp-teaser__logo-slot) .cmp-teaser__content a",
      "event": {
        "event": "GA4event",
        "eventCategory": "loyalty program",
        "eventAction": "hub airlines - more partners - click on cta",
        "eventLabel": "fetch"
      },
      "event2": {
        "event": "GA4event",
        "eventName": "bloc_interact",
        "event_data": {
          "pagename": "partnership::airlines::discover::$airlineCompany",
          "bloc_name": "other privilege airlines partners",
          "bloc_interaction": "fetch"
        }
      }
    }
  },
  "offer-page-v2": {
    "obj-reference-1": {
      "selector": ".loyalty-login-block .ace-loyalty-container .ace-loyalty__button .ace-button a.ace-loyalty__btn",
      "event": {
        "event": "GAevent",
        "eventCategory": "promotion",
        "eventAction": "click on cta",
        "eventLabel": "login",
        "eventName": "cta_authentication",
        "event_data": {
          "cta_name": "log in"
        }
      },
      "event2": {
        "event": "GA4event",
        "eventName": "bloc_interact",
        "event_data": {
          "pagename": "partnership::airlines::discover::$airlineCompany",
          "bloc_name": "other privilege airlines partners",
          "bloc_interaction": "fetch"
        }
      }
    }
  }
}