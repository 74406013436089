export default class Faq extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-faq-component';

  /** @inheritDoc */

  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */

  // Initializing Function
  initialize() {
    super.initialize();

    // Call the setFaqClickListener method
    this.setFaqClickListener();
    this.accordion = {
      item: '.cmp-accordion__item',
      button: '.cmp-accordion__button',
      panel: '.cmp-accordion__panel'
    };

    this.accItem = this.componentHost?.querySelectorAll(this.accordion.item);
    this.accButtonAll = this.componentHost?.querySelectorAll(this.accordion.button);
    this.accPanelAll = this.componentHost?.querySelectorAll(this.accordion.panel);
    this.singleExpandedVal = true;
    this.accButtonAll?.forEach((item) => {
      if (item.closest(this.accordion.item)?.hasAttribute('data-cmp-expanded')) {
        item.closest(this.accordion.item)?.classList.add('active');
        item.setAttribute('aria-expanded', 'true');
        this.singleExpandedVal = false;
      }
    });

    this.accButtonAll?.forEach((item) => {
      if (this.singleExpandedVal) {
        item.addEventListener(CoreJS.DomEventConstants.CLICK, () =>{
          this.multipleFaqGroupHandler(item);
        });
      } else {
        item.addEventListener(CoreJS.DomEventConstants.CLICK, () =>{
          if (item.closest(this.accordion.item)?.classList.contains('active')) {
            item.closest(this.accordion.item)?.classList.toggle('active');
            item.setAttribute('aria-expanded', 'false');
            this.collapseHandler();
          } else {
            this.expandHandler(item);
          }
        });
      }
    });
  }

  // Handler for expanding item
  expandHandler(item) {
    this.accItem?.forEach((accordion)=>{
      accordion.classList.remove('active');
    });
    this.collapseHandler();
    item.closest(this.accordion.item)?.classList.toggle('active');
    item.setAttribute('aria-expanded', 'true');
  }
  // Handler for handling multiple item
  multipleFaqGroupHandler(item) {
    item.closest(this.accordion.item)?.classList.toggle('active');
    if (item.closest(this.accordion.item)?.classList.contains('active')) {
      item.setAttribute('aria-expanded', 'true');
    } else {
      item.setAttribute('aria-expanded', 'false');
    }
  }
  // Handler for collapsing item
  collapseHandler() {
    this.accButtonAll?.forEach((accordion)=>{
      accordion.classList.remove('cmp-accordion__button--expanded'); accordion.setAttribute('aria-expanded', 'false');
    });
    this.accPanelAll?.forEach((accordion)=>{
      accordion.classList.remove('cmp-accordion__panel--expanded');
      accordion.classList.add('cmp-accordion__panel--hidden');
    });
  }
  // Setting Click Event Listener
  setFaqClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageTarget = dataLayer.pageName.split('::')[1];
    const faqEvent = this.componentHost.querySelector('.cmp-accordion__more-button a.cmp-button.cmp-button--secondary');
    if (faqEvent) {
      if (pageTarget != 'cobrand') {
        faqEvent.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          window.dataLayer.push({
            'event': 'GAevent',
            'eventCategory': 'loyalty program',
            'eventAction': 'all signature - faq - click on cta',
            'eventLabel': 'faq'
          });
        });
      }
    }
  }
}

// Register the Faq component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Faq.CLASS_NAMESPACE, Faq);
