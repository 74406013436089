/* eslint-disable no-unused-expressions */
/**
 * @author Arif
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class Message extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-message-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.button = this.componentHost.querySelector(
      '.cmp-ace-message__close__button'
    );

    this.editMode = document.querySelector('.aem-edit-mode');

    this.elementsClosed = [];

    this.selectors = {
      message: '[data-cmp-is="ace-message"]',
      close: '.cmp-ace-message__close__button',
      pageHeader: 'header',
      sharedHeader: 'link-navigation__mainWrapper__fixed',
      messageComponent: 'cmp-ace-message',
      alertComponent: 'message'
    };
    if (document.readyState !== 'loading') {
      this.onDocumentReady();
    } else {
      document.addEventListener(CoreJS.DomEventConstants.CoreJS.DomEventConstants.DOM_CONTENT_LOADED, this.onDocumentReady);
    }
  }

  /**
     * Message Configuration
     *
     * @typedef {Object} MessageConfig Represents a Message configuration
     * @property {HTMLElement} element The HTMLElement representing the Message close button
     * @property {Boolean} option The message option (message to hide = true)
     */
  /*
     * Binds Message event handling
     *
     * @private
     */
  bindEvent(MessageConfig) {
    const selectMessage = this.selectors.message;
    const elementsClosed = [];
    if (MessageConfig.element !== null) {
      MessageConfig.element.focus();
      MessageConfig.element.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const toHideElement = MessageConfig.element.closest(
          selectMessage
        );
        elementsClosed.push(toHideElement.dataset.id);
        sessionStorage.setItem('elementsClosed', elementsClosed);
        toHideElement.style.display = 'none';
        document.querySelector('a.cmp-page__skiptomaincontent-link')?.focus();
      });
    }
  }

  /**
     * Message
     *
     * @class Message
     * @param {MessageConfig} config
     */
  message(config) {
    config.element?.forEach((element) => {
      const selectedMessage = element.closest(this.selectors.message);
      if (config.option && selectedMessage !== null) {
        selectedMessage.style.display = 'none';
      } else {
        selectedMessage.style.display = 'block';
        this.bindEvent({
          element: element,
          option: config.option
        });
      }
    });
  }

  /*
     * Determine whether or not an element have to be hidden
     * @private
     */
  hideElement(element) {
    this.elementsClosed = sessionStorage.getItem(CoreJS.StorageConstants.ELEMENT_CLOSED) ?
      JSON.parse(`[${ sessionStorage.getItem(CoreJS.StorageConstants.ELEMENT_CLOSED) }]`) :
      [];
    for (let i = 0; i < this.elementsClosed.length; i++) {
      if (
        element.dataset.id !== null &&
                element.dataset.id === `${ this.elementsClosed[i]}`
      ) {
        return true;
      }
    }
    return false;
  }

  // Placing a message before specific element
  placeMessageBefore() {
    // choose between header and shared header
    const messageParentDiv = this.componentHost.firstChild.nextElementSibling.classList;
    const message = messageParentDiv.contains('ace-message-warning') || messageParentDiv.contains('ace-message-error') || messageParentDiv.contains('ace-message-default') ?
      document.getElementsByClassName(
        this.selectors.alertComponent
      )[0] : document.getElementsByClassName(
        this.selectors.messageComponent
      )[0];
    const header = messageParentDiv.contains('ace-message-warning') || messageParentDiv.contains('ace-message-error') || messageParentDiv.contains('ace-message-default') ?
      document.getElementsByClassName(
        this.selectors.sharedHeader
      )[0] : document.getElementsByClassName(
        this.selectors.pageHeader
      )[0];
    if (!this.editMode) {
      if (message && header) {
        header.prepend(message);
      }
    }
  }

  /**
     * Document ready handler. Initializes Message components as necessary.
     *
     * @private
     */
  onDocumentReady() {
    if (this.componentHost) {
      this.placeMessageBefore();
      this.message({
        element: this.componentHost.querySelectorAll(this.selectors.close),
        option: this.hideElement(this.componentHost)
      });
    }
  }
}

// Register the Message component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Message.CLASS_NAMESPACE, Message);
