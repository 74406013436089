/* eslint-disable no-undef */
/**
 * @author HariomTomar
 * @class Callout
 * @version 1.0
 * @inheritDoc
 */

export default class Callout extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-callout-component';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    this.title = this.componentHost.querySelector('.cmp-teaser__pretitle');
    this.setCalloutClickListener();
    this.calloutSpacing();
    this.escapeCalloutsList();
    this.calloutreviewResort();
  }
  /*
  Function to handle Escape Callouts List.
  */
  escapeCalloutsList() {
    this.componentHost.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
        let calloutContainer = event.target.closest('.callout-container');
        const originCalloutContainer = calloutContainer;
        if (calloutContainer) {
          let moveNextBlock = true;
          while (moveNextBlock) {
            if (calloutContainer.nextElementSibling?.querySelector('a,button')) {
              calloutContainer.nextElementSibling?.querySelector('a,button').focus();
              moveNextBlock = false;
            } else {
              if (calloutContainer.nextElementSibling) {
                calloutContainer = calloutContainer.nextElementSibling;
              } else {
                originCalloutContainer.querySelector('a')?.focus();
                moveNextBlock = false;
              }
            }
          }
        }
      }
    });
  }


  /*
  Based on the availabllity of carousel inside callout component redirect respective function
  */
  setCalloutClickListener() {
    if (this.componentHost.closest('.ace-carousel__item') ) {
      // Setting Carousel Click Listener
      this.setCarouselClickListener();
    } else {
      // Resort the Callout
      this.calloutResort();
    }
  }

  /*
  Function to adjust Callout Spacing
  */
  calloutSpacing() {
    const listblue = document.querySelector('.ace-callout-list-blue,.ace-callout-list-vcard,.ace-callout-clickable');
    // Check if listblue element exists
    if (listblue) {
      listblue.closest('.ace-callout-section').classList.add('ace-callout-section-blue-card');
    }
  }

  /**
   * Data layer attributes added for resort template/subscription page
   */
  calloutResort() {
    const layerElement = document.querySelector('[data-cmp-datalayer]');
    const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
    // eslint-disable-next-line no-unused-vars
    const pageName = layerElement?.getAttribute('data-layer-page');
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const pageNameSplit = datalayerValue.pageName.split('::');
      const resortTemplate = document.querySelector('[data-template-name="resort-template"]');
      if (pageNameSplit[0] === 'resorts') {
        if (resortTemplate) {
          this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GAevent',
              eventName: 'see hotel page',
              eventCategory: `resorts - ${ pageNameSplit[1]}`,
              eventAction: 'click on cta',
              eventLabel: 'see hotel page'
            };
            TagManager.trackEvent(datasEvent);
            const datasEvents = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `destination - ${ pageNameSplit[2]}`,
                bloc_name: 'hotel',
                bloc_interaction: 'see hotel page'
              }
            };
            TagManager.trackEvent(datasEvents);
          });
        }
      } else if (pageName === 'subscription-page') {
        this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          let titleValue = '';
          if (this.title) {
            titleValue = this.title.innerText;
          }
          window.dataLayer.push({
            'event': 'GAevent',
            'eventCategory': 'loyalty program',
            'eventAction': 'all signature - partener transfer - click on cta',
            'eventLabel': titleValue
          });
        });
      }
    }
  }


  /**
   * Data layer attributes based on the click event in callout
   */
  setCarouselClickListener() {
    const dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayer) {
      // eslint-disable-next-line no-unused-vars
      const datalayerValue = JSON.parse(dataLayer);
      let titleValue = '';
      if (this.title) {
        titleValue = this.title.innerText;
      }

      this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        window.dataLayer.push({
          'event': 'GAevent',
          'eventCategory': 'loyalty program',
          'eventAction': 'all signature- platform access - click on cta',
          'eventLabel': titleValue
        });
      });
    }
  }


  /*
  Function to handle guest view more list.
  */
  guestviewmoreList() {
    // Find all the elements with the selector
    const guestList = document.querySelectorAll('.ace-callout-section-blue-card li');
    const guestViewmore = document.querySelector('.ace-guest-viewmore');

    // Check if guestViewmore element available
    if (guestViewmore) {
      guestList.forEach((element, index) => {
        const hiddenList = 7;
        // Check if index is less than or equal to hiddenlist
        if (index <= hiddenList) {
          // Remove 'hidden' class from the element
          element.classList.remove('hidden');
        } else {
          // Add 'hidden' class
          element.classList.add('hidden');
          // Add Event Listener for click on guestviewmore
          guestViewmore.addEventListener(CoreJS.DomEventConstants.CLICK, (event)=>{
            guestViewmore.classList.add('hidden');
            element.classList.remove('hidden');
            event.preventDefault();
          });
        }
      });
    }
  }
  calloutreviewResort() {
    const layerElement = document?.querySelector('[data-cmp-datalayer]');
    const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const pageNameSplit = datalayerValue.pageName.split('::');
      if (pageNameSplit[0] === 'resorts') {
        const reviewdescription = document?.querySelectorAll('.callout-container .cmp-teaser__description.line-clamp');
        reviewdescription.forEach((item) =>{
          item.style.display = 'none';
        });
      }
    }
  }
}

const layerElement = document.querySelector('[data-cmp-datalayer]');
const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
const pageName = layerElement?.getAttribute('data-layer-page');
// eslint-disable-next-line no-unused-vars
if (dataLayer && JSON.parse(dataLayer)?.siteCode == 'all') {
  const datalayerValue = JSON.parse(dataLayer);
  const pageNameSplit = datalayerValue.pageName.split('::');

  const bookingHotelPage = document.querySelectorAll('.offer-page .callout-container .ace-callout-container .cmp-container .callout-section .ace-callout-section li .ace-callout-component');
  bookingHotelPage.forEach((item) =>{
    item.addEventListener('click', () => {
      const datasEvent = {
        event: 'GAevent',
        eventCategory: 'promotion',
        eventAction: 'click on cta',
        eventLabel: 'hotel',
        eventName: 'bloc_interact',
        event_data: {
          pagename: datalayerValue.pageName,
          offer_id: `${pageNameSplit[3]}`,
          bloc_name: 'hotel',
          bloc_interaction: 'discover hotel'
        }
      };
      TagManager.trackEvent(datasEvent);
    });
  });
} else if (pageName === 'offer-page-v2') {
  const datalayerValue = JSON.parse(dataLayer);
  const hotelbloc = document?.querySelectorAll('.callout-container .ace-callout-component[data-product-id]');
  hotelbloc.forEach((item) =>{
    item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      const datasGa4Event = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: datalayerValue.pageName,
          bloc_type: item.getAttribute('data-product-id'),
          bloc_name: 'hotel',
          bloc_interaction: 'discover hotel'
        }
      };
      TagManager.trackEvent(datasGa4Event);
    });
  });
}

// Register the Callout component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Callout.CLASS_NAMESPACE, Callout);
