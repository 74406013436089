/* eslint-disable no-console */
/**
 * @author Zayam
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */

import { getTSMAuthUrl } from '@accor/customer-api/programs/tsm';

export default class HeadingHero extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-headingpagehero';

  /** @inheritDoc */

  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */

  initialize() {
    super.initialize();
    this.fullGrid();
    this.HeadingHeroOnebutton();
    this.getUserData();
    this.layerElements = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.layerElements)?.pageName?.split('::');
    this.subscriptionTSM();
  }

  fullGrid() {
    const buttonLeft = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-btn-group__item--left');
    const overlay = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero .cmp-headingpagehero__overlay');
    const componentWrapper = document.querySelector('.ace-headingpagehero');
    const cmpHeadingPageHero = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero');
    const buttonWrap = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-btn-group ');
    const headingImage = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero .cmp-ace-image');
    if (buttonLeft && buttonLeft.classList.contains('buttonleft-fullgrid')) {
      componentWrapper.classList.add('fullGrid');
      cmpHeadingPageHero.classList.add('fullGrid');
      buttonWrap.classList.add('fullGrid');
      if(overlay ) {
         overlay.classList.add('fullGrid');
       }
       if(headingImage) {
         headingImage.classList.add('fullGrid1');
       }
    }
  }

  HeadingHeroOnebutton() {
    const img1length = this.componentHost.querySelector('.ace-headingpagehero .cmp-ace-image.cmp-imaged')?.children?.length;
    const button1length = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-btn-group__item.cmp-headingpagehero-btn-group__item--left')?.children?.length;
    const button1 = this.componentHost.querySelector('.ace-headingpagehero .cmp-ace-hotel__gallery--button ');
    const button2 = this.componentHost.querySelector('.ace-headingpagehero .virtual-btn');
    const buttonWrapper = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-btn-group ');
    const compWrapper = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero');
    const mainContent = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-maincontent');
    const descriptionWrapper = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-decription');
    const kickerWrapper = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-kicker');
    const titleWrapper = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-title');
    const overlay = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero .cmp-headingpagehero__overlay');
    const contentWrapper = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero__content');
    const singleButtonCenter = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero-btn-group__item--left');
    const singleButtonBottom = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group');
    const oneImageFullGrid = this.componentHost.querySelector('.ace-headingpagehero .cmp-headingpagehero.fullGrid');

    // with image
    if (img1length === 1 && button1length === 1) {
      button1?.children[0]?.classList?.add('displayLabel');
      button1?.children[2]?.classList?.add('hideIcon');
      buttonWrapper?.classList?.add('alignCenter');
      button1?.classList?.add('singleButton');
      singleButtonCenter?.classList?.add('singleButtonCenter');
      oneImageFullGrid?.classList?.add('oneImageFullGrid');
      button1?.classList?.add('oneImageButton');
      compWrapper?.classList?.add('oneImageFullWidth');
    }

    // without image
    if (img1length === 0 && button1length === 1) {
      mainContent?.classList?.add('wrapperMainContent');
      descriptionWrapper?.classList?.add('wrapperDescription');
      kickerWrapper?.classList?.add('wrapperKicker');
      titleWrapper?.classList?.add('wrapperTitle');
      button1?.children[0]?.classList?.add('displayLabel');
      button1?.children[2]?.classList?.add('hideIcon');
      buttonWrapper?.classList?.add('alignCenter');
      button1?.classList?.add('singleButton');
      buttonWrapper?.classList?.add('buttonPos');
      overlay?.classList?.add('hideOverlay');
      contentWrapper?.children[0]?.classList?.add('wrapperContent');
      singleButtonCenter?.classList?.add('singleButtonCenter');
      singleButtonBottom?.classList?.add('singleButtonBottom');
    }

    // with image and 2 buttons
    if (img1length === 1 && button1length === 2) {
      button1?.classList?.add('buttonSize1');
      button2?.classList?.add('buttonSize2');
    }

    // with no image and no buttons
    if (img1length === 0) {
      mainContent?.classList?.add('wrapperMainContent');
      kickerWrapper?.classList?.add('wrapperKicker');
      descriptionWrapper?.classList?.add('wrapperDescription');
      titleWrapper?.classList?.add('wrapperTitle');
      overlay?.classList?.add('hideOverlay');
    }
  }

  // Display customer’s first name as a variable from the customer-api in the heading hero component
  getUserData() {
    const method = 'GET';
    const fields = 'individual.individualName';
    const url = `${CoreJS.Constants.REC_URL_CONSTANT.me}?fields=${fields}`;
    const getUserFilterData = {
      fields: fields
    };
    const data = CoreJS.CommonUtils.toggleEnvironment(url, method, getUserFilterData);
    data?.then((response)=>{
      const getFirstName = response?.individual?.individualName?.firstName;
      this.headingTitle = this.componentHost.querySelector('.cmp-headingpagehero-title');
      if (this.headingTitle) {
        if (CoreJS.CommonUtils.userLoginStatus()) {
          this.headingTitle.innerHTML = this.headingTitle.innerHTML.replace('${individual.individualName.firstName}', getFirstName);
        } else {
          this.headingTitle.innerHTML = this.headingTitle.innerHTML.replace('${individual.individualName.firstName}', '');
        }
        this.headingTitle?.classList.remove('hidden');
      }
    });
  }

  subscriptionTSM() {
    this.headingTSMbtn = this.componentHost.querySelector('.cmp-button.cmp-button--inverse.cmp-ace-hotel__gallery--button.singleButton');
    this.calltoActionTSM = document.querySelector('.landing-page-template .call-to-action .cmp-button.cmp-button--secondary');
    const searchParams = new URLSearchParams(window.location.search);
    const typePCValue = searchParams.get('typePC') ? searchParams.get('typePC') : '';
    const promoCodeValue = searchParams.get('promoCode') ? searchParams.get('promoCode') : '';
    if (this.datalayerValues[1] === 'temporary status match') {
      this.headingTSMbtn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        window.location.href = getTSMAuthUrl({ typePC: typePCValue, promoCode: promoCodeValue });
      });
    }
    this.calltoActionTSM?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      window.location.href = getTSMAuthUrl({ typePC: typePCValue, promoCode: promoCodeValue });
    });
  }
}

CoreJS.BaseComponent.registerComponent(HeadingHero.CLASS_NAMESPACE, HeadingHero);

