import { TagManager } from '@accor/ace-ui-core';

export default class AllCalltoaction extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-cmp-CTA';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.ctaTagmanager();
  };

  /*
      Function to overwrite tagmanager for Cobrand
    */

  ctaTagmanager() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.querySelector('.call-to-action .cmp-button--secondary')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'offer bloc',
              bloc_interaction: 'compare payment cards'
            }
          };
          // eslint-disable-next-line no-undef

          TagManager.trackEvent(datasEvent);
        });

        this.componentHost.querySelector('.call-to-action .cmp-button--tertiary')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'offer bloc',
              bloc_interaction: 'subscribe'
            }
          };
          // eslint-disable-next-line no-undef

          TagManager.trackEvent(datasEvent);
        });

        const pdfButtons = this.componentHost.querySelectorAll('.call-to-action__link');
        pdfButtons.forEach((item) =>{
          item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: dataLayer.pageName,
                bloc_name: 'faq',
                bloc_interaction: item.innerText.toLowerCase()
              }
            };
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEvent);
          });
        });
      } else if (pageToTarget == 'homepage') {
        this.Btn = document.querySelector('.highlight .cmp-button.cmp-button--secondary');
        this.BtnTertiary = document.querySelector('.highlight .cmp-button.cmp-button--tertiary');
        this.Btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          window.dataLayer.push({
            'event_data':null
          });
            const datasEvent = {
              event: 'GA4event',
              eventName:'cta_enroll',
              event_data: {
                pagename : dataLayer.pageName,
                form_action:'enroll',
                enroll_context:'homepage'
              }
            };
            TagManager.trackEvent(datasEvent);
        });
        this.BtnTertiary?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          window.dataLayer.push({
            'event_data':null
          });
            const datasEvent = {
              event: 'GA4event',
              eventName:'cta_enroll',
              event_data: {
                pagename : dataLayer.pageName,
                form_action:'discover program',
                enroll_context:'homepage'
              }
            };
            TagManager.trackEvent(datasEvent);
        });
      } else if (pageToTarget == 'temporary status match') {
        this.calloutVariation = this.componentHost.closest('.call-to-action.aem-GridColumn.aem-GridColumn--default--12').previousElementSibling;
        this.lnk = this.componentHost.querySelector('.call-to-action__link');
        this.Btn = this.componentHost.querySelector('.cmp-button.cmp-button--secondary')
        this.lnk?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if(this.calloutVariation?.querySelector('li div')?.style?.backgroundColor !== undefined ){
          window.dataLayer.push({
            'event_data':null
          });
          const datasEvent = {
          event: 'GA4event',
          eventName:'bloc_interact',
          event_data: {
            pagename : dataLayer.pageName,
            bloc_interaction:'discover gold benefits',
            bloc_type:'benefits',
            context:'loyalty program'
          }
        };
        TagManager.trackEvent(datasEvent);
        } else {
          window.dataLayer.push({
            'event_data':null
          });
          const datasEvent = {
            event: 'GA4event',
            eventName:'bloc_interact',
            event_data: {
              pagename : dataLayer.pageName,
              bloc_interaction:'terms and conditions',
              bloc_type:'time to play',
              context:'loyalty program'
            }
          };
          TagManager.trackEvent(datasEvent);
        }
      })
      this.Btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        window.dataLayer.push({
          'event_data':null
        });
          const datasEvent = {
            event: 'GA4event',
            eventName:'cta_enroll',
            event_data: {
              pagename : dataLayer.pageName,
              cta_name:'join us',
              enroll_context:'loyalty program'
            }
          };
          TagManager.trackEvent(datasEvent);
      })
      }
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(AllCalltoaction.CLASS_NAMESPACE, AllCalltoaction, true);