

/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class SharedFooter extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'shared-footer';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Function Initialization
  initialize() {
    super.initialize();
    this.dataFetchHeader = this.componentHost.querySelector('#shared-footer-markup')?.getAttribute('data-fetch-footer');
    this.SharedNavigationMarkup = this.componentHost?.querySelector('#shared-footer-markup');
    if (this.dataFetchHeader == 'true') {
      this.callAjaxUrl();
    }
  }
  // Function
  callAjaxUrl() {
    // Creating a new instance of XMLHttpRequest
    const xhttp = new XMLHttpRequest();
    xhttp.open('GET', this.SharedNavigationMarkup?.getAttribute('data-remote-url'), true);
    // Sending request
    xhttp.send();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === XMLHttpRequest.DONE && xhttp.status === 200) {
        this.SharedNavigationMarkup.innerHTML = xhttp.response;
      }
    };
  }
}

// Register the SharedFooter component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(SharedFooter.CLASS_NAMESPACE, SharedFooter);
