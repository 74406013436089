/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Quick Access Component instance
 * @author Nitesh.Vaidya
 * @class QuickAccess
 * @classdesc Ace quick access component to inherit the base component
 * @inheritDoc
 * @extends CoreJS.BaseComponent
 * @version 1.0
 */
export default class QuickAccess extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-quick-access-component';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.tab = this.componentHost.querySelectorAll(
      '.cmp-ace-anchor-navigation__tab'
    );
    this.tabs = this.componentHost.querySelectorAll(
      '.cmp-ace-anchor-navigation__tabs'
    );
    this.anchorNavigation = this.componentHost.querySelector(
      '.cmp-ace-anchor-navigation'
    );
    this.anchorButton = this.componentHost.querySelector(
      '.cmp-anchor-nav-button'
    );
    this.filterIcon = this.componentHost.querySelector('.cmp-filter__icon');
    this.filterDropdown = this.componentHost.querySelector('.cmp-filter__menu');
    this.mobileDropdownButton = this.componentHost.querySelector(
      '.cmp-anchor-nav-button button'
    );
    this.mobileDropdownButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.mobileDropdownButton.getAttribute('aria-expanded') === 'true' ?
        this.closeDropdown() :
        this.openDropdown();
    });
    this.tabActive = 'cmp-ace-anchor-navigation__tab--active';
    this.pageSections = 'heading';
    this.activeLinkHanlder();
    this.dropdownOutsideClickHandler();
    window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
      this.sticky();
    });
  }
  /**
     * Handler to open the dropdown
     */
  openDropdown() {
    this.mobileDropdownButton.setAttribute('aria-expanded', true);
    this.filterDropdown.classList.add('show');
  }
  /**
     * Handler to close the dropdown
     */
  closeDropdown() {
    this.mobileDropdownButton.setAttribute('aria-expanded', false);
    this.filterDropdown.classList.remove('show');
  }
  /**
     * Close dropdown on clicking outside
     */
  dropdownOutsideClickHandler() {
    window.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        if (!event.target.closest('.ace-quick-access-component')) {
          this.closeDropdown();
        }
      },
      false
    );
  }

  /*
     * scrollToElement
     */
  scrollToElement(selector, onLoad = false) {
    let anchorHeight = document.querySelector('.cmp-ace-anchor-navigation').clientHeight;
    if (onLoad) {
      anchorHeight += 25;
    }
    const element = document.querySelector('.cmp-ace-anchor-navigation');
    const cssObj = window.getComputedStyle(element);
    let scrollDiv;
    if (cssObj.position === 'fixed') {
      scrollDiv = document.querySelector(selector).closest('.heading').offsetTop;
      window.scrollTo({ top: scrollDiv - anchorHeight - 15, behavior: 'smooth' });
    } else {
      scrollDiv = document.querySelector(selector).closest('.heading').offsetTop;
      window.scrollTo({ top: scrollDiv - (anchorHeight * 2.3) - 29, behavior: 'smooth' });

      if (selector == '#america') {
        window.scrollTo({ top: scrollDiv - (anchorHeight * 2) - 17, behavior: 'smooth' });
      } else if (selector == '#europe') {
        window.scrollTo({ top: scrollDiv - (anchorHeight * 2.3) - 17, behavior: 'smooth' });
      } else if (selector == '#asia') {
        window.scrollTo({ top: scrollDiv - anchorHeight - 59.5 * 2, behavior: 'smooth' });
      } else if (selector == '#pacific') {
        window.scrollTo({ top: scrollDiv - anchorHeight - 58.3 * 2, behavior: 'smooth' });
      } else {
        if (onLoad) {
          window.scrollTo({ top: scrollDiv - (anchorHeight * 2.5) - 40, behavior: 'smooth' });
        }
        window.scrollTo({ top: scrollDiv - (anchorHeight * 2.3) - 29, behavior: 'smooth' });
      }
    }
  }

  /*
  Function to scroll to a mobile elememt.
  Handles logic for scrolling to each mobile element.
  */
  scrollToMobileElement(selector) {
    document.querySelectorAll('.cmp-anchor-nav-button').forEach((item) =>{
      item.addEventListener('click', ()=>{
        const mobileElement = document.querySelector('.cmp-anchor-nav-button');
        const mobileElementHeight = document.querySelector('.cmp-anchor-nav-button').clientHeight;
        let scrollDiv;
        if (window.getComputedStyle(mobileElement).position === 'fixed') {
          scrollDiv = document.querySelector( selector ).closest('.heading').offsetTop;
          window.scrollTo({ top: scrollDiv - mobileElementHeight, behavior: 'smooth' });
        } else {
          scrollDiv = document.querySelector( selector ).closest('.heading').offsetTop;
          window.scrollTo({ top: scrollDiv - (mobileElementHeight * 3) - 30, behavior: 'smooth' });
        }
      });
    });
  }

  /**
     * sticky handler
     */
  sticky() {
    this.closeDropdown();
    if (this.componentHost) {
      document.querySelector('html').classList.add('scroll-top-padding', 'hydrated');
    }
    const sentinalElement = document.querySelector('.ace-quick-access-component');
    const handler = (entries) => {
      if (!entries[0].isIntersecting) {
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          this.anchorNavigation.style.position = 'fixed';
          this.anchorNavigation.style.top = '0px';
        } else {
          this.anchorButton.style.position = 'fixed';
          if (this.filterIcon) {
            this.filterIcon.style.top = '20px';
          }
        }
      } else {
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          this.anchorNavigation.style.position = 'absolute';
        } else {
          this.anchorButton.style.position = 'absolute';
        }
      }
    };
    // create the observer
    const observer = new window.IntersectionObserver(handler, {
      rootMargin: '0px 0px 100% 0px',
      threshold: 1.0
    });
    // give the observer some dom nodes to keep an eye on
    observer.observe(sentinalElement);
    let activeLinkItem = '';
    const pageSections = document.getElementsByClassName(this.pageSections);
    for (const element of pageSections) {
      const presentSection = element;
      const sectionTop = presentSection.offsetTop;
      if (window.pageYOffset >= sectionTop - 320) {
        if (presentSection.getElementsByTagName('h1').length > 0) {
          activeLinkItem = presentSection
            .getElementsByTagName('h1')[0]
            .getAttribute('id');
        } else if (presentSection.getElementsByTagName('h2').length > 0) {
          activeLinkItem = presentSection
            .getElementsByTagName('h2')[0]
            .getAttribute('id');
        } else if (presentSection.getElementsByTagName('h3').length > 0) {
          activeLinkItem = presentSection
            .getElementsByTagName('h3')[0]
            .getAttribute('id');
        } else if (presentSection.getElementsByTagName('h4').length > 0) {
          activeLinkItem = presentSection
            .getElementsByTagName('h4')[0]
            .getAttribute('id');
        }
      }
    }
    this.tab.forEach((tabItem) => {
      tabItem.classList.remove(this.tabActive);
      tabItem.setAttribute('aria-current', false);
    });
    this.tab.forEach((tabItem) => {
      if (
        activeLinkItem !== '' &&
          decodeURI(tabItem.getAttribute('href')) === `#${activeLinkItem}`
      ) {
        tabItem.classList.add(this.tabActive);
        tabItem.setAttribute('aria-current', true);
        // update section names into stickybar on scroll
        if (this.mobileDropdownButton) {
          this.mobileDropdownButton.innerHTML = tabItem.innerHTML;
        }
      }
    });

    this.tabs.forEach((tab) => {
      tab.addEventListener('click', (event) => {
        event.preventDefault();
        window.location.hash = event.target.getAttribute('href');
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.scrollToElement(event.target.getAttribute('href'));
        }
        if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.scrollToMobileElement(event.target.getAttribute('href'));
        }
      });
    });
  }


  /**
     * Active links handler
     */
  activeLinkHanlder() {
    this.tabs.forEach((tabItem) => tabItem.classList.remove(this.tabActive));
    this.tabs.forEach((tab) => {
      tab.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.tabs.forEach((tabItem) =>
          tabItem.classList.remove(this.tabActive)
        );
        tab.classList.add(this.tabActive);
      });
    });


    window.addEventListener(CoreJS.DomEventConstants.LOAD, () => {
      if (window.location.hash !== '') {
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.scrollToElement(window.location.hash, true);
        }
        if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.scrollToMobileElement(window.location.hash);
        }
      }
      document.querySelectorAll('.ace-quick-access-component nav ul li a')?.forEach((item) => {
        if (item.getAttribute('href') == window.location.hash) {
          const el = item.getAttribute('href').slice(1);
          document.getElementById(el).scrollIntoView({ block: 'center' });
          //  this.scrollToElement(el);
          //  this.scrollToMobileElement(e1);
        }
      });
    });
  }
}


// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(QuickAccess.CLASS_NAMESPACE, QuickAccess);

