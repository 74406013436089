/* eslint-disable no-unused-vars */
/** * @author Rishabh * @classdesc
 * This class is defining an implementation from Base * @version 1.0 */

export default class LoyaltyInfo extends CoreJS.BaseComponent {
  /** @inheritDoc */

  static CLASS_NAMESPACE = 'loyalty-info';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.getuserloyaltyinfos();
    this.loyaltyInfoContainer = this.componentHost;
    this.productTierValue = this.componentHost.querySelector('.productTierValue');
  }

  getuserloyaltyinfos() {
    const fields = 'loyalty.balances.currentNightsBalance,loyalty.balances.nbPointsEarnLast12Months,loyalty.loyaltyCards.card';
    const filter = 'loyalty.loyaltyCards.card:isLastActiveCard=true';
    const url = `${CoreJS.Constants.REC_URL_CONSTANT.me}?fields=${fields}&filter=${filter}`;
    const method = 'GET';
    const getUserFilterData = {
      fields: fields,
      filter: filter
    };
    const data = CoreJS.CommonUtils.toggleEnvironment(url, method, getUserFilterData);
    this.getUserData = data;
    this.getUserData?.then((resp) => {
      if (CoreJS.CommonUtils.userLoginStatus()) {
        this.loyaltyInfoContainer.style.display = 'block';
        const noOfNights = resp?.loyalty?.balances?.currentNightsBalance;
        const noOfPoints = resp?.loyalty?.balances?.nbPointsEarnLast12Months;
        const productTier = resp?.loyalty?.loyaltyCards?.card[0]?.cardProduct?.productTier;

        this.componentHost.querySelector('.noOfNightsValue').innerHTML = noOfNights;
        this.componentHost.querySelector('.noOfPointsValue').innerHTML = noOfPoints;
        this.componentHost.querySelector('.productTierValue').innerHTML = productTier;

        if (this.productTierValue.innerHTML == 'Classic') {
          this.componentHost.querySelector('.productTierValue').classList.add('classic');
        } else if (this.productTierValue.innerHTML == 'Gold') {
          this.componentHost.querySelector('.productTierValue').classList.add('gold');
        } else if (this.productTierValue.innerHTML == 'Silver') {
          this.componentHost.querySelector('.productTierValue').classList.add('silver');
        } else if (this.productTierValue.innerHTML == 'Platinum') {
          this.componentHost.querySelector('.productTierValue').classList.add('platinum');
        } else if (this.productTierValue.innerHTML == 'Diamond') {
          this.componentHost.querySelector('.productTierValue').classList.add('diamond');
        } else if (this.productTierValue.innerHTML == 'Limitless') {
          this.componentHost.querySelector('.productTierValue').classList.add('limitless');
        }
      }
    });
  }
}

// Register the Loyalty component with the BaseComponent
CoreJS.BaseComponent.registerComponent(LoyaltyInfo.CLASS_NAMESPACE, LoyaltyInfo);
