import { TagManager } from '@accor/ace-ui-core';

export default class AllCardeditorial extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'cardeditorial';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);

    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.pageName = this.datalayerValues.pageName.split('::');

    if (this.pageName[0] === 'thematic') {
        this.thematicBookingTrackings();
    }
  }


  thematicBookingTrackings() {
    const dealsCardEdito = this.componentHost;
    const cards = dealsCardEdito.querySelectorAll('.cardeditorial .cmp-slideshow__callouts .cmp-slideshow__callout-item');
    if (!cards.length) return;
    cards.forEach((card) => {
        const hotelName = card.querySelector('.cmp-slideshow__callout-title');
        const btn = card.querySelectorAll('a');
        btn.forEach((button) => {
            button.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
                const datasEvent = {
                    event: 'GA4event',
                    eventName: 'bloc_interact',
                    event_data: {
                        pagename: this.datalayerValues.pageName,
                        bloc_name: hotelName.innerText,
                        bloc_type: 'thematic_bloc::top_thematic',
                        bloc_interaction: 'click'
                    }
                };
                TagManager.trackEvent(datasEvent);
            });
        });
    });
  }
}


CoreJS.BaseComponent.registerComponent(AllCardeditorial.CLASS_NAMESPACE, AllCardeditorial, true);
