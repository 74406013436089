export const Nationality = { Russian: 'RU', RUSSIAN: 'RU' }

export const nationalityDictionary = { ru: Nationality.Russian }

export const nationalityData = {
  [Nationality.Russian]: {
    urls: {
      domain: 'https://all.accor.su',
      createAccount: 'create-account',
      updateAccount: 'update-account',
    },
  },
}
