import { addHeaderInterceptor } from './add-header-interceptor-factory'
import { ORIGIN, X_ORIGIN } from '../../common/constants'

const originInterceptor = async axiosInstance => {
  axiosInstance.interceptors.request.use(config => {
    const origin = config[ORIGIN] || config[X_ORIGIN]

    return origin ? addHeaderInterceptor(X_ORIGIN, origin, config) : config
  })
}

export default originInterceptor
