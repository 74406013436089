// taggin plan for section.js

import { TagManager } from '@accor/ace-ui-core';

export default class AllSection extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-shorttitle';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.layerElements = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.layerElements)?.pageName?.split('::');
    if (this.datalayerValues[0] === 'lp-europcar') {
      this.europcarPages();
    }
  }

  europcarPages() {
    const link = this.componentHost.querySelector('.ace-shorttitle__link .ace-shorttitle__link--text');
    link?.addEventListener('click', () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: 'loyalty program::partners::mobility::europcar',
          bloc_name: 'other partners',
          bloc_interaction: 'see all partners',
          bloc_type: 'partners listing',
          context: 'partners mobility'
        }
      };
      // eslint-disable-next-line no-undef
      TagManager.trackEvent(datasEvent);
    });
  }
}

CoreJS.BaseComponent.registerComponent(AllSection.CLASS_NAMESPACE, AllSection, true);
