/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Quick Access Component instance
 * @author Nitesh.Vaidya
 * @class QuickAccessV2
 * @classdesc Ace quick access component to inherit the base component
 * @inheritDoc
 * @extends CoreJS.BaseComponent
 * @version 1.0
 */
export default class QuickAccessV2 extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-quick-access-component-v2';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.tab = this.componentHost.querySelectorAll(
      '.cmp-ace-anchor-navigation__tab'
    );
    this.tabs = this.componentHost.querySelectorAll(
      '.cmp-ace-anchor-navigation__tabs .cmp-ace-anchor-navigation__tabs__anchors ul li .cmp-ace-anchor-navigation__tab'
    );
    this.anchorNavigation = this.componentHost.querySelector(
      '.cmp-ace-anchor-navigation'
    );
    this.anchorButton = this.componentHost.querySelector(
      '.cmp-anchor-nav-button'
    );
    this.filterIcon = this.componentHost.querySelector('.cmp-filter__icon');
    this.filterDropdown = this.componentHost.querySelector('.cmp-filter__menu');
    this.mobileDropdownButton = this.componentHost.querySelector(
      '.cmp-anchor-nav-button button'
    );
    this.mobileDropdownButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.mobileDropdownButton.getAttribute('aria-expanded') === 'true' ?
        this.closeDropdown() :
        this.openDropdown();
    });
    this.tabActive = 'cmp-ace-anchor-navigation__tab--active';
    this.pageSections = 'title aem-GridColumn aem-GridColumn--default--12';
    this.addPageSectionsToTitle();
    this.activeLinkHanlder();
    this.dropdownOutsideClickHandler();
    this.flagSticky = false;
    window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
      if (window.scrollY > this.componentHost.offsetTop) {
        this.flagSticky = true;
      } else {
        this.flagSticky = false;
      }
      this.sticky();
    });
    // Checking Quick Access in Novotel Brands
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]').getAttribute('data-cmp-datalayer');
    const dataLayerJson = JSON.parse(dataLayerValue);
    const brand = dataLayerJson?.brandContext;
    // Check the value of brand is 'novotel'
    if (brand === 'novotel') {
      document?.querySelectorAll('.section-background-container .cmp-container')?.forEach(( sectionQuickAccess ) => {
        if (sectionQuickAccess) {
          sectionQuickAccess?.removeAttribute('id');
        }
      });
    }
  }

  /**
         * Handler to add this.pageSections value to title component if not available
         */
  addPageSectionsToTitle() {
    document?.querySelectorAll('.container.responsivegrid .cmp-container .title')?.forEach(( title ) => {
      if (!title?.classList?.contains(this.pageSections)) {
        title?.classList?.add('aem-GridColumn');
        title?.classList?.add('aem-GridColumn--default--12');
      }
    });
  }
  /**
         * Handler to open the dropdown
         */
  openDropdown() {
    this.mobileDropdownButton.setAttribute('aria-expanded', true);
    this.filterDropdown.classList.add('show');
  }
  /**
         * Handler to close the dropdown
         */
  closeDropdown() {
    this.mobileDropdownButton.setAttribute('aria-expanded', false);
    this.filterDropdown.classList.remove('show');
  }
  /**
         * Close dropdown on clicking outside
         */
  dropdownOutsideClickHandler() {
    window.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        if (!event.target.closest('.ace-quick-access-component-v2')) {
          this.closeDropdown();
        }
      },
      false
    );
  }

  /*
         * scrollToElement
         */
  scrollToElement(selector, onLoad = false) {
    let anchorHeight = document.querySelector('.cmp-ace-anchor-navigation').clientHeight;
    if (onLoad) {
      anchorHeight += 25;
    }
    const element = document.querySelector('.cmp-ace-anchor-navigation');
    const cssObj = window.getComputedStyle(element);
    let scrollDiv;

    if (document.querySelector(selector).closest('.heading')) {
      scrollDiv = document.querySelector(selector).closest('.heading').offsetTop;
    } else {
      scrollDiv = document.querySelector(selector).offsetTop;
    }
    if (cssObj.position === 'fixed') {
      window.scrollTo({ top: scrollDiv - anchorHeight - 40, behavior: 'smooth' });
    } else {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      });

      window.scrollTo({ top: scrollDiv - (anchorHeight * 2.3) - 29, behavior: 'smooth' });

      if (selector == '#america') {
        window.scrollTo({ top: scrollDiv - (anchorHeight * 2) - 17, behavior: 'smooth' });
      } else if (selector == '#europe') {
        window.scrollTo({ top: scrollDiv - (anchorHeight * 2.3) - 17, behavior: 'smooth' });
      } else if (selector == '#asia') {
        window.scrollTo({ top: scrollDiv - anchorHeight - 59.5 * 2, behavior: 'smooth' });
      } else if (selector == '#pacific') {
        window.scrollTo({ top: scrollDiv - anchorHeight - 58.3 * 2, behavior: 'smooth' });
      } else {
        document.querySelector(selector).scrollIntoView({
          behavior: 'smooth'
        });
        /*
          if (onLoad) {
            window.scrollTo({ top: scrollDiv - (anchorHeight * 2.5) - 40, behavior: 'smooth' });
          }
          window.scrollTo({ top: scrollDiv - (anchorHeight * 2.3) - 29, behavior: 'smooth' });

          */
      }
    }
  }

  /*
      Function to scroll to a mobile elememt.
      Handles logic for scrolling to each mobile element.
      */
  scrollToMobileElement(selector) {
    document.querySelectorAll('.cmp-anchor-nav-button').forEach((item) => {
      const mobileElementHeight = item.clientHeight;
      const headerHeight = document.querySelector('header')?.clientHeight;
      item.addEventListener('click', () => {
        let scrollDiv;
        let scrolltolevel = headerHeight;
        if (document.querySelector(selector).closest('.heading')) {
          scrollDiv = document.querySelector(selector).closest('.heading').offsetTop;
        } else {
          scrollDiv = document.querySelector(selector).offsetTop;
        }
        if (headerHeight > mobileElementHeight && window.getComputedStyle(item).position === 'fixed') {
          scrolltolevel = headerHeight;
        } else scrolltolevel = mobileElementHeight;

        if (window.getComputedStyle(item).position === 'fixed') {
          window.scrollTo({ top: scrollDiv - scrolltolevel, behavior: 'smooth' });
        } else {
          window.scrollTo({ top: scrollDiv - (scrolltolevel * 3) - 30, behavior: 'smooth' });
        }
      });
    });
  }

  hasBookingEngineSticky () {
    return (document.querySelector('.sticky-booking-engine.visible'));
  }

  /**
         * sticky handler
         */
  sticky() {
    this.closeDropdown();
    if (this.componentHost) {
      document.querySelector('html').classList.add('scroll-top-padding', 'hydrated');
    }
    const sentinalElement = document.querySelector('.ace-quick-access-component-v2');
    const handler = (entries) => {
      if (!entries[0].isIntersecting && this.flagSticky) {
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          this.anchorNavigation.style.position = 'fixed';
          if (this.hasBookingEngineSticky()) {
            this.anchorNavigation.style.top = `${document.querySelector('.sticky-booking-engine.visible').offsetHeight}px`;
          } else {
            this.anchorNavigation.style.top = '0px';
          }

        } else {
          this.anchorButton.classList.add('sticky-behavior');
          this.anchorButton.style.position = 'fixed';
          if (this.filterIcon) {
            this.filterIcon.style.top = '1.25rem';
          }
        }
      } else {
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          this.anchorNavigation.style.position = 'absolute';
          this.anchorNavigation.style.top = "0px";
        } else {
          this.anchorButton.classList.remove('sticky-behavior');
          this.anchorButton.style.position = 'absolute';
          this.anchorNavigation.style.top = "0px";
        }
      }
    };
      // create the observer
    const observer = new window.IntersectionObserver(handler, {
      rootMargin: '0px 0px 100% 0px',
      threshold: 1.0
    });
      // give the observer some dom nodes to keep an eye on
    observer.observe(sentinalElement);
    let activeLinkItem = '';
    const pageSections = document.getElementsByClassName(this.pageSections);
    for (const element of pageSections) {
      const presentSection = element;
      const sectionTop = presentSection.offsetTop;
      let offsetData = 320;
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        offsetData = 200;
      }
      if (window.pageYOffset >= sectionTop - offsetData) {
        if (presentSection.querySelector('[id]')) {
          activeLinkItem = presentSection.querySelector('[id]')?.getAttribute('id');
        }
      }
    }

    this.tab.forEach((tabItem) => {
      if (
        activeLinkItem !== '' &&
          decodeURI(tabItem.getAttribute('href')) === `#${activeLinkItem}`
      ) {
        this.tab.forEach((tabItem) => {
          tabItem.classList.remove(this.tabActive);
          tabItem.removeAttribute('aria-current');
        });
        tabItem.classList.add(this.tabActive);
        tabItem.setAttribute('aria-current', 'true');
        // update section names into stickybar on scroll
        if (this.mobileDropdownButton) {
          this.mobileDropdownButton.innerHTML = tabItem.innerHTML;
        }
      }
    });
  }


  /**
         * Active links handler
         */
  activeLinkHanlder() {
    this.tab.forEach((tabItem) =>
      tabItem.classList.remove(this.tabActive)
    );
    this.tab.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        event.preventDefault();
        this.tab.forEach((tabItem) =>
          tabItem.classList.remove(this.tabActive)
        );
        item.classList.add(this.tabActive);
        item.setAttribute('aria-current', 'page');
        if (this.mobileDropdownButton) {
          this.mobileDropdownButton.innerHTML = item.innerHTML;
        }
        if (event.target.getAttribute('href').startsWith('#')) {
          document.querySelector(event.target.getAttribute('href'))?.scrollIntoView({
            behavior: 'smooth'
          });
          const element1 = document.querySelector('.cmp-ace-anchor-navigation');
          const cssObj1 = window.getComputedStyle(element1);
          //  const menuHeight = document.querySelector('.cmp-ace-anchor-navigation ').offsetHeight;
          const currentSelector = event.target.getAttribute('href');
          let scrollDivID;

          if (document.querySelector(currentSelector).closest('.heading')) {
            scrollDivID = document.querySelector(currentSelector).closest('.heading').offsetTop;
          } else {
            scrollDivID = document.querySelector(currentSelector).offsetTop;
          }

          if (cssObj1.position === 'fixed') {
            document.querySelector(event.target.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
            });
          } else {
            window.scrollTo({ top: scrollDivID - (25 * 2.3) - 29, behavior: 'smooth' });
            document.querySelector(event.target.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          window.open(event.target?.getAttribute('href'), event.target?.getAttribute('target') || '_self');
        }
      });
    });


    window.addEventListener(CoreJS.DomEventConstants.LOAD, () => {
      if (window.location.hash !== '') {
        if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.scrollToElement(window.location.hash, true);
        }
        if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
          this.scrollToMobileElement(window.location.hash);
        }
      }
    });
  }
}


// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(QuickAccessV2.CLASS_NAMESPACE, QuickAccessV2);
