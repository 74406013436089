{
    "ar": {
      "$booking.LabelTravellers": "المسافرون",
      "$booking.fidelityCard.label": "بطاقة الولاء أو الاشتراك",
      "$booking.fidelityCard.helper": "الرقم المؤلف من 16 عددًا على بطاقتك",
      "$booking.LabelProTravellers": "المسافرون المهنيون",
      "$booking.reserverId.label": "المسافرون بداعي الأعمال المتعاقدون",
      "$booking.reserverId.helper": "رمز العميل - sc، as…",
      "$booking.reserverId.Codeclient": "رمز العميل",
      "$booking.reserverContract.label": "رمز الوصول",
      "$booking.reserverContract.helper": "10رموز",
      "$booking.LabelPromo":"الرمز الترويجي",
      "$booking.preferredCode.label": "رمز الأفضلية",
      "$booking.LabelPromoDrop": "أسعار خاصة أخرى ورمز ترويجي",
      "$booking.preferredCodeDrop.label": "اختيار سعر",
      "$booking.promoCodeList.1": "لا شيء",
      "$booking.promoCodeList.2": "عضو AAA \/ CAA",
      "$booking.promoCodeList.3": "الحكومة",
      "$booking.promoCodeList.4": "عسكري ومحارب قديم",
      "$booking.promoCodeList.5": "خصم كبير",
      "$booking.promoCodeList.6": "\nالرمز الترويجي",
      "$booking.promoCodeList.cta": "تأكيد"
    },
    "de": {
      "$booking.LabelTravellers": "Reisende",
      "$booking.fidelityCard.label": "Treue- oder Abo-Karte",
      "$booking.fidelityCard.helper": "16-stellige Nummer auf Ihrer Karte",
      "$booking.LabelProTravellers": "Business Reisende",
      "$booking.reserverId.label": "Geschäftsreisende mit Vertrag",
      "$booking.reserverId.helper": "Kundennummer (SC oder AS Nr.)",
      "$booking.reserverId.Codeclient": "Kundennummer",
      "$booking.reserverContract.label": "Zugangscode",
      "$booking.reserverContract.helper": "10 Zeichen",
      "$booking.LabelPromo": "Werbe-Code",
      "$booking.preferredCode.label": "Präferenzcode",
      "$booking.LabelPromoDrop": "Weitere Sondertarife und Aktionscode",
      "$booking.preferredCodeDrop.label": "Tarif wählen",
      "$booking.promoCodeList.1": "Keine",
      "$booking.promoCodeList.2": "AAA-\/CAA-Mitglied",
      "$booking.promoCodeList.3": "Regierung",
      "$booking.promoCodeList.4": "Militär und Veteranen",
      "$booking.promoCodeList.5": "Seniorenrabatt",
      "$booking.promoCodeList.6": "Aktionscode",
      "$booking.promoCodeList.cta": "Bestätigen"
    },
    "en": {
      "$booking.LabelTravellers": "Travellers",
      "$booking.fidelityCard.label": "Loyalty or subscription number",
      "$booking.fidelityCard.helper": "16-digit number on your card",
      "$booking.LabelProTravellers": "Professional Travellers",
      "$booking.reserverId.label": "Business client with contract",
      "$booking.reserverId.helper": "Client code (SC, AS...)",
      "$booking.reserverId.Codeclient": "Code client",
      "$booking.reserverContract.label": "Access code",
      "$booking.reserverContract.helper":"10 characters",
      "$booking.LabelPromo":"Promo code",
      "$booking.preferredCode.label":"Preferential code",
      "$booking.LabelPromoDrop": "Other special rates and promo code",
      "$booking.preferredCodeDrop.label": "Choose a rate",
      "$booking.promoCodeList.1": "None",
      "$booking.promoCodeList.2": "AAA \/ CAA member",
      "$booking.promoCodeList.3": "Government",
      "$booking.promoCodeList.4": "Military & Veteran",
      "$booking.promoCodeList.5": "Senior Discount",
      "$booking.promoCodeList.6": "Promo code",
      "$booking.promoCodeList.cta": "Confirm"
    },
    "es": {
      "$booking.LabelTravellers": "Viajeros",
      "$booking.fidelityCard.label": "Tarjeta de fidelidad o de suscripción",
      "$booking.fidelityCard.helper": "número de 16 dígitos de su tarjeta",
      "$booking.LabelProTravellers": "Viajeros Profesionales",
      "$booking.reserverId.label": "Clientes de negocios con contrato",
      "$booking.reserverId.helper": "Código de cliente (SC, AS...)",
      "$booking.reserverId.Codeclient": "Código de cliente",
      "$booking.reserverContract.label": "Código de acceso",
      "$booking.reserverContract.helper": "10 caracteres",
      "$booking.LabelPromo": "Código promocional",
      "$booking.preferredCode.label": "Código preferente",
      "$booking.LabelPromoDrop": "Otras tarifas especiales y código promocional",
      "$booking.preferredCodeDrop.label": "Elija una tarifa",
      "$booking.promoCodeList.1": "Ninguna",
      "$booking.promoCodeList.2": "Miembro de la AAA\/CAA",
      "$booking.promoCodeList.3": "Gobierno",
      "$booking.promoCodeList.4": "Ejército y veteranos",
      "$booking.promoCodeList.5": "Descuento sénior",
      "$booking.promoCodeList.6": "Código promocional",
      "$booking.promoCodeList.cta": "Confirmar"
    },
    "fr": {
      "$booking.LabelTravellers": "Voyageurs",
      "$booking.fidelityCard.label": "Carte de fidélité ou d'abonnement",
      "$booking.fidelityCard.helper": "Numéro de carte à 16 chiffres",
      "$booking.LabelProTravellers": "Voyageurs professionnels",
      "$booking.reserverId.label": "Voyageurs d'affaires avec contrat",
      "$booking.reserverId.helper": "Code client (SC, AS….)",
      "$booking.reserverId.Codeclient": "Code client",
      "$booking.reserverContract.label": "Code d'accès",
      "$booking.reserverContract.helper": "10 caractères",
      "$booking.LabelPromo": "Code promo",
      "$booking.preferredCode.label": "Code préférentiel",
      "$booking.LabelPromoDrop": "Autres tarifs spéciaux et code promo",
      "$booking.preferredCodeDrop.label": "Choisir un tarif",
      "$booking.promoCodeList.1": "Aucun",
      "$booking.promoCodeList.2": "Membre AAA\/CAA",
      "$booking.promoCodeList.3": "Fonction publique",
      "$booking.promoCodeList.4": "Militaires et anciens combattants",
      "$booking.promoCodeList.5": "Réduction pour les retraités",
      "$booking.promoCodeList.6": "Code promo",
      "$booking.promoCodeList.cta": "Validez"
    },
    "id": {
      "$booking.LabelTravellers": "Wisatawan",
      "$booking.fidelityCard.label": "kartu loyalitas atau langganan",
      "$booking.fidelityCard.helper": "angka 16 digit di kartu Anda",
      "$booking.LabelProTravellers": "Wisatawan Profesional",
      "$booking.reserverId.label": "Pelancong bisnis dengan kontrak",
      "$booking.reserverId.helper": "Kode Klien (SC, AS…)",
      "$booking.reserverId.Codeclient": "Kode Klien",
      "$booking.reserverContract.label": "Kode akses",
      "$booking.reserverContract.helper": "10 karakter",
      "$booking.LabelPromo": "Kode promosi",
      "$booking.preferredCode.label": "Kode preferensial",
      "$booking.LabelPromoDrop": "Harga khusus dan kode promo lainnya",
      "$booking.preferredCodeDrop.label": "Pilih harga",
      "$booking.promoCodeList.1": "Tidak Ada",
      "$booking.promoCodeList.2": "Anggota AAA\/CAA",
      "$booking.promoCodeList.3": "Pemerintah",
      "$booking.promoCodeList.4": "Anggota Militer & Veteran",
      "$booking.promoCodeList.5": "Diskon Lansia",
      "$booking.promoCodeList.6": "\nKode promo",
      "$booking.promoCodeList.cta": "Konfirmasi"
    },
    "it": {
      "$booking.LabelTravellers": "Viaggiatori",
      "$booking.fidelityCard.label": "Carta fedeltà o abbonamento",
      "$booking.fidelityCard.helper": "numero a 16 cifre sulla tessera",
      "$booking.LabelProTravellers": "Viaggiatori professionisti",
      "$booking.reserverId.label": "Clienti con contratto che viaggiano per lavoro",
      "$booking.reserverId.helper": "Codice cliente (SC, AS...)",
      "$booking.reserverId.Codeclient": "Codice cliente",
      "$booking.reserverContract.label": "Codice d'accesso",
      "$booking.reserverContract.helper":"10 caratteri",
      "$booking.LabelPromo":"Codice promozionale",
      "$booking.preferredCode.label":"Codice preferenziale",
      "$booking.LabelPromoDrop": "Altre tariffe speciali e codice promozionale",
      "$booking.preferredCodeDrop.label": "Scegli una data",
      "$booking.promoCodeList.1": "Nessuna",
      "$booking.promoCodeList.2": "Socio AAA \/ CAA",
      "$booking.promoCodeList.3": "Amministrazione",
      "$booking.promoCodeList.4": "Militari e veterani",
      "$booking.promoCodeList.5": "Sconto Senior",
      "$booking.promoCodeList.6": "Codice promozionale",
      "$booking.promoCodeList.cta": "Conferma"
    },
    "ja": {
      "$booking.LabelTravellers": "旅行者",
      "$booking.fidelityCard.label": "ロイヤリティカードまたは登録カード",
      "$booking.fidelityCard.helper": "お客さまのカードの16桁の数字",
      "$booking.LabelProTravellers": "プロの旅行者",
      "$booking.reserverId.label": "契約済みビジネス旅行者",
      "$booking.reserverId.helper": "お客様コード （SC、ASなど）",
      "$booking.reserverId.Codeclient": "お客様コード",
      "$booking.reserverContract.label": "アクセスコード",
      "$booking.reserverContract.helper": "10文字",
      "$booking.LabelPromo": "プロモーションコード",
      "$booking.preferredCode.label": "優待コード",
      "$booking.LabelPromoDrop": "その他のスペシャル料金およびキャンペーンコード",
      "$booking.preferredCodeDrop.label": "料金を選択",
      "$booking.promoCodeList.1": "なし",
      "$booking.promoCodeList.2": "ALL\/CAA メンバー",
      "$booking.promoCodeList.3": "政府",
      "$booking.promoCodeList.4": "軍人および退役軍人",
      "$booking.promoCodeList.5": "シニア割引",
      "$booking.promoCodeList.6": "キャンペーンコード",
      "$booking.promoCodeList.cta": "確認"
    },
    "ko": {
      "$booking.LabelTravellers": "여행자",
      "$booking.fidelityCard.label": "로열티/가입 카드",
      "$booking.fidelityCard.helper": "카드의 16자리 번호",
      "$booking.LabelProTravellers": "전문 여행자",
      "$booking.reserverId.label": "계약사 비즈니스 여행객",
      "$booking.reserverId.helper": "회사 코드(SC, AS..으로 시작)",
      "$booking.reserverId.Codeclient": "회사 코드",
      "$booking.reserverContract.label": "액세스 코드",
      "$booking.reserverContract.helper": "10文字",
      "$booking.LabelPromo": "프로모션 코드",
      "$booking.preferredCode.label": "특전 코드",
      "$booking.LabelPromoDrop": "기타 특가 및 프로모션 코드",
      "$booking.preferredCodeDrop.label": "요금 선택",
      "$booking.promoCodeList.1": "없음",
      "$booking.promoCodeList.2": "AAA \/ CAA 회원",
      "$booking.promoCodeList.3": "정부",
      "$booking.promoCodeList.4": "군인 & 퇴역 군인",
      "$booking.promoCodeList.5": "시니어 할인",
      "$booking.promoCodeList.6": "프로모션 코드",
      "$booking.promoCodeList.cta": "확인"
    },
    "nl": {
      "$booking.LabelTravellers": "Reizigers",
      "$booking.fidelityCard.label": "Loyaliteits- of lidmaatschapskaart",
      "$booking.fidelityCard.helper": "16-cijferig nummer op uw kaart",
      "$booking.LabelProTravellers": "Professionele reizigers",
      "$booking.reserverId.label": "Zakelijke reizigers met een contract",
      "$booking.reserverId.helper": "Klantcode (SC, AS...)",
      "$booking.reserverId.Codeclient": "Klantcode",
      "$booking.reserverContract.label": "Toegangscode",
      "$booking.reserverContract.helper": "10 tekens",
      "$booking.LabelPromo": "Promotiecode",
      "$booking.preferredCode.label": "Voordeelcode",
      "$booking.LabelPromoDrop": "Andere speciale tarieven en promotiecode",
      "$booking.preferredCodeDrop.label": "Kies een tarief",
      "$booking.promoCodeList.1": "Geen",
      "$booking.promoCodeList.2": "Lid van AAA\/CAA",
      "$booking.promoCodeList.3": "Overheid",
      "$booking.promoCodeList.4": "Militair en veteraan",
      "$booking.promoCodeList.5": "Korting voor senioren",
      "$booking.promoCodeList.6": "Promotiecode",
      "$booking.promoCodeList.cta": "Bevestigen"
    },
    "pl": {
      "$booking.LabelTravellers": "Program lojalnościowy",
      "$booking.fidelityCard.label": "Numer Twojej karty",
      "$booking.fidelityCard.helper": "16-cyfrowy numer karty",
      "$booking.LabelProTravellers": "Podróże służbowe",
      "$booking.reserverId.label": "Wyjazdy służbowe objęte umową",
      "$booking.reserverId.helper": "Kod klienta (SC, AS...)",
      "$booking.reserverId.Codeclient": "Kod klienta",
      "$booking.reserverContract.label": "Kod dostępu",
      "$booking.reserverContract.helper": "10 znaków",
      "$booking.LabelPromo": "Oferty specjalne",
      "$booking.preferredCode.label": "Kod promocji",
      "$booking.LabelPromoDrop": "Inne specjalne ceny i kody promocyjne",
      "$booking.preferredCodeDrop.label": "Wybierz stawkę",
      "$booking.promoCodeList.1": "Brak",
      "$booking.promoCodeList.2": "Uczestnik AAA \/ CAA",
      "$booking.promoCodeList.3": "Karta rządowa",
      "$booking.promoCodeList.4": "Wojsko i weterani",
      "$booking.promoCodeList.5": "Zniżka dla seniorów",
      "$booking.promoCodeList.6": "Kod promocyjny",
      "$booking.promoCodeList.cta": "Potwierdź"
    },
    "pt-br": {
      "$booking.LabelTravellers": "Viajantes",
      "$booking.fidelityCard.label": "Cartão de fidelidade ou inscrição",
      "$booking.fidelityCard.helper": "número de 16 dígitos no seu cartão",
      "$booking.LabelProTravellers": "Viajantes Profissionais",
      "$booking.reserverId.label": "Viajantes a negócios que possuem contrato",
      "$booking.reserverId.helper": "Código SC",
      "$booking.reserverId.Codeclient": "Código",
      "$booking.reserverContract.label": "Código de acesso",
      "$booking.reserverContract.helper": "10 caracteres",
      "$booking.LabelPromo": "Código promocional",
      "$booking.preferredCode.label": "Código preferencial",
      "$booking.LabelPromoDrop": "Outras tarifas especiais e código promocional",
      "$booking.preferredCodeDrop.label": "Escolha uma tarifa",
      "$booking.promoCodeList.1": "Nenhuma",
      "$booking.promoCodeList.2": "Associado da AAA\/CAA",
      "$booking.promoCodeList.3": "Governo",
      "$booking.promoCodeList.4": "Militares e veteranos",
      "$booking.promoCodeList.5": "Desconto sênior",
      "$booking.promoCodeList.6": "Código promocional",
      "$booking.promoCodeList.cta": "Confirmar"
    },
    "pt": {
      "$booking.LabelTravellers": "Viajantes",
      "$booking.fidelityCard.label": "Cartão de fidelidade ou de subscrição",
      "$booking.fidelityCard.helper": "Número de 16 dígitos no seu cartão",
      "$booking.LabelProTravellers": "Viajantes Profissionais",
      "$booking.reserverId.label": "Clientes de negócios com contrato",
      "$booking.reserverId.helper": "Código de cliente (SC, AS…)",
      "$booking.reserverId.Codeclient": "Código de cliente",
      "$booking.reserverContract.label": "Código de acesso",
      "$booking.reserverContract.helper": "10 caracteres",
      "$booking.LabelPromo": "Código promocional",
      "$booking.preferredCode.label": "Código preferencial",
      "$booking.LabelPromoDrop": "Outras tarifas especiais e código promocional",
      "$booking.preferredCodeDrop.label": "Escolha uma tarifa",
      "$booking.promoCodeList.1": "Nenhum",
      "$booking.promoCodeList.2": "Membro AAA \/ CAA",
      "$booking.promoCodeList.3": "Governo",
      "$booking.promoCodeList.4": "Militar e Veterano",
      "$booking.promoCodeList.5": "Desconto para seniores",
      "$booking.promoCodeList.6": "Código promocional",
      "$booking.promoCodeList.cta": "Validar"
    },
    "ru": {
      "$booking.LabelTravellers": "Путешественники",
      "$booking.fidelityCard.label": "Карта лояльности или постоянного клиента",
      "$booking.fidelityCard.helper": "номер карты из 16 цифр",
      "$booking.LabelProTravellers": "ПРОФ. ПУТЕШЕСТВЕННИКИ",
      "$booking.reserverId.label": "Деловые путешественники с",
      "$booking.reserverId.helper": "контрактом Код клиента (SC, AS...)",
      "$booking.reserverId.Codeclient": "Код клиента",
      "$booking.reserverContract.label": "Код доступа",
      "$booking.reserverContract.helper": "10 символов",
      "$booking.LabelPromo": "Промо код",
      "$booking.preferredCode.label": "Специальный код",
      "$booking.LabelPromoDrop": "Другие специальные тарифы и промо-код",
      "$booking.preferredCodeDrop.label": "Выбрать тариф",
      "$booking.promoCodeList.1": "Нет",
      "$booking.promoCodeList.2": "Участник AAA\/CAA",
      "$booking.promoCodeList.3": "Государственная организация",
      "$booking.promoCodeList.4": "Военный и ветеран",
      "$booking.promoCodeList.5": "Скидка для пожилых людей",
      "$booking.promoCodeList.6": "Промо-код",
      "$booking.promoCodeList.cta": "Подтвердить"
    },
    "th": {
      "$booking.LabelTravellers": "นักท่องเที่ยว",
      "$booking.fidelityCard.label": "บัตรสมาชิกหรือสะสมคะแนน",
      "$booking.fidelityCard.helper": "หมายเลขบนบัตรของคุณ 16 หลัก",
      "$booking.LabelProTravellers": "นักเดินทางมืออาชีพ",
      "$booking.reserverId.label": "นักเดินทางธุรกิจที่มีสัญญา",
      "$booking.reserverId.helper": "รหัสลูกค้า (SC, AS...)",
      "$booking.reserverId.Codeclient": "รหัสลูกค้า",
      "$booking.reserverContract.label": "รหัสเข้าใช้งาน",
      "$booking.reserverContract.helper": "10 ตัวอักษร",
      "$booking.LabelPromo": "รหัสโปรโมชั่น",
      "$booking.preferredCode.label": "รหัสพิเศษ",
      "$booking.LabelPromoDrop": "อัตราราคาพิเศษและรหัสโปรโมชันอื่น",
      "$booking.preferredCodeDrop.label": "เลือกอัตราราคา",
      "$booking.promoCodeList.1": "ไม่มี",
      "$booking.promoCodeList.2": "สมาชิก AAA\/CAA",
      "$booking.promoCodeList.3": "ภาครัฐ",
      "$booking.promoCodeList.4": "ทหารและทหารผ่านศึก",
      "$booking.promoCodeList.5": "ส่วนลดสำหรับผู้สูงอายุ",
      "$booking.promoCodeList.6": "รหัสโปรโมชัน",
      "$booking.promoCodeList.cta": "ยืนยัน"
    },
    "tr": {
      "$booking.LabelTravellers": "Gezginler",
      "$booking.fidelityCard.label": "Sadakat veya abonelik kartı",
      "$booking.fidelityCard.helper": "16 haneli kart numaranız",
      "$booking.LabelProTravellers": "Profesyonel Gezginler",
      "$booking.reserverId.label": "Sözleşmeli olarak iş amaçlı seyahat edenler",
      "$booking.reserverId.helper": "Müşteri kodu (SC, AS...)",
      "$booking.reserverId.Codeclient": "Müşteri kodu",
      "$booking.reserverContract.label": "Erişim kodu",
      "$booking.reserverContract.helper": "10 karakter",
      "$booking.LabelPromo": "Promosyon kodu",
      "$booking.preferredCode.label": "Tercih kodu",
      "$booking.LabelPromoDrop": "Diğer özel fiyatlar ve promosyon kodu",
      "$booking.preferredCodeDrop.label": "Bir fiyat seçin",
      "$booking.promoCodeList.1": "Hiçbiri",
      "$booking.promoCodeList.2": "AAA \/ CAA üyesi",
      "$booking.promoCodeList.3": "Devlet Memuru Kredi Kartı",
      "$booking.promoCodeList.4": "Ordu ve Gazi",
      "$booking.promoCodeList.5": "Yaşlı İndirimi",
      "$booking.promoCodeList.6": "Promosyon kodu",
      "$booking.promoCodeList.cta": "Onayla"
    },
    "zh": {
      "$booking.LabelTravellers": "游客",
      "$booking.fidelityCard.label": "常客卡或预订卡",
      "$booking.fidelityCard.helper": "卡上的16位数字",
      "$booking.LabelProTravellers": "专业旅行者",
      "$booking.reserverId.label": "合同商务旅客",
      "$booking.reserverId.helper": "客户编号 （SC、AS...）",
      "$booking.reserverId.Codeclient": "客户编号",
      "$booking.reserverContract.label": "合同编号",
      "$booking.reserverContract.helper": "10个字符",
      "$booking.LabelPromo": "促销代码",
      "$booking.preferredCode.label": "优惠代码",
      "$booking.LabelPromoDrop": "其他特价和优惠码",
      "$booking.preferredCodeDrop.label": "选择房价",
      "$booking.promoCodeList.1": "无",
      "$booking.promoCodeList.2": "AAA\/CAA会员",
      "$booking.promoCodeList.3": "政府",
      "$booking.promoCodeList.4": "军队&老兵",
      "$booking.promoCodeList.5": "老人折扣",
      "$booking.promoCodeList.6": "优惠码",
      "$booking.promoCodeList.cta": "确认"
    }
  }
  