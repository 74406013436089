import { TagManager } from '@accor/ace-ui-core';

export default class SkipTheCleanTemplate extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'lp-skip-the-clean';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    if (this.dataLayers) {
      this.datalayerValues = JSON.parse(this.dataLayers);
      this.pageName = this.datalayerValues.pageName.split('::');
      this.initTracking();
    }
  }

  async initTracking() {
    const datasEvent = {
      category: 'loyalty program',
      page_sub_category_level1: 'skip the clean'
    };
    setTimeout(() => {
      window.dataLayer = window.dataLayer.filter((el) => el?.category !== 'lp-skip-the-clean');
    }, 2500);
    // to track the page use the following code
    TagManager.trackPage('loyalty::skip the clean', datasEvent);
  }
}


CoreJS.BaseComponent.registerComponent(SkipTheCleanTemplate.CLASS_NAMESPACE, SkipTheCleanTemplate, true);

// Initialiser l'instance de SkipTheCleanTemplate après le chargement du DOM
document.addEventListener(CoreJS.DomEventConstants.DOM_CONTENT_LOADED, () => {
  const componentHost = document.querySelector('.lp-skip-the-clean');
  if (componentHost) {
    new SkipTheCleanTemplate(componentHost);
  }
});
