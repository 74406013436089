import Cookies from 'js-cookie'
import { getLang } from './lang'

export const GEO_ZONES = [
  'algeria',
  'argentina',
  'australia',
  'austria',
  'bahrain',
  'belgium',
  'benin',
  'brazil',
  'bulgaria',
  'cambodia',
  'cameroon',
  'canada',
  'chad',
  'chile',
  'china',
  'colombia',
  'cuba',
  'czech-republic',
  'democratic-republic-lao',
  'democratic-republic-of-congo',
  'denmark',
  'dominican-republic',
  'ecuador',
  'egypt',
  'equatorial-guinea',
  'fiji',
  'finland',
  'france',
  'georgia',
  'germany',
  'ghana',
  'greece',
  'guatemala',
  'hong-kong',
  'hungary',
  'india',
  'indonesia',
  'ireland',
  'israel',
  'italy',
  'ivory-coast',
  'japan',
  'jordan',
  'kazakhstan',
  'kuwait',
  'latvia',
  'lebanon',
  'lithuania',
  'luxembourg',
  'macao',
  'macedonia',
  'madagascar',
  'malaysia',
  'maurice',
  'mexico',
  'morocco',
  'myanmar',
  'netherlands',
  'new-zealand',
  'nigeria',
  'norway',
  'oman',
  'other-regions-of-africa',
  'other-regions-of-asia',
  'other-regions-of-europe',
  'other-regions-of-middle-east',
  'other-regions-of-north-america',
  'other-regions-of-south-america',
  'panama',
  'paraguay',
  'peru',
  'philippines',
  'poland',
  'portugal',
  'qatar',
  'republic-of-korea',
  'romania',
  'russia',
  'saudi-arabia',
  'senegal',
  'singapore',
  'slovakia',
  'south-africa',
  'spain',
  'sweden',
  'switzerland',
  'taiwan',
  'thailand',
  'togo',
  'tunisia',
  'turkey',
  'ukraine',
  'united-arab-emirates',
  'united-kingdom',
  'united-states-of-america',
  'uruguay',
  'viet-nam',
]

const DEFAULT_CONTRIB_ZONE_BY_GEOZONE = {
  'other-regions-of-africa': 'africa',
  'other-regions-of-asia': 'asia',
  australia: 'australia',
  belgium: 'belgium',
  brazil: 'brasil',
  canada: 'canada',
  china: 'china',
  'other-regions-of-europe': 'czech-republic-slovakia-hungary-romania',
  denmark: 'denmark-norway-finland',
  germany: 'deutschland',
  spain: 'espana',
  france: 'france',
  india: 'india',
  indonesia: 'indonesia',
  ireland: 'ireland',
  italy: 'italia',
  japan: 'japan',
  mexico: 'mexico',
  'other-regions-of-middle-east': 'middle-east',
  netherland: 'nederland',
  'new-zealand': 'new-zealand-fiji',
  austria: 'oesterreich',
  poland: 'polska',
  portugal: 'portugal',
  russia: 'russia',
  malaysia: 'singapore-malaysia',
  'republic-of-korea': 'south-korea',
  'other-regions-of-south-america': 'sudamerica',
  sweden: 'sweden',
  switzerland: 'switzerland',
  'hong-kong': 'taiwan-hongkong-macao',
  thailand: 'thailand',
  turkey: 'turkey',
  'united-kingdom': 'united-kingdom',
  'other-regions-of-north-america': 'usa',
}

const DEFAULT_GEOZONE_BY_LANGUAGE_CODE = {
  ar: 'other-regions-of-middle-east',
  de: 'germany',
  en: 'united-kingdom',
  es: 'spain',
  fr: 'france',
  id: 'indonesia',
  it: 'italy',
  ja: 'japan',
  ko: 'republic-of-korea',
  nl: 'netherland',
  pl: 'poland',
  pt: 'portugal',
  'pt-br': 'brazil',
  ru: 'russia',
  sv: 'sweden',
  th: 'thailand',
  tr: 'turkey',
  zh: 'china',
}

export const getCountryFromCookies = () =>
  Cookies.get('contribZone') || Cookies.get('displayZone')

export const getCountry = (global = window) => {
  const locationInfo = global.document.location.href.match(
    /\/([a-z]{2}(?:-[a-z]{2})?)\/([a-z-]*)/
  )

  if (locationInfo?.length) {
    const country = locationInfo[2]
    const contribZone = DEFAULT_CONTRIB_ZONE_BY_GEOZONE[country]

    if (contribZone) contribZone

    if (GEO_ZONES.indexOf(country) != -1) return country
  }

  const countryFromCookies = getCountryFromCookies()
  if (countryFromCookies) return countryFromCookies

  const lang = getLang()

  if (lang) {
    const geoZone = DEFAULT_GEOZONE_BY_LANGUAGE_CODE[lang]

    if (geoZone) {
      const contribZone = DEFAULT_CONTRIB_ZONE_BY_GEOZONE[geoZone]
      return contribZone ? contribZone : geoZone
    }
  }
}
