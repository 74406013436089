/**
 * @author Keerthana
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class Separator extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-separator-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    // Call the parent class function
    super.initialize();
    this.seperatorLines = this.componentHost.querySelectorAll('hr');

    // Iterate over each line in the "seperatorLines" collection
    this.seperatorLines.forEach((eachLine) => {
      // Validate if the cuurent line has 'aria-hidden' attribute
      this.hasHiddenAttribute = eachLine.getAttribute('aria-hidden');

      // If the line does not have 'aria-hidden' attribute, set it to 'true'
      if (!this.hasHiddenAttribute) {
        eachLine.setAttribute('aria-hidden', 'true');
      }
    });
  }
}

// Register the Separator component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Separator.CLASS_NAMESPACE, Separator);
