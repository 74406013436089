/* eslint-disable no-console */
/**
 * @author Mohamed.Chaaben
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */

export default class CampaignBanner extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-campaign-banner';

  /** @inheritDoc */

  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */

  initialize() {
    super.initialize();
    this.layerElements = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.layerElements);
    this.initTrackingEvent();
  }

  initTrackingEvent() {
    this.bannerBtn = this.componentHost.querySelector('.ace-campaign-banner .ace-campaign-banner__content .ace-campaign-banner__btn');
    this.bannerBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
            pagename: this.datalayerValues?.pageName,
            bloc_name: 'regional offer',
            bloc_type: this.bannerBtn.getAttribute('data-tracking')
        }
      };
      TagManager.trackEvent(datasEvent);
    });
  }
}

CoreJS.BaseComponent.registerComponent(CampaignBanner.CLASS_NAMESPACE, CampaignBanner);

