/**
 * @author Samuvel Parthiban
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class Newsletter extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-newsletter';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Function Initialization
  initialize() {
    super.initialize();
    this.getLang = document.documentElement.lang || 'en';
    this.apiUrl = '';
    if (this.getLang) {
      this.apiUrl = `https://secure.accor.com/newsletter/index.${this.getLang}.shtml#/NOV/subscribe/email?email=`;
    }
    if (this.apiUrl) {
      this.newsLetterValid();
    }
  }
  // Function
  newsLetterValid() {
    const vaildButton = this.componentHost.querySelector(`.${Newsletter.CLASS_NAMESPACE}__button button`);
    const vaildInput = this.componentHost.querySelector(`.${Newsletter.CLASS_NAMESPACE}__input input`);
    const dataError = this.componentHost?.dataset?.error;
    const errorDiv = this.componentHost.querySelector(`.${Newsletter.CLASS_NAMESPACE}__email--error`);
    const validateEmail = (email) => {
      const re = /^\S+@\S+\.\S+$/;
      return re.test(email);
    };
    vaildButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (validateEmail(vaildInput.value)) {
        errorDiv.innerHTML = '';
        vaildInput.classList.remove('invaild');
        window.location = `${this.apiUrl}${vaildInput.value}`;
      } else {
        vaildInput.classList.add('invaild');
        errorDiv.innerHTML = dataError || '';
      }
    });
  }
}

// Register the Newsletter component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Newsletter.CLASS_NAMESPACE, Newsletter);
