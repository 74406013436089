export default class HomePageV2 extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'homepage-v2';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    this.calltoAction = this.componentHost.querySelector('.call-to-action');
    this.heading = this.calltoAction?.previousElementSibling?.matches('.heading');
    if (this.heading) {
      this.calltoAction?.parentNode?.classList?.add('header-cta');
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(HomePageV2.CLASS_NAMESPACE, HomePageV2);
