{
  "en":{
    "pagination-next":"Next slide go to slide index of length",
    "pagination-previous":"Previous slide go to slide index of length",
    "pagination-next-disabled":"Next slide disabled",
    "pagination-previous-disabled":"Previous slide disabled"
  },
  "fr":{
    "pagination-next":"Diapositive suivante aller à la diapositive index sur length",
    "pagination-previous":"Diapositive précédente aller à la diapositive index sur length",
    "pagination-next-disabled":"Diapositive suivante désactivée",
    "pagination-previous-disabled":"Diapositive précédente désactivée"
  }
}
