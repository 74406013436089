import { TagManager } from '@accor/ace-ui-core';

export default class AllCtaEnrollment extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'btn-become-member--container';

  /** @inheritDoc */

  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.layerElements = document?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.layerElements);
    const pageName = this.datalayerValues?.pageName?.split('::');
    if (pageName[0] === 'lp-skip-the-clean') {
      this.enrollementBtn();
    }
  }

  enrollementBtn() {
    const button = this.componentHost.querySelector('.btn--become-member');
    button?.addEventListener('click', () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'cta_enroll',
        event_data: {
          pagename: 'loyalty program::skip the clean',
          enroll_context: 'in page',
          cta_name: 'become a member'
        }
      };
      // eslint-disable-next-line no-undef
      TagManager.trackEvent(datasEvent);
    });
  }
}

CoreJS.BaseComponent.registerComponent(AllCtaEnrollment.CLASS_NAMESPACE, AllCtaEnrollment, true);


