import { TagManager } from '@accor/ace-ui-core';

export default class AllParagraph extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-paragraph';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.pageName = this.datalayerValues.pageName.split('::');

    if (this.pageName[1] == 'magazine') {
      this.hubeditoPages();
    }
    if (this.pageName[0] == 'all-content-hub') {
      this.contentHubPages();
    }
  }

  hubeditoPages() {
    const hubeditoPageParagraph = this.componentHost;
    const listItems = hubeditoPageParagraph.querySelectorAll('.ace-paragraph .ace-button .ace-paragraph__content--link');

    if (!listItems.length) return;

    listItems.forEach((calloutListItem) => {
      const url = calloutListItem.getAttribute('data-url');
      const target = calloutListItem.getAttribute('data-target');

      calloutListItem.addEventListener('click', (event) => {
        event.preventDefault();

        window.dataLayer.push({
          'event_data': null
        });

        TagManager.trackEvent({
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: 'book hotel',
            bloc_interaction: 'click',
            context: 'magazine'
          }
        });

        window.open(url, target);
      });
    });
  }


  contentHubPages() {
    const contentHubParagraph = document.querySelectorAll('.ace-paragraph');
    if (!contentHubParagraph.length) return;

    contentHubParagraph.forEach((calloutListItem) => {
      const paragraphTitle = calloutListItem.querySelector('.ace-paragraph__title');
      const paragraphBtn = calloutListItem.querySelector('.ace-paragraph__item--list .ace-button a');
      if (paragraphBtn) {
        paragraphBtn.addEventListener('click', (event) => {
          event.preventDefault();

          TagManager.trackEvent({
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.datalayerValues.pageName,
              bloc_name: `${paragraphTitle.innerText}`,
              bloc_interaction: 'click',
              block_type: 'paragraph'
            }
          });
        });
      }
    });
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(AllParagraph.CLASS_NAMESPACE, AllParagraph, true);
