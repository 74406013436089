{
  "novotel-page": {
    "obj-reference-1": {
      "selector": ".ace-billboard.ace-billboard-component .ace-billboard__fullwidth__button .cmp-button.cmp-button--secondary.btn-inverse",
      "event": {
        "event": "GAevent",
        "eventName": "click_cta_enroll",
        "eventCategory": "loyalty",
        "eventAction": "loyalty program - click on cta",
        "eventLabel": "discover the program",
        "event_enroll_context": "novotel"
      }
    },
    "obj-reference-2": {
      "selector": ".ace-billboard.ace-billboard-component .ace-billboard__fullwidth__button .cmp-button.cmp-button--primary.btn-inverse",
      "event": {
        "event": "GAevent",
        "eventName": "click_cta_enroll",
        "eventCategory": "loyalty",
        "eventAction": "loyalty program - click on cta",
        "eventLabel": "become a member",
        "event_enroll_context": "novotel"
      }
    },
    "obj-reference-3": {
      "selector": ".ace-loyalty.loyalty-billboard .cmp-button.ace-loyalty__btn.cmp-button--secondary",
      "event": {
        "event": "GAevent",
        "eventName": "click_cta_enroll",
        "eventCategory": "loyalty",
        "eventAction": "loyalty program - click on cta",
        "eventLabel": "discover the program",
        "event_enroll_context": "novotel"
      }
    }
  }
}
