import { Faq, TagManager } from '@accor/ace-ui-core';

export default class AllFaq extends Faq {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.setFaqCobrandClickListener();
  }

  /*
      Function to overwrite tagmanager for Cobrand
    */
  setFaqCobrandClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageToTarget = dataLayer.pageName.split('::')[1];
    const pageName = dataLayer.pageName.split('::');
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.querySelector('.cmp-accordion__more-button .cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'faq',
              bloc_interaction: 'see all questions'
            }
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      }

      if (pageName[0] === 'lp-skip-the-clean') {
        const answerLinks = this.componentHost.querySelectorAll('.cmp-accordion .cmp-accordion__item');
        answerLinks.forEach((answerLink) => {
          const question = answerLink.querySelector('.cmp-accordion__button .cmp-accordion__title').textContent;
          answerLink.querySelector('.cmp-accordion__panel')?.addEventListener('click', () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: 'loyalty program::skip the clean',
                bloc_interaction: question,
                bloc_type: 'faq',
                context: 'loyalty program'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        });
      }
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(Faq.CLASS_NAMESPACE, AllFaq, true);
