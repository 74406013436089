import { CalloutContainer, TagManager } from '@accor/ace-ui-core';
export default class AllCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.offerVar = this.componentHost.classList.contains('offer-variation');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.pageName = this.datalayerValues.pageName.split('::');
    if (this.pageName[1] == 'homepage' && this.offerVar != true) {
      this.allHomepage();
    }
    if (this.pageName[1] == 'magazine') {
      this.hubeditoPages();
    }
    if (this.pageName[0] == 'hotels') {
      this.dealsCornerPages();
    }
    if (this.pageName[0] === 'all-content-hub') {
      this.contentHubPages();
    }
    if (this.pageName[0] === 'europcar') {
      this.lpEuropcarPages();
    }

    if (this.pageName[0] === 'lp-skip-the-clean') {
      this.skipTheCleanTracking();
    }
  }

  allHomepage() {
    if (this.pageName[0] === 'europcar') return; // Fix to avoid duplicate events for europcar

    const homePageCallout = this.componentHost;
    const listItems = homePageCallout.querySelectorAll('.ace-callout-container .cmp-container .callout-section .ace-callout-section li ');
    listItems.forEach((calloutListItem, index) => {
      calloutListItem.addEventListener('click', () => {
        const tripAdvisor = calloutListItem?.querySelector('.cmp-teaser__content .cmp-teaser__reviews');
        const logoSlot = calloutListItem?.querySelector('.ace-callout-component .cmp-teaser__logo-slot');
        const contentLength = calloutListItem?.querySelector('.cmp-teaser__content').children.length;
        const cityName = calloutListItem?.querySelector('.cmp-teaser__content .cmp-teaser__pretitle a')?.innerText;
        const countryName = calloutListItem?.querySelector('.cmp-teaser__content .cmp-teaser__kicker')?.innerText;
        this.blocType = '';
        this.blocName = '';
        if (tripAdvisor) {
          const hotelRID = calloutListItem.querySelector('a').getAttribute('href').split('/')[4];
          this.blocType = hotelRID;
          this.blocName = 'push hotel';
        } else if (logoSlot) {
          const newIndex = index + 1;
          this.blocType = newIndex.toString();
          this.blocName = 'partners offers';
        } else if (!logoSlot && !tripAdvisor && contentLength === 1) {
          const newIndex = index + 1;
          this.blocType = newIndex.toString();
          this.blocName = 'experience';
        } else if (!logoSlot && !tripAdvisor && contentLength === 2) {
          const newIndex = index + 1;
          const cityCountry = cityName ? `${cityName}, ${countryName}` : newIndex.toString();
          this.blocType = `${cityCountry || ''}`;
          this.blocName = 'city trip';
        }

        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: this.blocName,
            bloc_type: this.blocType
          }
        };
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(datasEvent);
      });
    });
    const listItemsCallout = homePageCallout.querySelectorAll('.ace-callout-container .cmp-container .callout-section .ace-carousel__content  li ');
    listItemsCallout.forEach((calloutListItem, index) => {
      calloutListItem.addEventListener('click', () => {
        const tripAdvisor = calloutListItem?.querySelector('.cmp-teaser__content .cmp-teaser__reviews');
        const logoSlot = calloutListItem?.querySelector('.ace-callout-component .cmp-teaser__logo-slot');
        const contentLength = calloutListItem?.querySelector('.cmp-teaser__content').children.length;
        const cityName = calloutListItem?.querySelector('.cmp-teaser__content .cmp-teaser__pretitle a')?.innerText;
        const countryName = calloutListItem?.querySelector('.cmp-teaser__content .cmp-teaser__kicker')?.innerText;
        this.blocType = '';
        this.blocName = '';
        if (tripAdvisor) {
          const hotelRID = calloutListItem.querySelector('a').getAttribute('href').split('/')[4];
          this.blocType = hotelRID;
          this.blocName = 'push hotel';
        } else if (logoSlot) {
          const newIndex = index + 1;
          this.blocType = newIndex.toString();
          this.blocName = 'partners offers';
        } else if (!logoSlot && !tripAdvisor && contentLength === 1) {
          const newIndex = index + 1;
          this.blocType = newIndex.toString();
          this.blocName = 'experience';
        } else if (!logoSlot && !tripAdvisor && contentLength === 2) {
          const newIndex = index + 1;
          const cityCountry = cityName ? `${cityName}, ${countryName}` : newIndex.toString();
          this.blocType = `${cityCountry || ''}`;
          this.blocName = 'city trip';
        }

        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: this.blocName,
            bloc_type: this.blocType
          }
        };
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(datasEvent);
      });
    });
  }
  hubeditoPages() {
    const homepage = document.querySelector('.hp-hub-edito');
    const thematic = document.querySelector('.hub-thematic-hub-edito');
    const landing = document.querySelector('.thematic-landing-hub-edito');

    const hubeditoPageCallout = this.componentHost;
    const listItems = hubeditoPageCallout.querySelectorAll('.ace-callout-container .cmp-container .callout-section .ace-callout-section li ');

    const datasEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.datalayerValues.pageName,
        context: 'magazine'
      }
    };

    listItems.forEach((calloutListItem) => {
      const splitUrl = calloutListItem.querySelector('a')?.getAttribute('href').split('/').pop().split('.')[0];

      calloutListItem.addEventListener('click', () => {
        window.dataLayer.push({
          'event_data': null
        });

        if (homepage) {
          TagManager.trackEvent({
            ...datasEvent,
            event_data: {
              ...datasEvent.event_data,
              bloc_name: 'categories',
              bloc_interaction: 'see_category',
              bloc_type: splitUrl || ''
            }
          });
        }

        if (thematic || landing) {
          TagManager.trackEvent({
            ...datasEvent,
            event_data: {
              ...datasEvent.event_data,
              bloc_name: 'top articles',
              bloc_interaction: 'see article',
              bloc_type: splitUrl || ''
            }
          });
        }
      });
    });
  }

  dealsCornerPages() {
    const dealsCornerPageCallout = this.componentHost;
    const listItems = dealsCornerPageCallout.querySelectorAll('.ace-callout-container .cmp-container .callout-section .ace-callout-section li');
    const calloutFilters = dealsCornerPageCallout.querySelectorAll('.callout-container .ace-chip__list li button');

    const datasEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: 'hotels::promotion::all offer page::global index',
        bloc_name: 'deals corner'
      }
    };

    // callout filter tracking
    if (calloutFilters) {
      calloutFilters.forEach((calloutFilter) => {
        calloutFilter.addEventListener('click', () => {
          TagManager.trackEvent({
            event: 'GA4event',
            eventName: 'apply_filter',
            event_data: {
              pagename: 'hotels::promotion::all offer page::global index',
              form_action: 'deals corner',
              enroll_context: 'sign up for free',
              filter_name: calloutFilter.getAttribute('data-filter-tab-id'),
              filter_label: Array.from(calloutFilters).indexOf(calloutFilter) + 1
            }
          });
        });
      });
    }
    // callout list item tracking
    if (listItems) {
      listItems.forEach((calloutListItem) => {
        const listId = calloutListItem?.querySelector('.ace-callout-component-default')?.dataset?.marketingOfferName;
        calloutListItem.addEventListener('click', () => {
          TagManager.trackEvent({
            ...datasEvent,
            event_data: {
              ...datasEvent.event_data,
              bloc_interaction: listId ? `see offer - ${listId}` : 'see offer'
            }
          });
        });
      });
    }

    // see more button tracking

    const seeMoreOffersButton = dealsCornerPageCallout.querySelector('.ace-guest-viewmore');

    if (seeMoreOffersButton) {
      seeMoreOffersButton.addEventListener('click', () => {
        TagManager.trackEvent({
          ...datasEvent,
          event_data: {
            ...datasEvent.event_data,
            bloc_interaction: 'see more offers'
          }
        });
      });
    }
  }

  // content hub tracking

  contentHubPages() {
    const contentHub = this.componentHost;
    const listItems = contentHub.querySelectorAll('.callout-section li');
    const offerVar = this.componentHost.classList.contains('offer-variation');
    const datasEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        bloc_interaction: 'click',
        bloc_name: `${ offerVar ? 'card image' : 'callout' }`,
        bloc_type: 'cta',
        pagename: this.datalayerValues.pageName
      }
    };

    listItems.forEach((calloutListItem) => {
      calloutListItem.addEventListener('click', () => {
        TagManager.trackEvent({
          ...datasEvent,
          event_data: {
            ...datasEvent.event_data,
            pagename: this.datalayerValues.pageName

          }
        });
      });
    });
  }

  lpEuropcarPages() {
    const europcarPageCallout = this.componentHost;
    const listItems = europcarPageCallout.querySelectorAll('.ace-callout-container .cmp-container .callout-section .ace-callout-section li');
    const datasEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: 'loyalty program::partners::mobility::europcar',
        bloc_name: 'other partners',
        bloc_type: 'partners listing',
        context: 'partners mobility'
      }
    };

    listItems.forEach((calloutListItem) => {
      const calloutTitle = calloutListItem.querySelector('.ui-heading-03');
      calloutListItem.addEventListener('click', () => {
        TagManager.trackEvent({
          ...datasEvent,
          event_data: {
            ...datasEvent.event_data,
            bloc_title: calloutTitle?.innerText
          }
        });
      });
    });
  }

  skipTheCleanTracking() {
    const skipTheCleanPageCallout = this.componentHost;
    const listItems = skipTheCleanPageCallout.querySelectorAll('.ace-callout-container .cmp-container .ace-callout-section li');
    const datasEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: 'loyalty program::skip the clean',
        bloc_type: 'together go further',
        context: 'loyalty program'
      }
    };

    // for the first callout i want the title to be make a donation and for the second one learn more
    listItems.forEach((calloutListItem, index) => {
      const title = index === 0 ? 'make a donation' : 'learn more';
      calloutListItem.addEventListener('click', () => {
        TagManager.trackEvent({
          ...datasEvent,
          event_data: {
            ...datasEvent.event_data,
            bloc_interaction: title
          }
        });
      });
    });
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(CalloutContainer.CLASS_NAMESPACE, AllCalloutContainer, true);
