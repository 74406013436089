/**
 * @author Keerthana
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class IconList extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-icon-list-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initializing Function
  initialize() {
    super.initialize();

    if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      this.totalFooterIconList = document.querySelectorAll('.ace-footer-icon');
      this.totalFooterIconList.forEach((ListItem) => {
        this.listVerticalStyle = ListItem.querySelector('.ace-footer-icon__list.listVertical');
        this.aceIconList();
      });
    }
  }

  /*
  Function to set up the Ace Icon List.
  */
  aceIconList() {
    // Check if listVerticalStyle exists
    if (this.listVerticalStyle) {
      // Get the Attribute
      this.ListItemCount = this.listVerticalStyle.getAttribute('data-iconcount');
      this.IconListItems = this.listVerticalStyle.querySelectorAll(
        '.ace-footer-icon__list--item'
      );
      this.IconListItems.forEach((item, index)=>{
        // Check if index is greater than or equal to 'ListItemCount'
        if (index >= this.ListItemCount) {
          // Hide the item by setting its display property to 'none'
          item.style.display = 'none';
        }
      });
    }
  }
}
// Register the IconList component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(IconList.CLASS_NAMESPACE, IconList);

