export const getSubscriptionFunnelUrl = ({
  funnel,
  lang = getLang(),
  state = 'register',
  origin,
}) =>
  getAuthUrl({
    state,
    origin: 'all-plus',
    lang,
    mode: EnrollMode.SecurityLink,
    redirect: attachDefinedQueryParamsToString(
      `${ALL_ACCOR_COM}/enroll-loyalty/index.${lang}.shtml#/subscribe/${funnel}`,
      { origin }
    ),
  })

import { ALL_ACCOR_COM } from '../../common/constants.js'

import {
  getLang,
  attachDefinedQueryParamsToString,
} from '../../common/utils.js'

import { getAuthUrl, EnrollMode } from '../../oidc/getAuthUrl.js'
