/**
 * @author Gunasundari
 * @classdesc This class is defining an Videocomponent
 * @version 1.0
 */
export default class VideoPlayer extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'dynamicmedia';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();
    // eslint-disable-next-line no-unused-expressions
    window.addEventListener ?
      window.addEventListener(CoreJS.DomEventConstants.LOAD, this.videoTabOrderHanlder()) :
      window.attachEvent && window.attachEvent(CoreJS.DomEventConstants.ON_LOAD, this.videoTabOrderHanlder());
  }
  // Handling the video tab order
  videoTabOrderHanlder() {
    this.componentHost.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event)=>{
      // Checking event.target class name & key event
      if (event.target.className === 's7knob' && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey ) {
        event.preventDefault();
        this.componentHost.querySelector('.s7mutebutton').focus();
      }
      if (event.target.classList.contains('s7mutebutton') && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey ) {
        event.preventDefault();
        this.componentHost.querySelector('.s7knob').focus();
      }
      if (event.target.classList.contains('s7mutebutton') && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey ) {
        event.preventDefault();
        this.componentHost.querySelector('.s7closedcaptionbutton').focus();
      }
      if (event.target.classList.contains('s7closedcaptionbutton') && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey ) {
        event.preventDefault();
        this.componentHost.querySelector('.s7mutebutton').focus();
      }
      if (event.target.classList.contains('s7closedcaptionbutton') && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && !event.shiftKey ) {
        event.preventDefault();
        this.componentHost.querySelector('.s7fullscreenbutton').focus();
      }
      if (event.target.classList.contains('s7fullscreenbutton') && event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey ) {
        event.preventDefault();
        this.componentHost.querySelector('.s7closedcaptionbutton').focus();
      }
    });
  }
}
// Register the VideoPlayer component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(VideoPlayer.CLASS_NAMESPACE, VideoPlayer);
