// Import the 'airlineDatalayer' object from '.json' file
import airlineDatalayer from './airlines.datalayer.json';

// Import the 'novotelDatalayer' object from '.json' file
import novotelDatalayer from './novotel.datalayer.json';

// Import the 'mantraDatalayer' object from '.json' file
import mantraDatalayer from './mantra.datalayer.json';

// Import Tag manager
import TagManager from '@accor/core-services/dist/gtm/index';

// Define SetGTMTrackingForClickEvents
const SetGTMTrackingForClickEvents = (obj) => {
  TagManager.trackEvent(obj);
};

export default {
  ...airlineDatalayer,
  ...novotelDatalayer,
  ...mantraDatalayer
};

// Export the 'SetGTMTrackingForClickEvents' function
export { SetGTMTrackingForClickEvents };
