export { getAuthUrl, EnrollMode }

const getAuthUrl = ({ state, ...rest } = {}) => {
  const handler =
    {
      login: getLoginURL,
      register: getEnrollURL,
      autologin: getAutoLoginURL,
    }[state] || getLoginURL
  return handler(rest)
}

const EnrollMode = { SecurityLink: 'SL', GeneratedCode: 'GC' }

const getEnrollURL = ({ mode, ...rest }) =>
  mode === EnrollMode.GeneratedCode
    ? getEnrollWithGeneratedCodeURL(rest)
    : getEnrollWithSecurityLinkURL(rest)

const getEnrollWithSecurityLinkURL = ({
  origin,
  context,
  global = window,
  lang = getLang(global),
  redirect = global.location.href,
  ...rest
}) =>
  attachDefinedQueryParamsToString(
    `${ALL_ACCOR_COM}/enroll-loyalty/index.${lang}.shtml#/verify-email`,
    {
      context,
      origin,
      redirect,
      ...rest,
    }
  )

const getEnrollWithGeneratedCodeURL = ({
  context,
  redirect,
  global = window,
  lang = getLang(global),
}) => {
  const getHashValue = (key, location = global.location) =>
    new URLSearchParams(new URL(location).hash.slice(1)).get(key)
  const getUrlParameter = (name, url = global.location) =>
    new URL(url).searchParams.get(name)

  const redirectFromQueryString = getUrlParameter('redirect')
  const redirectFromHash = getHashValue('redirect')

  const isOnErrorPage = global.location.href.includes('error-page')

  const getOidcRedirectUrl = () =>
    isOnErrorPage
      ? redirectFromQueryString || redirectFromHash || ALL_ACCOR_COM
      : global.location.href

  return context
    ? attachQueryParamsToString(
        `/authentication/index.${lang}.shtml#/register`,
        {
          context,
          redirect: redirect || getOidcRedirectUrl(),
        }
      )
    : attachDefinedQueryParamsToString(AUTH_URL, {
        appId: APP_ID,
        ui_locales: lang,
        redirect_uri: OIDC_LANDING_URL,
        register: true,
        redirect_site_uri: redirect || getOidcRedirectUrl(),
      })
}

const getAutoLoginURL = () =>
  attachQueryParamsToString(AUTH_URL, {
    appId: APP_ID,
    prompt: 'none',
    redirect_uri: OIDC_LANDING_URL,
  })

const getLoginURL = ({
  lang = getLang(),
  context = '',
  redirect = window.location.href,
  promoCode,
}) =>
  attachDefinedQueryParamsToString(AUTH_URL, {
    appId: APP_ID,
    ui_locales: lang,
    redirect_uri: OIDC_LANDING_URL,
    redirect_site_uri: context
      ? getRegisterWithContextUrl({ redirect, context, promoCode })
      : redirect,
  })

import {
  APP_ID,
  AUTH_URL,
  ALL_ACCOR_COM,
  OIDC_LANDING_URL,
} from '../common/constants.js'
import {
  getLang,
  attachQueryParamsToString,
  attachDefinedQueryParamsToString,
} from '../common/utils.js'
import { getRegisterWithContextUrl } from './getRegisterWithContextUrl.js'
