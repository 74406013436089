export { Error, refreshToken }

import { setAuthCookie, getJwtPayload, setTokenCookie } from './shared.js'
import { getTokenFromIframe } from './getToken.js'
import { Enum, debouncePromiseGlobally } from '../common/utils.js'
import { REFRESH_URL } from '../common/constants.js'
import axiosAuthApi from '../http/axiosAuthApi'

const Error = Enum('RefreshTokenFailed')

const refreshToken = debouncePromiseGlobally((options = {}) => {
  return axiosAuthApi
    .post(REFRESH_URL, null, { ...options })
    .then(({ data: { access_token } }) => {
      if (!access_token) return getTokenFromIframe(global)

      const { pmid } = getJwtPayload(access_token)
      setAuthCookie(pmid)
      return setTokenCookie(access_token)
    })
    .catch(() => {
      throw Error.RefreshTokenFailed
    })
}, '_AccorRefreshToken')
