/* eslint-disable no-console */
/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */
export default class CountryFlag extends CoreJS.BaseComponent {
  // Declare the CLASS_NAMESPACE
  static CLASS_NAMESPACE = 'country-language-selector';
  /** @inheritDoc */
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    // Initialize function of parent class
    super.initialize();
    // Add click EventListener to all elements matching the a.country-region__name
    document.querySelectorAll('a.country-region__name').forEach((link) => {
      link.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        event.preventDefault();
        // Call the posHandler function
        this.posHandler(event)
          .then(() => {
            window.location.href = event.target.closest('a').href;
          });
      });
    });
  }

  // posHandler function
  posHandler(event) {
    // Return a promise to handle any operation
    return new Promise((resolve, reject) => {
      const selector = document?.querySelector('[data-pos-handler]')?.getAttribute('data-pos-handler');
      if (selector) {
        const posHanlder = JSON.parse(selector);
        const myHeaders = new Headers();
        myHeaders.append('apikey', posHanlder.key);
        const requestOptions = {
          'method': 'GET',
          'headers': myHeaders,
          'redirect': 'follow',
          'credentials': 'include'
        };
        const urlParams = {
          accorCountryCode: event.currentTarget?.getAttribute('data-accor-country-code'),
          siteCode: event.currentTarget?.closest('.ace-country-flag')?.getAttribute('data-sitecode'),
          languageCode: event.currentTarget?.getAttribute('data-language-code')
        };
        // linkBuilder arrow function takes no parameters
        const linkBuilder = () => {
          let url = `${posHanlder.apiUrlManual}?usecase=rootDomain`;
          for (const [key, value] of Object.entries(urlParams)) {
            // Check key & value
            if (key && value) {
              url = `${url}&${key}=${value}`;
            }
          }
          // Return the constructed URL
          return url;
        };
        const url = linkBuilder();
        fetch(url, requestOptions).then((response) => response.json()).then(resolve).catch(reject);
      }
    });
  }
}

// Register the CountryFlag component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(CountryFlag.CLASS_NAMESPACE, CountryFlag);
