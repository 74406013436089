export { Nationality } from './constants.js'
export {
  isNationality,
  getRedirectUrlAction,
  redirectToUpdateAccount,
  getCreateRussianAccountUrl,
}
const makeNationalityFromID = nationalityID =>
  nationalityDictionary[nationalityID] || nationalityID

const getNationalityFromCookies = () =>
  makeNationalityFromID(
    Cookies.get('userLocalization') || Cookies.get('userLocalizationInitial')
  )

const isRussian = user => user?.nationality === Nationality.Russian

const getNationality = user =>
  isRussian(user) ? user.nationality : getNationalityFromCookies()

const isNationality = (n1, n2 = getNationality()) => n1 === n2

const getRedirectUrlAction = (
  actionUrl,
  hash,
  user = {},
  userNationalityData = getNationalityData(user)
) => {
  if (!userNationalityData) return null

  const urls = userNationalityData.urls
  const lang = getLang()
  const url = `${urls.domain}/${lang}/${urls[actionUrl]}`
  return getRedirectUrl(url, hash)
}

const getNationalityData = (user = {}) =>
  nationalityData[getNationality(user)] || null

const getCreateRussianAccountUrl = ({ redirect } = {}) => {
  const data = getNationalityData()
  if (!data) return null
  const urls = data.urls
  const lang = getLang()
  return attachQueryParamsToString(
    `${urls.domain}/${lang}/${urls['createAccount']}`,
    removeNullAndUndefinedEntries({ redirect })
  )
}

const redirectToUpdateAccount = (
  hash,
  user,
  redirectUrl = getRedirectUrlAction('updateAccount', hash, user),
  global = window
) => {
  if (!redirectUrl) return false
  global.location.href = redirectUrl
  return true
}

const getRedirectUrl = (url, hash) => {
  const redirect = getUrlParams(url).redirect || window.location.href
  const data = { appId: APP_ID, redirect }
  return attachQueryParamsToString(url, data) + (hash ? `#${hash}` : '')
}

import {
  Nationality,
  nationalityData,
  nationalityDictionary,
} from './constants.js'
import Cookies from 'js-cookie'
import { getLang } from '../common/lang.js'
import { APP_ID } from '../common/constants.js'
import {
  getUrlParams,
  attachQueryParamsToString,
  removeNullAndUndefinedEntries,
} from '../common/utils.js'
