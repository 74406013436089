/**
 *  *
 * @author Dharsini
 * @class Welcome
 * @classdesc Ace Welcome component to inherit the base component
 * @inheritDoc
 */
export default class WeclomeMessage extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-welcome-component';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();
    this.description = this.componentHost?.querySelector('.ace-welcome-component__description');
    this.seeMore = this.componentHost?.querySelector('.ace-seemore');
    this.seeless = this.componentHost?.querySelector('.ace-seeless');
    this.expandCta = this.componentHost?.querySelector('.js-ace-welcome-message_button-expand');
    this.paragraphs = this.componentHost?.querySelectorAll('.ace-welcome-component__description p');

    this.expandCta?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.description?.classList.toggle('show');
      if (this.description.getAttribute('aria-expanded') === 'true') {
        this.description.setAttribute('aria-expanded', false);
        this.seeless.setAttribute('aria-hidden', true);
        this.seeMore.removeAttribute('aria-hidden');
      } else {
        this.description.setAttribute('aria-expanded', true);
        this.seeMore.setAttribute('aria-hidden', true);
        this.seeless.removeAttribute('aria-hidden');
      }
    });

    // acdaa-14443 - hiding the see more link if there is just 1 paragraph
    if (this.expandCta && this.paragraphs.length == 1) {
      this.expandCta.style.display = 'none';
    }
  }
}

// Register the Welcome component with the BaseComponent
CoreJS.BaseComponent.registerComponent(WeclomeMessage.CLASS_NAMESPACE, WeclomeMessage, true);
