/* eslint-disable no-unused-expressions */
/**
 *
 * @author Nitesh Vaidya
 * @class Dropdown
 * @version 1.0
 * @inheritDoc
 */
export default class Dropdown extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-dropdown';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    this.dropdownHooks();
    this.bindDropdownEvents();
  }
  /**
   * element selector hooks
   */
  dropdownHooks() {
    this.selectPanel = this.componentHost.querySelector('.ace-dropdown__select');
    this.dropdownOptions =
      this.componentHost.querySelectorAll('option');
  }
  /*
   * remove option selection
   * @param {event}
   */
  removeOptionSelection(event) {
    event.preventDefault();
    this.dropdownOptions.forEach((option) => {
      option.setAttribute('aria-selected', false);
    });
    event.target.setAttribute('aria-selected', true);
  }
  static resetDropdown(dropdown) {
    dropdown?.querySelectorAll('li')?.forEach((option, index) => {
      if (index === 0) {
        option.classList.add('selected');
        option.setAttribute('aria-selected', true);
      } else {
        option.classList.remove('selected');
        option.setAttribute('aria-selected', false);
      }
    });
  }
  /**
   * pickOptionFromDropdown
   * @param {event} event
   */
  pickOptionFromDropdown(event) {
    this.dropdownOptions?.forEach((option) => {
      option.classList.remove('selected');
    });
    event.target.classList.add('selected');
    event.target.setAttribute('data-value', event.target.value);
  }
  /**
   *  bind dropdown  events handler
   */
  bindDropdownPanelEvents() {
    // Focus In event
    this.dropdownOptions?.forEach((option) => {
      option.addEventListener(CoreJS.DomEventConstants.FOCUS_IN, (event) =>
        this.removeOptionSelection(event)
      );
    });
    // Mouse Enter
    this.dropdownOptions?.forEach((option) => {
      option.addEventListener(CoreJS.DomEventConstants.MOUSE_ENTER, (event) =>
        this.removeOptionSelection(event)
      );
    });
    // Mouse Leave
    this.dropdownOptions?.forEach((option) => {
      option.addEventListener(CoreJS.DomEventConstants.MOUSE_LEAVE, (event) =>
        this.removeOptionSelection(event)
      );
    });
    // Arrow Keys
    this.dropdownOptions?.forEach((option) => {
      option.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        event.preventDefault();
        if (event.keyCode === CoreJS.Constants.KEY_CODES.down) {
          event.target.nextElementSibling?.focus();
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.up) {
          event.target.previousElementSibling?.focus();
        } else if (
          event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter ||
          event.code === CoreJS.Constants.KEYBOARD_EVENT_KEYS.space
        ) {
          this.pickOptionFromDropdown(event);
        }
      });
      this.componentHost.querySelectorAll('select').forEach((element) => {
        element.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
          this.pickOptionFromDropdown(event);
        });
      });
    });
  }
  bindDropdownEvents() {
    this.bindDropdownPanelEvents();
  }
}

CoreJS.BaseComponent.registerComponent(Dropdown.CLASS_NAMESPACE, Dropdown);
