/* eslint-disable no-prototype-builtins */
import i18n from './i18n.json';

/**
 * @author Nitesh Vaidya
 * @fileOverview
 * @class slideshow
 * @classdesc An interactive slideshow component for navigating a list of generic items
 */
export default class SlideShow extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-slideshow';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();
    this.NS = 'cmp';
    this.IS = 'slideshow';
    this.dataLayerEnabled = '';
    this.selectors = {
      self: `[data-${this.NS}-is="${this.IS}"]`
    };
    this.pageIndex = 0;
    this.properties = {
      /**
             * Determines whether the slideshow will automatically transition between slides
             *
             * @memberof slideshow
             * @type {Boolean}
             * @default false
             */
      autoplay: {
        default: false,
        transform: (value) => {
          return !(value === null || typeof value === 'undefined');
        }
      },
      /**
             * Determines whether the slideshow will automatically transition between slides
             *
             * @memberof slideshow
             * @type {Boolean}
             * @default false
             */
      autoshow: {
        default: true,
        transform: (value) => {
          return !(value === null || typeof value === 'undefined');
        }
      },
      /**
             * Duration (in milliseconds) before automatically transitioning to the next slide
             *
             * @memberof slideshow
             * @type {Number}
             * @default 1000
             */
      delay: {
        default: 1000,
        transform: (value) => {
          value = parseFloat(value);
          return !isNaN(value) ? value : null;
        }
      },
      /**
             * Determines whether automatic pause on hovering the slideshow is disabled
             *
             * @memberof slideshow
             * @type {Boolean}
             * @default false
             */
      autopauseDisabled: {
        default: false,
        transform: (value) => {
          return !(value === null || typeof value === 'undefined');
        }
      }
    };

    this.onDocumentReady();
  }


  /**
     * slideshow Configuration
     *
     * @typedef {Object} slideshowConfig Represents a slideshow configuration
     * @property {HTMLElement} element The HTMLElement representing the slideshow
     * @property {Object} options The slideshow options
     */

  /**
     * slideshow
     * @param { slideshowConfig } config The slideshow configuration
     */
  slideshow(config) {
    /**
         * Caches the slideshow elements as defined via the {@code data-slideshow-hook="ELEMENT_NAME"} markup API
         *
         * @private
         * @param {HTMLElement} wrapper The slideshow wrapper element
         */
    const cacheElements = (wrapper) => {
      this._elements = {};
      this._elements.self = wrapper;
      const hooks = this._elements.self.querySelectorAll(
        `[data-${this.NS}-hook-${this.IS}]`
      );

      for (let i = 0; i < hooks.length; i++) {
        const hook = hooks[i];
        let capitalized = this.IS;
        capitalized =
                    capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
        const key = hook.dataset[`${this.NS}Hook${capitalized}`];
        if (this._elements[key]) {
          if (!Array.isArray(this._elements[key])) {
            const tmp = this._elements[key];
            this._elements[key] = [tmp];
          }
          this._elements[key].push(hook);
        } else {
          this._elements[key] = hook;
        }
      }
    };

    /**
         * Sets up properties for the slideshow based on the passed options.
         *
         * @private
         * @param {Object} options The slideshow options
         */
    const setupProperties = (options) => {
      this._properties = {};

      for (const key in this.properties) {
        if (this.properties.hasOwnProperty(key)) {
          const property = this.properties[key];
          let value = null;

          if (options && options[key] !== null) {
            value = options[key];

            // transform the provided option
            if (property && typeof property.transform === 'function') {
              value = property.transform(value);
            }
          }

          if (value === null) {
            // value still null, take the property default
            value = this.properties[key].default;
          }

          this._properties[key] = value;
        }
      }
    };

    /**
         * Parses the dataLayer string and returns the ID
         *
         * @private
         * @param {HTMLElement} item the accordion item
         * @return {String} dataLayerId or undefined
         */
    const getDataLayerId = (item) => {
      if (item && item.dataset.cmpDataLayer) {
        return Object.keys(JSON.parse(item.dataset.cmpDataLayer))[0];
      }
      return item.id;
    };

    /**
         * Sets the disabled state for an action and toggles the appropriate CSS classes
         *
         * @private
         * @param {HTMLElement} action Action to disable
         * @param {Boolean} [disable] {@code true} to disable, {@code false} to enable
         */
    const setActionDisabled = (action, disable) => {
      if (!action) {
        return;
      }
      if (disable !== false) {
        action.disabled = true;
        action.classList.add('cmp-slideshow__action--disabled');
      } else {
        action.disabled = false;
        action.classList.remove('cmp-slideshow__action--disabled');
      }
    };

    /**
         * Refreshes the play/pause action markup based on the {@code slideshow#_paused} state
         *
         * @private
         */
    const refreshPlayPauseActions = () => {
      setActionDisabled(this._elements.pause, this._paused);
      setActionDisabled(this._elements.play, !this._paused);
    };

    /**
         * Clears/pauses automatic slide transition interval
         *
         * @private
         */
    const clearAutoplayInterval = () => {
      window.clearInterval(this._autoplayIntervalId);
      this._autoplayIntervalId = null;
    };
    /**
         * Handles slideshow mouseenter events
         *
         * @private
         */
    const onMouseEnter = () => {
      clearAutoplayInterval();
    };

    /**
         * Retrieves the next active index, with looping
         *
         * @private
         * @return {Number} Index of the next slideshow item
         */
    const getNextIndex = () => {
      return this._active === this._elements.item.length - 1 ?
        0 :
        this._active + 1;
    };

    /**
         * Retrieves the previous active index, with looping
         *
         * @private
         * @return {Number} Index of the previous slideshow item
         */
    const getPreviousIndex = () => {
      return this._active === 0 ?
        this._elements.item.length - 1 :
        this._active - 1;
    };

    /*
             * Disable the pagination looping
             *
             */
    const paginationi18n = (index, length, string) => {
      const indexString = string.replace('index', ++index);
      const computedString = indexString.replace('length', `${length}`);
      return computedString;
    };

    const disablePaginationLoop = () => {
      const currentPageLanguage =
                i18n[document.documentElement.lang] || i18n.en;
      const currentIndex = this._active;
      const lastIndex = this._elements.indicator?.length - 1;
      if (currentIndex === 0) {
        this._elements.previous.classList.add('disabled');
        this._elements.previous.setAttribute('aria-disabled', true);
        this._elements.next.classList.remove('disabled');
        this._elements.next.removeAttribute('aria-disabled');
        this._elements.previous.querySelector('.sr-only').innerHTML =
                    currentPageLanguage['pagination-previous-disabled'];
        this._elements.next.querySelector('.sr-only').innerHTML =
                    paginationi18n(
                      this._active + 1,
                      this._elements.indicator?.length,
                      currentPageLanguage['pagination-next']
                    );
      } else if (currentIndex === lastIndex) {
        this._elements.next.classList.add('disabled');
        this._elements.next.setAttribute('aria-disabled', true);
        this._elements.previous.classList.remove('disabled');
        this._elements.previous.removeAttribute('aria-disabled');
        this._elements.next.querySelector('.sr-only').innerHTML =
                    currentPageLanguage['pagination-next-disabled'];
        this._elements.previous.querySelector('.sr-only').innerHTML =
                    paginationi18n(
                      this._active - 1,
                      this._elements.indicator?.length,
                      currentPageLanguage['pagination-previous']
                    );
      } else {
        this._elements.next.classList.remove('disabled');
        this._elements.next.removeAttribute('aria-disabled');
        this._elements.previous.classList.remove('disabled');
        this._elements.previous.removeAttribute('aria-disabled');
        this._elements.previous.querySelector('.sr-only').innerHTML =
                    paginationi18n(
                      this._active - 1,
                      this._elements.indicator?.length,
                      currentPageLanguage['pagination-previous']
                    );
        this._elements.next.querySelector('.sr-only').innerHTML =
                    paginationi18n(
                      this._active + 1,
                      this._elements.indicator?.length,
                      currentPageLanguage['pagination-next']
                    );
      }
      this._elements.link?.forEach((item) => {
        if (item.offsetParent !== null && this.pageIndex !== 0) {
          item
            .closest('.cmp-slideshow__callout-item')
            ?.querySelector('a, button')
            ?.focus();
        }
      });
      this.pageIndex++;
      setTimeout(() => {
        document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.RESIZE_EVENT_WRAPPER));
      }, 800);
    };

    /**
         * Refreshes the item markup based on the current {@code slideshow#_active} index
         * @private
         */
    const refreshActive = () => {
      const items = this._elements.item;
      const callouts = this._elements.callout;
      const actionContent = this._elements.self.querySelector(
        '.cmp-slideshow__action-content'
      );
      const indicators = this._elements.indicator;

      if (items) {
        if (Array.isArray(items)) {
          for (let i = 0; i < items.length; i++) {
            actionContent.innerHTML = `${this._active + 1} / ${items.length}`;
            if (items[i]) {
              if (i === parseInt(this._active)) {
                items[i].removeAttribute('aria-hidden');
                items[i].classList.add('cmp-slideshow__item--active');
                if (indicators && indicators[i]) {
                  indicators[i].classList.add('cmp-slideshow__indicator--active');
                  items[i].classList.remove('hidden');
                  indicators[i].setAttribute('aria-selected', true);
                  indicators[i].setAttribute('tabindex', '0');
                  callouts[i].style.display = 'flex';
                }
              } else {
                items[i].classList.remove('cmp-slideshow__item--active');
                items[i].classList.add('hidden');
                items[i].setAttribute('aria-hidden', true);
                if (indicators && indicators[i]) {
                  indicators[i].classList.remove('cmp-slideshow__indicator--active');
                  indicators[i].setAttribute('aria-selected', false);
                  indicators[i].setAttribute('tabindex', '-1');
                  callouts[i].style.display = 'none';
                }
              }
            }
          }
          disablePaginationLoop();
        } else {
          // only one item
          items?.classList.add('cmp-slideshow__item--active');
          indicators?.classList.add('cmp-slideshow__indicator--active');
        }
      }
    };

    /**
         * Navigates to the item at the provided index
         *
         * @private
         * @param {Number} index The index of the item to navigate to
         */
    const navigate = (index) => {
      if (index < 0 || index > this._elements.item.length - 1) {
        return;
      }

      this._active = index;
      refreshActive();
      document.dispatchEvent(new Event('cmp-imaged-loaded'));

      if (this.dataLayerEnabled) {
        const slideshowId = this._elements.self.id;
        const activeItem = getDataLayerId(this._elements.item[index]);
        const updatePayload = { component: {} };
        updatePayload.component[slideshowId] = { shownItems: [activeItem] };

        const removePayload = { component: {} };
        removePayload.component[slideshowId] = { shownItems: undefined };

        window.dataLayer.push(removePayload);
        window.dataLayer.push(updatePayload);
      }
    };

    /**
         * Focuses the element and prevents scrolling the element into view
         *
         * @param {HTMLElement} element Element to focus
         */
    const focusWithoutScroll = (element) => {
      const x = window.scrollX || window.pageXOffset;
      const y = window.scrollY || window.pageYOffset;
      element.focus();
      window.scrollTo(x, y);
    };

    /**
         * Navigates to the item at the provided index and ensures the active indicator gains focus
         *
         * @private
         * @param {Number} index The index of the item to navigate to
         */
    const navigateAndFocusIndicator = (index) => {
      navigate(index);
      focusWithoutScroll(this._elements.indicator[index]);

      if (this.dataLayerEnabled) {
        window.dataLayer.push({
          event: 'cmp:show',
          eventInfo: {
            path: `component.${getDataLayerId(this._elements.item[index])}`
          }
        });
      }
    };
    /**
         * Starts/resets automatic slide transition interval
         *
         * @private
         */
    const resetAutoplayInterval = () => {
      if (this._paused) {
        return;
      }
      clearAutoplayInterval();
      this._autoplayIntervalId = window.setInterval(() => {
        if (document.visibilityState && document.hidden) {
          return;
        }
        const { indicators } = this._elements;
        if (
          indicators !== document.activeElement &&
                    indicators.contains(document.activeElement)
        ) {
          // if an indicator has focus, ensure we switch focus following navigation
          navigateAndFocusIndicator(getNextIndex());
        } else {
          navigate(getNextIndex());
        }
      }, this._properties.delay);
    };

    /**
         * Handles slideshow mouseleave events
         *
         * @private
         */
    const onMouseLeave = () => {
      resetAutoplayInterval();
    };
    /**
         * Pauses the playing of the slideshow. Sets {@code slideshow#_paused} marker.
         * Only relevant when autoplay is enabled
         *
         * @private
         */
    const pause = () => {
      this._paused = true;
      clearAutoplayInterval();
      refreshPlayPauseActions();
    };
    /**
         * Handles pause element click events
         *
         * @private
         */
    const onPauseClick = () => {
      pause();
      this._elements.play.focus();
    };

    /**
         * Enables the playing of the slideshow. Sets {@code slideshow#_paused} marker.
         * Only relevant when autoplay is enabled
         *
         * @private
         */
    const play = () => {
      this._paused = false;
      resetAutoplayInterval();
      refreshPlayPauseActions();
    };
    /**
         * Handles play element click events
         *
         * @private
         */
    const onPlayClick = () => {
      play();
      this._elements.pause.focus();
    };

    /**
         * Handles slideshow keydown events
         *
         * @private
         * @param {Object} event The keydown event
         */
    const onKeyDown = (event) => {
      const index = this._active;
      const lastIndex = this._elements.indicator.length - 1;

      switch (event.keyCode) {
        case CoreJS.Constants.KEY_CODES.left:
        case CoreJS.Constants.KEY_CODES.up:
          event.preventDefault();
          if (index > 0) {
            navigateAndFocusIndicator(index - 1);
          }
          break;
        case CoreJS.Constants.KEY_CODES.right:
        case CoreJS.Constants.KEY_CODES.down:
          event.preventDefault();
          if (index < lastIndex) {
            navigateAndFocusIndicator(index + 1);
          }
          break;
        case CoreJS.Constants.KEY_CODES.home:
          event.preventDefault();
          navigateAndFocusIndicator(0);
          break;
        case CoreJS.Constants.KEY_CODES.end:
          event.preventDefault();
          navigateAndFocusIndicator(lastIndex);
          break;
        case CoreJS.Constants.KEY_CODES.space:
          if (
            this._properties.autoplay &&
                        event.target !== this._elements.previous &&
                        event.target !== this._elements.next
          ) {
            event.preventDefault();
            if (!this._paused) {
              pause();
            } else {
              play();
            }
          }
          if (event.target === this._elements.pause) {
            this._elements.play.focus();
          }
          if (event.target === this._elements.play) {
            this._elements.pause.focus();
          }
          break;
        default:
          return;
      }
    };

    /**
         * Binds slideshow event handling
         *
         * @private
         */
    const bindEvents = () => {
      if (this._elements.previous) {
        this._elements.previous.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          const index = getPreviousIndex();
          if (!event.target.classList.contains('disabled')) {
            navigate(index);
          }
          if (this.dataLayerEnabled) {
            window.dataLayer.push({
              event: 'cmp:show',
              eventInfo: {
                path: `component.${getDataLayerId(this._elements.item[index])}`
              }
            });
          }
        });
      }
      if (this._elements.next) {
        this._elements.next.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          const index = getNextIndex();
          if (!event.target.classList.contains('disabled')) {
            navigate(index);
          }
          if (this.dataLayerEnabled) {
            window.dataLayer.push({
              event: 'cmp:show',
              eventInfo: {
                path: `component.${getDataLayerId(this._elements.item[index])}`
              }
            });
          }
        });
      }
      const indicators = this._elements.indicator;
      if (indicators) {
        for (let i = 0; i < indicators.length; i++) {
          ((index) => {
            indicators[i].addEventListener(CoreJS.DomEventConstants.CLICK, () => {
              navigateAndFocusIndicator(index);
            });
          })(i);
        }
      }
      if (this._elements.pause) {
        this._elements.pause.addEventListener(CoreJS.DomEventConstants.CLICK, onPauseClick);
      }
      if (this._elements.play) {
        this._elements.play.addEventListener(CoreJS.DomEventConstants.CLICK, onPlayClick);
      }
      this._elements.self.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, onKeyDown);
      if (!this._properties.autopauseDisabled) {
        this._elements.self.addEventListener(CoreJS.DomEventConstants.MOUSE_ENTER, onMouseEnter);
        this._elements.self.addEventListener(CoreJS.DomEventConstants.MOUSE_LEAVE, onMouseLeave);
      }
      // for accessibility we pause animation when a element get focused
      const items = this._elements.item;
      if (items) {
        for (let j = 0; j < items.length; j++) {
          items[j].addEventListener(CoreJS.DomEventConstants.FOCUS_IN, onMouseEnter);
          items[j].addEventListener(CoreJS.DomEventConstants.FOCUS_OUT, onMouseLeave);
        }
      }
    };
    /**
         * Initializes the slideshow
         *
         * @private
         * @param {slideshowConfig} config The slideshow configuration
         */
    const init = (config) => {
      // prevents multiple initialization
      config.element.removeAttribute(`data-${this.NS}-is`);
      setupProperties(config.options);
      cacheElements(config.element);
      this._active = 0;
      this._paused = true;
      if (this._elements.item) {
        refreshActive();
        bindEvents();
        resetAutoplayInterval();
        refreshPlayPauseActions();
      }

      // TODO: this section is only relevant in edit mode and should move to the editor clientLib
      if (
        window.Granite &&
                window.Granite.author &&
                window.Granite.author.MessageChannel
      ) {
        /*
                         * Editor message handling:
                         * - subscribe to "cmp.panelcontainer" message requests sent by the editor frame
                         * - check this the message data panel container type is correct and this the id (path) matches this specific slideshow component
                         * - if so, route the "navigate" operation to enact a navigation of the slideshow based on index data
                         */
        window.CQ = window.CQ || {};
        window.CQ.CoreComponents = window.CQ.CoreComponents || {};
        window.CQ.CoreComponents.MESSAGE_CHANNEL =
                    window.CQ.CoreComponents.MESSAGE_CHANNEL ||
                    new window.Granite.author.MessageChannel('cqauthor', window);
        window.CQ.CoreComponents.MESSAGE_CHANNEL.subscribeRequestMessage(
          'cmp.panelcontainer',
          (message) => {
            if (
              message.data &&
                            message.data.type === 'cmp-slideshow' &&
                            message.data.id ===
                            this._elements.self.dataset.cmpPanelcontainerId
            ) {
              if (message.data.operation === 'navigate') {
                navigate(message.data.index);
              }
            }
          }
        );
      }
    };

    if (config && config.element) {
      init(config);
    }
  }

  /**
     * Reads options data from the slideshow wrapper element, defined via {@code data-cmp-*} data attributes
     *
     * @private
     * @param {HTMLElement} element The slideshow element to read options data from
     * @return {Object} The options read from the component data attributes
     */
  readData(element) {
    const data = element?.dataset;
    const options = [];
    let capitalized = this.IS;
    capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
    const reserved = ['is', `hook${capitalized}`];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (key.indexOf(this.NS) === 0) {
          let newKey = key.slice(this.NS.length);
          newKey = newKey.charAt(0).toLowerCase() + newKey.substring(1);
          if (reserved.indexOf(newKey) === -1) {
            options[newKey] = value;
          }
        }
      }
    }
    return options;
  }

  /**
     * Document ready handler and DOM mutation observers. Initializes Carousel components as necessary.
     *
     * @private
     */
  onDocumentReady() {
    this.slideshow({
      element: this.componentHost.querySelector(`.${this.NS}-${this.IS}`),
      options: this.readData(
        this.componentHost.querySelector(`.${this.NS}-${this.IS}`)
      )
    });
  }
}

// Register the SlideShow component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(SlideShow.CLASS_NAMESPACE, SlideShow);
