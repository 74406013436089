/* eslint-disable no-unused-expressions */
import i18n from './i18n.json';
/**
 * @author Jonas.Fournel
 * @classdesc This class is defining an implementation from CoreJS.BaseComponent for CalloutContainer component
 * It's mainly handling filtering feature on the callout container component
 * @version 1.0
 */


export default class CalloutContainer extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-callout-container';
  static CALLOUT_CLASS_NAMESPACE = 'ace-callout-component';
  static FILTER_CLASS_NAMESPACE = 'ace-filter';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.numberOfItem = 0;
    // Horizontal tab filter for the callout filtering
    this.horizontalTabFilterInitailize();
    // view more callouts
    this.guestviewmoreList();
    // Price rating iniitalize
    this.priceRating();
    // Rating filled based on the reviews
    this.UpdateRating();
    if (document.querySelector('.callout-container-api')) {
      this.tabSlider();
      this.showmapSticky();
    }
    // Checking if callout container has filters activated
    this.containerFilters = this.componentHost.querySelector(`.${CalloutContainer.CLASS_NAMESPACE}-filters`);
    if (this.containerFilters) {
      // clear all checkbox on initialize
      this.containerFilters.querySelectorAll('input').forEach((filter) => {
        filter.checked = false;
        sessionStorage.removeItem(CoreJS.StorageConstants.FILTER_SELECT);
      });
      // Reload page on back button for safari
      window.onpageshow = function () {
        if (sessionStorage.getItem(CoreJS.StorageConstants.FILTER_SELECT) === 'true') {
          window.location.reload();
          sessionStorage.removeItem(CoreJS.StorageConstants.FILTER_SELECT);
        }
      };

      // Component common hook
      this.showHideFiltersButtons = this.componentHost.querySelector(`button.${CalloutContainer.FILTER_CLASS_NAMESPACE}-dropdownbutton__toggle`);
      this.filterMenu = this.componentHost.querySelector(`div.${CalloutContainer.FILTER_CLASS_NAMESPACE}-dropdownbutton__menu`);
      this.closeFilterButton = this.componentHost.querySelector('span.close-filters-button');
      this.applyFilterButton = this.componentHost.querySelector('button.apply-filters-button');
      this.clearFilterLink = this.componentHost.querySelector('button.clear-filters-button');
      this.filteredItemNumberElement = this.containerFilters.querySelector('.filtered-items-number');
      this.filteredItemNumberElement?.setAttribute('data-sr-event-text', '0 filtered items');
      this.filterDropDownButton = this.componentHost.querySelector('.ace-filter-dropdownbutton');

      // Adding Event Listeners
      this.showHideFiltersButtons.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => this.toggleFiltersButtonHandler(event));
      this.clearFilterLink.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => this.resetFilters(event));
      this.clearFilterLink.addEventListener(CoreJS.DomEventConstants.BLUR, () => {
        this.closeFiltersButtonHandler();
      });
      this.closeFilterButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => this.closeFiltersButtonHandler());

      const runningBreakpoint = CoreJS.ResponsiveUtils.determineActualBreakpointLabel();
      this.mobileFilters = false;
      if (runningBreakpoint !== CoreJS.ResponsiveUtils.S) {
        this.containerFilters.querySelectorAll('input').forEach((input) => {
          input.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => this.filterCalloutItems(event));
        });
      } else {
        this.mobileFilters = true;
        this.applyFilterButton.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => this.filterCalloutItems(event));
        this.containerFilters.querySelectorAll('input').forEach((input) => {
          input.addEventListener(CoreJS.DomEventConstants.CHANGE, () => this.countAndDisplayNumberOfSelectedFilters());
        });
      }

      this.cleanUnusedFilters();
    }
    this.defaultCalloutItems = this.componentHost?.querySelectorAll('li');
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.calloutType = this.componentHost?.getAttribute('data-callout-type');
    this.PageName = this.datalayerValues.pageName.split('::');
    if (this.PageName[1] == 'all signature' && this.calloutType !== 'iconslot') {
      this.latamTracking();
    }
  }


  latamTracking() {
    this.defaultCalloutItems?.forEach((item) =>{
      item?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.blocInteraction = item?.querySelector('.cmp-teaser__content a')?.innerHTML.trim();
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: 'partner transfer',
            bloc_interaction: this.blocInteraction
          }
        };
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(datasEvent);
      }
      );
    });
  }


  cleanUnusedFilters() {
    const filters = [];
    const usedFilters = new Set();

    this.componentHost.querySelectorAll('.ace-filter-form li input').forEach((filter) => {
      filters.push(filter.value);
    });

    this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}`).forEach((callout) => {
      if (callout.dataset && callout.dataset.filters) {
        const itemFilter = callout.dataset.filters;
        if (itemFilter.includes(',')) {
          const split = itemFilter.split(',');
          split.forEach((element) => usedFilters.add(element));
        } else {
          usedFilters.add(callout.dataset.filters);
        }
      }
    });

    filters.forEach((filter) => {
      if (!usedFilters.has(filter)) {
        document.querySelector(`input[value=${filter}]`).parentElement.style.display = 'none';
      }
    });
  }

  /**
    *
    */
  countAndDisplayNumberOfSelectedFilters() {
    const count = this.containerFilters.querySelectorAll('input:checked').length;
    const span = this.applyFilterButton.firstElementChild;
    span.innerText = span.dataset.text.replace('{0}', count);

    if (count > 0) {
      this.clearFilterLink.classList.remove('hidden');
      sessionStorage.removeItem(CoreJS.StorageConstants.FILTER_SELECT);
    } else {
      this.clearFilterLink.classList.add('hidden');
      sessionStorage.removeItem(CoreJS.StorageConstants.FILTER_SELECT);
    }
  }

  /*
    *
    * @param event
    */
  closeFiltersButtonHandler() {
    if (this.showHideFiltersButtons?.classList.contains('active')) {
      this.showHideFiltersButtons.focus();
    }
    this.showHideFiltersButtons.classList.remove('active');
    this.showHideFiltersButtons.setAttribute('aria-expanded', 'false');
    window.removeEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideFilters(clickEvent));
    document.body.classList.remove('disable-scroll-callout-container');
    if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      this.filterDropDownButton.scrollIntoView();
      this.showHideFiltersButtons.nextElementSibling.classList.remove('active');
    }
  }

  /*
    *
    * @param event
    */
  toggleFiltersButtonHandler(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.showHideFiltersButtons?.classList.contains('active')) {
      this.showHideFiltersButtons.focus();
    }
    this.showHideFiltersButtons.classList.toggle('active');
    const isActive = event.currentTarget.classList.contains('active');
    this.showHideFiltersButtons.setAttribute('aria-expanded', isActive ? 'true' : 'false');
    this.showHideFiltersButtons.nextElementSibling.classList.toggle('active');
    if (isActive) {
      window.addEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideFilters(clickEvent));
      document.body.classList.add('disable-scroll-callout-container');
    } else {
      window.removeEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideFilters(clickEvent));
      document.body.classList.remove('disable-scroll-callout-container');
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        this.filterDropDownButton.scrollIntoView();
      }
    }
  }

  /*
    *
    * @param event
    */
  windowClickHideFilters(event) {
    if (!event.target.closest(`.${CalloutContainer.CLASS_NAMESPACE}`)) {
      if (this.showHideFiltersButtons?.classList.contains('active')) {
        const bounding = this.containerFilters.getBoundingClientRect();
        if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth ||
          document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
          this.showHideFiltersButtons.focus();
        }
      }
      this.showHideFiltersButtons.classList.remove('active');
      this.showHideFiltersButtons.setAttribute('aria-expanded', 'false');
      this.showHideFiltersButtons.nextElementSibling.classList.remove('active');
      document.body.classList.remove('disable-scroll-callout-container');
    }
  }

  /*
    * This method is handling filter feature, hiding non-matching filters elements and then only show
    * matching callout filter elements. If none filters is checked, then we display all elements
    * @param event Source Event
    */
  filterCalloutItems(event) {
    const activeFilters = this.containerFilters.querySelectorAll('input:checked');
    if (activeFilters && activeFilters.length > 0) {
      this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}`).forEach((callout) => {
        let calloutFilters = callout.dataset?.filters;
        if (calloutFilters && calloutFilters.includes(',')) {
          calloutFilters = calloutFilters.split(',');
        }

        let toHide = true;
        activeFilters.forEach((filter) => {
          if (Array.isArray(calloutFilters)) {
            calloutFilters.forEach((splitFilter) => {
              if (filter.value === splitFilter) {
                toHide = false;
              }
            });
          } else {
            if (filter.value === calloutFilters) {
              toHide = false;
            }
          }
        });
        if (toHide) {
          callout.classList.add('hidden');
          callout.parentElement.classList.add('filter-li-hide');
        } else {
          callout.parentElement.classList.remove('filter-li-hide');
          callout.classList.remove('hidden');
        }
      });
      CoreJS.ScreenReaderHelper.makeScreeReaderSpeak(event.currentTarget);

      // Updating filtered elements number in total display HTML field, and adding listener of the 'clear filters' link
      this.clearFilterLink.classList.remove('hidden');
      sessionStorage.setItem(CoreJS.StorageConstants.FILTER_SELECT, 'true');
      const totalCalloutElements = this.componentHost?.querySelectorAll('ul.ace-callout-section li')?.length;
      const totalHiddenCallout = this.componentHost?.querySelectorAll('.filter-li-hide')?.length;
      this.filteredItemNumberElement.innerText = totalCalloutElements - totalHiddenCallout;
      this.filteredItemNumberElement.setAttribute('data-sr-event-text', `${this.filteredItemNumberElement.innerText} filtered items`);
      CoreJS.ScreenReaderHelper.makeScreeReaderSpeak(this.filteredItemNumberElement);
      const filterCounter = this.componentHost?.querySelector(`.${CalloutContainer.CLASS_NAMESPACE}-filters__filter-counter`);
      filterCounter.setAttribute('aria-live', 'polite');
      if (this.mobileFilters) {
        this.closeFilterButton.dispatchEvent(new Event(CoreJS.DomEventConstants.CLICK));
      }
    } else {
      this.resetFilters(null);
    }
  }

  /*
    *
    * @param event Event
    */
  resetFilters(event) {
    if (event) {
      event.stopImmediatePropagation();
    }

    // Resetting view
    this.clearFilterLink.classList.add('hidden');
    sessionStorage.removeItem(CoreJS.StorageConstants.FILTER_SELECT);
    this.filteredItemNumberElement.innerText = '0';
    this.filteredItemNumberElement.setAttribute('data-sr-event-text', `${this.filteredItemNumberElement.innerText} filtered items`);
    CoreJS.ScreenReaderHelper.makeScreeReaderSpeak(this.filteredItemNumberElement);

    this.containerFilters.querySelectorAll('input').forEach((filter) => {
      filter.checked = false;
    });

    this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}.hidden`).forEach((callout) => {
      callout.classList.remove('hidden');
      callout.parentElement.classList.remove('filter-li-hide');
    });
    this.countAndDisplayNumberOfSelectedFilters();
    this.closeFiltersButtonHandler();
  }
  i18nString(string, value) {
    return string.replace('$value', value);
  }
  /* Display callouts based on the number of columns and no of items */
  identifyNumberOfItems() {
    const rowCount = this.componentHost.querySelector('.ace-guest-viewmore')?.getAttribute('data-callout-row');
    const columnClassName = Array.from(this.componentHost.querySelector('ul.ace-callout-section li')?.classList).filter((s) => s.includes('aem-GridColumn--default--'));
    const columnCount = columnClassName.toString().split('-').pop();
    if (rowCount >= 1) {
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) { // Mobile  view
        this.numberOfItem = rowCount * 1;
      } else if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) { // Tab  view
        this.numberOfItem = rowCount * 2;
      } else if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) { // Desktop  view
        const guestDynamic = 12 / columnCount;
        this.numberOfItem = rowCount * guestDynamic;
      }
    } else {
      this.numberOfItem = 8;
    }
  }
  /**
  * View more callouts
  */
  guestviewmoreList() {
    const guestList = this.componentHost.querySelectorAll('li:not(.filter-li-hide) .ace-callout-component');
    const guestViewmore = this.componentHost.querySelector('.ace-guest-viewmore');
    if (guestViewmore) {
      this.identifyNumberOfItems();
    }
    if (guestViewmore && guestList.length > this.numberOfItem) {
      const currentPageLanguage = i18n[document.documentElement.lang] || i18n.en;
      const srOnly = guestViewmore.querySelector('.sr-only');
      this.seeMoreButtonVariable = guestViewmore?.querySelector('.cmp-button__text')?.innerText?.trim();
      if (srOnly) {
        srOnly.innerHTML = this.i18nString(currentPageLanguage['view-more'], guestList.length - this.numberOfItem);
      }

      guestList.forEach((callout, index) => {
        const hiddenList = this.numberOfItem - 1;
        if (index <= hiddenList) {
          callout.parentElement.removeAttribute('hidden');
          callout?.setAttribute('isLoaded', 'true');
        } else {
          callout.parentElement.setAttribute('hidden', true);
          if (index === hiddenList + 1) {
            callout?.setAttribute('lazy-flag', true);
          }
          guestViewmore.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            if (!guestViewmore?.getAttribute('data-step')) {
              guestViewmore.classList.add('hidden');
              callout.parentElement.removeAttribute('hidden');
              event.preventDefault();
            }
          });
        }
      });
    } else {
      guestViewmore?.classList.add('hidden');
    }
    guestViewmore?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (this.componentHost?.closest('.callout-container-api') && guestViewmore?.getAttribute('data-step')) {
        guestViewmore?.classList?.add('loader');
        guestViewmore.querySelector('.cmp-button__text').innerText = '';
        guestViewmore.querySelector('.cmp-button__text')?.classList?.add('icon-loader');
        this.lazyLoadNextCallouts(guestList, guestViewmore);
      }
      const currentActiveElement = this.tabNavigation?.querySelector('[aria-current="true"]');
      if (currentActiveElement) {
        const filterId = currentActiveElement?.getAttribute('data-filter-tab-id');
        this.componentHost.querySelectorAll(`[data-filter-tab-id="${filterId}"]`)?.forEach((item) => {
          item.setAttribute('data-visited', 'true');
        });
      }
      setTimeout(() => {
        const displayList = this.componentHost.querySelectorAll('li:not(.filter-li-hide) .ace-callout-component');
        displayList.forEach((callout, index) => {
          const focusIndex = this.numberOfItem;
          if (focusIndex === index) {
            callout.querySelector('a')?.focus();
          }
        });
      }, 100);
    });
  }
  /**
   * Price rating hooks
   */
  priceRatingHooks() {
    this.priceRatingCallouts = this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}-default .ace-callout-component-default__link`);
  }
  /**
    * Render callouts price
    */
  renderCalloutsPrice() {
    if (this.priceRatingCallouts) {
      const lazyPriceLoad = Array.from(this.priceRatingCallouts);
      const url = '/a/content/sling/servlets/ace/legal-offer.legalOffer.json';
      if ('IntersectionObserver' in window) {
        const priceReplace = (string, value) => {
          return string.replace('--', value);
        };
        const renderPrice = (json, hotel, hotelId) => {
          if (json &&
            Object.keys(json).length === 0 &&
            Object.getPrototypeOf(json) === Object.prototype) {
          } else {
            hotel.querySelector('.ace-callout-component-default__link span:first-child').innerHTML = priceReplace(
              hotel.querySelector('.ace-callout-component-default__link span:first-child').innerHTML, json[hotelId]);
            hotel.querySelector('.ace-callout-component-default__link').classList.add('show');
            hotel.querySelector('.ace-callout-component-default__link').classList.remove('hide');
          }
        };
        const hotelObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const hotel = entry.target.closest(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}-default`);
              const hotelId = hotel?.getAttribute('data-product-id');
              if (hotel && hotelId) {
                fetch(`${url}?productId=${hotelId}&lang=${document.documentElement.lang}`, {
                  headers: new Headers({
                    'Content-Type': 'application/json'
                  })
                })
                  .then((response) => response.json())
                  .then((json) => renderPrice(json, hotel, hotelId));
              }
              hotelObserver.unobserve(hotel);
            }
          });
        });
        lazyPriceLoad?.forEach((hotel) => hotelObserver.observe(hotel));
      }
    }
  }
  /**
  * Hooks for the horizontal tab filtering variation
  */
  horizontalTabFilterHooks() {
    this.tabNavigation = this.componentHost.querySelector(`.${CalloutContainer.CLASS_NAMESPACE}-filters__tab-nav`);
    this.tabNavigationList = this.componentHost.querySelector(`.${CalloutContainer.CLASS_NAMESPACE}-filters__tab-list`);
    this.tabNavigationItem = this.componentHost.querySelectorAll(`.${CalloutContainer.CLASS_NAMESPACE}-filters__tab-item`);
    this.tabNavigationButton = this.componentHost.querySelectorAll(`.${CalloutContainer.CLASS_NAMESPACE}-filters__tab-button`);
    this.tabNavigationCounter = this.componentHost.querySelectorAll(`.${CalloutContainer.CLASS_NAMESPACE}-filters__tab-counter`);
  }
  /*
  * Horizontal refresh filters
  */
  horizontalTabFilterRefresh(id) {
    this.tabNavigationButton.forEach((item) => {
      if (item.getAttribute('data-filter-tab-id') === id) {
        item.setAttribute('aria-current', true);
      } else {
        item.setAttribute('aria-current', false);
      }
    });
    this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}`).forEach((callout) => {
      callout.parentElement.classList.add('filter-li-hide');
    });
    this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}[data-filters*="${id}"]`).forEach((callout) => {
      callout.parentElement.classList.remove('filter-li-hide');
    });
    setTimeout(() => {
      document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.RESIZE_EVENT_WRAPPER));
    }, 100);
    this.tabNavigationButton.forEach((item) => {
      if (item.getAttribute('data-filter-tab-id') === id) {
        item.setAttribute('aria-current', true);
        const isVisited = item.getAttribute('data-visited');
        if (isVisited === 'false') {
          this.componentHost?.querySelector('.ace-guest-viewmore')?.classList.remove('hidden');
          this.guestviewmoreList();
        } else if (isVisited === 'true') {
          this.componentHost?.querySelector('.ace-guest-viewmore')?.classList.add('hidden');
        }
      } else {
        item.setAttribute('aria-current', false);
      }
    });
  }
  /**
  * Horizontal tab filter bind events
  */
  horizontalTabFilterEvents() {
    const dropdown = this.tabNavigation?.querySelector('select');
    dropdown?.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
      this.horizontalTabFilterRefresh(event.currentTarget.value);
      CoreJS.ScreenReaderHelper.makeScreeReaderSpeak(event.target.options[event.target.selectedIndex]);
    });
    [CoreJS.DomEventConstants.CLICK].forEach((index) => {
      this.tabNavigationButton?.forEach((button) => {
        button.setAttribute('data-visited', false);
        button?.addEventListener(index, (event) => {
          this.horizontalTabFilterRefresh(event.target.getAttribute('data-filter-tab-id'));
        });
      });
    });
  }
  /**
  * Horizonatal filter counter
  */
  horizontalTabFilterCounter() {
    const currentPageLanguage = i18n[document.documentElement.lang] || i18n.en;
    const filterItems = []; const counter = {};
    this.componentHost.querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}[data-filters]`)?.forEach((item) => {
      filterItems.push(item?.getAttribute('data-filters')?.split(','));
    });
    const totalFilters = filterItems?.flat();
    const calloutInstance = this.componentHost;
    totalFilters?.forEach((item) => {
      counter[item] = (counter[item] || 0) + 1;
    });
    Object.keys(counter).forEach((key) => {
      calloutInstance?.querySelectorAll(`[data-filter-tab-id="${key}"]`)?.forEach((selectors) => {
        if (selectors.tagName === 'OPTION') {
          selectors.innerHTML += ` (${counter[key]})`;
          selectors.setAttribute('data-sr-event-text', `${currentPageLanguage['tab-filter-refresh']} ${selectors.innerHTML}`);
        } else {
          if (selectors.children[1]) {
            selectors.children[1].innerHTML = ` (${counter[key]})`;
          }
          if (selectors.children[2]) {
            selectors.children[2].innerHTML = `${counter[key]} Results`;
          }
        }
      });
    });
  }

  horizontalTabFilterArrowNavigation() {
    this.tabNavigationItem.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.keyCode === CoreJS.Constants.KEY_CODES.right) {
          event.target.parentElement.nextElementSibling ?
            event.target.parentElement.nextElementSibling.firstElementChild?.focus() : event.target.parentElement.parentElement.firstElementChild.firstElementChild?.focus();
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.left) {
          event.target.parentElement.previousElementSibling ?
            event.target.parentElement.previousElementSibling.firstElementChild?.focus() : event.target.parentElement.parentElement.lastElementChild.firstElementChild?.focus();
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.home) {
          event.target.parentElement.parentElement.firstElementChild.firstElementChild?.focus();
          event.preventDefault();
        } else if (event.keyCode === CoreJS.Constants.KEY_CODES.end) {
          event.target.parentElement.parentElement.lastElementChild.firstElementChild?.focus();
          event.preventDefault();
        }
      });
    });
  }
  tabSlider() {
    const numberofcomponents = document.querySelectorAll('.callout-container-api');


    numberofcomponents.forEach((item) => {
      const tabsBox = item.querySelector('.ace-callout-container-filters__tab-nav .ace-callout-container-filters__tab-list');
      const arrowButtons = item.querySelectorAll('.ace-callout-container-filters__tab-nav button.icon');

      let scrollWidth;

      if (tabsBox) {
        if (tabsBox.scrollWidth < tabsBox.parentNode.clientWidth) {
          tabsBox.parentElement.querySelectorAll('button span.rightArrow')[0].parentNode.style.display = 'none';
        }
        arrowButtons.forEach((icon) => {
          const leftarrowIcon = icon.querySelector('.leftArrow');
          if (leftarrowIcon) {
            leftarrowIcon.parentElement.style.display = 'none';
          }

          const handleIcons = (scrollVal) => {
            const currentTabbox = icon.parentElement.querySelectorAll('.ace-callout-container-filters__tab-list')[0];
            const leftIconOfCurrentContainer = icon.parentElement.querySelectorAll('button span.leftArrow')[0];
            const rightIconOfCurrentContainer = icon.parentElement.querySelectorAll('button span.rightArrow')[0];

            const maxScrollableWidth = currentTabbox.scrollWidth - currentTabbox.clientWidth;
            leftIconOfCurrentContainer.parentElement.style.display = scrollVal <= 0 ? 'none' : 'flex';
            rightIconOfCurrentContainer.parentNode.style.display = maxScrollableWidth - scrollVal <= 1 ? 'none' : 'flex';
          };
          icon.addEventListener('click', () => {
            // if clicked icon is left, reduce 350 from tabsBox scrollLeft else add
            scrollWidth = icon.parentElement.querySelectorAll('.ace-callout-container-filters__tab-list')[0].scrollLeft += icon.childNodes[0].classList.contains('leftArrow') ? -340 : 340;
            handleIcons(scrollWidth);
          });
        });
      } else {
        if (item.querySelector('.ace-callout-container-filters__tab-nav button.icon .rightArrow')) {
          item.querySelector('.ace-callout-container-filters__tab-nav button.icon .rightArrow').parentElement.style.display = 'none';
        }
        if (item.querySelector('.ace-callout-container-filters__tab-nav button.icon .leftArrow')) {
          item.querySelector('.ace-callout-container-filters__tab-nav button.icon .leftArrow').parentElement.style.display = 'none';
        }
      }
    });
  }

  /**
  * Horizontal tab filter , UI variation for the callout container filtering
  */
  horizontalTabFilterInitailize() {
    // Initalize the hooks
    this.horizontalTabFilterHooks();
    // bind events
    this.horizontalTabFilterEvents();
    // filter counter
    this.horizontalTabFilterCounter();
    // display filter for first active item

    // Filters with Arrow Navigation
    this.horizontalTabFilterArrowNavigation();

    const currentActiveFilter = this.tabNavigation?.querySelector('[aria-current="true"]')?.getAttribute('data-filter-tab-id');
    if (currentActiveFilter) {
      this.horizontalTabFilterRefresh(currentActiveFilter);
    }
  }
  priceRating() {
    this.priceRatingHooks();
    this.renderCalloutsPrice();
  }

  showmapSticky() {
    this.showmap = this.componentHost?.querySelector('.ace-modal-component');
    window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
      if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && !this.componentHost.querySelector('.carousel-v2') && !document.querySelector('.sticky-booking-engine')) {
        if (!this.showmap) return;
        if (this.componentHost.querySelector('.cmp-container').getBoundingClientRect().top < 0 &&
        this.componentHost.querySelector('.cmp-container').getBoundingClientRect().bottom > window.innerHeight) {
          if (this.showmap.style.position === 'fixed') return;
          this.showmap?.classList?.add('sticky');
          this.showmap.style.position = 'fixed';
          this.showmap.style.bottom = '0';
        } else {
          if (this.showmap.style.position === 'relative') return;
          this.showmap?.classList?.remove('sticky');
          this.showmap.style.position = 'relative';
        }
      }
    });
  }

  UpdateRating() {
    this.componentHost.querySelectorAll('.cmp-teaser__reviews').forEach((reviews) => {
      const ratings = reviews.querySelectorAll('.cmp-teaser__rating-fill');
      if (reviews.querySelector('.cmp-teaser__rating-trustyou') !== null) {
        const ratingValue = reviews.querySelector('.cmp-teaser__rating-trustyou').getAttribute('data-rating');
        const filledratings = Math.floor(ratingValue);
        const decimal = ratingValue - filledratings;
        const bgcolor = '#1E1852';
        const whitebg = '#ffffff';
        ratings.forEach((star, index) => {
          const CurrentRating = index + 1;
          if (CurrentRating <= filledratings) {
            star.style.background = `linear-gradient(to right,${bgcolor} 100%,${whitebg} 0%)`;
          } else if (CurrentRating === filledratings + 1 && decimal > 0) {
            const bgpercent = `${(decimal * 100).toFixed(2)}%`;
            star.style.background = `linear-gradient(to right,${bgcolor} ${bgpercent},${whitebg} 0%)`;
          }
        });
      }
    });
  }

  /**
  * Lazy load next callouts by calling api returning callout img paths
  * on click of view more button
  * @private
  * @param {Object} guestList All available callouts to show
  * @param {HTML} guestViewmore View more button
  */
  lazyLoadNextCallouts(guestList, guestViewmore) {
    // Do lazy load manipulation only when no chip selected
    if (!this.componentHost?.querySelector('.ace-chip .ace-chip--button.active')) {
      let flagIndex;
      let v2ImageFlag = false;
      const hotelList = [];
      const { posHandler } = document?.body?.dataset || {};
      const brandSite = JSON.parse(posHandler)?.siteCode;
      const imageRatios = this.componentHost.querySelector('.ace-callout-section')?.getAttribute('data-image-ratios');
      const columns = this.componentHost.querySelector('.ace-callout-section')?.getAttribute('data-columns') ?
        `&noOfColumns=${this.componentHost.querySelector('.ace-callout-section')?.getAttribute('data-columns')}` : '';
      guestList?.forEach((item, index) => {
        if (item?.getAttribute('lazy-flag')) {
          flagIndex = index;
          // check if v2 image
          if (item?.querySelector('.ace-image-v2')) {
            v2ImageFlag = true;
          }
        }
        if (index >= flagIndex && index < flagIndex + this.numberOfItem) {
          if (item?.getAttribute('data-product-id')) {
            if (!item?.getAttribute('isLoaded')) {
              hotelList.push(item?.getAttribute('data-product-id'));
            } else {
              item?.parentElement?.removeAttribute('hidden');
            }
          }
        }
      });

      if (hotelList?.length > 0) {
        let url = `/a/bin/productImage?hotelIds=${hotelList?.toString()}`;
        if (v2ImageFlag) {
          url = `/a/bin/productImage?hotelIds=${hotelList?.toString()}&imageVersion=v2&brand=${brandSite}&ratios=${imageRatios}${columns}`;
        }

        new CoreJS.XHRPromisifiedRequest(url, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)
          .setRequestHeaders({ 'Content-Type': 'application/json' })
          .executeRequest()
          .then((response) => {
            const dataResponse = JSON.parse(response.responseText);
            const responseKey = Object.keys(dataResponse);
            const responseValue = Object.values(dataResponse);
            guestList?.forEach((item) => {
              responseKey?.forEach((key, count) => {
                if (item?.getAttribute('data-product-id') === key) {
                  if (v2ImageFlag) {
                    const pictureSource = item?.querySelectorAll('picture source');
                    pictureSource[0]?.setAttribute('srcset', responseValue[count]?.S);
                    pictureSource[1]?.setAttribute('srcset', responseValue[count]?.M);
                    pictureSource[2]?.setAttribute('srcset', responseValue[count]?.L);
                    pictureSource[3]?.setAttribute('srcset', responseValue[count]?.XL);
                    pictureSource[4]?.setAttribute('srcset', responseValue[count]?.XXL);
                    item?.querySelector('img')?.setAttribute('src', responseValue[count]?.default);
                  } else {
                    item?.querySelector('img')?.setAttribute('data-src', responseValue[count]?.default);
                  }
                  item?.parentElement?.removeAttribute('hidden');
                }
              });
              item?.removeAttribute('lazy-flag');
            });
            if (guestList?.length > flagIndex + this.numberOfItem) {
              guestList[flagIndex + this.numberOfItem]?.setAttribute('lazy-flag', true);
              guestViewmore?.classList?.remove('loader');
              guestViewmore.querySelector('.cmp-button__text')?.classList?.remove('icon-loader');
              guestViewmore.querySelector('.cmp-button__text').innerText = this.seeMoreButtonVariable;
            } else {
              guestViewmore.classList.add('hidden');
            }
            this.srEventText = 'No items displayed';
            const totalFilteredItems = this.componentHost?.parentElement?.querySelectorAll('.ace-callout-section li:not([hidden])')?.length;
            if (totalFilteredItems > 0) {
              this.srEventText = `${totalFilteredItems} items displayed`;
            }
            this.componentHost?.parentElement?.setAttribute('data-sr-event-text', this.srEventText);
            CoreJS.ScreenReaderHelper.makeScreeReaderSpeak(this.componentHost, CoreJS.ScreenReaderHelper.ARIA_LIVE_ASSERTIVE);
            this.componentHost?.querySelector('.cmp-container')?.setAttribute('tabindex', '-1');
            this.componentHost?.querySelector('.cmp-container').focus();
          })
          .catch((error) => {
            guestViewmore?.classList?.remove('loader');
            guestViewmore.querySelector('.cmp-button__text')?.classList?.remove('icon-loader');
            guestViewmore.querySelector('.cmp-button__text').innerText = this.seeMoreButtonVariable;
            console.error(error);
          });
      }
    } else {
      // Lazy load manipulation executed for chip selected written in chip.js
    }
  }
}
CoreJS.BaseComponent.registerComponent(
  CalloutContainer.CLASS_NAMESPACE,
  CalloutContainer
);
