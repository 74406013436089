import { CoreBookingEngine } from '@accor/ace-ui-core';

export default class CoreBookingEngineHomepage extends CoreBookingEngine {
  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.autocompleteDestination = this.componentHost.querySelector('.ace-core-booking-engine__autocomplete');

    document.addEventListener(CoreJS.DomEventConstants.DOM_CONTENT_LOADED, () => {
      this.isCoreBookingEngineHomepage = document.querySelector('.core-booking-engine-homepage');
      if (this.isCoreBookingEngineHomepage) {
        this.bindSearchDestinationEvents();
      }
    });
  }

  /**
   * Bind search destination events
   */
  bindSearchDestinationEvents() {
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.componentHost.querySelector('input[id="search.destination.userlang"')?.addEventListener(CoreJS.DomEventConstants.FOCUS, () => {
        this.openFullscreenSearch();
        if (this.componentHost.querySelector('.ahAutocomplete__result')) {
          this.componentHost.querySelector('.ahAutocomplete__result').style.maxHeight = '';
        }
      });

      this.componentHost.querySelector('input[id="search.destination.userlang"')?.addEventListener(CoreJS.DomEventConstants.BLUR, () => {
        if (this.componentHost.querySelector('.ahAutocomplete__result')) {
          this.componentHost.querySelector('.ahAutocomplete__result').style.maxHeight = 'calc(100vh - 16rem)';
        }
      });

      this.componentHost.querySelector('input[id="search.destination.userlang"')?.addEventListener(CoreJS.DomEventConstants.INPUT, () => {
        this.openFullscreenSearch();
      });

      this.componentHost.querySelector('[id="mount-autocomplete"]').addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        event.stopPropagation();
        if (event.srcElement.id != 'search.destination.userlang' && event.srcElement.id != 'destinationSuggest' && !event.target.classList.contains('ahAutocomplete__result')) {
          if ((event.target.offsetParent && !event.target.offsetParent.classList.contains('autocompleteItem--noResult')) || !this.componentHost.querySelector('.ahAutocomplete__result')) {
            this.closeFullscreenSearch();
          }
        }
      });

      this.componentHost.querySelector('.ace-core-booking-engine__title .close')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.closeFullscreenSearch();
      });
    }
  }

  fixScrollingOnFullscreenSearch() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  highlightCursorAndScrollOnFullscreenSearch() {
    // change color and reset after delay (force show cursor on input after scrolling)
    this.componentHost.querySelector('input[id="search.destination.userlang"]').style.caretColor = 'blue';
    setTimeout(() => {
      window.scrollTo(0, 250); // force scroll on mobile purpose after delay to bypass url navigator height
      this.componentHost.querySelector('input[id="search.destination.userlang"]').style.caretColor = '';
    }, 250);
  }

  openFullscreenSearch() {
    if (!this.autocompleteDestination.classList.contains('fullscreen')) {
      this.autocompleteDestination.classList.add('fullscreen');
      document.querySelector('body').style.height = 0;
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('html').style.overflow = 'hidden';
      this.componentHost.querySelector('.ace-core-booking-engine__title').style.display = '';
      this.componentHost.querySelector('.ace-core-booking-engine__label').style.display = 'revert';
      this.componentHost.querySelector('.ace-core-booking-engine__label span').style.display = 'none';
      window.addEventListener(CoreJS.DomEventConstants.SCROLL, this.fixScrollingOnFullscreenSearch);
      this.highlightCursorAndScrollOnFullscreenSearch();
    }
  }

  closeFullscreenSearch() {
    if (this.autocompleteDestination.classList.contains('fullscreen')) {
      this.autocompleteDestination.classList.remove('fullscreen');
      document.querySelector('body').style.height = '';
      document.querySelector('body').style.overflow = '';
      document.querySelector('html').style.overflow = '';
      this.componentHost.querySelector('.ace-core-booking-engine__title').style.display = 'none';
      this.componentHost.querySelector('.ace-core-booking-engine__label').style.display = '';
      this.componentHost.querySelector('.ace-core-booking-engine__label span').style.display = '';
      this.componentHost.querySelector('input[id="search.destination.userlang"').blur();
      window.removeEventListener(CoreJS.DomEventConstants.SCROLL, this.fixScrollingOnFullscreenSearch);
    }
  }
}
CoreJS.BaseComponent.registerComponent(
  CoreBookingEngine.CLASS_NAMESPACE,
  CoreBookingEngineHomepage,
  true
);
