const supportedLang =
  '(ar|de|es|fr|gb|en|id|it|ja|ko|nl|pl|pt|pt-br|ru|th|tr|vi|zh)'

const regExpNewGeoloc = new RegExp(
  '\\/([0-9a-z-]*)\\/[0-9a-z-]*\\.' + supportedLang + '\\.[a-z]{2,6}'
)

const storeAccountRegExp = new RegExp(/\/(store|account)\//)

export const transformLang = lang => (lang === 'gb' ? 'en' : lang)

export const getLang = (global = window) => {
  const lang =
    getLangFromURL() ||
    getLangFromCookies() ||
    global.navigator.language ||
    global.document.documentElement.lang ||
    global.document.getElementsByTagName('html')[0].getAttribute('xml:lang')

  return transformLang(lang) || 'en'
}

export const getLangFromURL = url => {
  let myUrl = url || window.location.pathname

  if (storeAccountRegExp.test(myUrl)) {
    myUrl = window.location.href
  }

  let regExpLang = new RegExp('\\/' + supportedLang + '\\/')
  let resultRegExp = regExpLang.exec(myUrl)
  let lang = resultRegExp ? resultRegExp[1] : null

  if (!lang) {
    resultRegExp = regExpNewGeoloc.exec(myUrl)
    lang = resultRegExp ? resultRegExp[2] : null
  }

  if (lang) {
    lang = transformLang(lang)
  }

  return lang
}

export const getLangFromCookies = (cookies = {}) => {
  return (
    cookies.userLang ||
    cookies.userPrefLocalization ||
    cookies.userLocalizationInitial ||
    null
  )
}

export const LANG_FORMAT_LOCALIZE = {
  ar: 'YYYY/MM/DD',
  'ar-us': 'YYYY/DD/MM',
  de: 'DD/MM/YYYY',
  en: 'DD/MM/YYYY',
  'en-us': 'MM/DD/YYYY',
  es: 'DD/MM/YYYY',
  'es-us': 'MM/DD/YYYY',
  fr: 'DD/MM/YYYY',
  id: 'DD/MM/YYYY',
  it: 'DD/MM/YYYY',
  ja: 'YYYY/MM/DD',
  ko: 'YYYY/MM/DD',
  nl: 'DD/MM/YYYY',
  pl: 'DD/MM/YYYY',
  pt: 'DD/MM/YYYY',
  'pt-br': 'DD/MM/YYYY',
  ru: 'DD/MM/YYYY',
  th: 'YYYY/MM/DD',
  tr: 'DD/MM/YYYY',
  zh: 'YYYY/MM/DD',
  'zh-us': 'YYYY/DD/MM',
}
