/* eslint-disable no-prototype-builtins */
/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */

export default class CarouselV2 extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-carousel-component-v2';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    this.NS = 'cmp';
    this.IS = 'ace-carousel';

    this.keyCodes = {
      SPACE: 32,
      END: 35,
      HOME: 36,
      ARROW_LEFT: 37,
      ARROW_UP: 38,
      ARROW_RIGHT: 39,
      ARROW_DOWN: 40
    };

    this.SCROLL_SENSIBILITY_RIGHT = 30;
    this.SCROLL_SENSIBILITY_LEFT = -30; // Keep negative values

    this.selectors = {
      self: `[data-${this.NS}-is="${this.IS}"]`
    };

    this.properties = {
      /**
                   * Carousel Type - Determines the number of elements to be displayed in the slide
                   *
                   * @memberof Carousel
                   * @type {Number}
                   * @default 5000
                   */
      carouselType: {
        default: 5,
        transform: (value) => {
          value = parseFloat(value);
          return !isNaN(value) ? value : null;
        }
      }
    };

    this.calloutItem = document.querySelectorAll('.cmp-teaser__cell');
    if (document.readyState !== CoreJS.DomEventConstants.READY_STATE_LOADING) {
      this.onDocumentReady();
    } else {
      document.addEventListener(CoreJS.DomEventConstants.DOM_CONTENT_LOADED, this.onDocumentReady);
    }
  }

  /**
     * Carousel
     *
     * @class Carousel
      * @classdesc An interactive Carousel component for navigating a list of generic items
     * @param {CarouselConfig} config The Carousel configuration
     */
  carousel(config) {
    /**
           * Caches the Carousel elements as defined via the {@code data-carousel-hook="ELEMENT_NAME"} markup API
           *
           * @private
           * @param {HTMLElement} wrapper The Carousel wrapper element
           */
    const cacheElements = (wrapper) => {
      this._elements = {};
      this._elements.self = wrapper;
      const hooks = this._elements.self.querySelectorAll(
        `[data-${this.NS}-hook-${this.IS}]`
      );
      hooks.forEach((hook) => {
        const key = hook.dataset.cmpHookAceCarousel;
        if (this._elements[key]) {
          if (!Array.isArray(this._elements[key])) {
            const tmp = this._elements[key];
            this._elements[key] = [tmp];
          }
          this._elements[key].push(hook);
        } else {
          this._elements[key] = hook;
        }
      });
    };

    /**
           * Sets up properties for the Carousel based on the passed options.
           *
           * @private
           * @param {Object} options The Carousel options
           */
    const setupProperties = (options) => {
      this._properties = {};

      for (const key in this.properties) {
        if (this.properties.hasOwnProperty(key)) {
          const property = this.properties[key];
          let value = null;

          if (options && options[key] !== null) {
            value = options[key];

            // transform the provided option
            if (property && typeof property.transform === 'function') {
              value = property.transform(value);
            }
          }

          if (value === null) {
            // value still null, take the property default
            value = this.properties[key].default;
          }

          this._properties[key] = value;
        }
      }
    };

    /**
           * Refreshes the item markup based on the current {@code Carousel#_active} index
           *
           * @private
           */
    const accessibilty = () => {
      const itemActive = this._contentEl.querySelectorAll('li.ace-carousel__item:not(.filter-li-hide)');
      const minStep = (this._active - 1) * this._activeItemCount;
      const maxStep = Math.min(minStep + this._activeItemCount - 1, itemActive.length - 1);
      itemActive.forEach((item, index) => {
        const getLink = item.querySelector('.cmp-teaser__action--link');
        const isVisible = index >= minStep && index <= maxStep;
        if (isVisible) {
          item.classList.remove('hide');
          if (getLink) getLink.setAttribute('tabindex', '0');
        } else {
          item.classList.add('hide');
          if (getLink) getLink.setAttribute('tabindex', '-1');
        }
      });
    };

    /**
           * Refreshes the componen
           *
           * @private
           * @param {Boolean} resize if call due to resize
           */
    const refreshActive = (resize = false) => {
      let tempItems = [];
      const { item } = this._elements;
      if (item) {
        if (Array.isArray(item)) {
          item.forEach((subItem) => {
            if (window.getComputedStyle(subItem).display !== 'none') {
              tempItems.push(subItem);
            }
          });
        } else {
          if (window.getComputedStyle(item).display !== 'none') {
            tempItems.push(item);
          }
        }
      }
      if (resize === true && window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        tempItems = this.resizeTempVar;
      }
      this._contentEl = this._elements.self.querySelector('.ace-carousel__content');
      this._actions = this._elements.self.querySelector('.ace-carousel__actions');
      this._actionContentEl = this._elements.self.querySelector('.ace-carousel__action-content');
      this.resizeTempVar = tempItems;
      if (tempItems && this._actions) {
        this._contentEl.scrollLeft = 0;
        this._actions.classList.add('show');

        if (Array.isArray(tempItems)) {
          const totalItems = tempItems.length;
          this._carouselIndexSteps = Math.ceil(totalItems / this._activeItemCount);
          this._active = 1;

          this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('disabled', '');
          this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', 'Previous button is disabled');
          this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);

          // Hide actions (controls) when only one slide present
          if (this._active === this._carouselIndexSteps) {
            this._actions.classList.remove('show');
          } else {
            this._actionContentEl.innerHTML = `${this._active}/${this._carouselIndexSteps}`;
          }

          // Based on breakpoint apply flex
          tempItems.forEach((item) => {
            if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
              item.classList.add('carousel-display');
            } else if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
              // For multiple variation of number of callout to be displayed apply flex
              if (this._properties.carouselType == 4) {
                item.classList.add('carousel-display');
              } else if (this._properties.carouselType == 3) {
                item.classList.add('carousel-display-3');
              } else {
                item.classList.add('carousel-display-2');
              }
            }
          });
        } else {
          this._actions.classList.remove('show');
        }
      }

      accessibilty();
    };
    /**
           * Setting value aria attributes
           *
           * @private
           */
    const ariaValueForButtons = () => {
      if (this._active === 1) {
        this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('disabled', '');
        this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', 'Previous button is disabled');
        this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');
      } else if (this._active === this._carouselIndexSteps) {
        this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('disabled', '');
        this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', 'Next button is disabled');
        this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', `Previous, go to the ${this._active - 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--previous').removeAttribute('disabled');
      } else {
        this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', `Previous, go to the ${this._active - 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);
        this._elements.self.querySelector('.ace-carousel__action--previous').removeAttribute('disabled');
        this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');
      }
    };
    /** refresh on onlt tab change in filter */
    const refreshOnTabChange = () => {
      const tempItems = [];
      const { item } = this._elements;
      if (item) {
        if (Array.isArray(item)) {
          item.forEach((subItem) => {
            if (!subItem.classList.contains('filter-li-hide')) {
              subItem?.classList?.remove('even-filter-callout');
              subItem?.classList?.remove('odd-filter-callout');
              tempItems.push(subItem);
            }
          });
        } else {
          if (!item.classList.contains('filter-li-hide')) {
            item?.classList?.remove('even-filter-callout');
            item?.classList?.remove('odd-filter-callout');
            tempItems.push(item);
          }
        }
      }
      this._contentEl = this._elements.self.querySelector('.ace-carousel__content');
      this._actions = this._elements.self.querySelector('.ace-carousel__actions');
      this._actionContentEl = this._elements.self.querySelector('.ace-carousel__action-content');

      if (tempItems && this._actions) {
        this._contentEl.scrollLeft = 0;
        this._actions.classList.add('show');

        if (Array.isArray(tempItems)) {
          const totalItems = tempItems.length;
          this._carouselIndexSteps = Math.ceil(totalItems / this._activeItemCount);
          this._active = 1;

          this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('disabled', '');
          this._elements.self.querySelector('.ace-carousel__action--previous').setAttribute('aria-label', 'Previous button is disabled');
          this._elements.self.querySelector('.ace-carousel__action--next').setAttribute('aria-label', `Next, go to the ${this._active + 1}/${this._carouselIndexSteps} slide`);

          // Hide actions (controls) when only one slide present
          if (this._active === this._carouselIndexSteps) {
            this._actions.classList.remove('show');
          } else {
            this._actionContentEl.innerHTML = `${this._active}/${this._carouselIndexSteps}`;
          }

          // Based on breakpoint apply flex
          tempItems.forEach((item, i) => {
            if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
              item.classList.add('carousel-display');
              if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
                if ((i + 1) % 2 == 0) {
                  item?.classList?.add('even-filter-callout');
                  if (i === tempItems?.length - 1) {
                    item?.classList?.add('tab-filter-last-even');
                  }
                } else {
                  item?.classList?.add('odd-filter-callout');
                  if (i === tempItems?.length - 1) {
                    item?.classList?.add('tab-filter-last-odd');
                  }
                }
              } else if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
                if (i === tempItems?.length - 1) {
                  item?.classList?.add('mobile-filter-last-item');
                }
              }
            } else if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
              // For multiple variation of number of callout to be displayed apply flex
              if (this._properties?.carouselType == 4) {
                item?.classList?.add('carousel-display');
              } else if (this._properties?.carouselType == 3) {
                item?.classList?.add('carousel-display-3');
              } else {
                item?.classList?.add('carousel-display-2');
              }
            }
          });
        } else {
          this._actions.classList.remove('show');
        }
      }

      accessibilty();
      ariaValueForButtons();
    };

    /**
           * Check if filter tab is applied
           * @private
           * @return {Boolean} return true if filter tab is applied
           */
    const isFilterTab = () => {
      let returnData = false;
      const calloutContainerApi = this.componentHost?.closest('.callout-container-api');
      const calloutContainerChip = this.componentHost?.parentNode?.parentNode?.previousElementSibling?.querySelector('.ace-chip');
      if (calloutContainerApi?.querySelector('.ace-callout-container-filters__tab-nav') || calloutContainerChip) {
        returnData = true;
      }
      return returnData;
    };

    const toggleOnLastPage = () => {
      if (this._active == this._carouselIndexSteps && this._active != 1) {
        this._contentEl.classList.add('onLastPage');
      } else {
        this._contentEl.classList.remove('onLastPage');
      }
    };

    /**
           * Native Mobile swipe handling pagination
           *
           * @private
           */
    const mobileSwipeEvent = () => {
      const thresholdDefinition = {
        threshold: 0.6
      };
      this.welcomeObserver = new IntersectionObserver((item) => {
        item.forEach((entry) => {
          if (entry.isIntersecting) {
            const displayId = parseInt(entry.target.getAttribute('display-id'));
            this._active = window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md ? displayId + 1 : Math.floor(displayId / 2) + 1;
            this._actionContentEl.innerHTML = `${this._active}/${this._carouselIndexSteps}`;
            toggleOnLastPage();
            ariaValueForButtons();
            accessibilty();
          }
        });
      }, thresholdDefinition);
      let itemList;
      if (isFilterTab()) {
        itemList = this.componentHost.querySelectorAll(' li.ace-carousel__item:not(.filter-li-hide)');
      } else {
        itemList = this.componentHost.querySelectorAll('.ace-carousel__item');
      }
      itemList?.forEach((item, index) => {
        item.setAttribute('display-id', index);
        if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          if (index == itemList.length - 1 && itemList.length % 2 == 0) {
            item.classList.add('even');
          }
        }
        this.welcomeObserver?.observe(item);
      });
    };

    /**
           * Increment pagination value
           *
           * @private
        */
    const getNextIndex = () => {
      this._elements.self.querySelector('.ace-carousel__action--previous').removeAttribute('disabled');

      if (this._active + 1 > this._carouselIndexSteps) {
        this._active = 1;
      } else {
        this._active += 1;
      }
    };

    /**
           * Increment pagination value for Tab and mobile
           *
           * @private
        */
    const getNextIndexSwipe = () => {
      this._elements.self.querySelector('.ace-carousel__action--previous').removeAttribute('disabled');

      if (this._active + 1 > this._carouselIndexSteps) {
        this._active = 1;
      } else {
        this._active += 1;
      }
      const ifRightToLeft = document.querySelector('html').getAttribute('dir');
      if (ifRightToLeft == 'rtl') {
        if (isFilterTab()) {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft -= 2 * this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft -= this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          }
        } else {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft -= 2 * this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft -= this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          }
        }
      } else {
        if (isFilterTab()) {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft += 2 * this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft += this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          }
        } else {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft += 2 * this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft += this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          }
        }
      }


      this._actionContentEl.innerHTML = `${this._active}/${this._carouselIndexSteps}`;
      ariaValueForButtons();
    };

    /**
           * Decrement pagination value for Tab and mobile
           *
           * @private
        */
    const getPreviousIndexSwipe = () => {
      this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');

      if (this._active - 1 < 1) {
        this._active = this._carouselIndexSteps;
      } else {
        this._active -= 1;
      }

      const ifRightToLeft = document.querySelector('html').getAttribute('dir');
      if (ifRightToLeft == 'rtl') {
        if (isFilterTab()) {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft += 2 * this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft += this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          }
        } else {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft += 2 * this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft += this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          }
        }
      } else {
        if (isFilterTab()) {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft -= 2 * this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft -= this.componentHost.querySelector(' li.ace-carousel__item:not(.filter-li-hide)')?.offsetWidth;
          }
        } else {
          if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md && window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._contentEl.scrollLeft -= 2 * this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          } else {
            this._contentEl.scrollLeft -= this.componentHost.querySelector('.ace-carousel__item')?.offsetWidth;
          }
        }
      }

      this._actionContentEl.innerHTML = `${this._active}/${this._carouselIndexSteps}`;
      ariaValueForButtons();
    };

    /**
           * Decrement pagination value
           *
           * @private
        */
    const getPreviousIndex = () => {
      this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');

      if (this._active - 1 < 1) {
        this._active = this._carouselIndexSteps;
      } else {
        this._active -= 1;
      }
    };

    /**
           * Navigates to the item at the provided index
           *
           * @private
           */
    const navigate = () => {
      const active = Math.max(1, this._active || 1);
      const activeItemCount = Math.max(1, Math.floor(this._activeItemCount || 0));
      const index = (active - 1) * activeItemCount;
      if (index < 0 || index >= this._elements.item.length) {
        console.error(`Index out of bounds: ${index}`);
        return;
      }
      const element = this._elements.item[index];
      const content = this._contentEl;
      content.scrollLeft = element ? element.offsetLeft : 0;
      this._actionContentEl.setAttribute('data-step', active);
      this._actionContentEl.innerHTML = `${active}/${this._carouselIndexSteps}`;
      toggleOnLastPage();
      ariaValueForButtons();
      accessibilty();
    };

    /**
           * Navigates to the next slides
           *
           * @private
           */
    const navigateToNext = () => {
      if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        getNextIndex();
        navigate();
      } else {
        getNextIndexSwipe();
      }
    };

    /**
           * Navigates to the previous slides
           *
           * @private
           */
    const navigateToPrevious = () => {
      if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        getPreviousIndex();
        navigate();
      } else {
        getPreviousIndexSwipe();
      }
    };

    /**
           * Handles carousel keydown events
           *
           * @private
           * @param {Object} event The keydown event
           */
    const onKeyDown = (event) => {
      const index = this._active;
      const elements = this._elements.indicator;
      if (elements) {
        const lastIndex = elements.length - 1;

        switch (event.keyCode) {
          case this.keyCodes.ARROW_LEFT:
          case this.keyCodes.ARROW_UP:
            event.preventDefault();
            if (index > 0) {
              this.navigateAndFocusIndicator(index - 1);
            }
            break;
          case this.keyCodes.ARROW_RIGHT:
          case this.keyCodes.ARROW_DOWN:
            event.preventDefault();
            if (index < lastIndex) {
              this.navigateAndFocusIndicator(index + 1);
            }
            break;
          case this.keyCodes.HOME:
            event.preventDefault();
            this.navigateAndFocusIndicator(0);
            break;
          case this.keyCodes.END:
            event.preventDefault();
            this.navigateAndFocusIndicator(lastIndex);
            break;
          default:
            return;
        }
      }
    };

    /**
           * Binds Carousel event handling
           *
           * @private
           */
    const bindEvents = () => {
      if (this._elements.previous) {
        this._elements.previous.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          navigateToPrevious();
        });
      }
      if (this._elements.next) {
        this._elements.next.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          navigateToNext();
        });
      }
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        mobileSwipeEvent();
      }
      this._elements.self.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, onKeyDown);
    };
    /**
     * Filter Carousel event handling
     * @private
     */
    const filterControl = () => {
      const filters = this._elements.filter;
      if (filters) {
        if (Array.isArray(filters)) {
          filters.forEach((item) => {
            item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
              refreshActive();
            });
          });
        } else {
          filters.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            refreshActive();
          });
        }
      }
    };

    /**
           * Handles windows resizing for responsive behavior
           *
           * @private
           * @param {Object} event The keydown event
           */
    const onWindowResize = () => {
      this._elements.self.querySelector('.ace-carousel__action--next').removeAttribute('disabled');

      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        this._activeItemCount = 1;
      } else if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this._activeItemCount = 2;
      } else {
        this._activeItemCount = this._properties.carouselType;
      }
      refreshActive(true);
    };
    const checkNumberOfTabs = () => {
      const numberofAPIcomponents = this.componentHost?.closest('.callout-container-api');
      const numberOfTabs = numberofAPIcomponents?.querySelectorAll('.ace-callout-container-filters__tab-nav .ace-callout-container-filters__tab-list .ace-callout-container-filters__tab-item');
      if (numberOfTabs?.length == 1) {
        const listOfTabs = numberofAPIcomponents?.querySelectorAll('.ace-callout-container-filters__tab-nav .ace-callout-container-filters__tab-list');
        listOfTabs[0].style.display = 'none';
        const mobileDropdown = numberofAPIcomponents?.querySelectorAll('.ace-callout-container-filters__tab-nav .ace-callout-container-filters__tab-dropdown');
        mobileDropdown[0].style.display = 'none';
      }
    };

    const changeTabEvent = () => {
      const calloutContainerApi = this.componentHost?.closest('.callout-container-api');
      const horizontalTabFilterItem = calloutContainerApi?.querySelectorAll('.ace-callout-container-filters__tab-nav  .ace-callout-container-filters__tab-item');
      const select = calloutContainerApi?.querySelectorAll('.ace-callout-container-filters__tab-nav .ace-callout-container-filters__tab-dropdown select');
      select?.forEach((item) => {
        item.addEventListener(CoreJS.DomEventConstants.CHANGE, () => {
          refreshOnTabChange();
          mobileSwipeEvent();
          refreshActive();
        });
      });
      horizontalTabFilterItem?.forEach((item) => {
        item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          refreshOnTabChange();
          mobileSwipeEvent();
        });
      });
      const btns = this.componentHost?.parentNode.parentNode.previousElementSibling.querySelectorAll('.ace-chip__list .ace-chip--button');
      btns?.forEach((btn) => {
        btn.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          setTimeout(() => {
            refreshOnTabChange();
            // For tab and mobile require swipe and refresh active
            if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
              mobileSwipeEvent();
              refreshActive();
            }
          }, 10);
        });
      });
    };
    /**
     * Initializes the Carousel
     *
     * @private
     * @param {CarouselConfig} initialConfig The Carousel configuration
     */
    const init = (initialConfig) => {
      // prevents multiple initialization
      initialConfig.element.removeAttribute(`data-${this.NS}-is`);

      setupProperties(initialConfig.options);
      cacheElements(initialConfig.element);

      this._active = 1;
      this._activeItemCount = this._properties.carouselType;
      if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        this._activeItemCount = 1;
      } else if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this._activeItemCount = 2;
      }

      if (this._elements.item) {
        refreshActive();
        bindEvents();
        filterControl();
        if (isFilterTab()) {
          changeTabEvent();
          checkNumberOfTabs();
          refreshOnTabChange();
        }
      }

      if (
        window.Granite &&
        window.Granite.author &&
        window.Granite.author.MessageChannel
      ) {
        window.CQ = window.CQ || {};
        window.CQ.CoreComponents = window.CQ.CoreComponents || {};
        window.CQ.CoreComponents.MESSAGE_CHANNEL =
          window.CQ.CoreComponents.MESSAGE_CHANNEL ||
          new window.Granite.author.MessageChannel('cqauthor', window);
        window.CQ.CoreComponents.MESSAGE_CHANNEL.subscribeRequestMessage(
          'cmp.panelcontainer',
          (message) => {
            if (
              message.data &&
              message.data.type === 'ace-carousel' &&
              message.data.id ===
              this._elements.self.dataset.cmpPanelcontainerId
            ) {
              if (message.data.operation === 'navigate') {
                this._active =
                  Math.floor(message.data.index / this._activeItemCount) + 1;
                if (this._active > this._carouselIndexSteps) {
                  this._active = this._carouselIndexSteps;
                }
                navigate();
              }
            }
          }
        );
      }

      // Responsive event handling
      window.addEventListener(CoreJS.DomEventConstants.RESIZE, onWindowResize.bind(this));
    };
    if (config && config.element) {
      init(config);
    }
  }

  /**
         * Reads options data from the Carousel wrapper element, defined via {@code data-cmp-*} data attributes
         *
         * @private
         * @param {HTMLElement} element The Carousel element to read options data from
         * @return {Object} The options read from the component data attributes
         */
  readData(element) {
    const data = element.dataset;
    const options = [];
    let capitalized = this.IS;
    capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
    const reserved = ['is', `hook${capitalized}`];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (key.indexOf(this.NS) === 0) {
          let newKey = key.slice(this.NS.length);
          newKey = newKey.charAt(0).toLowerCase() + newKey.substring(1);

          if (reserved.indexOf(newKey) === -1) {
            options[newKey] = value;
          }
        }
      }
    }
    return options;
  }

  /**
         * Document ready handler and DOM mutation observers. Initializes Carousel components as necessary.
         *
         * @private
         */
  onDocumentReady() {
    this.carousel({ element: this.componentHost.querySelector(`.${this.IS}`), options: this.readData(this.componentHost.querySelector(`.${this.IS}`)) });
  }
}
// Register the Carousel component with the BaseComponent
CoreJS.BaseComponent.registerComponent(CarouselV2.CLASS_NAMESPACE, CarouselV2);
