import { Error as UserError } from '../user'
import axiosAllAccorApi from '../http/axiosAllAccorApi'
import { API_CONTACT_URL } from '../common/constants'

const getContact =
  (requestConfig = {}) =>
  async (requestOptions = {}) => {
    return axiosAllAccorApi
      .get(API_CONTACT_URL, {
        ...requestConfig,
        ...requestOptions,
      })
      .then(({ data }) => {
        data.fields = requestOptions.fields
        data.filter = requestOptions.filter

        return data
      })
      .catch(() => {
        throw UserError.GetUserFailed
      })
  }

const defaultEndpoint = 'profile'

const putContact =
  (requestConfig = {}) =>
  async (requestOptions = {}) => {
    const {
      service = defaultEndpoint,
      payload,
      ...getTokenOptions
    } = requestOptions

    const url = API_CONTACT_URL + '/' + service

    return axiosAllAccorApi
      .put(url, payload, {
        ...getTokenOptions,
        ...requestConfig,
      })
      .then(({ data }) => ({ success: true, data }))
  }

const deleteContactItem =
  (requestConfig = {}) =>
  async (requestOptions = {}) => {
    const { service, id, ...getTokenOptions } = requestOptions

    const getId = idOption => {
      if (idOption === 'all') return ''
      if (idOption) return `/${idOption}`
      return '/undefined'
    }

    const myId = getId(id)
    const url = API_CONTACT_URL + '/' + service + myId

    return axiosAllAccorApi
      .delete(url, { ...getTokenOptions, ...requestConfig })
      .catch(() => {
        throw UserError.DeleteUserFailed
      })
  }

export { getContact, putContact, deleteContactItem }
