export { getPlans, getLatamAuthUrl }

const getPlans = () =>
  Axios.get(API_ACCOR_COM + '/latam/v1/plans?active=true', {
    headers: { apikey: PROD_API_KEY },
  }).then(({ data }) => data)

const getLatamAuthUrl = ({
  freq,
  plan,
  lang = getLang(),
  state = 'register',
  origin = 'all-signature',
}) =>
  getAuthUrl({
    state,
    origin,
    lang,
    mode: EnrollMode.SecurityLink,
    redirect: attachDefinedQueryParamsToString(
      `${ALL_ACCOR_COM}/enroll-loyalty/index.${lang}.shtml#${ALL_SIGNATURE_SUMMARY}`,
      { plan, freq }
    ),
  })

import {
  PROD_API_KEY,
  ALL_ACCOR_COM,
  API_ACCOR_COM,
  ALL_SIGNATURE_SUMMARY,
} from '../../common/constants.js'

import {
  getLang,
  attachDefinedQueryParamsToString,
} from '../../common/utils.js'

import Axios from 'axios'
import { getAuthUrl, EnrollMode } from '../../oidc/getAuthUrl.js'
