/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/**
 *
 * @author Alpana
 * @class ace-currency-selector
 * @version 1.0
 * @inheritDoc
 */
import TagManager from '@accor/core-services/dist/gtm/index';

export default class CurrencySelector extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-currency-selector';
  static CURRENCY_SESSION_NAME = 'POS.currencyDisplay';
  static CURRENCY_ZONE_COOKIE_NAME = 'userCurrency';
  static CURRENCY_COOKIE_DOMAIN = '.accor.com';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.currencySelectorHooks();
    this.currencySelectorBindEvents();
    this.currencySelectorApiHandler(CoreJS.DomEventConstants.ON_LOAD);
    CurrencySelector.currencyFirstDropHtml();
  }

  currencySelectorHooks() {
    this.languageButton = this.componentHost.querySelector(`.${CurrencySelector.CLASS_NAMESPACE}__button`);
    this.languageModal = this.componentHost.querySelector(`.${CurrencySelector.CLASS_NAMESPACE}__modal`);
    this.redirectButton = this.languageModal?.querySelector('.currencydropdown__btn');
    CurrencySelector.languageButtonSymbol = this.languageButton?.querySelector(`.${CurrencySelector.CLASS_NAMESPACE}__symbol-value`);
    CurrencySelector.languageButtonLabel = this.languageButton?.querySelector(`.${CurrencySelector.CLASS_NAMESPACE}__label-value`);
    CurrencySelector.currencyFirstSSD = this.languageModal?.querySelector('.currency-first-ssd');
    CurrencySelector.currencySecondSSD = this.languageModal?.querySelector('.currency-second-ssd');
  }

  currencySelectorBindEvents() {
    this.redirectButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.currencySelectorApiHandler(CoreJS.DomEventConstants.CLICK);
    });
  }

  currencySelectorApiHandler(event) {
    const { siteCode, apiUrlCurrencyManual, apiUrlPosCurrencies, key } = JSON.parse(
      document.body?.dataset?.posHandler
    );
    this.sessionExist = CurrencySelector.getCurrencySession(CurrencySelector.CURRENCY_SESSION_NAME);
    if (
      siteCode &&
            apiUrlPosCurrencies &&
            key &&
            !this.sessionExist &&
            event !== CoreJS.DomEventConstants.CLICK
    ) {
      const apiUrlManualFinal = `${apiUrlPosCurrencies}?${'groupByContinent=true'}`;
      new CoreJS.XHRPromisifiedRequest(
        apiUrlManualFinal,
        CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET
      )
        .setRequestHeaders({ apikey: key })
        .executeRequest()
      // Do nothing, but xhr call has worked
        .then(this.currencyResponseStorage)
        .catch((err) => console.error('Augh, there was an error!', err));
    } else if (
      siteCode &&
            apiUrlCurrencyManual &&
            key &&
            event === CoreJS.DomEventConstants.CLICK
    ) {
      this.secondDropSelectedValue = CurrencySelector.currencySecondSSD?.options[CurrencySelector.currencySecondSSD.selectedIndex];
      const { currency } = this.secondDropSelectedValue?.dataset;
      const datasEvent = {
        event: 'GAevent',
        eventAction: 'Menu - Select currency - Click on CTA',
        eventCategory: 'Navigation',
        eventLabel: `Validate - ${currency}`
      };
      TagManager.trackEvent(datasEvent);
      const paramsObj = { 'currencyCode': currency };
      const apiUrlParams = new URLSearchParams(paramsObj);
      const apiUrlManualFinal = `${apiUrlCurrencyManual}?${apiUrlParams.toString()}`;

      function redirectToNextPage(cRes) {
        const cData = JSON.parse(cRes.responseText);

        const cookieAccorDomain = '.accor.com';
        const today = new Date();
        today.setFullYear(today.getFullYear() + 1);
        CoreJS.CookieHelper.createMaxAgeCookie(CurrencySelector.CURRENCY_ZONE_COOKIE_NAME, cData.currencyCode, today.toGMTString(), CoreJS.CookieHelper.COOKIE_PATH_DEFAULT, CoreJS.CookieHelper.COOKIE_SECURE_DEFAULT, cookieAccorDomain);
        window.location.reload();
      }

      new CoreJS.XHRPromisifiedRequest(
        apiUrlManualFinal,
        CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET
      )
        .setRequestHeaders({ apikey: key })
        .executeRequest()
      // Do nothing, but xhr call has worked
        .then(redirectToNextPage)
        .catch((err) => console.error('Augh, there was an error!', err));
    }
  }

  currencyResponseStorage(res) {
    const data = JSON.parse(res.responseText);
    new CoreJS.SessionStorageMgt(CurrencySelector.CURRENCY_SESSION_NAME, data, 1440).sessionSet();
    CurrencySelector.currencyFirstDropHtml();
  }

  static getCurrencyCookie(key) {
    return CoreJS.CookieHelper.getCookieValue(key);
  }

  static getCurrencySession(name) {
    return new CoreJS.SessionStorageMgt(name).sessionGet();
  }

  static currencyFirstDropHtml() {
    const sessionData = CurrencySelector.getCurrencySession(CurrencySelector.CURRENCY_SESSION_NAME);
    const displayZoneCookie = CurrencySelector.getCurrencyCookie(CurrencySelector.CURRENCY_ZONE_COOKIE_NAME);
    if (sessionData) {
      let dynamicContinentOptionMarkup = '';
      let defaultSelectedContinent = false;
      for (const continent of sessionData) {
        for (const currency of continent.currencyList) {
          if (displayZoneCookie && currency.currencyCode === displayZoneCookie) {
            defaultSelectedContinent = true;
          } else if (!displayZoneCookie && currency.currencyCode === 'EUR') {
            defaultSelectedContinent = true;
          }
        }
        this.selectedAttribute = defaultSelectedContinent ? 'selected' : '';
        dynamicContinentOptionMarkup += `<option ${this.selectedAttribute} data-id="${continent.continentLabel}" value="${continent.continentLabel}">
          ${continent.continentLabel}
        </option>`;
        defaultSelectedContinent = false;
      }
      CurrencySelector.currencyFirstSSD?.insertAdjacentHTML('beforeend', dynamicContinentOptionMarkup);
      this.firstDropSelectedValue = CurrencySelector.currencyFirstSSD?.options[CurrencySelector.currencyFirstSSD.selectedIndex].value;
      CurrencySelector.currencySecondDropHtml(this.firstDropSelectedValue, sessionData, displayZoneCookie, CoreJS.DomEventConstants.ON_LOAD);
      CurrencySelector.currencyFirstSSD?.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
        this.firstDropChangeValue = event.target.options[event.target.selectedIndex].value;
        CurrencySelector.currencySecondDropHtml(this.firstDropChangeValue, sessionData, displayZoneCookie, CoreJS.DomEventConstants.CHANGE);
      });
    }
  }

  static currencySecondDropHtml(firstDropSelectedValue, sessionData, displayZoneCookie, event) {
    if (sessionData) {
      let dynamicCurrencyOptionMarkup = '';
      let defaultSelectedCurrency = false;
      let currencyMLabel = '';
      let currencyMSymbol = '';
      for (const continent of sessionData) {
        if (continent.continentLabel === firstDropSelectedValue) {
          for (const currency of continent.currencyList) {
            if (displayZoneCookie &&
                            currency.currencyCode === displayZoneCookie &&
                            event !== CoreJS.DomEventConstants.CHANGE) {
              defaultSelectedCurrency = true;
              currencyMLabel = currency.currencyCode;
              currencyMSymbol = currency.currencySymbol;
            } else if (!displayZoneCookie && currency.currencyCode === 'EUR') {
              defaultSelectedCurrency = true;
              currencyMLabel = currency.currencyCode;
              currencyMSymbol = currency.currencySymbol;
            }
            this.selectedSecondAttribute = defaultSelectedCurrency ? 'selected' : '';
            dynamicCurrencyOptionMarkup += `<option ${this.selectedSecondAttribute} data-continent="${continent.continentLabel}"
              data-currency="${currency.currencyCode}"
              value="${currency.currencyCode}">
              ${currency.currencyCode} - ${currency.currencyLabel}
            </option>`;
            defaultSelectedCurrency = false;
          }
        }
      }
      CurrencySelector.clearCustomFieldMarkup(CurrencySelector.currencySecondSSD);
      CurrencySelector.currencySecondSSD?.insertAdjacentHTML('beforeend', dynamicCurrencyOptionMarkup);

      function handleHeaderCurrencyChange() {
        CurrencySelector.clearCustomFieldMarkup(CurrencySelector.languageButtonLabel);
        CurrencySelector.clearCustomFieldMarkup(CurrencySelector.languageButtonSymbol);
        CurrencySelector.languageButtonLabel.innerHTML = `${currencyMLabel}`;
        if (currencyMSymbol) {
          CurrencySelector.languageButtonSymbol.innerHTML = `(${currencyMSymbol})`;
        }
      }

      if (event != CoreJS.DomEventConstants.CHANGE) {
        handleHeaderCurrencyChange();
      }
    }
  }

  static clearCustomFieldMarkup(elementID) {
    if (elementID) {
      elementID.innerHTML = '';
    }
  }
}

CoreJS.BaseComponent.registerComponent(CurrencySelector.CLASS_NAMESPACE, CurrencySelector);
