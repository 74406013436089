import { Callout, TagManager } from '@accor/ace-ui-core';

export default class AllCallout extends Callout {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.setOfferCalloutClickListener();
    // ACDAA-14125 Offer Variation Home Page Tagging Plan
    this.setOfferVariationTaggingPlan();
    this.setCarouselCobrandClickListener();
  }

  /*
      Function to overwrite tagmanager for Cobrand
    */
  setOfferCalloutClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const blockTitle = this.componentHost.querySelector('.cmp-teaser__content h3')?.innerText;
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.querySelector('.cmp-teaser__content .cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: blockTitle?.toLowerCase(),
              bloc_interaction: 'see details'
            }
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      }
    }
  }

  /**
   * Data layer attributes based on the click event in callout
   */
  setCarouselCobrandClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const blockTitle = this.componentHost.querySelector('.cmp-teaser__content h3')?.innerText;
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'reward points offers',
              bloc_interaction: blockTitle?.toLowerCase()
            }
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      }
    }
  }
  // ACDAA-14125 Offer Variation Home Page Tagging Plan
  setOfferVariationTaggingPlan() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer && dataLayer.brandContext === 'all') {
      const offerClosest = this.componentHost?.closest('.offer-variation');
      if (pageToTarget === 'homepage' && offerClosest) {
        this.componentHost?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
          const position = this.componentHost?.closest('li').id.split('-')[1];
          let NewPosition = parseInt(position);
              NewPosition = NewPosition + 1;
          const offerId = e.currentTarget.dataset.offerid;
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'thematic offers',
              bloc_interaction: '',
              bloc_type: `${offerId || NewPosition.toString()}`,
              context: ''
            }
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      }
    }    
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(Callout.CLASS_NAMESPACE, AllCallout, true);
