import { TagManager } from '@accor/ace-ui-core';

export default class BasicPageTemplate extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'basic-page';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    if (this.dataLayers) {
      this.datalayerValues = JSON.parse(this.dataLayers);
      this.pageName = this.datalayerValues.pageName.split('::');
      this.legalTracking();
    }
  }

  async legalTracking() {
    const datasEvent = { category: 'legal' };
    TagManager.trackPage(`legal::${this.datalayerValues?.pageName}`, datasEvent);
    setTimeout(() => {
      const duplicated = window.dataLayer.findIndex((el) => el.category === 'resorts' && el.clienttype === 'B2C');
      window.dataLayer.splice(duplicated, 1);
    }, 2500);
  }
}

CoreJS.BaseComponent.registerComponent(BasicPageTemplate.CLASS_NAMESPACE, BasicPageTemplate, true);
