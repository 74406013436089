/**
 *
 * @author Gunasundari
 * @class Mantra Long Stay Enquiry Form
 * @version 1.0
 * @inheritDoc
 */

export default class LongStayForm extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-long-stay-form';
  static ERROR_URL = '/content/mantra/en/long-stay/enquire-error.html';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.i18nStrings = {};
    const i18 = this.componentHost.querySelector('[data-i18n]')?.getAttribute('data-i18n');
    if (i18) {
      this.i18nStrings = JSON.parse(i18);
    }
    this.errorStrings = [];
    this.formErrorCounter = 0;
    this.rootStyle = document.querySelector(':root');

    this.pickers = this.componentHost.querySelectorAll('duet-date-picker');
    if (this.pickers) {
      const mutationConfig = {
        childList: true,
        subtree: true
      };
      this.pickers.forEach((picker) => {
        const observer = new MutationObserver(() => {
          const readyPickers = this.componentHost.querySelectorAll('duet-date-picker.hydrated .duet-date__input');
          if (readyPickers.length === 2) {
            this.formSelector();
            this.bindFormEvents();
            this.errorSelector();
            this.countryCodeapiCall();
          }
        });
        observer.observe(picker, mutationConfig);
      });
    };
  }

  formSelector() {
    this.form = this.componentHost.querySelector('form');
    this.longstay = this.componentHost;
    this.name = this.componentHost.querySelector('.form-control.name input');
    this.email = this.componentHost.querySelector('.form-control.email input');
    this.countrycode = this.componentHost.querySelector('.form-control.phone select');
    this.phone = this.componentHost.querySelector('.form-control.phone input');
    this.company = this.componentHost.querySelector('.form-control.company input');
    this.arrival = this.componentHost.querySelector('input[name="search.dateIn"]');
    this.departure = this.componentHost.querySelector('input[name="search.dateOut"]');
    this.preferredProperty = this.componentHost.querySelector('.form-control.preferred-property select');
    this.preferredPropertyOption = this.preferredProperty.querySelectorAll('option');
    this.budget = this.componentHost.querySelector('.form-control.budget input');
    this.guest = this.componentHost.querySelector('.ace-long-stay-form__guests');
    this.specialNote = this.componentHost.querySelector('.form-control.special-notes textarea');
    this.terms = this.componentHost.querySelector('.ace-long-stay-form__terms-condition input');
    this.submitButton = this.componentHost.querySelector('.ace-long-stay-form .ace-button button');
    this.submitButtonSpan = this.componentHost.querySelector('.ace-long-stay__btn span');
    this.errorBlock = this.componentHost.querySelectorAll('.ace-long-stay-form__error-block');
    this.errorBlocks = this.componentHost.querySelectorAll('.ace-long-stay-form__error-block');
    this.errorBlockList = this.componentHost.querySelector('.ace-long-stay-form__error-block ul');
    this.apartment_type = this.componentHost.querySelector('.content');
    this.apartment_type.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
      this.selectedRadio = event.target.id;
    });
    /**
         *  Select dropdown change event function for preferred property & country code
         */
    this.selectedValue = this.preferredProperty.options[this.preferredProperty.selectedIndex].text;
    this.preferredProperty.addEventListener(CoreJS.DomEventConstants.CHANGE, () => {
      this.selectedValue = this.preferredProperty.options[this.preferredProperty.selectedIndex].text;
    });

    this.specialNote?.addEventListener(CoreJS.DomEventConstants.KEY_PRESS, (event) => {
      if (event.keyCode == CoreJS.Constants.KEY_CODES.space && !event.target.value) {
        event.returnValue = false;
        return false;
      }
    });

    this.name?.addEventListener(CoreJS.DomEventConstants.KEY_PRESS, (event) => {
      if (event.keyCode == CoreJS.Constants.KEY_CODES.space && !event.target.value) {
        event.returnValue = false;
        return false;
      }
    });
    /**
         *  Set datepicker placeholder - (DD-MM-YYYY)
         */
    this.checkinDatePicker = this.componentHost.querySelector("[data-date='checkin']");
    this.checkoutDatePicker = this.componentHost.querySelector("[data-date='checkout']");
    this.minimumCheckInDate = this.checkinDatePicker.getAttribute('placeholder');
    this.minimumCheckOutDate = this.checkoutDatePicker.getAttribute('placeholder');
    if (this.minimumCheckInDate) {
      this.checkinDatePicker.querySelector('.duet-date__input')?.setAttribute('placeholder', 'DD/MM/YYYY');
    }
    if (this.minimumCheckOutDate) {
      this.checkoutDatePicker.querySelector('.duet-date__input').setAttribute('placeholder', 'DD/MM/YYYY');
    }
  }

  errorSelector() {
    this.nameError = document.querySelector('#error-name');
    this.emailError = document.querySelector('#error-email');
    this.phoneError = document.querySelector('#error-phone');
    this.checkInError = document.querySelector('#error-check-in');
    this.checkOutError = document.querySelector('#error-check-out');
    this.budgetError = document.querySelector('#error-budget');
    this.specialNoteError = document.querySelector('#error-special-notes');
    this.termsError = document.querySelector('#error-terms');
  }

  /**
     * To generate error message
     * @param {String} errorBlock
     * @param {String} id
     * @param {String} message
     * @param {String} componentGroup
     * @param {String} errorGroup
     * @param {String} event
     */
  errorGenerator = (
    errorBlock,
    id,
    message,
    componentGroup,
    errorGroup,
    // eslint-disable-next-line no-unused-vars
    event
  ) => {
    this.formErrorCounter++;
    this.componentHost.querySelector(errorBlock)?.classList.add('invalid');
    this.errorStrings.push({
      errorBlock,
      id,
      message,
      componentGroup,
      errorGroup
    });
  };


  /**
     * @param {Object} formData
     * Name validation
     */
  validateNameField() {
    let NameStatus = false;
    const NameregExp = /^[a-zA-Z ]*$/;
    if (this.name.value !== '' && this.name.value.match(NameregExp)) {
      this.nameError.style.display = 'none';
    } else {
      this.nameError.style.display = 'flex';
      NameStatus = true;
    }
    if (NameStatus) {
      this.errorGenerator('input[id="name"]', 'name', this.i18nStrings.name, 'name', 'name', 'name');
    }
  }

  /**
     * @param {Object} formData
     * Name validation
     */
  validateEmailField() {
    const regExp = /\S+@\S+\.\S+/;
    let EmailStatus = false;
    if (this.email.value !== '' && this.email.value.match(regExp)) {
      this.emailError.style.display = 'none';
    } else {
      this.emailError.style.display = 'flex';
      EmailStatus = true;
    }
    if (EmailStatus) {
      this.errorGenerator('input[id="email"]', 'email', this.i18nStrings.email, 'email', 'email', 'email');
    }
  }

  /**
     * @param {Object} formData
     * Phone validation
     */
  validatePhoneField() {
    let PhoneStatus = false;
    const phoneregExp = /^[0-9]+$/;
    if (this.phone.value !== '' && this.phone.value.match(phoneregExp)) {
      this.phoneError.style.display = 'none';
    } else {
      this.phoneError.style.display = 'flex';
      PhoneStatus = true;
    }
    if (PhoneStatus) {
      this.errorGenerator('input[id="phone"]', 'phone', this.i18nStrings.phone, 'phone', 'phone', 'phone');
    }
  }

  /**
     *  validate stay details
     * @param {Object} formData
     */
  validateArrivalField = () => {
    let checkInStatus = false;
    const checkin = this.componentHost.querySelector('input[name="search.dateIn"]');
    if (!this.checkinDatePicker.getAttribute('data-value-wrong') && checkin.value) {
      this.checkInError.style.display = 'none';
    } else {
      this.checkInError.style.display = 'flex';
      checkInStatus = true;
    }
    if (checkInStatus) {
      this.errorGenerator(
        '#check-in-core-book-engine-12345',
        'check-in-core-book-engine-12345',
        this.i18nStrings.checkin,
        'checkIn',
        'dateFormat',
        'arrival date'
      );
    }
  };

  /**
     * @param {Object} formData
     * Departure validation
     */
  validateDepartureField = () => {
    let checkOutStatus = false;
    const checkout = this.componentHost.querySelector('input[name="search.dateOut"]');
    if (checkout.value && !this.checkoutDatePicker.getAttribute('data-value-wrong')) {
      this.checkOutError.style.display = 'none';
    } else {
      this.checkOutError.style.display = 'flex';
      checkOutStatus = true;
    }
    if (checkOutStatus) {
      this.errorGenerator(
        '#check-out-core-book-engine-12345',
        'check-out-core-book-engine-12345',
        this.i18nStrings.checkout,
        'checkOut',
        'checkout',
        'departure date'
      );
    }
  };

  /**
     * @param {Object} formData
     * Budget validation
     */
  validateBudgetField() {
    let budgetStatus = false;
    const BudgetregExp = /^[0-9]+$/;
    if (this.budget.value !== '' && this.budget.value.length <= 6 && this.budget.value.match(BudgetregExp)) {
      this.budgetError.style.display = 'none';
    } else {
      this.budgetError.style.display = 'flex';
      budgetStatus = true;
    }
    if (budgetStatus) {
      this.errorGenerator('input[id="budget"]', 'budget', this.i18nStrings.budget, 'budget', 'budget', 'budget');
    }
  }

  /**
     * @param {Object} formData
     * Special note validation
     */
  validateSpecialNoteField() {
    let specialNoteStatus = false;
    if (this.specialNote.value.length <= 350) {
      this.specialNoteError.style.display = 'none';
    } else {
      this.specialNoteError.style.display = 'flex';
      specialNoteStatus = true;
    }
    if (specialNoteStatus) {
      this.errorGenerator('textarea[id="special-notes"]', 'special-notes', this.i18nStrings.specialnote, 'special-note', 'special-note', 'special-note');
    }
  }

  /**
     * @param {Object} formData
     * Terms and condition checkbox validation
     */
  validateTermsAndConditionField() {
    let TermsAndConditionStatus = false;
    if (this.terms.checked) {
      this.termsError.style.display = 'none';
    } else {
      TermsAndConditionStatus = true;
    }
    if (TermsAndConditionStatus) {
      this.errorGenerator('input[id="terms-condition"]', 'terms-condition', this.i18nStrings.termscondition, 'terms-condition', 'terms-condition', 'terms-condition');
    }
  }

  /**
     * Error reset handler
     */
  errorResetHanlder = () => {
    this.errorStrings = [];
    this.formErrorCounter = 0;
    this.rootStyle.style.setProperty('--errorHeight', '0');
    this.errorBlocks.forEach((block) => (block.style.display = 'none'));
    this.componentHost.querySelectorAll('.invalid')?.forEach((item) => {
      item.classList.remove('invalid');
    });
  };

  /**
     * Create form data object
     * @return {Object}
     */
  surfaceControlCheck() {
    const formData = new FormData();
    this.validateNameField();
    this.validateEmailField();
    this.validatePhoneField();
    this.validateArrivalField();
    this.validateDepartureField();
    this.validateBudgetField();
    this.validateSpecialNoteField();
    this.validateTermsAndConditionField();
    return formData;
  };

  /**
     *
     * @param {Array} errorArray
     * @param {String} sortByKey
     * @return {Array}
     */
  getUniqueErrorListBy(errorArray, sortByKey) {
    const uniqueIds = [];
    const unique = errorArray.filter((element) => {
      const isDuplicate = uniqueIds.includes(element[sortByKey]);
      if (!isDuplicate) {
        uniqueIds.push(element[sortByKey]);
        return true;
      }
      return false;
    });
    return unique;
  }


  displayErrorMessages = () => {
    this.errorBlocks[0].style.display = 'flex';
    // this.errorBlocks[1].style.display = 'none';
    const filteredErrors = this.getUniqueErrorListBy(
      this.errorStrings,
      'errorGroup'
    );
    this.errorBlocks?.forEach((block) => {
      block.querySelector('.error-count').innerHTML = this.formErrorCounter;
      let list = '';
      filteredErrors.forEach((item) => {
        list += `<li><a target="_self" href="#${item.id}" data-component-group="${item.componentGroup}"
           data-error-group="${item.errorGroup}" class="ui-body-01">${item.message}</a></li>`;
      });
      block.querySelector('ul').innerHTML = list;
      block.querySelector('ul li a')?.focus();
    });
    const errorBlockHeight = this.errorBlocks[0]?.offsetHeight;
    this.rootStyle.style.setProperty(
      '--errorHeight',
      `${errorBlockHeight + 32}px`
    );
  };

  countryCodeapiCall() {
    const posHanlder = JSON.parse(document.body?.dataset?.posHandler);
    const { key } = JSON.parse(document.body?.dataset?.posHandler);
    const url = `${posHanlder.apiUrlCountries}`;
    new CoreJS.XHRPromisifiedRequest(url, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)
      .setRequestHeaders({ apikey: key })
      .executeRequest().then((response) => {
        const responseJson = JSON.parse(response.responseText);
        responseJson.sort((a, b) => (a.name > b.name) ? 1 : -1);
        responseJson.forEach((user) => {
          let markup;
          if (user.name === 'Australia' || user.name === 'Australie') {
            markup = `<option selected value=+${user.callingCodes}>${user.name} (+${user.callingCodes})</option>`;
          } else {
            markup = `<option value=+${user.callingCodes}>${user.name} (+${user.callingCodes})</option>`;
          }
          this.componentHost.querySelector('select').insertAdjacentHTML('beforeend', markup);
        });
        this.selectDropdown();
      })
    // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  }

  selectDropdown() {
    this.selectedCountrycode = this.countrycode.options[this.countrycode.selectedIndex].value;
    this.countrycode.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
      this.selectedCountrycode = event.target.value;
    });
  }

  /**
     * Form submit handler
     * @param {SubmitEvent} event
     */
  formSubmit(event) {
    event.preventDefault();
    this.errorResetHanlder();
    this.surfaceControlCheck();

    if (this.formErrorCounter !== 0) {
      this.displayErrorMessages();
    } else {
      /**
             * @return {Object}
             */
      const sendEmailObject = {
        'Name': this.name.value,
        'E-mail': this.email.value,
        // eslint-disable-next-line prefer-template
        'Phone': this.selectedCountrycode + ' ' + this.phone.value,
        'Company': this.company.value,
        'Preferred Property': this.selectedValue,
        'Arrival Date': this.arrival.value,
        'Departure Date': this.departure.value,
        'Apartment Type': this.selectedRadio,
        'Budget': this.budget.value,
        'Number Of Guests': this.guest.value,
        'Notes or special request': this.specialNote.value
      };

      this.submitButtonSpan.innerHTML = '';
      this.submitButtonSpan?.classList?.add('icon-loader');
      this.submitButtonSpan?.parentElement?.classList?.add('loader');

      const url = `/content/sling/servlets/mantra/send-mail.sendMail.json?sendMail=${JSON.stringify(sendEmailObject)}`;
      new CoreJS.XHRPromisifiedRequest(url, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)
        .setRequestHeaders({ 'Content-Type': 'application/json' })
        .executeRequest()
        .then((response) => {
          const resTxt = JSON.parse(response.responseText);
          window.open(resTxt.redirectUrl, '_self');
        })
        .catch(() => {
          window.open(`${window.origin}${LongStayForm.ERROR_URL}`, '_self');
        });
    }
  }

  bindFormEvents() {
    this.componentHost
      .querySelector('form')
      ?.addEventListener(CoreJS.DomEventConstants.SUBMIT, (event) => {
        this.formSubmit(event);
      });
  }
}

CoreJS.BaseComponent.registerComponent(
  LongStayForm.CLASS_NAMESPACE,
  LongStayForm
);
