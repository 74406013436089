import axios from 'axios'
import bearerTokenInterceptor from './request-interceptor/bearer-token-interceptor'
import apiKeyInterceptor from './request-interceptor/api-key-interceptor'
import fieldsInterceptor from './request-interceptor/fields-interceptor'
import filterInterceptor from './request-interceptor/filter-interceptor'
import traceidInterceptor from './request-interceptor/traceid-interceptor'
import originInterceptor from './request-interceptor/origin-interceptor'
import callerInterceptor from './request-interceptor/caller-interceptor'

import { API_ACCOR_COM } from '../common/constants'

const axiosAllAccorApi = axios.create({
  baseURL: API_ACCOR_COM,
})

bearerTokenInterceptor(axiosAllAccorApi)
apiKeyInterceptor(axiosAllAccorApi)
fieldsInterceptor(axiosAllAccorApi)
filterInterceptor(axiosAllAccorApi)
traceidInterceptor(axiosAllAccorApi)
originInterceptor(axiosAllAccorApi)
callerInterceptor(axiosAllAccorApi)

export default axiosAllAccorApi
