/**
 *  *
 * @author Gunasundari
 * @class List-V2
 * @classdesc Ace List component to inherit the base component
 * @inheritDoc
 */
export default class ListV2 extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-list-v2-component';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();
    this.showItemsButton = this.componentHost.querySelector('.ace-list__button');
    this.upDownArrow = this.componentHost.querySelector('.ace-list__button--icon');
    if (this.showItemsButton) {
      this.showItemsButton.classList.remove('see-more');
    }
    this.showItems = this.componentHost.querySelector('.ace-list__button--title');
    this.fullItems = this.componentHost.querySelectorAll('.ace-list-default__item--list .ace-list-default__item--display').length;
    this.elementList = [...this.componentHost.querySelectorAll('.ace-list-default__item--list .ace-list-default__item--display ')];
    this.refreshListV2();
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, this.onWindowResize.bind(this));
  }

  /**
       * Refreshing a list
       */
  refreshListV2() {
    if (this.componentHost.querySelector('.ace-list-default__item--list.column-1')) {
      if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        this.currentItems = 3;
      }
    }

    if (this.componentHost.querySelector('.ace-list-default__item--list.column-2')) {
      if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.currentItems = 6;
      }
    }

    if (this.componentHost.querySelector('.ace-list-default__item--list.column-3')) {
      if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.currentItems = 9;
      }
    }

    if (this.componentHost.querySelector('.ace-list-default__item--list.column-4')) {
      if (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.currentItems = 12;
      }
    }

    if (!(this.componentHost.querySelector('.ace-list-default__item--list.column-1')) && (window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) &&
    (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg)) {
      this.currentItems = 6;
    } else if ((window.screen.width >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.sm) && (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg)) {
      this.currentItems = 3;
    }
    this.showHideButtonV2(this.currentItems);
  }

  /**
       * Function for show or hide a button
       * @param {*} currentItems
       */
  showHideButtonV2(currentItems) {
    this.newItems = this.fullItems - currentItems;
    if (this.newItems > 0) {
      this.showItemsButton.classList.add('see-more');
      this.showItems.innerHTML = `${this.showItems.dataset.showMoreLabel} (${this.newItems})`;
      this.showItemsButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.executeButtonV2();
      });
    }
  }

  // Executing a button action
  executeButtonV2() {
    for (let i = this.currentItems; i < this.fullItems; i++) {
      if (this.elementList[i].style.display === 'flex') {
        this.elementList[i].style.display = 'none';
        this.showItems.innerHTML = `${this.showItems.dataset.showMoreLabel} (${this.newItems})`;
        this.upDownArrow.classList.remove('icon-chevron-up-24icon');
        this.upDownArrow.classList.add('icon-chevron-down-24icon');
        this.upDownArrow?.setAttribute('aria-hidden', 'true');
        this.showItemsButton?.setAttribute('aria-expanded', 'false');
      } else {
        this.elementList[i].style.display = 'flex';
        this.showItems.innerHTML = `${this.showItems.dataset.showLessLabel} (${this.newItems}) `;
        this.upDownArrow.classList.remove('icon-chevron-down-24icon');
        this.upDownArrow.classList.add('icon-chevron-up-24icon');
        this.upDownArrow?.setAttribute('aria-hidden', 'false');
        this.showItemsButton?.setAttribute('aria-expanded', 'true');
      }
    }
  }

  /**
       * Handling window resize events
       * Show or hide button based on current items
       */
  onWindowResize() {
    this.showHideButtonV2(this.currentItems);
    this.refreshListV2();
  }
}

// Register the List component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(ListV2.CLASS_NAMESPACE, ListV2);

