export { setToken, Error }

import {
  getJwtPayload,
  setAuthCookie,
  setTokenCookie,
  deleteAuthCookies,
  setDisconnectCookie,
} from './shared.js'
import axiosAuthApi from '../http/axiosAuthApi'
import { Enum } from '../common/utils.js'
import { LOGIN_URL } from '../common/constants.js'

const Error = Enum('SetTokenFailed')

const setToken = (options = {}) => {
  deleteAuthCookies()

  return axiosAuthApi
    .post(LOGIN_URL, null, { ...options })
    .then(({ data, data: { access_token } }) => {
      if (!access_token) setDisconnectCookie()
      else {
        setTokenCookie(access_token)
        const { pmid } = getJwtPayload(access_token)
        setAuthCookie(pmid)
      }
      return data
    })
    .catch(() => {
      throw Error.SetTokenFailed
    })
}
