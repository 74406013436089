export { get as getUser, put as putUser, post as createUser } from './user'
export {
  EnrollMode,
  getAuthUrl,
  getToken,
  setToken,
  refreshToken,
  logout,
  getRegisterFormUrl,
  getRegisterWithContextUrl,
  getNewRegisterUrl,
} from './oidc'
export { getContact, putContact, deleteContactItem } from './contact'
export * from './validators'
export * from './russia'
export * from './programs/all-signature'
export * from './programs/allplus'
export { getReferentials } from './referentials'

export { Error }

export { isMobile, isIE, isWebView } from './common/device.js'
export { getLang, LANG_FORMAT_LOCALIZE } from './common/lang.js'
export { getCountry } from './common/countries.js'
export {
  getEnv,
  Environment,
  generateTraceId,
  getUrlSearchParams,
  attachQueryParamsToString,
} from './common/utils.js'

import { combineObjects } from './common/utils.js'
import { Error as OidcError } from './oidc'
import { Error as UserError } from './user'

const Error = combineObjects(OidcError, UserError)

import { getToken, getAuthUrl, refreshToken, logout } from './oidc'
import { getLatamAuthUrl } from './programs/all-signature'
import { getSubscriptionFunnelUrl } from './programs/allplus'
import { getTSMAuthUrl } from './programs/tsm'

window &&
  (window.$accor ||= {
    getLatamAuthUrl,
    getSubscriptionFunnelUrl,
    getTSMAuthUrl,
    refreshToken,
    getAuthUrl,
    getToken,
    logout,
  })
