/* eslint-disable no-unused-expressions */
/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */

import TagManager from '@accor/core-services/dist/gtm/index';

export default class Heading extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-heading-component';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initializing Function
  initialize() {
    super.initialize();
    this.headingNoImage();
    this.headingDropdownButton = this.componentHost?.querySelector('.ace-heading-dropdown__button');
    this.headingOpenIcon = this.componentHost?.querySelector('.ace-heading-dropdown__icon');
    this.headingSingleBtn = this.componentHost?.querySelector('.ace-headingpagehero-headline .cmp-button.cmp-button--primary.btn-inverse');
    this.headingDropdownButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.headingDropdownButton.getAttribute('aria-expanded') === 'true' ?
        this.closeHeadingDropdown(this.headingDropdownButton) : this.openHeadingDropdown(this.headingDropdownButton);
    });

    this.headingDropdownListItem = this.componentHost?.querySelectorAll('.ace-heading-dropdown__item-label');
    this.headingDropdownListItem?.forEach((element) => {
      if (element.innerText === this.headingDropdownButton.firstElementChild.innerText) {
        element.parentElement.setAttribute('aria-current', 'true' );
        element.parentElement.previousElementSibling.classList.add('active');
      }
    });
    this.listItem = this.componentHost?.querySelectorAll('.ace-heading-dropdown__list-item');
    this.listItem.forEach((item, index, array)=>{
      if (index === array?.length - 1) {
        item.lastElementChild.addEventListener(CoreJS.DomEventConstants.BLUR, () => {
          this.closeHeadingDropdown(this.headingDropdownButton);
        });
      }
    });
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.PageName = this.datalayerValues.pageName.split('::');
    if (this.PageName[1] == 'all signature') {
      this.latamTracking();
    }
  }


  latamTracking() {
    this.headingSingleBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'cta_enroll',
        event_data: {
          pagename: this.datalayerValues.pageName,
          enroll_context: 'all signature',
          cta_name: 'join us'
        }
      };
      window.dataLayer.push({
        'event_data': null
      });
      TagManager.trackEvent(datasEvent);
    }
    );
  }

  // close heading dropdown
  closeHeadingDropdown(button) {
    const addZindexToHeading = this.componentHost?.querySelector('.ace-headingpagehero__content');
    addZindexToHeading?.classList.remove('heading-drop-active');
    button?.setAttribute('aria-expanded', 'false');
    button?.parentElement.nextElementSibling.classList.remove('active');
    button?.parentElement.classList.remove('heading-drop-show');
    button?.parentElement.nextElementSibling.setAttribute('aria-hidden', 'true');
    this.headingOpenIcon?.classList.add('icon-chevron-down-24icon');
    this.headingOpenIcon?.classList.remove('icon-chevron-up-24icon');
    window.removeEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideHeadingDropdown(clickEvent));
  }
  // open heading dropdown
  openHeadingDropdown(button) {
    const addZindexToHeading = this.componentHost?.querySelector('.ace-headingpagehero__content');
    addZindexToHeading?.classList.add('heading-drop-active');
    button?.setAttribute('aria-expanded', 'true');
    button?.parentElement.nextElementSibling.classList.add('active');
    button?.parentElement.classList.add('heading-drop-show');
    button?.parentElement.nextElementSibling.setAttribute('aria-hidden', 'false');
    this.headingOpenIcon?.classList.remove('icon-chevron-down-24icon');
    this.headingOpenIcon?.classList.add('icon-chevron-up-24icon');
    window.addEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.windowClickHideHeadingDropdown(clickEvent));

    button?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
      if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
        this.closeHeadingDropdown(button);
      }
      if (e.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.enter) {
        this.closeHeadingDropdown(button);
      }
    });
  }

  // Hide the HeadingDropdown on window click
  windowClickHideHeadingDropdown(event) {
    if (!event.target.closest('.ace-heading-dropdown__button')) {
      this.closeHeadingDropdown(this.headingDropdownButton);
    }
  }
  // Method for Heading without image
  headingNoImage() {
    const noImage = this.componentHost.querySelector('.headingno-image');
    if (noImage) {
      this.componentHost.closest('.heading').classList.add('heading-override');
    }
  }
}

// Register the Heading component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Heading.CLASS_NAMESPACE, Heading);
