import { BasePageTemplate, TagManager } from '@accor/ace-ui-core';

export default class BrandCorner extends BasePageTemplate {
  static CLASS_NAMESPACE = 'brand-corner-index-page-template';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.brandCornerDeatil = this.componentHost.classList.contains('brand-corner-detail-page-template');
    const fileNameWithExt = new URL(window.location.href).pathname.split('/').pop();
    const fileNameWithOutExt = fileNameWithExt.split(".")[0];
    if (this.brandCornerDeatil) {
      this.brandCornerDetail(fileNameWithOutExt);
    }
  }

  /*
      Function to tagmanager for brandCorner
    */
  brandCornerDetail() {
    let dataLayerJson = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');

    if (dataLayerJson) {
      dataLayerJson = JSON.parse(dataLayerJson);
    }

    const portfolioBlock = () => {
      const lastIndex = dataLayerJson.pageName.lastIndexOf('::');
      const pagename = dataLayerJson.pageName.substring(0, lastIndex);
      const urlBrandName = window.location.pathname?.split('/')?.filter(Boolean).pop()?.split('.html')[0];

      const brandItems = this.componentHost.querySelectorAll('.brandcorner_portfolio .brands__cards .brands__item');
      brandItems?.forEach((item) => {
        const brand = item.getAttribute('data-brand');
        const link = item.querySelector('.brands__item-link');
        const url = link?.getAttribute('href');

        if (link) {
          link.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            event.preventDefault();

            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${pagename || ''}::${urlBrandName || ''}`,
                bloc_name: 'brand block',
                bloc_interaction: 'discover brand',
                bloc_type: `${brand || ''}`
              }
            };
            window.dataLayer.push({
              'event_data': null
            });
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEvent);

            window.open(url)
          })
        }
      })
    }
    portfolioBlock();
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, BrandCorner, true);
